import { useFormContext } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React, { useEffect } from 'react';
import WebsiteLink from '../website-link/websiteLink';
import formValidationRules from '../../utils/formValidationRules';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { Box } from '@chakra-ui/react';

const { required } = formValidationRules;

const MicrosoftTeamsOptionsFormFields = () => {
  const { control, unregister } = useFormContext();

  const webhookUrlFieldPath = `microsoftTeamsOptions.webhookUrl`;

  useEffect(() => {
    return () => {
      unregister(webhookUrlFieldPath);
    };
  }, []);

  return (
    <FormFieldWrapper
      title="Webhook URL"
      description={
        <Box>
          <Box display="inline-block">
            Use{' '}
            <WebsiteLink
              href="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"
              target="_blank"
            >
              this guide
            </WebsiteLink>
          </Box>{' '}
          to generate Microsoft Teams Webhook URL
        </Box>
      }
    >
      <ControlledInput name={webhookUrlFieldPath} control={control} placeholder="Webhook URL" rules={{ required }} />
    </FormFieldWrapper>
  );
};

export default MicrosoftTeamsOptionsFormFields;
