import React, { useEffect, useState } from 'react';
import { operationWrapper } from '../utils/operationWrapper';
import statusPingClient from '../app/client/statusPingClient';
import { toast } from 'react-hot-toast';
import { dismissibleToast } from '../utils/dismissibleToast';
import { Box, Flex, Image } from '@chakra-ui/react';
import UserNotificationSettingsForm from '../components/user-notification-settings-form/userNotificationSettingsForm';
import Spinner from '../components/spinner/spinner';
import * as queryString from 'query-string';

const UserNotificationSettingsPage = () => {
  const [initialized, setInitialized] = useState(false);
  const [userSettings, setUserSettings] = useState(null);
  const parsed = queryString.parse(location.search);
  const token = parsed.token as string;
  const action = parsed.action as string;
  const list = parsed.list as string;

  useEffect(() => {
    const fetchUserSettings = operationWrapper(
      async () => {
        if (action === 'unsubscribe' && ['subscribedToWeeklyMonitorReport', 'subscribedToProductNewsletter'].includes(list)) {
          setUserSettings(
            await statusPingClient.protectedPatchUserSettings(
              {
                [list]: false,
              },
              token
            )
          );

          toast.success('Notification settings updated');
        } else {
          setUserSettings(await statusPingClient.protectedGetUserSettings(token));
        }

        setInitialized(true);
      },
      {
        errorCallback: (e) => {
          toast.error(dismissibleToast('Error loading notification settings. Please try again later'), { duration: 99999999 });
        },
      }
    );

    if (token) {
      fetchUserSettings();
    }
  }, [token, action, list]);

  return (
    <Flex flexDirection="column" gap={4} alignItems="center" justifyContent="center" py={8}>
      <Image maxHeight="64px" src="/logo.png" />
      <Box fontSize="3xl" fontWeight="bold">
        Notification settings
      </Box>
      {!initialized && <Spinner />}
      {initialized && <UserNotificationSettingsForm userSettings={userSettings} />}
    </Flex>
  );
};

export default UserNotificationSettingsPage;
