"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvaluateStatusPageStatusMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class EvaluateStatusPageStatusMessage extends workerMessage_1.WorkerMessage {
    constructor(statusPageId) {
        super(model_1.QueueDefinitions.EVALUATE_STATUS_PAGE_STATUS);
        this.statusPageId = statusPageId;
    }
    getRoutingKey() {
        return this.statusPageId;
    }
}
exports.EvaluateStatusPageStatusMessage = EvaluateStatusPageStatusMessage;
