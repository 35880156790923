import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpgradeModalReason } from '../model/upgradeModalReason';
import * as amplitude from '@amplitude/analytics-browser';
import trackEvent from '../../utils/trackEvent';

type SliceType = {
  showUpgradeModal: boolean;
  reason: UpgradeModalReason;
};

const initialState: SliceType = {
  showUpgradeModal: false,
  reason: null,
};

export const upgrade = createSlice({
  name: 'upgrade',
  initialState,
  reducers: {
    reset: () => initialState,
    showUpgradeModal: (state, action: PayloadAction<UpgradeModalReason>) => {
      state.reason = action.payload;
      state.showUpgradeModal = true;

      trackEvent('Upgrade Modal Displayed', {
        reason: action.payload,
      });

      return state;
    },
    closeUpgradeModal: (state) => {
      state.showUpgradeModal = false;
      return state;
    },
  },
});

export default upgrade.reducer;
