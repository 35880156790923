"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorUpdatedApplicationEvent = void 0;
const applicationEvent_1 = require("./applicationEvent");
class MonitorUpdatedApplicationEvent extends applicationEvent_1.ApplicationEvent {
    constructor(monitorId, organizationId) {
        super(false, organizationId);
        this.monitorId = monitorId;
        this.organizationId = organizationId;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.MONITOR_UPDATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.MONITOR_UPDATED;
    }
}
exports.MonitorUpdatedApplicationEvent = MonitorUpdatedApplicationEvent;
