import { useFormContext } from 'react-hook-form';
import { getFormErrorMessage } from '../../utils/getFormErrorMessage';
import React, { useEffect, useMemo, useState } from 'react';
import { FormSelect } from '../form-controller/formController';
import formValidationRules from '../../utils/formValidationRules';
import MultipleNotificationChannelSelector from '../multiple-notification-channel-selector/multipleNotificationChannelSelector';
import { Box } from '@chakra-ui/react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { MonitorFormModel } from '../../models/monitorFormModel';

const NotificationChannelsFormFields: React.FC = () => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<MonitorFormModel>();

  const notificationChannelsFieldPath = `notificationChannels`;
  const { notificationChannels } = getValues();
  const [all, setAll] = useState(notificationChannels?.length < 1);

  useEffect(() => {
    if (notificationChannels.length < 1) {
      setAll(true);
    }
  }, [notificationChannels]);

  const rules = useMemo(
    () => ({
      validate: formValidationRules.conditionalRequired(!all),
    }),
    [all]
  );

  return (
    <FormFieldWrapper
      title="Notification channels"
      description="Pick notification channels that you want to be notified through when this monitor fails"
    >
      <Box>
        <FormSelect name={notificationChannelsFieldPath} rules={rules} control={control}>
          {(field) => (
            <MultipleNotificationChannelSelector
              disabled={all}
              placeholder="Notification integrations"
              error={getFormErrorMessage(errors, notificationChannelsFieldPath)}
              {...field}
            />
          )}
        </FormSelect>
      </Box>
    </FormFieldWrapper>
  );
};

export default NotificationChannelsFormFields;
