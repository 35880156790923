"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifySSLCertificateExpirationMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class NotifySSLCertificateExpirationMessage extends workerMessage_1.WorkerMessage {
    constructor(monitorId, monitorCheckId) {
        super(model_1.QueueDefinitions.NOTIFY_SSL_CERTIFICATE_EXPIRATION_MESSAGE);
        this.monitorId = monitorId;
        this.monitorCheckId = monitorCheckId;
    }
}
exports.NotifySSLCertificateExpirationMessage = NotifySSLCertificateExpirationMessage;
