"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CleanupOldMonitorChecksMessage = void 0;
const genericTaskMessage_1 = require("./genericTaskMessage");
class CleanupOldMonitorChecksMessage extends genericTaskMessage_1.GenericTaskMessage {
    constructor() {
        super(genericTaskMessage_1.GenericTaskMessageType.CLEANUP_OLD_MONITOR_CHECKS);
    }
}
exports.CleanupOldMonitorChecksMessage = CleanupOldMonitorChecksMessage;
