import React, { lazy, Suspense, useCallback, useState } from 'react';
import { StatusPageModelVO } from '@um/uptime-monitoring-shared';
import { useStatusPageMutation } from '../../query/statusPageQueries';
import { StatusPageFormModel } from '../status-page-form/statusPageFormModel';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';
import { dismissibleToast } from '../../utils/dismissibleToast';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import Spinner from '../spinner/spinner';

const StatusPageForm = lazy(() => import('../status-page-form/statusPageForm'));

export type CreateStatusPageFormProps = {
  onCreated?: (statusPage?: StatusPageModelVO) => any;
  onError?: (err?: any) => any;
};

const CreateStatusPageForm = ({ onCreated, onError }: CreateStatusPageFormProps) => {
  const { isLoading: mutationLoading, mutateAsync } = useStatusPageMutation();
  const [formErrors, setFormErrors] = useState([]);
  const loading = mutationLoading;
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  const handleSubmit = useCallback(async (data: StatusPageFormModel) => {
    const finalStatusPage: StatusPageFormModel = {
      ...data,
      organizationId,
      sections: (data.sections || []).map((section, idx) => ({
        ...section,
        order: idx + 1,
        entries: (section.entries || []).map(({ monitorId, monitorGroupId, ...entry }, entryIdx) => ({
          ...entry,
          ...(monitorId ? { monitorId } : {}),
          ...(monitorGroupId ? { monitorGroupId } : {}),
          order: entryIdx + 1,
        })),
      })),
    };

    await mutateAsync(
      { createRequest: finalStatusPage, action: MutationAction.POST },
      {
        onSuccess: (data: StatusPageModelVO) => {
          toast.success('Status page created');
          onCreated && onCreated(data);
        },
        onError: (error: any) => {
          const errorResponse = error?.response?.data;
          if (errorResponse && errorResponse.errorType === 'VALIDATION_FAILED') {
            toast.error(dismissibleToast(errorResponse.message || 'Please fix errors in the form'), {
              duration: 10000,
            });

            setFormErrors(errorResponse.errors);
          } else {
            toast.error('Error occurred. Please try again later');
          }
        },
      }
    );
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <StatusPageForm mainActionText="Create" disabled={loading} formErrors={formErrors} onSubmit={handleSubmit} />
    </Suspense>
  );
};

export default CreateStatusPageForm;
