import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { OnCallDuty } from '@um/uptime-monitoring-shared';
import { QueryResultWithHash, toQueryResultWithHash } from '../models/queryResultWithHash';

export const useOrganizationOnCallDutiesQuery = (organizationId: string, options: UseQueryOptions<QueryResultWithHash<OnCallDuty[]>> = {}) => {
  return useQuery(
    ['onCallDuty', 'organization', organizationId],
    () => toQueryResultWithHash(statusPingClient.protectedGetOnCallDuties(organizationId)),
    {
      enabled: Boolean(organizationId),
      keepPreviousData: true,
      refetchOnMount: true,
      ...options,
    }
  );
};
