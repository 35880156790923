import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import monitors from './reducer/monitors';
import monitorDetails from './reducer/monitorDetails';
import editMonitor from './reducer/editMonitor';
import monitorCheckDetails from './reducer/monitorCheckDetails';
import app from './reducer/app';
import createIntegration from './reducer/createIntegration';
import createMonitor from './reducer/createMonitor';
import monitorOverviewTab from './reducer/monitorOverviewTab';
import monitorResponseStatistics from './reducer/monitorResponseStatistics';
import notificationsOverviewTab from './reducer/notificationsOverviewTab';
import upgrade from './reducer/upgrade';
import websocket from './reducer/websocket';
import rootSaga from './saga';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const store = configureStore({
  reducer: {
    app,
    monitors,
    monitorDetails,
    monitorCheckDetails,
    createIntegration,
    createMonitor,
    editMonitor,
    monitorOverviewTab,
    websocket,
    monitorResponseStatistics,
    notificationsOverviewTab,
    upgrade,
  },
  middleware,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
