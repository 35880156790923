import { Box, BoxProps, Button, Flex, Icon } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { BsPauseCircleFill, BsPlayCircleFill, BsTrash2Fill } from 'react-icons/bs';
import { useBatchMonitorsMutation } from '../../query/monitorQueries';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { MutationAction } from '../../query/mutationAction';
import DeleteConfirmationModal from '../delete-confirmation-modal/deleteConfirmationModal';
import ConfirmationModal from '../confirmation-modal/confirmationModal';

type Props = BoxProps & {
  selectedMonitorIds?: string[];
  onActionPerformed?: () => void;
};

const MonitorPageHeader: React.FC<Props> = ({ selectedMonitorIds, onActionPerformed, ...rest }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const onSettled = useCallback(() => {
    if (onActionPerformed) {
      onActionPerformed();
    }

    setShowDeleteModal(false);
    setShowResumeModal(false);
    setShowPauseModal(false);
  }, [onActionPerformed, setShowDeleteModal, setShowResumeModal, setShowPauseModal]);
  const { mutateAsync, isLoading } = useBatchMonitorsMutation({ onSettled });

  const disabled = selectedMonitorIds?.length < 1 || isLoading;
  const loading = isLoading;

  return (
    <Box paddingBottom={4} pr={4} {...rest}>
      <Flex borderBottom="2px solid" borderColor="inherit" pb={2} pl={4} gap={2}>
        <Button
          colorScheme="gray"
          isLoading={loading}
          onClick={() => setShowResumeModal(true)}
          isDisabled={disabled}
          variant="outline"
          leftIcon={<Icon as={BsPlayCircleFill} boxSize={4} />}
        >
          Resume
        </Button>
        <Button
          colorScheme="gray"
          onClick={() => setShowPauseModal(true)}
          isLoading={loading}
          isDisabled={disabled}
          variant="outline"
          leftIcon={<Icon as={BsPauseCircleFill} boxSize={4} />}
        >
          Pause
        </Button>
        <Box flexGrow={1} />
        <Button
          colorScheme="red"
          isLoading={loading}
          isDisabled={disabled}
          onClick={() => setShowDeleteModal(true)}
          variant="outline"
          leftIcon={<Icon as={BsTrash2Fill} boxSize={4} />}
        >
          Delete
        </Button>
      </Flex>

      <DeleteConfirmationModal
        title="Delete monitors"
        content={`Are you sure you want to delete ${selectedMonitorIds.length} monitor(s)? This action is irreversible`}
        onConfirm={() => mutateAsync({ organizationId, monitorIds: selectedMonitorIds, action: MutationAction.DELETE })}
        onClose={() => setShowDeleteModal(loading)}
        loading={loading}
        open={showDeleteModal}
      />
      <ConfirmationModal
        title="Resume monitors"
        content={`Are you sure you want to resume ${selectedMonitorIds.length} monitor(s)?`}
        onConfirm={() => mutateAsync({ organizationId, monitorIds: selectedMonitorIds, action: MutationAction.PATCH, enabled: true })}
        onClose={() => setShowResumeModal(loading)}
        loading={loading}
        open={showResumeModal}
      />
      <ConfirmationModal
        title="Pause monitors"
        content={`Are you sure you want to pause ${selectedMonitorIds.length} monitor(s)?`}
        onConfirm={() => mutateAsync({ organizationId, monitorIds: selectedMonitorIds, action: MutationAction.PATCH, enabled: false })}
        onClose={() => setShowPauseModal(loading)}
        loading={loading}
        open={showPauseModal}
      />
    </Box>
  );
};

export default MonitorPageHeader;
