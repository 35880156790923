import { useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import { PushoverPriority } from '@um/uptime-monitoring-shared';
import { ControlledFormSelector } from '../form-select/formSelector';
import { selectOptionWithDescription } from '../../utils/selectOptionWithDescription';
import { components, SingleValueProps } from 'react-select';

const options = [
  selectOptionWithDescription(PushoverPriority.DISABLED, 'Disabled', 'Notifications not sent'),
  selectOptionWithDescription(
    PushoverPriority.LOWEST,
    'Lowest',
    'Messages will not generate any notification. On iOS, the application badge number will be increased.'
  ),
  selectOptionWithDescription(
    PushoverPriority.LOW,
    'Low',
    `Messages will not generate any sound or vibration, but will still generate a popup/scrolling notification depending on the client operating system.`
  ),
  selectOptionWithDescription(
    PushoverPriority.NORMAL,
    'Normal',
    `Messages trigger sound, vibration, and display an alert according to the user's device settings. On iOS, the message will display at the top of the screen or as a modal dialog, as well as in the notification center. On Android, the message will scroll at the top of the screen and appear in the notification center. If a user has quiet hours set and your message is received during those times, your message will be delivered as though it had a Low priority.`
  ),
  selectOptionWithDescription(
    PushoverPriority.HIGH,
    'High',
    `Messages will always play a sound and vibrate (if the user's device is configured to) regardless of the delivery time. High-priority should only be used when necessary and appropriate. High-priority messages are highlighted in red in the device clients.`
  ),
  selectOptionWithDescription(
    PushoverPriority.EMERGENCY,
    'Emergency',
    `Notifications are similar to high-priority notifications, but they are repeated every 5 minutes for 3 hours or until the notification is acknowledged by the user.`
  ),
];
const SingleValue = ({ ...props }: SingleValueProps<any>) => {
  return <components.SingleValue {...props}>{props.data.chipLabel}</components.SingleValue>;
};
const ControlledPushoverPrioritySelector = ({ name, rules = null, disabled = false }) => {
  const { control, watch } = useFormContext();
  const value = watch(name);
  const selectedOption = useMemo(() => options.find((option) => option.value === value), [options, value]);

  return (
    <ControlledFormSelector
      control={control}
      name={name}
      options={options}
      value={selectedOption}
      transformOption={(option) => option.value}
      components={{ SingleValue }}
      isDisabled={disabled}
      rules={rules}
    />
  );
};

export default ControlledPushoverPrioritySelector;
