import { useMemo, useState } from 'react';
import { UserResponse, UserSettingsResponse } from '@um/uptime-monitoring-shared';
import UserProfileForm, { UserProfileFormModel } from './userProfileForm';
import { useUserProfileMutation } from '../../query/userQueries';
import toast from 'react-hot-toast';
import mutationDefaults from '../../query/mutationDefaults';
import { MutationAction } from '../../query/mutationAction';
import useDefaultUserTimezone from '../../hooks/useDefaultUserTimezone';

export type UpdateUserSettingsFormProps = {
  user: UserResponse;
  userSettings: UserSettingsResponse;
  onUpdated?: (project?: UserResponse) => any;
};

const UpdateUserProfileForm = ({ user, userSettings, onUpdated }: UpdateUserSettingsFormProps) => {
  const [formErrors, setFormErrors] = useState([]);
  const defaultTimezone = useDefaultUserTimezone();

  const { isLoading, mutateAsync } = useUserProfileMutation({
    onSuccess: (data) => {
      toast.success('User settings updated');
      onUpdated && onUpdated(data);
    },
    onError: mutationDefaults.onErrorWithFormHandling(setFormErrors),
  });

  const handleSubmit = async (data: UserProfileFormModel) => {
    await mutateAsync({ updateRequest: data, action: MutationAction.PATCH });
  };

  const formValues: UserProfileFormModel = useMemo(
    () => ({
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      timezone: user.timezone || defaultTimezone?.name,
      subscribedToProductNewsletter: userSettings.subscribedToProductNewsletter,
      subscribedToWeeklyMonitorReport: userSettings.subscribedToWeeklyMonitorReport,
    }),
    []
  );

  return (
    <UserProfileForm
      formValues={formValues}
      formErrors={formErrors}
      mainActionText="Update settings"
      disabled={isLoading}
      loading={isLoading}
      onSubmit={handleSubmit}
    />
  );
};

export default UpdateUserProfileForm;
