"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationIntegrationUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class NotificationIntegrationUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(originalItem, updatedItem) {
        super(model_1.QueueDefinitions.NOTIFICATION_INTEGRATION_UPDATED);
        this.originalItem = originalItem;
        this.updatedItem = updatedItem;
    }
}
exports.NotificationIntegrationUpdatedMessage = NotificationIntegrationUpdatedMessage;
