import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { OrganizationUserResponse } from '@um/uptime-monitoring-shared';
import { operationWrapper } from '../../utils/operationWrapper';
import statusPingClient from '../../app/client/statusPingClient';
import toast from 'react-hot-toast';
import { logError } from '../../utils/logError';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';

type Props = {
  open: boolean;
  onClose: () => void;
  onRemoved: () => void;
  user: OrganizationUserResponse;
};

const RemoveUserAccessDialog: React.FC<Props> = ({ open, onClose, onRemoved, user }) => {
  const [loading, setLoading] = useState(false);
  const cancelRef = useRef();
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  const onDeleteUserAccess = () => {
    const deleteUser = operationWrapper(
      async () => {
        setLoading(true);

        await statusPingClient.protectedDeleteOrganizationUser(organizationId, user.id);
      },
      {
        successCallback: () => {
          toast.success(`${user.email} has been removed from the organization`);
          onRemoved();
        },
        errorCallback: (error) => {
          const errorMessage = error?.response?.data?.message;

          toast.error(errorMessage || 'Error occurred. Please try again later');
          logError(error);
        },
        finallyCallback: () => {
          setLoading(false);
        },
      }
    );

    deleteUser();
  };

  return (
    <AlertDialog isOpen={open} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remove user access
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to remove{' '}
            <Text fontWeight="bold">
              {user?.firstName ?? '-'} ({user?.email})
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} disabled={loading} isLoading={loading}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onDeleteUserAccess} ml={3} disabled={loading} isLoading={loading}>
              Remove
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default RemoveUserAccessDialog;
