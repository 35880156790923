import MonitorCheckStatusIndicator from '../indicator/monitorCheckStatusIndicator';
import Routes from '../../utils/routes';
import React from 'react';
import { DurationFormat } from '../../utils/durationFormat';
import { generatePath, Link } from 'react-router-dom';
import { Box, Flex, Skeleton, Tooltip } from '@chakra-ui/react';
import useRunningDuration from '../../hooks/useRunningDuration';
import { useMonitorLatestChecks } from '../../query/queries';

type Props = { monitorId: string };
const indexes = Array.from(Array(15).keys());

const MonitorCheckList = ({ monitorId }: Props) => {
  const { isFetched, data: monitorChecks } = useMonitorLatestChecks(monitorId);

  return (
    <Flex gap={2} alignItems="center" gridTemplateColumns="fit-content(100%) auto">
      {!isFetched && <CheckPlaceholder />}
      {isFetched &&
        indexes.map((idx) => {
          const monitorCheck = monitorChecks.length > idx ? monitorChecks[idx] : null;

          if (!monitorCheck) {
            return <MonitorCheckStatusIndicator key={idx} width={5} height={10} />;
          }

          const href = generatePath(Routes.MONITOR_CHECK_DETAILS, { id: monitorCheck.id });

          return (
            <Tooltip key={monitorCheck.id} hasArrow placement="bottom" label={<DurationPopupContent monitorCheck={monitorCheck} />}>
              <Box as={Link} to={href} cursor="pointer">
                <MonitorCheckStatusIndicator width={5} height={10} status={monitorCheck.status} cursor="pointer" />
              </Box>
            </Tooltip>
          );
        })}
    </Flex>
  );
};

const DurationPopupContent = ({ monitorCheck }) => {
  const [, formattedDuration] = useRunningDuration(monitorCheck.createdAt, DurationFormat.DHMS);

  return <Box>Checked {formattedDuration} ago</Box>;
};

const CheckPlaceholder = () => <Skeleton h={10} w={96} />;

export default MonitorCheckList;
