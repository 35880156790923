import { useCallback, useEffect, useState } from 'react';
import { duration } from '../utils/duration';
import { formatDuration } from 'date-fns';
import { DurationFormat } from '../utils/durationFormat';

const useRunningDuration = (initialDate, format = DurationFormat.DHM, endDate = null) => {
  const [elapsedDuration, setElapsedDuration] = useState(null);
  const [elapsedMs, setElapsedMs] = useState(null);

  const updateElapsedDuration = useCallback(() => {
    if (initialDate) {
      const now = new Date();
      const initial = new Date(initialDate);

      setElapsedDuration(duration(initial, endDate || now));
      setElapsedMs((endDate?.getTime() || now.getTime()) - initial.getTime());
    }
  }, [initialDate]);

  useEffect(() => {
    updateElapsedDuration();

    if (endDate) {
      return;
    }

    const interval = setInterval(updateElapsedDuration, 1000);
    return () => clearInterval(interval);
  }, [updateElapsedDuration, endDate]);

  if (!initialDate || !elapsedDuration) {
    return [null, null, null];
  }

  const formattedDuration = formatDuration(elapsedDuration, {
    format,
  });

  return [elapsedDuration, formattedDuration, elapsedMs];
};

export default useRunningDuration;
