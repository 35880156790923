"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageIncidentResourceCreatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class StatusPageIncidentResourceCreatedMessage extends workerMessage_1.WorkerMessage {
    constructor(statusPageIncidentResource) {
        super(model_1.QueueDefinitions.STATUS_PAGE_INCIDENT_RESOURCE_CREATED);
        this.statusPageIncidentResource = statusPageIncidentResource;
    }
}
exports.StatusPageIncidentResourceCreatedMessage = StatusPageIncidentResourceCreatedMessage;
