import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { RootState } from '../../store';
import orderBy from 'lodash.orderby';
import { logError } from '../../../utils/logError';

function* initialize(action) {
  try {
    yield put(actions.monitorOverviewTab.setInitialized(false));

    const monitorId = action.payload;

    const [monitor, downtimeStatistics] = yield all([
      call(statusPingClient.protectedGetMonitor, monitorId),
      call(statusPingClient.protectedGetStatistics, { monitorId }),
    ]);
    const monitorDowntimeStatistics = (downtimeStatistics || []).length > 0 ? downtimeStatistics[0] : null;

    yield put(actions.monitorOverviewTab.setMonitor(monitor));
    yield put(actions.monitorOverviewTab.setMonitorDowntimeStatistics(monitorDowntimeStatistics));

    yield put(actions.monitorOverviewTab.setInitialized(true));
  } catch (e) {
    logError(e);
  }
}

export function* loadResponseTimeStatistics(action) {
  try {
    const monitorId = action.payload;

    const monitor = yield call(statusPingClient.protectedGetPeriodicMonitorResponseTimeStatistics, { monitorId });

    yield put(actions.monitorOverviewTab.setResponseTimeStatistics(monitor));
  } catch (e) {
    logError(e);
  }
}

export function* loadDowntimePeriodicStatistics(action) {
  try {
    const monitorId = action.payload;

    const downtimePeriodicStatistics = yield call(statusPingClient.protectedGetPeriodicMonitorDowntimeStatistics, { monitorId });

    yield put(actions.monitorOverviewTab.setDowntimePeriodicStatistics(downtimePeriodicStatistics));
  } catch (e) {
    logError(e);
  }
}

function* onMonitorCheckChangedEvent(action) {
  try {
    const { initialized, monitor, monitorExecutions: originalMonitorExecutions } = yield select((state: RootState) => state.monitorOverviewTab);

    if (!initialized) {
      return;
    }

    const monitorExecutions = [...originalMonitorExecutions];
    const monitorExecution = action.payload;

    if (monitor.id !== monitorExecution.monitorId) {
      return;
    }

    const idx = monitorExecutions.findIndex((item) => item.id === monitorExecution.id);

    if (idx !== -1) {
      const existingItem = monitorExecutions[idx];

      if (existingItem.updatedAt < monitorExecution.updatedAt) {
        monitorExecutions[idx] = monitorExecution;
      }
    } else {
      monitorExecutions.push(monitorExecution);
    }

    const orderedMonitorExecutions = orderBy(monitorExecutions, ['createdAt'], ['asc']);
    const finalMonitorExecutions = orderedMonitorExecutions.slice(-15);

    // yield put(actions.monitorOverviewTab.setMonitorExecutions(finalMonitorExecutions));
  } catch (e) {
    logError(e);
  }
}

function* onMonitorUpdatedEvent(action) {
  try {
    const { initialized, monitor: originalMonitor } = yield select((state: RootState) => state.monitorOverviewTab);
    const monitor = action.payload;

    if (!initialized || monitor.id !== originalMonitor?.id) {
      return;
    }

    if (monitor.updatedAt > originalMonitor.updatedAt) {
      yield put(actions.monitorOverviewTab.setMonitor(monitor));
    }
  } catch (e) {
    logError(e);
  }
}

export default function* monitorOverviewTabSaga() {
  yield all([
    // takeEvery(actions.websocket.onMonitorCheckCreatedEvent.type, onMonitorCheckChangedEvent),
    // takeEvery(actions.websocket.onMonitorCheckUpdatedEvent.type, onMonitorCheckChangedEvent),
    takeEvery(actions.websocket.onMonitorUpdatedEvent.type, onMonitorUpdatedEvent),
    takeEvery(actions.monitorOverviewTab.initialize.type, initialize),
    takeEvery(actions.monitorOverviewTab.initialize.type, loadResponseTimeStatistics),
    takeEvery(actions.monitorOverviewTab.initialize.type, loadDowntimePeriodicStatistics),
  ]);
}
