"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageIncidentResourceStatusUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class StatusPageIncidentResourceStatusUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(originalStatus, updatedStatus) {
        super(model_1.QueueDefinitions.STATUS_PAGE_INCIDENT_RESOURCE_STATUS_UPDATED);
        this.originalStatus = originalStatus;
        this.updatedStatus = updatedStatus;
    }
}
exports.StatusPageIncidentResourceStatusUpdatedMessage = StatusPageIncidentResourceStatusUpdatedMessage;
