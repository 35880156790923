import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationResponse, SubscriptionResponse, UserResponse } from '@um/uptime-monitoring-shared';

type SliceType = {
  initialized: boolean;
  subscriptionInitialized: boolean;
  initializing: boolean;
  initializationError: boolean;
  organizations: OrganizationResponse[];
  selectedOrganization: OrganizationResponse;
  accessToken: string;
  user: UserResponse;
  showAdmin: boolean;
  activeSubscription: SubscriptionResponse;
};

const initialState: SliceType = {
  initialized: false,
  subscriptionInitialized: false,
  initializing: false,
  initializationError: false,
  organizations: [],
  selectedOrganization: null,
  accessToken: '',
  user: null,
  showAdmin: false,
  activeSubscription: null,
};

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      return state;
    },
    setInitializing: (state, action: PayloadAction<boolean>) => {
      state.initializing = action.payload;
      return state;
    },
    setInitializationError: (state, action: PayloadAction<boolean>) => {
      state.initializationError = action.payload;
      return state;
    },
    setOrganizations: (state, action: PayloadAction<OrganizationResponse[]>) => {
      state.organizations = action.payload;
      return state;
    },
    setSelectedOrganization: (state, action: PayloadAction<OrganizationResponse>) => {
      state.selectedOrganization = action.payload;
      return state;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      return state;
    },
    setUser: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload;
      return state;
    },
    setShowAdmin: (state, action: PayloadAction<boolean>) => {
      state.showAdmin = action.payload;
      return state;
    },
    setActiveSubscription: (state, action: PayloadAction<SubscriptionResponse>) => {
      state.activeSubscription = action.payload;
      return state;
    },
    setSubscriptionInitialized: (state, action: PayloadAction<boolean>) => {
      state.subscriptionInitialized = action.payload;
      return state;
    },
  },
});

export default app.reducer;
