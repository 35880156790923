import { useCallback, useEffect, useState } from 'react';
import { duration } from '../utils/duration';
import { formatDuration } from 'date-fns';
import { DurationFormat } from '../utils/durationFormat';

const useRemainingDuration = (endDate, format = DurationFormat.DHM) => {
  const [remainingDuration, setRemainingDuration] = useState(endDate ? duration(new Date(), new Date(endDate)) : null);
  const [remainingMs, setRemainingMs] = useState(null);

  const updateElapsedDuration = useCallback(() => {
    if (endDate) {
      const now = new Date();
      const initialEndDate = new Date(endDate);

      setRemainingDuration(duration(now, initialEndDate));
      setRemainingMs(initialEndDate.getTime() - now.getTime());
    }
  }, [endDate]);

  useEffect(() => {
    const interval = setInterval(updateElapsedDuration, 1000);
    return () => clearInterval(interval);
  }, [updateElapsedDuration, endDate]);

  if (!endDate || !remainingDuration) {
    return [null, null, null];
  }

  const formattedDuration = formatDuration(remainingDuration, {
    format,
  });

  return [remainingDuration, formattedDuration, remainingMs];
};

export default useRemainingDuration;
