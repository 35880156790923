import { Link, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

type Props = LinkProps & ChakraLinkProps & { underline?: boolean };

const AppLink = ({ fontSize = 'lg', color = 'blue.400', underline = false, children, ...rest }: Props) => {
  return (
    <Link
      as={RouterLink}
      textDecoration={underline ? 'underline' : null}
      fontSize={fontSize}
      cursor="pointer"
      color={color}
      _hover={{ textColor: 'blue.600', textDecoration: underline ? 'underline' : null }}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default AppLink;
