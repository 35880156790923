import React from 'react';
import EditMonitorGroupForm from '../edit-monitor-group-form/editMonitorGroupForm';

export type Props = {
  monitorGroupId: string;
};

const EditMonitorGroupTab = ({ monitorGroupId }: Props) => {
  return <EditMonitorGroupForm monitorGroupId={monitorGroupId} />;
};

export default EditMonitorGroupTab;
