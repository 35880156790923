import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '../../utils/withPayloadType';
import { createMonitor } from '../reducer/createMonitor';
import { MonitorFormModel } from '../../models/monitorFormModel';

const createMonitorAction = createAction('createMonitor/create', withPayloadType<MonitorFormModel>());

const createMonitorActions = {
  ...createMonitor.actions,
  createMonitor: createMonitorAction,
};

export default createMonitorActions;
