"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendVerificationTokenNotificationMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class SendVerificationTokenNotificationMessage extends workerMessage_1.WorkerMessage {
    constructor(token) {
        super(model_1.QueueDefinitions.SEND_RESET_PASSWORD_TOKEN_EMAIL);
        this.token = token;
    }
}
exports.SendVerificationTokenNotificationMessage = SendVerificationTokenNotificationMessage;
