"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckDomainNameExpirationMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class CheckDomainNameExpirationMessage extends workerMessage_1.WorkerMessage {
    constructor(monitorId) {
        super(model_1.QueueDefinitions.CHECK_DOMAIN_NAME_EXPIRATION);
        this.monitorId = monitorId;
    }
}
exports.CheckDomainNameExpirationMessage = CheckDomainNameExpirationMessage;
