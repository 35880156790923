import { useFormContext, useWatch } from 'react-hook-form';
import { FormSelect } from '../form-controller/formController';
import React from 'react';
import { getFormErrorMessage } from '../../utils/getFormErrorMessage';
import HttpStatusCodeDropdown from '../http-status-code-dropdown/httpStatusCodeDropdown';
import { HttpMonitorAssertionType } from '@um/uptime-monitoring-shared';
import JSONResponseBodyPropertyFormFields from './jsonResponseBodyPropertyFormFields';
import formValidationRules from '../../utils/formValidationRules';
import { VStack } from '@chakra-ui/react';
import HttpMonitorAssertionTypeSelect from '../http-monitor-assertion-type-select/httpMonitorAssertionTypeSelect';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';

type HttpMonitorAssertionProps = { fieldPrefix: string };

const HttpMonitorAssertionForm = ({ fieldPrefix }: HttpMonitorAssertionProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const valueFieldPath = `${fieldPrefix}value`;
  const typeFieldPath = `${fieldPrefix}type`;
  const operationFieldPath = `${fieldPrefix}operation`;
  const propertyFieldPath = `${fieldPrefix}property`;
  const watchedAssertionType = useWatch({ control, name: typeFieldPath });
  const resetTypeSettings = () => {
    setValue(valueFieldPath, '');
    setValue(propertyFieldPath, '');
    setValue(operationFieldPath, '');
  };

  return (
    <VStack spacing={4} align="stretch">
      <FormFieldWrapper title="Notify when">
        <FormSelect
          name={typeFieldPath}
          rules={{
            validate: formValidationRules.conditionalRequired(true),
          }}
          control={control}
        >
          {({ value, onChange, ...rest }) => (
            <HttpMonitorAssertionTypeSelect
              onChange={(...args) => {
                onChange(...args);
                resetTypeSettings();
              }}
              error={getFormErrorMessage(errors, typeFieldPath)}
              value={value}
              {...rest}
            />
          )}
        </FormSelect>
      </FormFieldWrapper>
      {watchedAssertionType === HttpMonitorAssertionType.STATUS_CODE && (
        <FormFieldWrapper description="Acceptable response status codes">
          <FormSelect
            name={valueFieldPath}
            rules={{
              validate: formValidationRules.conditionalRequired(true),
            }}
            control={control}
          >
            {({ value, ...rest }) => <HttpStatusCodeDropdown error={getFormErrorMessage(errors, valueFieldPath)} value={value} {...rest} />}
          </FormSelect>
        </FormFieldWrapper>
      )}
      {watchedAssertionType === HttpMonitorAssertionType.JSON_RESPONSE_BODY_PROPERTY && (
        <JSONResponseBodyPropertyFormFields fieldPrefix={fieldPrefix} disabled={false} />
      )}
    </VStack>
  );
};

export default HttpMonitorAssertionForm;
