import { useAppSelector } from '../../app/store';
import React, { Suspense } from 'react';
import Spinner from '../spinner/spinner';
import MonitorCheckList from '../monitor-check-list/monitorCheckList';
import { monitorStatusColor } from '../../utils/monitorStatusColor';
import { monitorStatusText } from '../../utils/monitorStatusText';
import IncidentsTable from '../incidents-table/incidentsTable';
import { SectionTitle } from '../common-components/informationLayoutComponents';
import { Box, Flex, HStack, StackDivider } from '@chakra-ui/react';
import Card from '../card/card';
import CertificateStatusMonitorCard from '../certificate-status-monitor-card/certificateStatusMonitorCard';
import DomainNameStatusMonitorCard from '../domain-name-status-monitor-card/domainNameStatusMonitorCard';
import {
  useMonitorDowntimeStatisticsQuery,
  useMonitorQuery,
  usePeriodicMonitorDowntimeStatisticsQuery,
  usePeriodicMonitorResponseTimeStatisticsQuery,
} from '../../query/queries';
import { MonitorTransport } from '@um/uptime-monitoring-shared';
import CheckInMonitorInformation from './checkInMonitorInformation';
import useMonitorUptimeStatistics, { MonitorUptimeStatisticsType } from '../../hooks/useMonitorUptimeStatistics';

const MonitorResponseTimesChart = React.lazy(() => import('../monitor-response-times-chart/monitorResponseTimesChart'));
const MonitorDowntimePeriodicChart = React.lazy(() => import('../monitor-downtime-periodic-chart/monitorDowntimePeriodicChart'));

type Props = { monitorId: string };

const MonitorOverviewTab = ({ monitorId }: Props) => {
  const { initialized: appInitialized } = useAppSelector((state) => state.app);
  const { isFetched, data: monitor } = useMonitorQuery(monitorId);
  const { isFetched: isFetched1, data: downtimeStatistics } = useMonitorDowntimeStatisticsQuery(monitorId);
  const { data: responseTimeStatistics } = usePeriodicMonitorResponseTimeStatisticsQuery(monitorId);
  const { isFetched: isFetched3, data: downtimePeriodicStatistics } = usePeriodicMonitorDowntimeStatisticsQuery(monitorId);

  const initialized = appInitialized && isFetched && isFetched1 && isFetched3;

  const loading = !initialized;

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const checkInMonitor = monitor?.transport === MonitorTransport.CHECK_IN;

  return (
    <Flex flexDirection="column" gap={4}>
      <Box>
        <SectionTitle>Overview</SectionTitle>
        <MonitorOverviewCards monitor={monitor} downtimeStatistics={downtimeStatistics} />
      </Box>
      {!checkInMonitor && (
        <Box>
          <SectionTitle>Response times</SectionTitle>
          <Suspense fallback={<Spinner />}>
            <Card>
              <MonitorResponseTimesChart responseTimeStatistics={responseTimeStatistics} />
            </Card>
          </Suspense>
        </Box>
      )}
      {checkInMonitor && (
        <Box>
          <SectionTitle>Check-in information</SectionTitle>
          <CheckInMonitorInformation monitor={monitor} />
        </Box>
      )}
      <Box>
        <SectionTitle>Downtime statistics</SectionTitle>
        <Suspense fallback={<Spinner />}>
          <Card>
            <MonitorDowntimePeriodicChart downtimeStatistics={downtimePeriodicStatistics} />
          </Card>
        </Suspense>
      </Box>
      {!checkInMonitor && (
        <Box>
          <SectionTitle>Latest checks</SectionTitle>
          <Box mb={4}>
            <MonitorCheckList monitorId={monitorId} />
          </Box>
        </Box>
      )}
      <Box>
        <SectionTitle>Incidents</SectionTitle>
        <IncidentsTable monitorId={monitorId} noMonitorLink />
      </Box>
    </Flex>
  );
};

const MonitorOverviewCards = ({ monitor, downtimeStatistics }) => {
  const [, sevenDaysUptimePercentageText] = useMonitorUptimeStatistics(monitor.status, downtimeStatistics, MonitorUptimeStatisticsType.SEVEN_DAYS);
  const [, thirtyDaysUptimePercentageText] = useMonitorUptimeStatistics(monitor.status, downtimeStatistics, MonitorUptimeStatisticsType.THIRTY_DAYS);
  const [, ninetyDaysUptimePercentageText] = useMonitorUptimeStatistics(monitor.status, downtimeStatistics, MonitorUptimeStatisticsType.NINETY_DAYS);
  const statusColor = !monitor.enabled ? 'gray.500' : monitorStatusColor(monitor.status);
  const statusText = !monitor.enabled ? 'Paused' : monitorStatusText(monitor);

  return (
    <Box display="grid" gridTemplateColumns={{ base: 'minmax(0, 1fr)', md: 'repeat(3, minmax(0, 1fr))' }} gap={4}>
      <Card gap={2} alignItems="center" m={0}>
        <SectionTitle fontWeight="semibold">Status</SectionTitle>
        <Flex fontSize="3xl" textColor={statusColor} fontWeight="bold" alignItems="center" justifyContent="center" textAlign="center" flexGrow={1}>
          {statusText}
        </Flex>
      </Card>
      <Card gap={2} alignItems="center" m={0}>
        <SectionTitle fontWeight="semibold">Uptime</SectionTitle>
        <HStack divider={<StackDivider borderColor="gray.200" />} gap={2} width="100%" justifyContent="center">
          <Flex flexDirection="column" alignItems="center" gap={1}>
            <Box color="gray.500">7 days</Box>
            <Box fontSize="xl" color="black" fontWeight="semibold">
              {sevenDaysUptimePercentageText}
            </Box>
          </Flex>
          <Flex flexDirection="column" alignItems="center" gap={1}>
            <Box color="gray.500">30 days</Box>
            <Box fontSize="xl" color="black" fontWeight="semibold">
              {thirtyDaysUptimePercentageText}
            </Box>
          </Flex>
          <Flex flexDirection="column" alignItems="center" gap={1}>
            <Box color="gray.500">90 days</Box>
            <Box fontSize="xl" color="black" fontWeight="semibold">
              {ninetyDaysUptimePercentageText}
            </Box>
          </Flex>
        </HStack>
      </Card>
      <CertificateStatusMonitorCard monitor={monitor} />
      <DomainNameStatusMonitorCard monitor={monitor} />
    </Box>
  );
};

export default MonitorOverviewTab;
