"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationReason = exports.NotificationHistoryStatus = void 0;
var NotificationHistoryStatus;
(function (NotificationHistoryStatus) {
    NotificationHistoryStatus["CREATED"] = "CREATED";
    NotificationHistoryStatus["SENT"] = "SENT";
    NotificationHistoryStatus["ERROR"] = "ERROR";
    NotificationHistoryStatus["QUOTA_REACHED"] = "QUOTA_REACHED";
})(NotificationHistoryStatus = exports.NotificationHistoryStatus || (exports.NotificationHistoryStatus = {}));
var NotificationReason;
(function (NotificationReason) {
    NotificationReason["MONITOR_DOWN"] = "MONITOR_DOWN";
    NotificationReason["MONITOR_UP"] = "MONITOR_UP";
    NotificationReason["MONITOR_GROUP_DOWN"] = "MONITOR_GROUP_DOWN";
    NotificationReason["MONITOR_GROUP_UP"] = "MONITOR_GROUP_UP";
    NotificationReason["SSL_CERTIFICATE_EXPIRATION"] = "SSL_CERTIFICATE_EXPIRATION";
    NotificationReason["DOMAIN_NAME_EXPIRATION"] = "DOMAIN_NAME_EXPIRATION";
})(NotificationReason = exports.NotificationReason || (exports.NotificationReason = {}));
