import { Organization } from '../../app/model/organization';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import mutationDefaults from '../../query/mutationDefaults';
import StatusPageIncidentUpdateForm from '../status-page-incident-update-form/statusPageIncidentUpdateForm';
import { StatusPageIncidentUpdateFormModel } from '../../models/statusPageIncidentUpdateFormModel';
import { useStatusPageIncidentUpdateMutation } from '../../query/statusPageIncidentQueries';
import { MutationAction } from '../../query/mutationAction';
import { Icon } from '@chakra-ui/react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import informationToast from '../../utils/informationToast';

export type CreateStatusPageIncidentUpdateFormProps = {
  statusPageId: string;
  statusPageIncidentId: string;
  onCreated?: (project?: Organization) => any;
  onError?: (err?: any) => any;
};

const CreateStatusPageIncidentUpdateForm: React.FC<CreateStatusPageIncidentUpdateFormProps> = ({
  statusPageId,
  statusPageIncidentId,
  onCreated,
  onError,
}) => {
  const [formErrors, setFormErrors] = useState([]);
  const { isLoading, mutateAsync } = useStatusPageIncidentUpdateMutation({
    onSuccess: (data) => {
      if (!data) {
        informationToast('Status page incident update was empty therefore it was not published');
      } else {
        toast.success('Status page incident update published');
      }

      onCreated && onCreated(data);
    },
    onError: mutationDefaults.onErrorWithFormHandling(setFormErrors, onError),
  });
  const handleSubmit = async (data: StatusPageIncidentUpdateFormModel) => {
    await mutateAsync({ statusPageIncidentId, createRequest: data, action: MutationAction.POST });
  };

  return (
    <StatusPageIncidentUpdateForm
      mainActionText="Publish incident update"
      statusPageId={statusPageId}
      statusPageIncidentId={statusPageIncidentId}
      disabled={isLoading}
      formErrors={formErrors}
      onSubmit={handleSubmit}
    />
  );
};

export default CreateStatusPageIncidentUpdateForm;
