import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { BsChevronExpand } from 'react-icons/bs';
import { AppInputProps, ControlledInput } from '../input/input';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import formValidationRules from '../../utils/formValidationRules';
import { HttpProtocolOptions } from '@um/uptime-monitoring-shared';

const { conditionalRequired } = formValidationRules;

type Props = AppInputProps & {
  protocolFieldName: string;
  required?: boolean;
};

const WebsiteUrlControlledInput: React.FC<Props> = ({ name, protocolFieldName, required, ...rest }) => {
  const { control, setValue, getValues, watch } = useFormContext();
  const protocol = getValues(protocolFieldName);
  const url = watch(name);

  useEffect(() => {
    const finalUrl = url || '';

    if (finalUrl.startsWith(HttpProtocolOptions.HTTP)) {
      setValue(name, finalUrl.replace(HttpProtocolOptions.HTTP, ''));
      setValue(protocolFieldName, HttpProtocolOptions.HTTP);
    } else if (finalUrl.startsWith(HttpProtocolOptions.HTTPS)) {
      setValue(name, finalUrl.replace(HttpProtocolOptions.HTTPS, ''));
      setValue(protocolFieldName, HttpProtocolOptions.HTTPS);
    }
  }, [url]);

  return (
    <ControlledInput
      inputLeftAddon={
        <Menu preventOverflow={true} matchWidth={true}>
          <MenuButton as={Button} rightIcon={<Icon as={BsChevronExpand} />} h={'100%'} borderRadius={0} boxShadow="none" outline="none">
            {protocol}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setValue(protocolFieldName, 'https://')}>https://</MenuItem>
            <MenuItem onClick={() => setValue(protocolFieldName, 'http://')}>http://</MenuItem>
          </MenuList>
        </Menu>
      }
      name={name}
      control={control}
      placeholder="Website URL"
      rules={{
        validate: conditionalRequired(required),
      }}
      {...rest}
    />
  );
};

export default WebsiteUrlControlledInput;
