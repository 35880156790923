import { FormProvider, useForm } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React, { FormHTMLAttributes, useEffect, useLayoutEffect, useState } from 'react';
import { FormSelect } from '../form-controller/formController';
import { PartialDeep } from 'type-fest';
import HttpMonitorAssertionForm from '../http-monitor-assertion-form/httpMonitorAssertionForm';
import { FormFieldError, MonitorTransport } from '@um/uptime-monitoring-shared';
import { ControlledToggle } from '../toggle/toggle';
import NotificationChannelsFormFields from '../notification-channels-form-fields/notificationChannelsFormFields';
import { Box, Button, Icon, StackDivider, Text, VStack } from '@chakra-ui/react';
import Card from '../card/card';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { BsChevronContract, BsChevronExpand } from 'react-icons/bs';
import CheckFrequencySelect from '../check-frequency-select/checkFrequencySelect';
import { ControlledMonitorGracePeriodSelector } from '../monitor-grace-period-selector/monitorGracePeriodSelector';
import MonitorTransportSelect from '../monitor-transport-select/monitorTransportSelect';
import WebsiteUrlControlledInput from './websiteUrlControlledInput';
import formValidationRules from '../../utils/formValidationRules';
import HttpRequestSettingsSubform from './httpRequestSettingsSubform';
import CertificateMonitorOptionsFormFields from './certificateMonitorOptionsFormFields';
import DomainNameMonitorOptionsFormFields from './domainNameMonitorOptionsFormFields';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { emptyCheckInMonitorOptions, MonitorFormDefaultValues, MonitorFormModel } from '../../models/monitorFormModel';
import CheckInMonitorFields from '../check-in-monitor-fields/checkInMonitorFields';

type Props = {
  formValues?: PartialDeep<MonitorFormModel>;
  mainActionText: string;
  disabled: boolean;
  onSubmit: (data: MonitorFormModel) => void;
  hideEnabledToggle?: boolean;
  formErrors?: FormFieldError[];
  showAdvancedSettings?: boolean;
  editMode?: boolean;
  createMode?: boolean;
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>;

const required = { value: true, message: 'This field is required' };

const MonitorForm = ({
  formValues = MonitorFormDefaultValues,
  mainActionText,
  className,
  disabled,
  onSubmit,
  hideEnabledToggle,
  formErrors,
  editMode = false,
  createMode = false,
  showAdvancedSettings = false,
  ...rest
}: Props) => {
  const [advancedSettingsVisible, setAdvancedSettingsVisible] = useState(showAdvancedSettings || false);
  const formMethods = useForm<MonitorFormModel>({ defaultValues: formValues });
  const [formErrorsProcessed, setFormErrorsProcessed] = useState(0);
  const {
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = formMethods;
  const { schedule, transport, checkInMonitorOptions } = watch();
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  useEffect(() => {
    if (formErrors && formErrors.length > 0) {
      for (const formError of formErrors) {
        setError(formError.dataPath as any, {
          type: 'manual',
          message: formError.message,
        });
      }

      setFormErrorsProcessed(formErrorsProcessed + 1);
    }
  }, [formErrors]);

  useLayoutEffect(() => {
    const erroneousElement = document.querySelector('.ui.error');

    if (erroneousElement) {
      erroneousElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formErrorsProcessed]);

  const submit = (data: MonitorFormModel) => {
    onSubmit({ ...data, organizationId });
  };

  const httpMonitor = transport === MonitorTransport.HTTP;
  const icmpPingMonitor = transport === MonitorTransport.ICMP_PING;
  const checkInMonitor = transport === MonitorTransport.CHECK_IN;

  const scheduleEnabled = icmpPingMonitor || httpMonitor;
  const gracePeriod = !checkInMonitor;

  useEffect(() => {
    if (transport !== MonitorTransport.CHECK_IN && checkInMonitorOptions) {
      setValue('checkInMonitorOptions', null);
    } else if (transport === MonitorTransport.CHECK_IN && !checkInMonitorOptions) {
      setValue('checkInMonitorOptions', emptyCheckInMonitorOptions());
    }
  }, [transport]);

  return (
    <FormProvider {...formMethods}>
      <form className={className} {...rest} onSubmit={handleSubmit(submit)}>
        <VStack spacing={4}>
          <Card>
            <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
              <FormFieldWrapper title="Monitor type" description="Cannot be changed once the monitor is created">
                <FormSelect name="transport" rules={{ required }} control={control}>
                  {(field) => (
                    <MonitorTransportSelect isDisabled={editMode} placeholder="Monitor type" value={transport} error={errors.transport} {...field} />
                  )}
                </FormSelect>
              </FormFieldWrapper>
              {httpMonitor && (
                <FormFieldWrapper title="Website URL" description="The URL to be checked">
                  <WebsiteUrlControlledInput
                    name="httpMonitorOptions.target"
                    protocolFieldName="httpMonitorOptions.protocol"
                    required={httpMonitor}
                  />
                </FormFieldWrapper>
              )}
              {icmpPingMonitor && (
                <FormFieldWrapper title="Hostname or IP address" description="Enter the hostname or the IP address of the server to ping">
                  <ControlledInput
                    name="icmpPingMonitorOptions.host"
                    control={control}
                    placeholder="Host"
                    rules={{
                      validate: formValidationRules.conditionalRequired(icmpPingMonitor),
                    }}
                  />
                </FormFieldWrapper>
              )}
              <FormFieldWrapper title="Name" description="User friendly name that will help you easily identify this monitor">
                <ControlledInput name="name" control={control} placeholder="Name" rules={{ required }} />
              </FormFieldWrapper>
              {scheduleEnabled && (
                <FormFieldWrapper title="Check frequency" description="How often should the check be performed">
                  <FormSelect name="schedule" rules={{ required }} control={control}>
                    {(field) => <CheckFrequencySelect placeholder="Check frequency" value={schedule} error={errors.schedule} {...field} />}
                  </FormSelect>
                </FormFieldWrapper>
              )}
              {httpMonitor && <CertificateMonitorOptionsFormFields />}
              {httpMonitor && <DomainNameMonitorOptionsFormFields />}
              {checkInMonitor && <CheckInMonitorFields />}
              {!hideEnabledToggle && (
                <FormFieldWrapper title="Monitor enabled" description="While monitor is disabled no checks for this monitor will be performed">
                  <ControlledToggle w="100%" name="enabled" control={control} />
                </FormFieldWrapper>
              )}
              {httpMonitor && (
                <Box>
                  <FormFieldWrapper
                    title="Ignore SSL issues"
                    description="If this option is disabled then monitor will fail when SSL certificate issues are encountered"
                  >
                    <ControlledToggle w="100%" name="httpMonitorOptions.ignoreCertificateIssues" control={control} />
                  </FormFieldWrapper>
                </Box>
              )}
            </VStack>
          </Card>

          <Card>
            <Text fontWeight="bold" fontSize="3xl" mb={4}>
              Notification Settings
            </Text>
            <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
              {gracePeriod && (
                <FormFieldWrapper
                  title="Grace period (seconds)"
                  description={
                    <div>
                      <div>You will only be notified if the monitor is down for at least the duration of grace period.</div>
                      <div>0 means it is disabled and you will be notified immediately</div>
                    </div>
                  }
                >
                  <ControlledMonitorGracePeriodSelector control={control} name="gracePeriod" rules={{ required }} />
                </FormFieldWrapper>
              )}
              <NotificationChannelsFormFields />
              {httpMonitor && <HttpMonitorAssertionForm fieldPrefix={`httpMonitorOptions.assertions[0].`} />}
            </VStack>
          </Card>

          {httpMonitor && (
            <Card>
              <Button
                alignSelf="center"
                justifySelf="center"
                variant="outline"
                leftIcon={<Icon as={advancedSettingsVisible ? BsChevronContract : BsChevronExpand} />}
                width="min-content"
                onClick={() => setAdvancedSettingsVisible(!advancedSettingsVisible)}
                size="lg"
              >
                Advanced settings
              </Button>
            </Card>
          )}

          {httpMonitor && (
            <Card hidden={!advancedSettingsVisible}>
              <Text fontWeight="bold" fontSize="3xl" mb={4}>
                Request Settings
              </Text>
              <HttpRequestSettingsSubform />
            </Card>
          )}
          <Button type="submit" disabled={disabled} colorScheme="blue" width="min-content" size="lg">
            {mainActionText}
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default MonitorForm;
