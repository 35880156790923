import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { RootState } from '../../store';
import { MonitorStatisticsVO } from '@um/uptime-monitoring-shared';
import { logError } from '../../../utils/logError';

export function* initialize(action) {
  try {
    const monitorId = action.payload.monitorId;

    yield put(actions.monitorResponseStatistics.setMonitorId(monitorId));

    yield call(loadStatistics);

    yield put(actions.monitorResponseStatistics.setInitialized(true));
  } catch (e) {
    logError(e);
  }
}

function* loadStatistics() {
  try {
    const { monitorId, timeframe, window } = yield select((state: RootState) => state.monitorResponseStatistics);

    yield put(actions.monitorResponseStatistics.setLoading(true));

    const statistics: MonitorStatisticsVO[] = yield call(statusPingClient.protectedGetMonitorStatistics, monitorId, timeframe, window);
    const dygraphStatistics = statistics.map((item) => {
      return [new Date(item.window), item.minDurationLocf / 1000.0, item.avgDurationLocf / 1000.0, item.maxDurationLocf / 1000.0];
    });

    yield put(actions.monitorResponseStatistics.setStatistics(statistics));
    yield put(actions.monitorResponseStatistics.setDygraphStatistics(dygraphStatistics));
    yield put(actions.monitorResponseStatistics.setLoading(false));
  } catch (e) {
    logError(e);
  }
}

export default function* monitorsSaga() {
  yield all([
    takeEvery(actions.monitorResponseStatistics.initialize.type, initialize),
    takeEvery(actions.monitorResponseStatistics.setWindow.type, loadStatistics),
    takeEvery(actions.monitorResponseStatistics.setTimeframe.type, loadStatistics),
  ]);
}
