import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import Spinner from '../spinner/spinner';

export enum IndicatorStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  STALE = 'STALE',
  LOADING = 'LOADING',
}

export type IndicatorProps = Omit<FlexProps, 'rounded'> & {
  status: IndicatorStatus;
  height?: string | number;
  width?: string | number;
  rounded?: boolean;
  shadow?: boolean;
  showLoader?: boolean;
};

const colorClass = {
  [IndicatorStatus.SUCCESS]: 'green.600',
  [IndicatorStatus.ERROR]: 'red.600',
  [IndicatorStatus.WARNING]: 'yellow.600',
  [IndicatorStatus.STALE]: 'gray.200',
  [IndicatorStatus.LOADING]: 'gray.200',
};

const Indicator: React.FC<IndicatorProps> = ({ status, height = 2, width = 4, rounded = false, showLoader = true, ...rest }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      w={width}
      h={height}
      background={colorClass[status]}
      boxShadow="sm"
      borderRadius={rounded ? 'full' : 'none'}
      {...rest}
    >
      {showLoader && status === IndicatorStatus.LOADING && <Spinner size="xs" hideLoading />}
    </Flex>
  );
};

export default Indicator;
