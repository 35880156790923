import React from 'react';
import { monitorStatusColor } from '../../utils/monitorStatusColor';
import { monitorStatusText } from '../../utils/monitorStatusText';
import { MonitorStatus, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import StatusIcon from '../status-icon/statusIcon';

const MonitorStatusIcon: React.FC<{ monitor: ProtectedMonitorDetailsResponse }> = ({ monitor }) => {
  const color = monitorStatusColor(monitor.status);
  const statusText = monitorStatusText(monitor);

  return <StatusIcon color={color} text={statusText} paused={!monitor.enabled} loading={monitor.status === MonitorStatus.NONE} />;
};

export default MonitorStatusIcon;
