import { Avatar, Flex } from '@chakra-ui/react';
import React from 'react';
import { combineUsersName } from '../../utils/combineUsersName';

type Props = {
  user: { firstName?: string; lastName?: string };
};

const UserLabel: React.FC<Props> = ({ user }) => {
  const name = combineUsersName(user);

  return (
    <Flex gap={1} alignItems="center">
      <Avatar name={name} size="sm" /> {name}
    </Flex>
  );
};

export default UserLabel;
