import getBrowserTimezone from '../utils/getBrowserTimezone';
import ct from 'countries-and-timezones';

const useTimezoneCountry = () => {
  const timezone = getBrowserTimezone();

  if (!timezone) {
    return null;
  }

  const timezoneMetadata = ct.getTimezone(timezone);

  if (!timezoneMetadata || !timezoneMetadata.countries || timezoneMetadata.countries.length < 1) {
    return null;
  }

  return timezoneMetadata.countries[0];
};

export default useTimezoneCountry;
