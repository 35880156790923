import { useFormContext } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React, { useEffect } from 'react';
import WebsiteLink from '../website-link/websiteLink';
import formValidationRules from '../../utils/formValidationRules';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { Box } from '@chakra-ui/react';

const { required } = formValidationRules;

const SlackOptionsFormFields = () => {
  const { control, unregister } = useFormContext();

  const webhookUrlFieldPath = `slackOptions.webhookUrl`;

  useEffect(() => {
    return () => {
      unregister(webhookUrlFieldPath);
    };
  }, []);

  return (
    <FormFieldWrapper
      title="Webhook URL"
      description={
        <Box>
          {"Don't"} have a Slack Webhook URL?
          <Box>
            <WebsiteLink href="https://slack.com/help/articles/115005265063-Incoming-webhooks-for-Slack" target="_blank">
              Use this guide to get one
            </WebsiteLink>
          </Box>
        </Box>
      }
    >
      <ControlledInput name={webhookUrlFieldPath} control={control} placeholder="Webhook URL" rules={{ required }} />
    </FormFieldWrapper>
  );
};

export default SlackOptionsFormFields;
