import React from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputProps as ChakraInputProps,
  InputRightAddon,
  VStack,
} from '@chakra-ui/react';
import { prepareRules } from '../../utils/formValidationRules';
import FormFieldLabel from '../form-field-label/formFieldLabel';

export interface AppInputProps extends Omit<ChakraInputProps, 'error'> {
  label?: string;
  error?: string;
  inputLabel?: React.ReactNode;
  inputLeftAddon?: React.ReactNode;
  inputRightAddon?: React.ReactNode;
  regularLabel?: boolean;
  rules?: any;
  helperText?: string;
}

export type ControlledInputProps = {
  control: Control<any>;
  required?: boolean;
  regularLabel?: boolean;
  inputLabel?: React.ReactNode;
  inputLeftAddon?: React.ReactNode;
  inputRightAddon?: React.ReactNode;
} & AppInputProps;

const Input: React.FC<AppInputProps> = ({
  placeholder,
  className,
  label,
  error,
  children,
  regularLabel,
  inputLabel,
  inputLeftAddon,
  inputRightAddon,
  helperText,
  ...rest
}) => {
  const hasError = !!error;

  return (
    <VStack>
      <FormControl isInvalid={hasError}>
        <InputGroup size="lg">
          {label && <FormFieldLabel regular={regularLabel}>{label}</FormFieldLabel>}
          {inputLeftAddon && <InputLeftAddon p={0}>{inputLeftAddon}</InputLeftAddon>}
          <ChakraInput label={inputLabel} placeholder={placeholder} background="white" {...rest} />
          {inputRightAddon && <InputRightAddon p={0}>{inputRightAddon}</InputRightAddon>}
        </InputGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
      {children}
    </VStack>
  );
};

Input.displayName = 'Input';

export const ControlledInput = ({ control, name, rules, inputLabel, ...rest }: ControlledInputProps) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return <Input error={error?.message} inputLabel={inputLabel} {...rest} {...field} />;
      }}
    />
  );
};

ControlledInput.displayName = 'ControlledInput';

export default Input;
