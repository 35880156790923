import { NotificationHistoryStatus } from '@um/uptime-monitoring-shared';

const getNotificationHistoryStatusText = (status: NotificationHistoryStatus) => {
  if (status === NotificationHistoryStatus.SENT) {
    return 'Sent';
  } else if (status === NotificationHistoryStatus.ERROR) {
    return 'Error';
  } else if (status === NotificationHistoryStatus.CREATED) {
    return 'Pending';
  } else if (status === NotificationHistoryStatus.QUOTA_REACHED) {
    return 'Quota reached';
  }

  return status;
};

export default getNotificationHistoryStatusText;
