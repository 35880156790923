import { Box, Button, Flex, Icon, Menu, MenuButton, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import React, { lazy, useState, Suspense } from 'react';
import InformationModal from '../../components/information-modal/informationModal';
import Spinner from '../../components/spinner/spinner';

const MonitorGroups = lazy(() => import('./monitorGroups.mdx'));

const MonitorGroupsPageHeader = () => {
  const [informationModalOpen, setInformationModalOpen] = useState(false);

  return (
    <>
      <InformationModal open={informationModalOpen} onClose={() => setInformationModalOpen(false)} title="Monitor groups">
        <Suspense fallback={<Spinner />}>
          <MonitorGroups />
        </Suspense>
      </InformationModal>
      <Tabs isLazy index={0} pb={4}>
        <TabList pb={2}>
          <Menu>
            <Box px={4}>
              <MenuButton as={Button} onClick={() => setInformationModalOpen(true)}>
                <Flex gap={2} alignItems="center">
                  <Icon as={InfoIcon} boxSize={6} fontWeight="bold" />
                  Learn more
                </Flex>
              </MenuButton>
            </Box>
          </Menu>
        </TabList>

        <TabPanels></TabPanels>
      </Tabs>
    </>
  );
};

export default MonitorGroupsPageHeader;
