import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponse, IntegrationResponse } from '@um/uptime-monitoring-shared';
import { AxiosError } from 'axios';

type SliceType = {
  inProgress: boolean;
  created: boolean;
  error: AxiosError<ErrorResponse>;
  integration: IntegrationResponse;
};

const initialState: SliceType = {
  inProgress: false,
  created: false,
  error: null,
  integration: null,
};

export const createIntegration = createSlice({
  name: 'createIntegration',
  initialState,
  reducers: {
    reset: () => initialState,
    setInProgress: (state, action: PayloadAction<boolean>) => {
      state.inProgress = action.payload;
      return state;
    },
    setCreated: (state, action: PayloadAction<boolean>) => {
      state.created = action.payload;
      return state;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      return state;
    },
    setIntegration: (state, action: PayloadAction<IntegrationResponse>) => {
      state.integration = action.payload;
      return state;
    },
  },
});

export default createIntegration.reducer;
