import { RootState, useAppDispatch, useAppSelector } from '../../app/store';
import React, { useEffect, useState } from 'react';
import actions from '../../app/actions';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import EditMonitorTab from '../../components/edit-monitor-tab/editMonitorTab';
import MonitorOverviewTab from '../../components/monitor-overview-tab/monitorOverviewTab';
import { generatePath, Outlet, useMatch, useNavigate, useOutlet, useOutletContext, useParams } from 'react-router-dom';
import Fallback from '../../components/fallback/fallback';
import AppLink from '../../components/app-link/appLink';
import { Flex, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { useMonitorQuery } from '../../query/queries';
import PageControls from '../../components/page-controls/pageControls';
import { BsPlayFill, BsTrash2Fill } from 'react-icons/bs';
import { BiReset } from 'react-icons/bi';
import DeleteConfirmationModal from '../../components/delete-confirmation-modal/deleteConfirmationModal';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';
import { useMonitorMutation } from '../../query/monitorQueries';
import RunMonitorModal from '../../components/run-monitor-modal/runMonitorModal';
import { CheckInPeriodType, MonitorTransport } from '@um/uptime-monitoring-shared';
import ResetMonitorButton from '../../components/reset-monitor-button/resetMonitorButton';

export const OVERVIEW_SLUG = 'overview';
export const SETTINGS_SLUG = 'settings';

const MonitorDetailsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const outlet = useOutlet();
  const monitorId = params.id ? params.id : null;
  const {
    app: { initialized: appInitialized },
  } = useAppSelector((state) => state);
  const { isFetched, data: monitor } = useMonitorQuery(monitorId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRunMonitorModal, setShowRunMonitorModal] = useState(false);
  const { admin = false } = useAppSelector((state: RootState) => state.app.user);

  const initialized = appInitialized && isFetched;
  const overviewActive = Boolean(useMatch(Routes.MONITOR_DETAILS_OVERVIEW));
  const settingsActive = Boolean(useMatch(Routes.MONITOR_SETTINGS));

  const { mutateAsync, isLoading: mutating } = useMonitorMutation();
  const handleDelete = async () => {
    await mutateAsync(
      { monitorId, action: MutationAction.DELETE },
      {
        onSuccess: () => {
          toast.success('Monitor deleted');
          navigate(Routes.MONITORS);
        },
        onError: () => {
          setShowDeleteModal(false);
        },
      }
    );
  };

  useEffect(() => {
    if (!outlet) {
      navigate(
        generatePath(Routes.MONITOR_DETAILS_OVERVIEW, {
          id: monitorId,
        })
      );
    }
  }, [outlet]);

  useEffect(() => {
    return () => {
      dispatch(actions.monitorDetails.reset());
    };
  }, []);

  const breadcrumbs = [{ title: 'Monitors', route: Routes.MONITORS }];
  const simpleExpressionCheckInMonitor =
    monitor?.transport === MonitorTransport.CHECK_IN && monitor?.checkInMonitorOptions?.periodType === CheckInPeriodType.SIMPLE;

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title={monitor?.name} breadcrumbs={monitor ? breadcrumbs : []} />
      <PageControls
        tabs={
          <>
            <PageControls.Tab
              isActive={overviewActive}
              onClick={() =>
                navigate(
                  generatePath(Routes.MONITOR_DETAILS_OVERVIEW, {
                    id: monitorId,
                  })
                )
              }
            >
              Overview
            </PageControls.Tab>
            <PageControls.Tab
              isActive={settingsActive}
              onClick={() =>
                navigate(
                  generatePath(Routes.MONITOR_SETTINGS, {
                    id: monitorId,
                  })
                )
              }
            >
              Settings
            </PageControls.Tab>
          </>
        }
      >
        {admin && (
          <IconButton
            colorScheme="blue"
            aria-label="Run monitor"
            icon={<Icon as={BsPlayFill} boxSize={6} />}
            size="md"
            onClick={() => setShowRunMonitorModal(true)}
          />
        )}
        {simpleExpressionCheckInMonitor && <ResetMonitorButton monitorId={monitorId} />}
        <Tooltip label="Delete monitor">
          <IconButton
            colorScheme="red"
            aria-label="Delete monitor"
            icon={<Icon as={BsTrash2Fill} boxSize={6} />}
            size="md"
            onClick={() => setShowDeleteModal(true)}
          />
        </Tooltip>
      </PageControls>
      <PageWrapper loading={!initialized} px={4}>
        {initialized && !monitor ? (
          <Fallback
            header="Monitor not found"
            subheader={
              <>
                Go back to <AppLink to={Routes.MONITORS}>Monitors</AppLink>
              </>
            }
          />
        ) : (
          <Outlet context={{ monitorId }} />
        )}
      </PageWrapper>
      <DeleteConfirmationModal
        title="Delete monitor"
        content="Are you sure you want to delete this monitor?"
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
        loading={mutating}
      />
      <RunMonitorModal open={showRunMonitorModal} monitorId={monitorId} onClose={() => setShowRunMonitorModal(false)} />
    </Flex>
  );
};

export const MonitorDetailsOverviewOutlet = () => {
  const { monitorId } = useOutletContext<{ monitorId: string }>();

  return <MonitorOverviewTab monitorId={monitorId} />;
};

export const MonitorDetailsSettingsOutlet = () => {
  const { monitorId } = useOutletContext<{ monitorId: string }>();

  return <EditMonitorTab monitorId={monitorId} />;
};

export default MonitorDetailsPage;
