import toast from 'react-hot-toast';
import { useMemo, useState } from 'react';
import MonitorForm from '../monitor-form/monitorForm';
import { HttpMonitorOptions, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import { useMonitorMutation } from '../../query/monitorQueries';
import { MutationAction } from '../../query/mutationAction';
import mutationDefaults from '../../query/mutationDefaults';
import { useMonitorQuery } from '../../query/queries';
import Spinner from '../spinner/spinner';
import { useEntityNotificationChannelAssignmentsQuery } from '../../query/notificationChannelQueries';
import { MonitorFormModel } from '../../models/monitorFormModel';
import { notificationChannelOptionToReference } from '../../utils/notificationChannelOptionToReference';

export type EditMonitorFormProps = {
  monitorId: string;
  onUpdated?: (project?: ProtectedMonitorDetailsResponse) => any;
  onError?: (err?: any) => any;
};

const EditMonitorForm = ({ monitorId, onUpdated, onError }: EditMonitorFormProps) => {
  const [formErrors, setFormErrors] = useState([]);

  const { data: monitor, isLoading: monitorLoading } = useMonitorQuery(monitorId);
  const { data: notificationChannels, isFetched: notificationChannelsFetched } = useEntityNotificationChannelAssignmentsQuery({ monitorId });
  const { isLoading, mutateAsync } = useMonitorMutation({
    onSuccess: (data) => {
      toast.success('Monitor settings updated');
      onUpdated && onUpdated(data);
    },
    onError: mutationDefaults.onErrorWithFormHandling(setFormErrors, onError),
  });

  const handleSubmit = async (data: MonitorFormModel) => {
    await mutateAsync({ monitor: data, action: MutationAction.PATCH });
  };

  const formValues: MonitorFormModel = useMemo(() => {
    if (!monitor || !notificationChannelsFetched || monitorLoading) {
      return null;
    }
    const httpMonitorOptions: HttpMonitorOptions = monitor.httpMonitorOptions || ({} as HttpMonitorOptions);

    return {
      id: monitor.id,
      name: monitor.name,
      description: monitor.description,
      schedule: monitor.schedule,
      gracePeriod: monitor.gracePeriod,
      transport: monitor.transport,
      httpMonitorOptions: {
        ...httpMonitorOptions,
        ignoreCertificateIssues: httpMonitorOptions.ignoreCertificateIssues || false,
      },
      checkInMonitorOptions: monitor.checkInMonitorOptions,
      icmpPingMonitorOptions: monitor.icmpPingMonitorOptions,
      enabled: monitor.enabled,
      notificationChannels: notificationChannels.map((option) => notificationChannelOptionToReference(option)),
    };
  }, [monitor, notificationChannelsFetched, monitorLoading]);

  if (!formValues) {
    return <Spinner />;
  }

  return (
    <MonitorForm
      showAdvancedSettings={true}
      formValues={formValues}
      mainActionText="Save"
      disabled={isLoading}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      editMode
    />
  );
};

export default EditMonitorForm;
