import ClickableText from '../components/clickable-text/clickableText';
import { toast } from 'react-hot-toast';
import { Flex } from '@chakra-ui/react';

export const dismissibleToast = (children) => (t) =>
  (
    <Flex as="span" alignItems="center" gap={2}>
      <div>{children}</div>
      <ClickableText onClick={() => toast.dismiss(t.id)}>Dismiss</ClickableText>
    </Flex>
  );
