"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorCertificateStatusValues = exports.MonitorCertificateStatus = void 0;
var MonitorCertificateStatus;
(function (MonitorCertificateStatus) {
    MonitorCertificateStatus["NONE"] = "NONE";
    MonitorCertificateStatus["VALID"] = "VALID";
    MonitorCertificateStatus["EXPIRED"] = "EXPIRED";
})(MonitorCertificateStatus = exports.MonitorCertificateStatus || (exports.MonitorCertificateStatus = {}));
exports.MonitorCertificateStatusValues = Object.values(MonitorCertificateStatus);
