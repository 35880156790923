"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnqueueMonitorsMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class EnqueueMonitorsMessage extends workerMessage_1.WorkerMessage {
    constructor(schedule) {
        super(model_1.QueueDefinitions.ENQUEUE_MONITORS);
        this.schedule = schedule;
    }
}
exports.EnqueueMonitorsMessage = EnqueueMonitorsMessage;
