import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, FlexProps, VStack } from '@chakra-ui/react';
import React from 'react';

const PricingFaq: React.FC<FlexProps> = ({ ...rest }) => {
  return (
    <Flex alignItems="center" justifyContent="center" {...rest}>
      <Box maxW="container.md" w="100%">
        <Accordion allowToggle w="100%">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  Frequently Asked Questions
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <VStack gap={4} alignItems="flex-start">
                <Box>
                  <Box fontWeight="bold">Q: Which payment methods do you accept?</Box>
                  <Box>A: We accept credit cards, PayPal, Apple Pay and Google Pay</Box>
                </Box>
                <Box>
                  <Box fontWeight="bold">Q: Will I get a refund if I cancel my paid plan?</Box>
                  <Box>A: You can cancel your subscription at any time, but no refunds are provided for prorated periods.</Box>
                </Box>
                <Box>
                  <Box fontWeight="bold">Q: Will I receive invoices?</Box>
                  <Box>A: Yes, you will receive a PDF invoice each time you are charged for the subscription.</Box>
                </Box>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Flex>
  );
};

export default PricingFaq;
