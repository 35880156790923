import React, { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import Spinner from '../spinner/spinner';
import useDefaultUserTimezone from '../../hooks/useDefaultUserTimezone';
import { OnCallScheduleFormModel, OnCallScheduleFormModelDefaultValues } from '../../models/onCallScheduleFormModel';
import { useOnCallScheduleMutation } from '../../query/onCallScheduleQueries';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { StatusPageFormModel } from '../status-page-form/statusPageFormModel';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';
import { dismissibleToast } from '../../utils/dismissibleToast';
import { OnCallScheduleModelVO } from '@um/uptime-monitoring-shared';

const OnCallScheduleForm = lazy(() => import('../on-call-schedule-form/onCallScheduleForm'));

type Props = {
  onCreated?: (data: OnCallScheduleModelVO) => void;
};

const CreateOnCallScheduleForm: React.FC<Props> = ({ onCreated }) => {
  const [formErrors, setFormErrors] = useState([]);
  const defaultTimezone = useDefaultUserTimezone();
  const { mutateAsync, isLoading } = useOnCallScheduleMutation();
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const formValues = useMemo(
    () => ({
      ...OnCallScheduleFormModelDefaultValues,
      timezone: defaultTimezone?.name || null,
      organizationId,
    }),
    [organizationId]
  );
  const handleSubmit = useCallback(async (data: OnCallScheduleFormModel) => {
    await mutateAsync(
      { createRequest: data, action: MutationAction.POST },
      {
        onSuccess: (data: OnCallScheduleModelVO) => {
          toast.success('On-call schedule created');

          onCreated && onCreated(data);
        },
        onError: (error: any) => {
          const errorResponse = error?.response?.data;
          if (errorResponse && errorResponse.errorType === 'VALIDATION_FAILED') {
            toast.error(dismissibleToast(errorResponse.message || 'Please fix errors in the form'), {
              duration: 10000,
            });

            setFormErrors(errorResponse.errors);
          } else {
            toast.error('Error occurred. Please try again later');
          }
        },
      }
    );
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <OnCallScheduleForm mainActionText="Create" formValues={formValues} onSubmit={handleSubmit} disabled={isLoading} formErrors={formErrors} />
    </Suspense>
  );
};

export default CreateOnCallScheduleForm;
