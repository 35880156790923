"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationPolicyStateStatusValues = exports.NotificationPolicyStateStatus = void 0;
var NotificationPolicyStateStatus;
(function (NotificationPolicyStateStatus) {
    NotificationPolicyStateStatus["CREATED"] = "CREATED";
    NotificationPolicyStateStatus["ACTIVE"] = "ACTIVE";
    NotificationPolicyStateStatus["COMPLETED"] = "COMPLETED";
    NotificationPolicyStateStatus["COMPLETED_ALERTING"] = "COMPLETED_ALERTING";
    NotificationPolicyStateStatus["ERROR"] = "ERROR";
})(NotificationPolicyStateStatus = exports.NotificationPolicyStateStatus || (exports.NotificationPolicyStateStatus = {}));
exports.NotificationPolicyStateStatusValues = Object.values(NotificationPolicyStateStatus);
