import { IncidentStatus, IncidentWithMetadata } from '@um/uptime-monitoring-shared';
import React from 'react';
import { Avatar, Box, Button, Flex } from '@chakra-ui/react';
import { useIncidentAcknowledgmentMutation } from '../../query/incidentQueries';
import { combineUsersName } from '../../utils/combineUsersName';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';

type Props = {
  incident?: IncidentWithMetadata;
  variant?: 'FULL' | 'MINIMAL';
};

const IncidentAcknowledgmentInformation: React.FC<Props> = ({ variant = 'FULL', incident }) => {
  const { mutateAsync, isLoading } = useIncidentAcknowledgmentMutation();
  const isFullVariant = variant === 'FULL';

  if (!incident.acknowledgedByUserId || !incident.acknowledgedByUser?.id) {
    return (
      <Flex flexDirection="column" gap={2}>
        <Box>Not acknowledged</Box>
        {incident.status === IncidentStatus.ALERTING && (
          <Button
            colorScheme="blue"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              mutateAsync({ incidentId: incident.id });
            }}
          >
            Acknowledge
          </Button>
        )}
      </Flex>
    );
  }

  const name = combineUsersName(incident.acknowledgedByUser);
  return (
    <Flex flexDirection="column" gap={2} justifyContent="center">
      {isFullVariant && incident.acknowledgedAt && (
        <Box>Acknowledged on {formatToUtc(new Date(incident.acknowledgedAt), DateFormats.FULL_SECONDS)} by</Box>
      )}
      <Flex alignItems="center" gap={2}>
        <Avatar name={name} size="sm" /> {name}
      </Flex>
    </Flex>
  );
};

export default IncidentAcknowledgmentInformation;
