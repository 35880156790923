import React, { useMemo } from 'react';
import { HttpStatusCodes } from '@um/uptime-monitoring-shared';
import Select, { components, MultiValueGenericProps } from 'react-select';

const httpStatusCodeOptions = HttpStatusCodes.map((entry) => ({
  label: `${entry.code} - ${entry.reason}`,
  value: `${entry.code}`,
}));

const MultiValueLabel = ({ data, ...rest }: MultiValueGenericProps<any>) => {
  return (
    <components.MultiValueLabel data={data} {...rest}>
      {data.value}
    </components.MultiValueLabel>
  );
};

const HttpStatusCodeDropdown = ({ value = [], onChange, ...rest }) => {
  const handleOnChange = (options) => {
    onChange(options.map((option) => option.value));
  };

  const selectedOptions = useMemo(() => {
    return httpStatusCodeOptions.filter((option) => (value || []).some((item) => item === option.value));
  }, [value]);

  return (
    <Select
      options={httpStatusCodeOptions}
      placeholder="Status codes"
      closeMenuOnSelect={false}
      isMulti
      components={{ MultiValueLabel }}
      value={selectedOptions}
      onChange={handleOnChange}
      {...rest}
    />
  );
};

export default HttpStatusCodeDropdown;
