import { createContext } from 'react';
import { QueryClient } from '@tanstack/react-query';
import memoize from 'lodash.memoize';
import debounce from 'lodash.debounce';
import { ContextOptions } from '@tanstack/react-query/src/types';
import * as React from 'react';

export class QueryInvalidator {
  private readonly memoizedInvalidationHandlers;

  constructor(queryClient: QueryClient) {
    this.memoizedInvalidationHandlers = memoize((key: string[]) => debounce(() => queryClient.invalidateQueries(key), 50, { maxWait: 1000 }));
    this.invalidateQueries = this.invalidateQueries.bind(this);
  }

  public invalidateQueries(key: any[]) {
    const invalidationHandler = this.memoizedInvalidationHandlers(key);

    invalidationHandler();
  }
}

export type QueryInvalidatorContextState = {
  queryInvalidator: QueryInvalidator;
};

const initialState: QueryInvalidatorContextState = {
  queryInvalidator: null,
};

export const QueryInvalidatorContext = createContext<QueryInvalidatorContextState>(initialState);

export const useQueryInvalidator = () => {
  const ctx = React.useContext(QueryInvalidatorContext);

  if (!ctx || !ctx.queryInvalidator) {
    throw new Error('No QueryInvalidator set, use QueryInvalidatorContext.Provider to set one');
  }

  return ctx.queryInvalidator;
};
