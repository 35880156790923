import { MonitorGroupStatus } from '@um/uptime-monitoring-shared';

const colors: { [key: string]: string } = {
  [MonitorGroupStatus.NONE]: 'gray.500',
  [MonitorGroupStatus.UP]: 'green.500',
  [MonitorGroupStatus.ERROR]: 'orange.500',
  [MonitorGroupStatus.PARTIALLY_FAILING]: 'orange.500',
  [MonitorGroupStatus.DOWN]: 'red.500',
  [MonitorGroupStatus.IN_PROGRESS]: 'blue.500',
};
const statusText: { [key: string]: string } = {
  [MonitorGroupStatus.NONE]: 'Checking',
  [MonitorGroupStatus.UP]: 'Healthy',
  [MonitorGroupStatus.ERROR]: 'Error',
  [MonitorGroupStatus.PARTIALLY_FAILING]: 'Partially failing',
  [MonitorGroupStatus.DOWN]: 'Down',
  [MonitorGroupStatus.IN_PROGRESS]: 'Checking',
};

export const monitorGroupStatusText = (status: MonitorGroupStatus) => statusText[status] || statusText[MonitorGroupStatus.NONE];
export const monitorGroupStatusColor = (status: MonitorGroupStatus) => colors[status] || colors[MonitorGroupStatus.NONE];
