import { useMemo } from 'react';
import { OrganizationResponse } from '@um/uptime-monitoring-shared';
import OrganizationForm, { OrganizationFormModel } from './organizationForm';
import { useOrganizationMutation } from '../../query/organizationQueries';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';

export type UpdateOrganizationFormProps = {
  organization: OrganizationResponse;
  onUpdated?: (organization?: OrganizationResponse) => any;
  onError?: (err?: any) => any;
};

const UpdateOrganizationForm = ({ onUpdated, organization, onError }: UpdateOrganizationFormProps) => {
  const { isLoading, mutateAsync } = useOrganizationMutation();
  const handleSubmit = async (data: OrganizationFormModel) => {
    await mutateAsync(
      { organizationId: organization.id, updateRequest: data, action: MutationAction.PATCH },
      {
        onSuccess: () => toast.success('Organization updated'),
        onError: () => toast.error('Error occurred. Please try again later'),
      }
    );
  };

  const formValues: OrganizationFormModel = useMemo(
    () => ({
      name: organization?.name,
    }),
    [organization]
  );

  return <OrganizationForm formValues={formValues} mainActionText="Save" disabled={isLoading} loading={isLoading} onSubmit={handleSubmit} />;
};

export default UpdateOrganizationForm;
