export enum UpgradeModalReason {
  USER_QUOTA = 'USER_QUOTA',
  MONITOR_QUOTA = 'MONITOR_QUOTA',
  SMS_INTEGRATION_NOT_AVAILABLE = 'SMS_INTEGRATION_NOT_AVAILABLE',
  DOMAIN_NAME_CHECK_NOT_AVAILABLE = 'DOMAIN_NAME_CHECK_NOT_AVAILABLE',
  STATUS_PAGE_LIMIT_REACHED = 'STATUS_PAGE_LIMIT_REACHED',
  STATUS_PAGE_CUSTOM_DOMAIN_NOT_AVAILABLE = 'STATUS_PAGE_CUSTOM_DOMAIN_NOT_AVAILABLE',
  STATUS_PAGE_PASSWORD_PROTECTED_NOT_AVAILABLE = 'STATUS_PAGE_PASSWORD_PROTECTED_NOT_AVAILABLE',
  STATUS_PAGE_DISABLE_BRANDING_NOT_AVAILABLE = 'STATUS_PAGE_DISABLE_BRANDING_NOT_AVAILABLE',
  ON_CALL_SCHEDULES_NOT_AVAILABLE = 'ON_CALL_SCHEDULES_NOT_AVAILABLE',
  ON_CALL_SCHEDULES_QUOTA = 'ON_CALL_SCHEDULES_QUOTA',
}
