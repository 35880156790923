import { useLocation } from 'react-router-dom';
import { usePrevious } from '../../hooks/usePrevious';
import React, { useEffect } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import ClickableText from '../clickable-text/clickableText';
import { logError } from '../../utils/logError';

const ErrorFallback = ({ error, resetErrorBoundary, heading = 'Error occurred while loading the page' }) => {
  const location = useLocation();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  useEffect(() => {
    if (previousLocation && previousLocation.pathname !== location.pathname && error) {
      resetErrorBoundary();
    }
  }, [location, previousLocation, error, resetErrorBoundary]);

  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'red.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center"
        >
          <CloseIcon boxSize={'20px'} color={'white'} />
        </Flex>
      </Box>
      <Heading as="h2" size="lg" mt={6} mb={2}>
        {heading}
      </Heading>
      <Text color={'gray.500'} fontSize="md">
        Please{' '}
        <ClickableText onClick={resetErrorBoundary} size="md">
          try again
        </ClickableText>{' '}
        later
      </Text>
    </Box>
  );
};

export default ErrorFallback;
