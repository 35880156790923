import PageHeader from '../../components/page-header/pageHeader';
import { useAppSelector } from '../../app/store';
import React from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import NotificationHistoryTable from '../../components/notification-history-table/notificationHistoryTable';
import { Box } from '@chakra-ui/react';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { Outlet, useMatch, useNavigate, useOutletContext } from 'react-router-dom';
import NotificationIntegrationsList from '../../components/notification-integrations-list/notificationIntegrationsList';
import PageControls from '../../components/page-controls/pageControls';
import Routes from '../../utils/routes';
import NotificationPolicyTable from '../../components/notification-policy-table/notificationPolicyTable';

const NotificationsPage = () => {
  const {
    app: { initialized },
  } = useAppSelector((state) => state);
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const navigate = useNavigate();
  const overviewActive = Boolean(useMatch(Routes.NOTIFICATIONS_OVERVIEW));
  const integrationsActive = Boolean(useMatch(Routes.NOTIFICATION_INTEGRATIONS));
  const policiesActive = Boolean(useMatch(Routes.NOTIFICATIONS_POLICIES));

  return (
    <Box w="100%">
      <PageHeader
        title={overviewActive ? 'Overview' : integrationsActive ? 'Integrations' : policiesActive ? 'Policies' : ''}
        breadcrumbs={[{ title: 'Notifications' }]}
        loading={!initialized}
      />

      <PageControls
      // tabs={
      //   <>
      //     <PageControls.Tab leftIcon={<Icon as={BsListUl} />} isActive={overviewActive} onClick={() => navigate(Routes.NOTIFICATIONS_OVERVIEW)}>
      //       Overview
      //     </PageControls.Tab>
      //     <PageControls.Tab
      //       leftIcon={<Icon as={GrIntegration} />}
      //       isActive={integrationsActive}
      //       onClick={() => navigate(Routes.NOTIFICATION_INTEGRATIONS)}
      //     >
      //       Integrations
      //     </PageControls.Tab>
      //     {AppConfig.NotificationPoliciesEnabled && (
      //       <PageControls.Tab
      //         leftIcon={<Icon as={BiCollection} />}
      //         isActive={policiesActive}
      //         onClick={() => navigate(Routes.NOTIFICATIONS_POLICIES)}
      //       >
      //         Policies
      //       </PageControls.Tab>
      //     )}
      //   </>
      // }
      ></PageControls>
      <PageWrapper loading={!initialized} px={4}>
        <Outlet context={{ organizationId }} />
      </PageWrapper>
    </Box>
  );
};

export const NotificationsOverviewOutlet = () => {
  const { organizationId } = useOutletContext<{ organizationId: string }>();

  return <NotificationHistoryTable organizationId={organizationId} />;
};

export const NotificationsIntegrationsOutlet = () => {
  return <NotificationIntegrationsList />;
};

export const NotificationPoliciesOutlet = () => {
  const { organizationId } = useOutletContext<{ organizationId: string }>();

  return <NotificationPolicyTable organizationId={organizationId} />;
};

export default NotificationsPage;
