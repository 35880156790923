import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import CreateIntegrationForm from '../../components/create-integration-form/createIntegrationForm';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { IntegrationResponse, IntegrationType } from '@um/uptime-monitoring-shared';
import { Flex } from '@chakra-ui/react';

const CreateNotificationIntegrationPage = () => {
  const navigate = useNavigate();
  const onCreated = (integration: IntegrationResponse) => {
    if (integration.type === IntegrationType.SMS) {
      navigate(generatePath(Routes.NOTIFICATION_INTEGRATION, { id: integration.id }));
    } else {
      navigate(Routes.NOTIFICATION_INTEGRATIONS);
    }
  };

  const breadcrumbs = [
    {
      title: 'Notifications',
    },
    {
      title: 'Notification integrations',
      route: Routes.NOTIFICATION_INTEGRATIONS,
    },
  ];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader title="Create" breadcrumbs={breadcrumbs} />
      <PageWrapper>
        <CreateIntegrationForm onCreated={onCreated} />
      </PageWrapper>
    </Flex>
  );
};

export default CreateNotificationIntegrationPage;
