import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { logError } from '../../../utils/logError';
import { MonitorFormModel } from '../../../models/monitorFormModel';

export function* initialize(action) {
  try {
    const monitorId = action.payload;

    const [monitor, notificationIntegrations] = yield all([
      call(statusPingClient.protectedGetMonitor, monitorId),
      call(statusPingClient.protectedGetNotificationIntegrations, {
        monitorId,
      }),
    ]);

    yield put(actions.editMonitor.setMonitor(monitor));
    yield put(actions.editMonitor.setNotificationIntegrations(notificationIntegrations));

    yield put(actions.editMonitor.setInitialized(true));
  } catch (e) {
    logError(e);
  }
}

function* editMonitor(action) {
  try {
    yield put(actions.editMonitor.setInProgress(true));

    const formData: MonitorFormModel = action.payload;

    yield call(statusPingClient.protectedPatchMonitor, formData.id, formData);

    yield put(actions.editMonitor.setUpdated(true));
    yield put(actions.editMonitor.setInProgress(false));
  } catch (e: any) {
    yield put(actions.editMonitor.setInProgress(false));
    yield put(actions.editMonitor.setError(e.response?.data));

    logError(e);
  }
}

export default function* editMonitorSaga() {
  yield all([takeEvery(actions.editMonitor.initialize.type, initialize), takeEvery(actions.editMonitor.editMonitor.type, editMonitor)]);
}
