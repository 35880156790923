import PageHeader from '../../components/page-header/pageHeader';
import { useAppSelector } from '../../app/store';
import React from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import { Box, Button } from '@chakra-ui/react';
import Routes from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import EmptyPageTopMenu from '../../components/empty-page-header/emptyPageHeader';
import NotificationPolicyTable from '../../components/notification-policy-table/notificationPolicyTable';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';

const NotificationPoliciesPage = () => {
  const navigate = useNavigate();
  const {
    app: { initialized },
  } = useAppSelector((state) => state);
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  const breadcrumbs = [{ title: 'Settings', route: Routes.SETTINGS }];

  return (
    <Box w="100%">
      <PageHeader title="Notification policies" loading={!initialized} breadcrumbs={breadcrumbs}>
        <div>
          <Button colorScheme="blue" onClick={() => navigate(Routes.CREATE_NOTIFICATION_POLICY)}>
            Create
          </Button>
        </div>
      </PageHeader>
      <EmptyPageTopMenu />
      <PageWrapper loading={!initialized} px={4}>
        <NotificationPolicyTable organizationId={organizationId} />
      </PageWrapper>
    </Box>
  );
};

export default NotificationPoliciesPage;
