"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageIncidentResourceStatusEnumValues = exports.StatusPageIncidentResourceStatusEnum = void 0;
var StatusPageIncidentResourceStatusEnum;
(function (StatusPageIncidentResourceStatusEnum) {
    StatusPageIncidentResourceStatusEnum["DOWNTIME"] = "DOWNTIME";
    StatusPageIncidentResourceStatusEnum["DEGRADED"] = "DEGRADED";
    StatusPageIncidentResourceStatusEnum["MONITORING"] = "MONITORING";
    StatusPageIncidentResourceStatusEnum["RESOLVED"] = "RESOLVED";
    StatusPageIncidentResourceStatusEnum["NOT_MONITORED"] = "NOT_MONITORED";
})(StatusPageIncidentResourceStatusEnum = exports.StatusPageIncidentResourceStatusEnum || (exports.StatusPageIncidentResourceStatusEnum = {}));
exports.StatusPageIncidentResourceStatusEnumValues = Object.values(StatusPageIncidentResourceStatusEnum);
