import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { prepareRules } from '../../utils/formValidationRules';
import FormFieldLabel from '../form-field-label/formFieldLabel';
import { Box, BoxProps, Flex, FormControl, FormErrorMessage, Switch, SwitchProps } from '@chakra-ui/react';

interface Props extends Omit<SwitchProps, 'error' | 'value'> {
  label?: string;
  error?: string;
  value: boolean;
  containerProps?: BoxProps;
}
type ControlledProps = { control: Control<any>; required?: boolean; rules?: any; onChange?: (checked: boolean) => boolean } & Omit<
  Props,
  'value' | 'onClick' | 'onChange'
>;

const Toggle: React.FC<Props> = ({ className, label, error, children, value, onClick, onChange, containerProps = {}, ...rest }) => {
  const hasError = !!error;

  return (
    <Box {...containerProps}>
      <Flex justifyContent="space-between" alignItems="center">
        <FormFieldLabel>{label}</FormFieldLabel>
        <Box
          onClick={(evt) => {
            evt?.stopPropagation();
            evt?.preventDefault();

            onChange(evt as any);
          }}
        >
          <Flex as={FormControl} alignItems="flex-end" flexDirection="column" isInvalid={hasError}>
            <Flex>
              <Switch isChecked={value} size="lg" {...rest} />
            </Flex>
            {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
          </Flex>
        </Box>
      </Flex>
      {children}
    </Box>
  );
};

Toggle.displayName = 'Toggle';

export const ControlledToggle: React.FC<ControlledProps> = ({ control, name, rules, onChange, isDisabled, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => (
        <Toggle
          error={error?.message}
          isDisabled={isDisabled}
          onChange={() => {
            if (isDisabled) {
              return;
            }

            const result = !onChange || onChange(!field.value);

            if (result) {
              field.onChange(!field.value);
            }
          }}
          value={field.value}
          {...rest}
        />
      )}
    />
  );
};

ControlledToggle.displayName = 'ControlledToggle';

export default Toggle;
