import { NotificationChannelReference } from '@um/uptime-monitoring-shared';
import { PartialDeep } from 'type-fest';

export type MonitorGroupFormModel = {
  name: string;
  description?: string;
  monitorIds?: string[];
  notifyOfStatusChange?: boolean;
  suppressIndividualMonitorNotifications?: boolean;
  notificationChannels: NotificationChannelReference[];
};

export const MonitorGroupFormModelDefaultValues: PartialDeep<MonitorGroupFormModel> = {
  name: '',
  description: '',
  monitorIds: [],
  notifyOfStatusChange: false,
  suppressIndividualMonitorNotifications: false,
  notificationChannels: [],
};
