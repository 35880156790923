import React from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { IntegrationType, PlanFeature } from '@um/uptime-monitoring-shared';
import Select, { components, Props, SingleValueProps } from 'react-select';
import { IntegrationTypeOptions } from '../../models/integrationTypeOptions';
import useHasFeature from '../../hooks/useHasFeature';
import { useAppDispatch } from '../../app/store';
import actions from '../../app/actions';
import { UpgradeModalReason } from '../../app/model/upgradeModalReason';
import { selectOptionWithDescription } from '../../utils/selectOptionWithDescription';

const integrationTypeOptions = IntegrationTypeOptions.map(({ type, name, icon, description }) =>
  selectOptionWithDescription(type, name, description, icon)
);

const SingleValue = ({ ...props }: SingleValueProps<any>) => {
  return <components.SingleValue {...props}>{props.data.chipLabel}</components.SingleValue>;
};

type SelectProps = Props & {
  error: any;
};

const IntegrationTypeSelect: React.FC<SelectProps> = ({ value, error, onChange, ...rest }) => {
  const dispatch = useAppDispatch();
  const hasSmsIntegrationFeature = useHasFeature(PlanFeature.SMS_NOTIFICATION_INTEGRATION);
  const handleOnChange = (newValue, actionMeta) => {
    if (!hasSmsIntegrationFeature && newValue?.value === IntegrationType.SMS) {
      dispatch(actions.upgrade.showUpgradeModal(UpgradeModalReason.SMS_INTEGRATION_NOT_AVAILABLE));
      return;
    }

    onChange(newValue, actionMeta);
  };

  return (
    <FormControl isInvalid={!!error}>
      <Select
        options={integrationTypeOptions}
        components={{ SingleValue }}
        isSearchable={false}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (styles) => ({
            ...styles,
            borderColor: error ? 'red' : styles.borderColor,
            '&:hover': {
              borderColor: error ? 'red' : styles['&:hover']['borderColor'],
            },
          }),
        }}
        value={integrationTypeOptions.find((option) => option.value === value) || ''}
        onChange={handleOnChange}
        {...rest}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default IntegrationTypeSelect;
