import React, { useState } from 'react';
import NotificationIntegrationListItem, { IntegrationListSegmentProps } from './notificationIntegrationListItem';
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import Toggle from '../toggle/toggle';
import IntegrationStatusText from './integrationStatusText';
import UniversalTooltip from '../universal-tooltip/universalTooltip';
import icons from '../../icons';

const verifyMessage =
  'We have sent a verification email to this address. You must verify this email address by clicking a link in the message before we can send you notifications.';

const EmailIntegrationListSegment: React.FC<IntegrationListSegmentProps> = ({ integration, onToggleIntegration }) => {
  const [enabled, setEnabled] = useState(integration.enabled);

  const handleToggleIntegration = (evt) => {
    const updated = !enabled;
    setEnabled(updated);

    onToggleIntegration(evt, integration.id, updated);
  };

  return (
    <NotificationIntegrationListItem integration={integration}>
      <Flex gap={4} alignItems="center">
        <Box>
          <Icon as={icons.EmailIcon} boxSize={12} />
        </Box>
        <Flex flexDirection="column" overflow="hidden" textOverflow="ellipsis" gap={2} flexGrow={1}>
          <Text as="div" fontSize="xl" fontWeight="bold">
            {integration.name}
          </Text>
          <Box whiteSpace="nowrap">
            <Tooltip label={integration.emailOptions.recipient}>{integration.emailOptions.recipient}</Tooltip>
          </Box>
          <Box>
            <Tooltip label={!integration.verified ? verifyMessage : ''}>
              <IntegrationStatusText integration={integration} />
            </Tooltip>
          </Box>
        </Flex>
        <Box>
          <Toggle value={enabled} onChange={handleToggleIntegration} />
        </Box>
      </Flex>
    </NotificationIntegrationListItem>
  );
};

export default EmailIntegrationListSegment;
