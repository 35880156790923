import React, { useEffect } from 'react';
import IntegrationForm, { IntegrationFormModel } from '../integration-form/integrationForm';
import { useAppDispatch, useAppSelector } from '../../app/store';
import actions from '../../app/actions';
import toast from 'react-hot-toast';
import { IntegrationResponse } from '@um/uptime-monitoring-shared';
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';

export type CreateIntegrationFormProps = {
  onCreated?: (integration?: IntegrationResponse) => any;
  onError?: (err?: any) => any;
  children?: React.ReactNode;
  showCard?: boolean;
};

const CreateIntegrationForm: React.FC<CreateIntegrationFormProps> = ({ onCreated, onError, showCard = true }) => {
  const { inProgress, created, error, integration } = useAppSelector((state) => state.createIntegration);
  const dispatch = useAppDispatch();
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  const handleSubmit = (data: IntegrationFormModel) => {
    dispatch(actions.notifications.createNotificationIntegration({ organizationId, ...data }));
  };

  useEffect(() => {
    if (created) {
      toast.success('Integration created');
      onCreated && onCreated(integration);
    }
  }, [created]);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(actions.createIntegration.reset());
    };
  }, []);

  return (
    <IntegrationForm disabled={inProgress} error={error} onSubmit={handleSubmit} showCard={showCard}>
      {({ onSubmit }) => (
        <Flex justifyContent="center">
          <Button isDisabled={inProgress} colorScheme={'blue'} onClick={onSubmit} width="min-content" size="lg">
            Create integration
          </Button>
        </Flex>
      )}
    </IntegrationForm>
  );
};

export const CreateIntegrationFormModal = ({ open, onClose, onCreated }) => {
  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create notification integration</ModalHeader>
        <ModalCloseButton />
        <ModalBody px={0}>
          <CreateIntegrationForm showCard={false} onCreated={onCreated} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateIntegrationForm;
