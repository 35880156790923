"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotaUsagePeriodType = exports.QuotaUsageEntityType = void 0;
var QuotaUsageEntityType;
(function (QuotaUsageEntityType) {
    QuotaUsageEntityType["ORGANIZATION"] = "ORGANIZATION";
})(QuotaUsageEntityType = exports.QuotaUsageEntityType || (exports.QuotaUsageEntityType = {}));
var QuotaUsagePeriodType;
(function (QuotaUsagePeriodType) {
    QuotaUsagePeriodType["MONTH"] = "MONTH";
})(QuotaUsagePeriodType = exports.QuotaUsagePeriodType || (exports.QuotaUsagePeriodType = {}));
