"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromMonitorStatus = exports.IncidentStatusValues = exports.IncidentStatus = void 0;
const monitorStatus_1 = require("./monitorStatus");
var IncidentStatus;
(function (IncidentStatus) {
    IncidentStatus["CREATED"] = "CREATED";
    IncidentStatus["CONFIRMING"] = "CONFIRMING";
    IncidentStatus["FALSE_POSITIVE"] = "FALSE_POSITIVE";
    IncidentStatus["RESOLVED"] = "RESOLVED";
    IncidentStatus["ALERTING"] = "ALERTING";
    IncidentStatus["GRACE_PERIOD"] = "GRACE_PERIOD";
    IncidentStatus["RESOLVED_DURING_GRACE_PERIOD"] = "RESOLVED_DURING_GRACE_PERIOD";
    IncidentStatus["SERVER_TIMEOUT"] = "SERVER_TIMEOUT";
})(IncidentStatus = exports.IncidentStatus || (exports.IncidentStatus = {}));
exports.IncidentStatusValues = Object.values(IncidentStatus);
const fromMonitorStatus = (status) => {
    switch (status) {
        case monitorStatus_1.MonitorStatus.SUCCESS:
            return IncidentStatus.RESOLVED;
        case monitorStatus_1.MonitorStatus.ALERTING:
            return IncidentStatus.ALERTING;
        default:
            return null;
    }
};
exports.fromMonitorStatus = fromMonitorStatus;
