import { Box, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Select from 'react-select/async';
import statusPingClient from '../../app/client/statusPingClient';
import { operationWrapper } from '../../utils/operationWrapper';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../app/store';
import selectors from '../../app/selectors';

const getUserOptions = async (query: string) => {
  const users = await statusPingClient.privateGetUsers(query);

  return users.map((user) => ({ value: user.id, label: user.email }));
};

const ImpersonateUserForm = () => {
  const [, setQuery] = useState('');
  const [userId, setUserId] = useState('');
  const impersonated = useAppSelector(selectors.selectIsImpersonated);
  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    const loadDefaultOptions = async () => {
      setDefaultOptions(await getUserOptions(''));
    };

    loadDefaultOptions();
  }, []);

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    setQuery(inputValue);
    return inputValue;
  };

  const loadOptions = async (inputValue: string) => {
    return await getUserOptions(inputValue);
  };

  const handleImpersonate = () => {
    const impersonate = operationWrapper(
      async () => {
        await statusPingClient.privatePostUserImpersonate(userId);
      },
      {
        successCallback: () => {
          toast.success('Impersonation successful');
          window.location.reload();
        },
        errorText: 'Error impersonating',
      }
    );

    impersonate();
  };

  const handleCancelImpersonation = () => {
    const cancelImpersonation = operationWrapper(
      async () => {
        await statusPingClient.privatePostCancelImpersonation();
      },
      {
        successCallback: () => {
          toast.success('Impersonation cancel successful');
          window.location.reload();
        },
        errorText: 'Error canceling impersonation',
      }
    );

    cancelImpersonation();
  };

  return (
    <Box>
      {!impersonated && (
        <Box width="100%" display="flex" gap="2">
          <Box width="100%">
            <Select
              defaultOptions={defaultOptions}
              loadOptions={loadOptions}
              cacheOptions
              onInputChange={handleInputChange}
              onChange={(option: any) => setUserId(option.value)}
            />
          </Box>
          <Box>
            <Button onClick={handleImpersonate} colorScheme="blue">
              Impersonate
            </Button>
          </Box>
        </Box>
      )}
      {impersonated && (
        <Box width="100%">
          <Button onClick={handleCancelImpersonation} colorScheme="blue">
            Cancel impersonation
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ImpersonateUserForm;
