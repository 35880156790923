"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentUpdatedEvent = exports.IncidentCreatedEvent = exports.StatusPageIncidentCreatedEvent = exports.StatusPageIncidentUpdatedEvent = void 0;
const applicationEvent_1 = require("./applicationEvent");
class StatusPageIncidentUpdatedEvent extends applicationEvent_1.ApplicationEvent {
    constructor(statusPageId, statusPageIncidentId, organizationId) {
        super(true, organizationId);
        this.statusPageId = statusPageId;
        this.statusPageIncidentId = statusPageIncidentId;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.STATUS_PAGE_INCIDENT_UPDATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.STATUS_PAGE_INCIDENT_UPDATED;
    }
}
exports.StatusPageIncidentUpdatedEvent = StatusPageIncidentUpdatedEvent;
class StatusPageIncidentCreatedEvent extends applicationEvent_1.ApplicationEvent {
    constructor(statusPageId, statusPageIncidentId, organizationId) {
        super(true, organizationId);
        this.statusPageId = statusPageId;
        this.statusPageIncidentId = statusPageIncidentId;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.STATUS_PAGE_INCIDENT_CREATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.STATUS_PAGE_INCIDENT_CREATED;
    }
}
exports.StatusPageIncidentCreatedEvent = StatusPageIncidentCreatedEvent;
class IncidentCreatedEvent extends applicationEvent_1.ApplicationEvent {
    constructor(incidentId, monitorId, monitorGroupId, organizationId) {
        super(true, organizationId);
        this.incidentId = incidentId;
        this.monitorId = monitorId;
        this.monitorGroupId = monitorGroupId;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.INCIDENT_CREATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.INCIDENT_CREATED;
    }
}
exports.IncidentCreatedEvent = IncidentCreatedEvent;
class IncidentUpdatedEvent extends applicationEvent_1.ApplicationEvent {
    constructor(incidentId, monitorId, monitorGroupId, organizationId) {
        super(true, organizationId);
        this.incidentId = incidentId;
        this.monitorId = monitorId;
        this.monitorGroupId = monitorGroupId;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.INCIDENT_UPDATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.INCIDENT_UPDATED;
    }
}
exports.IncidentUpdatedEvent = IncidentUpdatedEvent;
