import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Button, Flex, StackDivider, VStack } from '@chakra-ui/react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import UserNotificationSettingsFields from '../user-notification-settings-form/userNotificationSettingsFields';
import UserProfileFormFields from '../user-profile-form/userProfileFormFields';
import toast from 'react-hot-toast';
import { logError } from '../../utils/logError';
import { UserProfileFormModel } from '../user-profile-form/userProfileForm';
import statusPingClient from '../../app/client/statusPingClient';
import { UserResponse, UserSettingsResponse } from '@um/uptime-monitoring-shared';
import Spinner from '../spinner/spinner';
import ControlledCheckbox from '../checkbox/checkbox';
import WebsiteLink from '../website-link/websiteLink';
import useDefaultUserTimezone from '../../hooks/useDefaultUserTimezone';

export type CompleteUserProfileFormModel = UserProfileFormModel & {
  acceptedTerms: boolean;
};

type Props = {
  onProfileCompleted?: () => void;
};

const CompleteUserProfileForm: React.FC<Props> = ({ onProfileCompleted }) => {
  const [initialized, setInitialized] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [user, setUser] = useState<UserResponse>(null);
  const [userSettings, setUserSettings] = useState<UserSettingsResponse>(null);
  const formMethods = useForm<CompleteUserProfileFormModel>();
  const { handleSubmit, reset, watch } = formMethods;
  const defaultTimezone = useDefaultUserTimezone();

  useEffect(() => {
    const fetchUser = async () => {
      const [user, userSettings] = await Promise.all([statusPingClient.protectedGetUser(), statusPingClient.protectedGetUserSettings()]);
      setUser(user);
      setUserSettings(userSettings);
    };

    if (!initialized) {
      fetchUser();
    }
  }, [initialized]);

  useEffect(() => {
    if (!user || !userSettings) {
      return;
    }

    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      timezone: user?.timezone || defaultTimezone?.name,
      subscribedToProductNewsletter: userSettings?.subscribedToProductNewsletter,
      subscribedToWeeklyMonitorReport: userSettings?.subscribedToWeeklyMonitorReport,
      acceptedTerms: false,
    });
    setInitialized(true);
  }, [user, userSettings]);

  const submit = async (data: CompleteUserProfileFormModel) => {
    setInProgress(true);

    try {
      await statusPingClient.protectedPostUserProfile(data);

      toast.success('Thanks for completing your profile setup!');

      if (onProfileCompleted) {
        onProfileCompleted();
      }
    } catch (e) {
      toast.success('Error updating notification settings. Please try again later');
      logError(e);
      setInProgress(false);
    }
  };
  const acceptedTerms = watch('acceptedTerms');

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submit)}>
        <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
          {!initialized && <Spinner />}
          {initialized && (
            <>
              <VStack divider={<StackDivider borderColor="gray.200" />} width="100%" spacing={4} align="stretch">
                <UserProfileFormFields />
                <FormFieldWrapper title="Notification settings">
                  <UserNotificationSettingsFields />
                </FormFieldWrapper>
                <ControlledCheckbox name="acceptedTerms">
                  I accept the{' '}
                  <WebsiteLink href="https://statusping.co/privacy" target="_blank">
                    Privacy Policy
                  </WebsiteLink>{' '}
                  and{' '}
                  <WebsiteLink href="https://statusping.co/terms" target="_blank">
                    Terms of Service
                  </WebsiteLink>
                </ControlledCheckbox>
              </VStack>
              <Button
                type="submit"
                isDisabled={inProgress || !acceptedTerms}
                isLoading={inProgress}
                colorScheme={'blue'}
                size="lg"
                width="min-content"
                mt={8}
              >
                Complete your profile
              </Button>
            </>
          )}
        </Flex>
      </form>
    </FormProvider>
  );
};

export default CompleteUserProfileForm;
