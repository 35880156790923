"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteOldNotActivatedUsersMessage = void 0;
const genericTaskMessage_1 = require("./genericTaskMessage");
class DeleteOldNotActivatedUsersMessage extends genericTaskMessage_1.GenericTaskMessage {
    constructor() {
        super(genericTaskMessage_1.GenericTaskMessageType.DELETE_OLD_NOT_ACTIVATED_USERS);
    }
}
exports.DeleteOldNotActivatedUsersMessage = DeleteOldNotActivatedUsersMessage;
