"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecuteMonitorMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class ExecuteMonitorMessage extends workerMessage_1.WorkerMessage {
    constructor(monitor, ignoreInProgress = false, excludedRegions = []) {
        super(model_1.QueueDefinitions.EXECUTE_MONITOR);
        this.monitor = monitor;
        this.ignoreInProgress = ignoreInProgress;
        this.excludedRegions = excludedRegions;
    }
}
exports.ExecuteMonitorMessage = ExecuteMonitorMessage;
