import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { operationWrapper } from '../../utils/operationWrapper';
import statusPingClient from '../../app/client/statusPingClient';
import toast from 'react-hot-toast';
import { logError } from '../../utils/logError';
import Input from '../input/input';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';

const InviteOrganizationUserModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  useEffect(() => {
    return () => {
      setEmail('');
      setLoading(false);
    };
  }, []);

  const onInviteUser = () => {
    const inviteUser = operationWrapper(
      async () => {
        setLoading(true);

        await statusPingClient.protectedPostOrganizationUser(organizationId, email);
      },
      {
        successCallback: () => {
          toast.success(`${email} has been invited`);
          onClose();
        },
        errorCallback: (error) => {
          const errorMessage = error?.response?.data?.message;

          toast.error(errorMessage || 'Error occurred. Please try again later');
          logError(error);
        },
        finallyCallback: () => {
          setLoading(false);
        },
      }
    );

    inviteUser();
  };

  return (
    <Modal isOpen={open} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite user</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input placeholder="Email address" type="email" onChange={(evt) => setEmail(evt.target.value)} value={email} />
        </ModalBody>
        <ModalFooter gap={4}>
          <Button variant="outline" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onInviteUser} isLoading={loading} disabled={loading}>
            Invite user
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteOrganizationUserModal;
