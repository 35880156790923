import { createSlice } from '@reduxjs/toolkit';
import { DowntimeStatisticsVO, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';

type SliceType = {
  initialized: boolean;
  monitors: ProtectedMonitorDetailsResponse[];
  downtimeStatistics: DowntimeStatisticsVO[];
};

const initialState: SliceType = {
  initialized: false,
  monitors: [],
  downtimeStatistics: [],
};

export const monitors = createSlice({
  name: 'monitors',
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      state.initialized = action.payload;
      return state;
    },
    setMonitors: (state, action) => {
      state.monitors = action.payload;
      return state;
    },
    setDowntimeStatistics: (state, action) => {
      state.downtimeStatistics = action.payload;
      return state;
    },
  },
});

export default monitors.reducer;
