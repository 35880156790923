import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { RootState } from '../../store';
import { logError } from '../../../utils/logError';

export function* initialize(action) {
  try {
    const monitorId = action.payload;

    const monitor = yield call(statusPingClient.protectedGetMonitor, monitorId);

    yield put(actions.monitorDetails.setMonitor(monitor));
  } catch (e) {
    logError(e);
  } finally {
    yield put(actions.monitorDetails.setInitialized(true));
  }
}

function* onMonitorUpdatedEvent(action) {
  try {
    const { initialized, monitor: originalMonitor } = yield select((state: RootState) => state.monitorDetails);
    const monitor = action.payload;

    if (!initialized || monitor.id !== originalMonitor?.id) {
      return;
    }

    if (monitor.updatedAt > originalMonitor.updatedAt) {
      yield put(actions.monitorDetails.setMonitor(monitor));
    }
  } catch (e) {
    logError(e);
  }
}

export default function* monitorsSaga() {
  yield all([
    takeEvery(actions.monitorDetails.initialize.type, initialize),
    takeEvery(actions.websocket.onMonitorUpdatedEvent.type, onMonitorUpdatedEvent),
  ]);
}
