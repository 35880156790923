"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentCreatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class IncidentCreatedMessage extends workerMessage_1.WorkerMessage {
    constructor(incident) {
        super(model_1.QueueDefinitions.INCIDENT_CREATED);
        this.incident = incident;
    }
}
exports.IncidentCreatedMessage = IncidentCreatedMessage;
