"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionStatusValues = exports.SubscriptionStatus = void 0;
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["ACTIVE"] = "ACTIVE";
    SubscriptionStatus["CANCELED"] = "CANCELED";
    SubscriptionStatus["INACTIVE"] = "INACTIVE";
})(SubscriptionStatus = exports.SubscriptionStatus || (exports.SubscriptionStatus = {}));
exports.SubscriptionStatusValues = Object.values(SubscriptionStatus);
