import { Organization } from '../../app/model/organization';
import React, { useCallback, useState } from 'react';
import MonitorGroupForm from '../monitor-group-form/monitorGroupForm';
import { useMonitorGroupMutation, useMonitorGroupQuery } from '../../query/queries';
import toast from 'react-hot-toast';
import Spinner from '../spinner/spinner';
import { MutationAction } from '../../query/mutationAction';
import mutationDefaults from '../../query/mutationDefaults';
import { useEntityNotificationChannelAssignmentsQuery } from '../../query/notificationChannelQueries';
import { MonitorGroupFormModel } from '../monitor-group-form/monitorGroupFormModel';

export type EditMonitorFormProps = {
  monitorGroupId: string;
  onEdited?: (project?: Organization) => any;
  onError?: (err?: any) => any;
};

const EditMonitorGroupForm: React.FC<EditMonitorFormProps> = ({ monitorGroupId, onEdited, onError }) => {
  const [formErrors, setFormErrors] = useState([]);

  const { isFetched, data: monitorGroup } = useMonitorGroupQuery(monitorGroupId);
  const { data: notificationChannels, isFetched: notificationChannelsFetched } = useEntityNotificationChannelAssignmentsQuery({ monitorGroupId });
  const { isLoading: mutationLoading, mutateAsync } = useMonitorGroupMutation();
  const initialized = isFetched && notificationChannelsFetched;
  const loading = mutationLoading || !isFetched || !notificationChannelsFetched;

  const handleSubmit = useCallback(
    async (data: MonitorGroupFormModel) => {
      await mutateAsync(
        { monitorGroupId, monitorGroup: data, action: MutationAction.PATCH },
        {
          onSuccess: () => toast.success('Monitor group updated'),
          onError: mutationDefaults.onErrorWithFormHandling(setFormErrors, onError),
        }
      );
    },
    [monitorGroupId]
  );

  const formValues: MonitorGroupFormModel = {
    ...monitorGroup,
    notificationChannels,
  };

  if (!initialized) {
    return <Spinner />;
  }

  return <MonitorGroupForm formValues={formValues} mainActionText="Save" disabled={loading} formErrors={formErrors} onSubmit={handleSubmit} />;
};

export default EditMonitorGroupForm;
