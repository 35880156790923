import { useFormContext } from 'react-hook-form';
import React from 'react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { MonitorFormModel } from '../../models/monitorFormModel';
import { ControlledInput } from '../input/input';
import { ControlledNumberInput } from '../number-input/numberInput';
import { CheckInPeriodType } from '@um/uptime-monitoring-shared';
import formValidationRules from '../../utils/formValidationRules';
import { ControlledTimezoneSelect } from '../timezone-select/timezoneSelect';
import { ControlledCheckInPeriodTypeSelector } from '../check-in-schedule-type-selector/checkInPeriodTypeSelector';
import useDebounce from '../../hooks/useDebounce';
import CronExpressionSchedules from '../cron-expression-schedules/cronExpressionSchedules';

const { required } = formValidationRules;

const DomainNameMonitorOptionsFormFields: React.FC = () => {
  const { control, watch } = useFormContext<MonitorFormModel>();

  const periodType = watch('checkInMonitorOptions.periodType');
  const cronExpression = watch('checkInMonitorOptions.periodCronExpression');
  const timezone = watch('checkInMonitorOptions.timezone');
  const debouncedCronExpression = useDebounce(cronExpression, 1000);

  const isSimpleExpression = periodType === CheckInPeriodType.SIMPLE;
  const isCronExpression = periodType === CheckInPeriodType.CRON;

  return (
    <>
      <FormFieldWrapper title="Scheduled job interval" description="At what interval does the job run">
        <Flex flexDirection="column" gap={4}>
          <ControlledCheckInPeriodTypeSelector name="checkInMonitorOptions.periodType" control={control} />
          <Divider borderColor="gray.300" />
          {isSimpleExpression && (
            <Flex flexDirection="column">
              <Flex gap={2} alignItems="center">
                <Box>Every</Box>
                <ControlledNumberInput
                  control={control}
                  name="checkInMonitorOptions.periodMinutes"
                  inputWrapperProps={{ min: 0, max: 60, maxWidth: 20, size: 'sm' }}
                  rules={{
                    validate: formValidationRules.conditionalRequired(isSimpleExpression),
                  }}
                />
                <Box>minutes</Box>
                <ControlledNumberInput
                  control={control}
                  name="checkInMonitorOptions.periodHours"
                  inputWrapperProps={{ min: 0, max: 24, maxWidth: 20, size: 'sm' }}
                  rules={{
                    validate: formValidationRules.conditionalRequired(isSimpleExpression),
                  }}
                />
                <Box>hours</Box>
                <ControlledNumberInput
                  control={control}
                  name="checkInMonitorOptions.periodDays"
                  inputWrapperProps={{ min: 0, max: 365, maxWidth: 20, size: 'sm' }}
                  rules={{
                    validate: formValidationRules.conditionalRequired(isSimpleExpression),
                  }}
                />
                <Box>days</Box>
              </Flex>
            </Flex>
          )}
          {isCronExpression && (
            <Flex flexDirection="column" gap={2}>
              <ControlledInput
                name="checkInMonitorOptions.periodCronExpression"
                isDisabled={!isCronExpression}
                placeholder="CRON expression"
                rules={{
                  validate: formValidationRules.conditionalRequired(isCronExpression),
                }}
                control={control}
              />
              <CronExpressionSchedules expression={debouncedCronExpression} timezone={timezone} />
            </Flex>
          )}
        </Flex>
      </FormFieldWrapper>
      {isCronExpression && (
        <>
          <Divider my={4} borderColor="gray.300" />
          <FormFieldWrapper title="Server timezone" description="We'll use this timezone to align the expected job execution times">
            <ControlledTimezoneSelect name="checkInMonitorOptions.timezone" control={control} placeholder="Timezone" rules={{ required }} />
          </FormFieldWrapper>
        </>
      )}
      <Divider my={4} borderColor="gray.300" />
      <FormFieldWrapper
        title="Scheduled job grace period"
        description="How long to wait after the job was supposed to start before considering it failed. This period should be the maximum amount of time you expect your job to run"
      >
        <Flex gap={2} alignItems="center">
          <ControlledNumberInput
            control={control}
            name="checkInMonitorOptions.gracePeriod"
            inputWrapperProps={{ min: 1, maxWidth: 32, size: 'sm' }}
          />
          minutes
        </Flex>
      </FormFieldWrapper>
    </>
  );
};

export default DomainNameMonitorOptionsFormFields;
