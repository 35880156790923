"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorStatusValues = exports.MonitorStatus = void 0;
var MonitorStatus;
(function (MonitorStatus) {
    MonitorStatus["NONE"] = "NONE";
    MonitorStatus["SUCCESS"] = "SUCCESS";
    MonitorStatus["ERROR"] = "ERROR";
    MonitorStatus["ALERTING"] = "ALERTING";
    MonitorStatus["IN_PROGRESS"] = "IN_PROGRESS";
    MonitorStatus["GRACE_PERIOD"] = "GRACE_PERIOD";
    MonitorStatus["IDLE"] = "IDLE";
})(MonitorStatus = exports.MonitorStatus || (exports.MonitorStatus = {}));
exports.MonitorStatusValues = Object.values(MonitorStatus);
