import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { CreateOrganizationTrialVO, OrganizationResponse, SetupOrganizationVO, UpdateOrganizationVO } from '@um/uptime-monitoring-shared';
import { MutationAction } from './mutationAction';
import Cookies from 'js-cookie';
import { getSelectedOrganizationId, SelectedOrganizationIdCookie } from '../utils/getSelectedOrganizationId';

export interface SetupOrganizationMutationParameters {
  organizationId: string;
  setupRequest: SetupOrganizationVO;
  action: MutationAction;
}

export interface CreateOrganizationTrialMutationParameters {
  organizationId: string;
  createRequest: CreateOrganizationTrialVO;
  action: MutationAction;
}

export interface UpdateOrganizationMutationParameters {
  organizationId: string;
  updateRequest: UpdateOrganizationVO;
  action: MutationAction;
}

export const useOrganizationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<OrganizationResponse, any, UpdateOrganizationMutationParameters>(
    async ({ organizationId, updateRequest, action }: UpdateOrganizationMutationParameters) => {
      if (action === MutationAction.PATCH) {
        return await statusPingClient.protectedPatchOrganization(organizationId, updateRequest);
      }

      return null;
    },
    {
      onSuccess: async (data: OrganizationResponse) => {
        queryClient.invalidateQueries(['selectedOrganization']);
        queryClient.invalidateQueries(['organization', data?.id]);
        queryClient.invalidateQueries(['organizations']);
      },
    }
  );
};

export const useOrganizationSetupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<OrganizationResponse, any, SetupOrganizationMutationParameters>(
    async ({ organizationId, setupRequest, action }: SetupOrganizationMutationParameters) => {
      if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostOrganizationSetup(organizationId, setupRequest);
      }

      return null;
    },
    {
      onSuccess: async (data: OrganizationResponse) => {
        queryClient.invalidateQueries(['selectedOrganization']);
        queryClient.invalidateQueries(['organization', data?.id]);
        queryClient.invalidateQueries(['organizations']);
      },
    }
  );
};

export const useOrganizationTrialMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<OrganizationResponse, any, CreateOrganizationTrialMutationParameters>(
    async ({ organizationId, createRequest, action }: CreateOrganizationTrialMutationParameters) => {
      if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostOrganizationTrial(organizationId, createRequest);
      }

      return null;
    },
    {
      onSuccess: async (data: OrganizationResponse) => {
        queryClient.invalidateQueries(['selectedOrganization']);
        queryClient.invalidateQueries(['organization', data?.id]);
        queryClient.invalidateQueries(['organizations']);
      },
    }
  );
};

export const useSelectedOrganizationIdQuery = () => {
  const { data: organizations, isLoading: organizationsLoading } = useOrganizationsQuery();

  return useQuery(
    [SelectedOrganizationIdCookie],
    () => {
      const id = getSelectedOrganizationId();

      if (!organizations || (id && organizations.some((org) => org.id === id))) {
        return id;
      }

      const organization = organizations[0];
      Cookies.set(SelectedOrganizationIdCookie, organization.id);

      return organization.id;
    },
    {
      enabled: !organizationsLoading,
      keepPreviousData: true,
      refetchOnMount: true,
    }
  );
};

export const useOrganizationsQuery = () => {
  return useQuery(['organizations'], async () => {
    return await statusPingClient.protectedGetUserOrganizations();
  });
};

export const useSelectedOrganizationQuery = (enabled = true) => {
  const { data: organizationId, isLoading: organizationIdLoading } = useSelectedOrganizationIdQuery();
  const { data: organizations, isLoading: organizationsLoading } = useOrganizationsQuery();

  return useQuery(
    ['selectedOrganization', organizationId, ...(organizations || []).map((i) => i.updatedAt)],
    async () => {
      const result = organizations.find((organization) => organization.id === organizationId) || organizations[0];

      return result || null;
    },
    {
      enabled: !organizationIdLoading && !organizationsLoading && enabled,
      refetchOnMount: 'always',
      keepPreviousData: true,
    }
  );
};

export const useSelectedOrganizationSubscriptionsQuery = () => {
  const { data: organizationId, isLoading: organizationIdLoading } = useSelectedOrganizationIdQuery();

  return useQuery(
    ['organization', 'subscriptions', organizationId],
    async () => {
      return await statusPingClient.protectedGetUserOrganizationSubscriptions(organizationId);
    },
    {
      enabled: !organizationIdLoading,
      refetchOnMount: 'always',
      keepPreviousData: true,
    }
  );
};

export const useSelectedOrganizationFeaturesQuery = () => {
  const { data: organization, isLoading } = useSelectedOrganizationQuery();

  return useQuery(
    ['organization', organization?.id, organization?.updatedAt, 'features'],
    async () => {
      return organization.features;
    },
    {
      enabled: !isLoading,
      refetchOnMount: 'always',
      keepPreviousData: true,
    }
  );
};

export const useSelectOrganizationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, { organizationId: string }>(
    async ({ organizationId }) => {
      Cookies.set(SelectedOrganizationIdCookie, organizationId, { expires: new Date('2100-01-01') });
    },
    { onSuccess: () => queryClient.invalidateQueries([SelectedOrganizationIdCookie]) }
  );
};
