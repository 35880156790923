import { useContext, useEffect } from 'react';
import { AppConfig } from '../utils/config';
import * as amplitude from '@amplitude/analytics-browser';
import { useAppSelector } from '../app/store';
import selectors from '../app/selectors';
import { CookieConsentContext } from '../components/cookie-consent/cookieConsent';

const useAmplitude = () => {
  const user = useAppSelector(selectors.selectUser);
  const { allCookiesConsentGiven } = useContext(CookieConsentContext);

  useEffect(() => {
    if (!allCookiesConsentGiven || !user || user.impersonated) {
      return;
    }

    if (AppConfig.AmplitudeApiKey && AppConfig.AmplitudeServerUrl) {
      amplitude.init(AppConfig.AmplitudeApiKey, user.id, { serverUrl: AppConfig.AmplitudeServerUrl });

      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('email', user.email);

      amplitude.identify(identifyEvent);
    }
  }, [user, allCookiesConsentGiven]);

  return null;
};

export default useAmplitude;
