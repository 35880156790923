import React from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';

type Props = {
  title: React.ReactNode;
  content: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
  open?: boolean;
  confirmActionText?: string;
};

const ConfirmationModal: React.FC<Props> = ({ title, confirmActionText = 'Confirm', content, onConfirm, onClose, loading, open }) => {
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm} isLoading={loading} disabled={loading}>
            {confirmActionText}
          </Button>
          <Button variant="outline" disabled={loading} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
