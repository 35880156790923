import React, { useEffect, useState } from 'react';
import PageWrapper from '../components/page-wrapper/pageWrapper';
import PageHeader from '../components/page-header/pageHeader';
import { Flex, Input, StackDivider, VStack } from '@chakra-ui/react';
import Card from '../components/card/card';
import FormFieldWrapper from '../components/form-field-wrapper/formFieldWrapper';
import UpdateUserProfileForm from '../components/user-profile-form/updateUserProfileForm';
import statusPingClient from '../app/client/statusPingClient';
import { UserResponse, UserSettingsResponse } from '@um/uptime-monitoring-shared';

const UserPage = () => {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<UserResponse>(null);
  const [userSettings, setUserSettings] = useState<UserSettingsResponse>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const [user, userSettings] = await Promise.all([statusPingClient.protectedGetUser(), statusPingClient.protectedGetUserSettings()]);
      setUser(user);
      setUserSettings(userSettings);

      setInitialized(true);
    };

    if (!initialized) {
      fetchUser();
    }
  }, [initialized]);

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title="Your profile" />
      <PageWrapper loading={!initialized} p={4}>
        {initialized && (
          <>
            <Card>
              <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
                <FormFieldWrapper title="Email">
                  <Input value={user?.email} disabled size="lg" />
                </FormFieldWrapper>
                <UpdateUserProfileForm user={user} userSettings={userSettings} />
              </VStack>
            </Card>
          </>
        )}
      </PageWrapper>
    </Flex>
  );
};

export default UserPage;
