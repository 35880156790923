import stringHash from '../utils/stringHash';

export interface QueryResultWithHash<T> {
  hash?: number;
  result?: T;
}

export const toQueryResultWithHash = async <T>(resultPromise: Promise<T>): Promise<QueryResultWithHash<T>> => {
  const result = await resultPromise;
  return {
    hash: stringHash(JSON.stringify(result)),
    result,
  };
};
