"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddUserToMailingListMessage = void 0;
const genericTaskMessage_1 = require("./genericTaskMessage");
class AddUserToMailingListMessage extends genericTaskMessage_1.GenericTaskMessage {
    constructor(user, mailingListType) {
        super(genericTaskMessage_1.GenericTaskMessageType.ADD_USER_TO_MAILING_LIST);
        this.user = user;
        this.mailingListType = mailingListType;
    }
}
exports.AddUserToMailingListMessage = AddUserToMailingListMessage;
