import React, { useMemo } from 'react';
import { CheckInPeriodType, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import DataDisplayErrorBoundary from '../data-display-error-boundary/dataDisplayErrorBoundary';
import DataCard from '../data-card/dataCard';
import useRemainingDuration from '../../hooks/useRemainingDuration';
import { DurationFormat } from '../../utils/durationFormat';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import { DateFormats } from '../../utils/formatToUtc';
import { AppConfig } from '../../utils/config';
import { Icon } from '@chakra-ui/icons';
import { AiOutlineCopy } from 'react-icons/ai';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';

const CheckInMonitorInformation: React.FC<{ monitor: ProtectedMonitorDetailsResponse; showCard?: boolean }> = ({ monitor, showCard = true }) => {
  const { checkInMonitorOptions, checkInMonitorMetadata } = monitor;
  const { lastExpectedCheckIn, expectedNextCheckIn, expectedNextCheckInWithGracePeriod } = checkInMonitorMetadata || {};
  const { periodType, periodCronExpression, periodMinutes, periodHours, periodDays, gracePeriod, timezone } = checkInMonitorOptions;
  const cronExpression = periodType === CheckInPeriodType.CRON;
  const simpleIntervalExpression = useMemo(() => {
    const parts = [
      periodMinutes ? `${periodMinutes} minutes` : null,
      periodHours ? `${periodHours} hours` : null,
      periodDays ? `${periodDays} days` : null,
    ].filter((item) => Boolean(item));

    return `Every ${parts.join(' ')}`;
  }, [periodMinutes, periodHours, periodDays]);
  const checkInUrl = `${AppConfig.CheckInHostname}/check-in/${monitor.checkInId}`;
  const handleCopyCheckInUrl = () => {
    copy(checkInUrl);
    toast.success('Copied to clipboard');
  };

  return (
    <DataDisplayErrorBoundary>
      <DataCard showCard={showCard}>
        <DataCard.Row heading="Check-in URL">
          <Tooltip label="Copy to clipboard">
            <Flex alignItems="center" textDecoration="underline" cursor="pointer" width="100%" maxWidth="fit-content" onClick={handleCopyCheckInUrl}>
              <Box width="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {checkInUrl}
              </Box>
              <Icon as={AiOutlineCopy} boxSize={6} color="gray.400" />
            </Flex>
          </Tooltip>
          <Box mt={2} color="gray.500">
            <Flex flexDirection="column" gap={2}>
              <Box>Call this URL after the scheduled job is done executing to signal a check-in.</Box>
              <Box>For example if it's a CRON job, then you can use curl to call this endpoint after the job is done.</Box>
            </Flex>
          </Box>
        </DataCard.Row>
        <DataCard.Row heading="Time of last received check-in">
          <CheckInTime time={lastExpectedCheckIn} timezone={timezone} />
        </DataCard.Row>
        <DataCard.Row heading="Time of next expected check-in">
          <CheckInTime time={expectedNextCheckIn} timezone={timezone} />
        </DataCard.Row>
        <DataCard.Row heading="Time of next expected check-in with grace period">
          <CheckInTime time={expectedNextCheckInWithGracePeriod} timezone={timezone} />
        </DataCard.Row>
        <DataCard.Row heading="Job interval type">{cronExpression ? 'CRON expression' : 'Simple expression'}</DataCard.Row>
        <DataCard.Row heading="Interval expression">{cronExpression ? periodCronExpression : simpleIntervalExpression}</DataCard.Row>
        <DataCard.Row heading="Grace period">{gracePeriod} minutes</DataCard.Row>
      </DataCard>
    </DataDisplayErrorBoundary>
  );
};

const CheckInTime = ({ time, timezone }) => {
  const [, formattedDuration, remainingMs] = useRemainingDuration(time, DurationFormat.DHMS);

  if (!time) {
    return <Box>-</Box>;
  }

  return (
    <Flex flexDirection="column" gap={1}>
      <Box>{formatInTimeZone(time, 'UTC', DateFormats.FULL_SECONDS_WITH_TZ)}</Box>
      <Box>({formatInTimeZone(time, timezone, DateFormats.FULL_SECONDS_WITH_TZ)} server's timezone)</Box>
      {formattedDuration && (
        <Box mt={2} color="gray.500">
          {remainingMs > 0 ? 'In ' : ''}
          {formattedDuration}
          {remainingMs < 0 ? ' ago' : ''}
        </Box>
      )}
    </Flex>
  );
};

export default CheckInMonitorInformation;
