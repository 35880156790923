"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessNotificationPolicyStateMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class ProcessNotificationPolicyStateMessage extends workerMessage_1.WorkerMessage {
    constructor(notificationPolicyStateId) {
        super(model_1.QueueDefinitions.PROCESS_NOTIFICATION_POLICY_STATE);
        this.notificationPolicyStateId = notificationPolicyStateId;
    }
}
exports.ProcessNotificationPolicyStateMessage = ProcessNotificationPolicyStateMessage;
