import Select, { components, SingleValueProps } from 'react-select';
import { MonitorTransport } from '@um/uptime-monitoring-shared';
import { selectOptionWithDescription } from '../../utils/selectOptionWithDescription';
import React from 'react';
import { Badge, Box, Flex } from '@chakra-ui/react';

const transportOptions = [
  selectOptionWithDescription(MonitorTransport.HTTP, 'HTTP/HTTPS', 'Check availability or functionality of your web services'),
  selectOptionWithDescription(MonitorTransport.ICMP_PING, 'ICMP Ping', 'Check whether your server is up'),
  selectOptionWithDescription(
    MonitorTransport.CHECK_IN,
    <Flex alignItems="center" justifyContent="center" gap={2}>
      Check-in
      <Box>
        <Badge colorScheme="purple">BETA</Badge>
      </Box>
    </Flex>,
    'Monitor your scheduled tasks (CRON or other)'
  ),
];

const SingleValue = ({ ...props }: SingleValueProps<any>) => {
  return <components.SingleValue {...props}>{props.data.chipLabel}</components.SingleValue>;
};

const MonitorTransportSelect = ({ value, error, ...rest }) => {
  const selectedOption = transportOptions.find((option) => option.value === value);

  return (
    <Box>
      <Select
        isSearchable={false}
        options={transportOptions}
        value={selectedOption}
        components={{ SingleValue }}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (styles) => ({
            ...styles,
            borderColor: error ? 'red' : styles.borderColor,
            '&:hover': {
              borderColor: error ? 'red' : styles['&:hover']['borderColor'],
            },
          }),
        }}
        {...rest}
      />
      <Box mt={2} fontSize="md" color="gray.500">
        {selectedOption ? selectedOption.description : null}
      </Box>
    </Box>
  );
};

export default MonitorTransportSelect;
