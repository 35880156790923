import { createContext } from 'react';

export type DataTableRowContextState = {
  hovering?: boolean;
  off: () => void;
  on: () => void;
};

const initialState: DataTableRowContextState = {
  hovering: false,
  off: null,
  on: null,
};

export const DataTableRowContext = createContext<DataTableRowContextState>(initialState);
