import Card from '../card/card';
import { SectionTitle } from '../common-components/informationLayoutComponents';
import { Box, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { MonitorTransport, PlanFeature, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import { CertificateCheckStatus } from '../../utils/getCertificateCheckStatus';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';
import { useAppSelector } from '../../app/store';
import selectors from '../../app/selectors';
import getDomainNameCheckStatus, { DomainNameCheckStatus } from '../../utils/getDomainNameCheckStatus';
import useHasFeature from '../../hooks/useHasFeature';

type Props = { monitor: ProtectedMonitorDetailsResponse };

const colors = {
  text: {
    [CertificateCheckStatus.NOT_CHECKED]: 'gray.400',
    [CertificateCheckStatus.VALID]: 'darkGreen.200',
    [CertificateCheckStatus.EXPIRES_SOON]: 'orange.300',
    [CertificateCheckStatus.EXPIRED]: 'red.300',
  },
};

const DomainNameStatusMonitorCard: React.FC<Props> = ({ monitor }) => {
  const canMonitorDomainName = useHasFeature(PlanFeature.DOMAIN_NAME_MONITORING);
  const { lastChecked, expiryDate, domainName } = monitor.domainNameMonitorMetadata || {};
  const domainNameCheckStatus = useMemo(() => getDomainNameCheckStatus(expiryDate), [expiryDate]);
  const textColor = useMemo(() => colors.text[domainNameCheckStatus], [domainNameCheckStatus]);
  const lastCheckedFormatted = lastChecked ? formatToUtc(new Date(lastChecked), DateFormats.FULL_MINUTES) : null;
  const domainNameExpiryDateFormatted = expiryDate ? formatToUtc(new Date(expiryDate), DateFormats.FULL_DAY) : null;

  if (!monitor || !monitor.monitorDomainName || monitor.transport !== MonitorTransport.HTTP || !canMonitorDomainName) {
    return null;
  }

  return (
    <Card gap={2} alignItems="center" m={0}>
      <SectionTitle>Domain name</SectionTitle>
      <Flex flexDirection="column" alignItems="center" justifyContent="cente" fontWeight="bold" fontSize="md">
        <Box color={textColor}>
          {domainNameCheckStatus === DomainNameCheckStatus.VALID && 'Valid'}
          {domainNameCheckStatus === DomainNameCheckStatus.EXPIRES_SOON && 'Expires soon'}
          {domainNameCheckStatus === DomainNameCheckStatus.EXPIRED && 'Expired'}
          {domainNameCheckStatus === DomainNameCheckStatus.NOT_CHECKED && 'Domain name has not been checked yet'}
        </Box>
        {domainNameCheckStatus !== DomainNameCheckStatus.NOT_CHECKED && <Box>Domain {domainName}</Box>}
        {domainNameCheckStatus !== DomainNameCheckStatus.NOT_CHECKED && <Box>Expires {domainNameExpiryDateFormatted}</Box>}
        {domainNameCheckStatus !== DomainNameCheckStatus.NOT_CHECKED && <Box>Checked {lastCheckedFormatted}</Box>}
      </Flex>
    </Card>
  );
};

export default DomainNameStatusMonitorCard;
