import React from 'react';
import Spinner from '../spinner/spinner';
import { SectionTitle } from '../common-components/informationLayoutComponents';
import { Flex } from '@chakra-ui/react';
import { useMonitorGroupDowntimeStatisticsQuery, useMonitorGroupMonitorsQuery } from '../../query/queries';
import IncidentsTable from '../incidents-table/incidentsTable';
import MonitorOverviewTable from '../monitor-overview-table/monitorOverviewTable';

type Props = { monitorGroupId: string };

const MonitorGroupOverviewTab: React.FC<Props> = ({ monitorGroupId }) => {
  const { isFetched: isFetched1, data: monitors } = useMonitorGroupMonitorsQuery(monitorGroupId);
  const { isFetched: isFetched2, data: downtimeStatistics } = useMonitorGroupDowntimeStatisticsQuery(monitorGroupId);
  const initialized = isFetched1 && isFetched2;

  if (!initialized) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <Flex flexDirection="column" gap={4}>
      <Flex flexDirection="column" gap={2}>
        <SectionTitle>Monitors</SectionTitle>
        <MonitorOverviewTable monitors={monitors} downtimeStatistics={downtimeStatistics} />
      </Flex>
      <Flex flexDirection="column" gap={2}>
        <SectionTitle>Incidents</SectionTitle>
        <IncidentsTable monitorGroupId={monitorGroupId} noMonitorLink />
      </Flex>
    </Flex>
  );
};

export default MonitorGroupOverviewTab;
