import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from '../actions';
import statusPingClient from '../client/statusPingClient';
import { logError } from '../../utils/logError';
import { MonitorFormModel } from '../../models/monitorFormModel';

function* createMonitor(action) {
  try {
    yield put(actions.createMonitor.setInProgress(true));

    const formData: MonitorFormModel = action.payload;

    const monitor = yield call(statusPingClient.protectedPostMonitor, formData);

    yield put(actions.createMonitor.setMonitor(monitor));
    yield put(actions.createMonitor.setCreated(true));
  } catch (e) {
    yield put(actions.createMonitor.setInProgress(false));
    yield put(actions.createMonitor.setError(e));

    logError(e);
  }
}

export default function* integrationsSaga() {
  yield all([takeEvery(actions.createMonitor.createMonitor.type, createMonitor)]);
}
