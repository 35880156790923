import React, { useMemo } from 'react';
import { Box, CardBody, CardHeader, CardHeaderProps, CloseButton, Flex, Heading, Stack, StackDivider } from '@chakra-ui/react';
import Card from '../card/card';
import Spinner from '../spinner/spinner';

type DataCardRowProps = {
  heading?: React.ReactNode;
};
type DataCardHeaderProps = CardHeaderProps & {
  onClose?: () => void;
};

const DataCardHeader: React.FC<DataCardHeaderProps> = ({ children, onClose, ...rest }) => (
  <CardHeader {...rest}>
    <Flex justifyContent="space-between" alignItems="center">
      <Heading size="md" color="gray.600">
        {children}
      </Heading>
      {onClose && <CloseButton size="lg" onClick={onClose} />}
    </Flex>
  </CardHeader>
);

const DataCardRow: React.FC<DataCardRowProps> = ({ heading, children, ...rest }) => (
  <Box>
    {heading && (
      <Heading size="xs" textTransform="uppercase" color="gray.600">
        {heading}
      </Heading>
    )}
    <Box pt="2" fontSize="lg">
      {children}
    </Box>
  </Box>
);

const DataCardStack = ({ ...rest }) => {
  return <Stack divider={<StackDivider />} spacing="4" shouldWrapChildren {...rest} />;
};

type DataCardProps = {
  header?: React.ReactNode;
  loading?: boolean;
  showCard?: boolean;
  footer?: React.ReactNode;
  children?: React.ReactNode | (() => React.ReactNode);
};

type DataCardType = React.FC<DataCardProps> & {
  Header: typeof DataCardHeader;
  Row: typeof DataCardRow;
  Stack: typeof DataCardStack;
};

const DataCard: DataCardType = ({ header, children, loading, showCard = true, footer, ...rest }) => {
  const cardStyles = useMemo(
    () =>
      showCard
        ? {}
        : {
            boxShadow: 'none',
            border: 'none',
            background: 'transparent',
          },
    [showCard]
  );
  const cardBodyStyles = useMemo(() => (showCard ? {} : { padding: 0, background: 'transparent' }), [showCard]);

  return (
    <Card p={0} {...cardStyles}>
      {header}

      <CardBody {...cardBodyStyles}>
        {loading && <Spinner />}
        {!loading && (
          <>
            {typeof children === 'function' ? children() : <DataCardStack>{children}</DataCardStack>}
            {footer}
          </>
        )}
      </CardBody>
    </Card>
  );
};

DataCard.Header = DataCardHeader;
DataCard.Row = DataCardRow;
DataCard.Stack = DataCardStack;

export default DataCard;
