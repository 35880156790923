"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const plans_1 = require("./plans");
const PlanDescriptions = {
    [plans_1.Plan.FREE]: {
        name: 'Free',
        features: [
            { description: '5 monitors', available: true },
            { description: 'No team members', available: false },
            { description: '1 status pages', available: true },
            { description: 'No SMS notifications', available: false },
            { description: 'No status page custom domain', available: false },
            { description: 'No domain expiration monitoring', available: false },
        ],
    },
    [plans_1.Plan.SOLO]: {
        name: 'Solo',
        features: [
            { description: '30 monitors', available: true },
            { description: 'No team members', available: false },
            { description: 'Additional team members', available: false },
            { description: '2 status pages', available: true },
            { description: '30 SMS notifications / month', available: true },
            { description: 'Status page custom domain', available: true },
            { description: 'Domain expiration monitoring', available: true },
        ],
    },
    [plans_1.Plan.TEAM]: {
        name: 'Team',
        features: [
            { description: '100 monitors', available: true },
            { description: '5 team members', available: true },
            { description: 'Additional team members', available: false },
            { description: '10 status pages', available: true },
            { description: '100 SMS notifications / month', available: true },
            { description: 'Status page custom domain', available: true },
            { description: 'Domain expiration monitoring', available: true },
        ],
    },
    [plans_1.Plan.BUSINESS]: {
        name: 'Business',
        features: [
            { description: '300 monitors', available: true },
            { description: '6 team members', available: true },
            { description: 'Additional team members 9€/month/user', available: true },
            { description: 'Unlimited status pages', available: true },
            { description: '250 SMS notifications / month', available: true },
            { description: 'Status page custom domain', available: true },
            { description: 'Domain expiration monitoring', available: true },
        ],
    },
};
exports.default = PlanDescriptions;
