"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeekdayValues = exports.DayISONumberWeekday = exports.WeekdayNames = exports.Weekday = void 0;
var Weekday;
(function (Weekday) {
    Weekday["MONDAY"] = "MONDAY";
    Weekday["TUESDAY"] = "TUESDAY";
    Weekday["WEDNESDAY"] = "WEDNESDAY";
    Weekday["THURSDAY"] = "THURSDAY";
    Weekday["FRIDAY"] = "FRIDAY";
    Weekday["SATURDAY"] = "SATURDAY";
    Weekday["SUNDAY"] = "SUNDAY";
})(Weekday = exports.Weekday || (exports.Weekday = {}));
exports.WeekdayNames = {
    [Weekday.MONDAY]: 'Monday',
    [Weekday.TUESDAY]: 'Tuesday',
    [Weekday.WEDNESDAY]: 'Wednesday',
    [Weekday.THURSDAY]: 'Thursday',
    [Weekday.FRIDAY]: 'Friday',
    [Weekday.SATURDAY]: 'Saturday',
    [Weekday.SUNDAY]: 'Sunday',
};
exports.DayISONumberWeekday = {
    1: Weekday.MONDAY,
    2: Weekday.TUESDAY,
    3: Weekday.WEDNESDAY,
    4: Weekday.THURSDAY,
    5: Weekday.FRIDAY,
    6: Weekday.SATURDAY,
    7: Weekday.SUNDAY,
};
exports.WeekdayValues = Object.values(Weekday);
