import { Organization } from '../../app/model/organization';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/store';
import actions from '../../app/actions';
import MonitorGroupForm from '../monitor-group-form/monitorGroupForm';
import statusPingClient from '../../app/client/statusPingClient';
import { operationWrapper } from '../../utils/operationWrapper';
import toast from 'react-hot-toast';
import { logError } from '../../utils/logError';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { MonitorGroupFormModel, MonitorGroupFormModelDefaultValues } from '../monitor-group-form/monitorGroupFormModel';
import { useOrganizationNotificationChannelOptionsQuery } from '../../query/notificationChannelQueries';
import { MonitorFormDefaultValues } from '../../models/monitorFormModel';
import { notificationChannelOptionToReference } from '../../utils/notificationChannelOptionToReference';
import { PartialDeep } from 'type-fest';

export type CreateMonitorFormProps = {
  onCreated?: (project?: Organization) => any;
  onError?: (err?: any) => any;
};

const CreateMonitorGroupForm: React.FC<CreateMonitorFormProps> = ({ onCreated, onError }) => {
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useAppDispatch();
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isFetched, data: notificationChannels } = useOrganizationNotificationChannelOptionsQuery(organizationId);

  const handleSubmit = useCallback(
    async (data: MonitorGroupFormModel) => {
      const create = operationWrapper(
        async () => {
          const monitorGroup = await statusPingClient.protectedPostMonitorGroup({ ...data, organizationId });

          if (onCreated) {
            onCreated(monitorGroup);
          }
        },
        {
          successCallback: () => {
            toast.success('Monitor group created');

            setInProgress(false);
          },
          errorCallback: (error) => {
            logError(error);
            setInProgress(false);

            toast.error('Error occurred. Please try again later');

            if (onError) {
              onError(error);
            }
          },
        }
      );

      return await create();
    },
    [organizationId]
  );

  useEffect(() => {
    return () => {
      dispatch(actions.createIntegration.reset());
    };
  }, []);

  if (!isFetched) {
    return null;
  }

  const defaultValues: PartialDeep<MonitorGroupFormModel> = {
    ...MonitorGroupFormModelDefaultValues,
    notificationChannels: (notificationChannels || []).map(notificationChannelOptionToReference),
  };

  return <MonitorGroupForm mainActionText="Create monitor group" formValues={defaultValues} disabled={inProgress} onSubmit={handleSubmit} />;
};

export default CreateMonitorGroupForm;
