import { Box, Flex, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { BiReset } from 'react-icons/bi';
import React from 'react';
import useDynamicModal from '../../hooks/useDynamicModal';
import { useResetMonitorMutation } from '../../query/monitorQueries';
import ConfirmationModal from '../confirmation-modal/confirmationModal';

const ResetMonitorButton = ({ monitorId }) => {
  const { mutateAsync, isLoading } = useResetMonitorMutation();

  const { setOpen, modal } = useDynamicModal({
    fullModal: ({ onClose, open, context }) => (
      <ConfirmationModal
        title="Reset check-in time"
        content={
          <Flex flexDirection="column" gap={2} fontSize="lg" width="100%">
            <Box>This action will reset monitor's check-in time which means it will be awaiting for first check-in.</Box>
            <Box>Are you sure you to continue?</Box>
          </Flex>
        }
        confirmActionText="Reset"
        onConfirm={() => mutateAsync({ monitorId }, { onSettled: onClose })}
        loading={isLoading}
        onClose={onClose}
        open={open}
      />
    ),
  });

  return (
    <>
      {modal}
      <Tooltip label="Reset monitor check-in time">
        <IconButton
          colorScheme="blackAlpha"
          aria-label="Run monitor"
          variant="solid"
          icon={<Icon as={BiReset} boxSize={6} />}
          size="md"
          onClick={() => setOpen(true)}
        />
      </Tooltip>
    </>
  );
};

export default ResetMonitorButton;
