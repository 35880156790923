import { PartialDeep } from 'type-fest';
import {
  NotificationPolicyStepOnCallScheduleConfiguration,
  NotificationPolicyStepVO,
  OnCallScheduleNoOneOnDutyAction,
} from '@um/uptime-monitoring-shared';
import { v4 as uuid } from 'uuid';

export type NotificationPolicyFormModel = {
  id?: string;
  name: string;
  description?: string;
  firstStepAfter: number;
  organizationId: string;
  steps: NotificationPolicyStepVO[];
};

export const NotificationPolicyFormModelDefaultValues: PartialDeep<NotificationPolicyFormModel> = {
  name: '',
  description: '',
  organizationId: null,
  firstStepAfter: 0,
  steps: [],
};

export const emptyNotificationPolicyStep = (order: number): NotificationPolicyStepVO => ({
  id: uuid(),
  order,
  nextStepAfter: 5,
  notificationPolicyId: null,
  notificationIntegrationId: null,
  onCallScheduleId: null,
  onCallScheduleConfiguration: null,
});

export const emptyOnCallScheduleConfiguration = (): NotificationPolicyStepOnCallScheduleConfiguration => ({
  notifySms: true,
  notifyEmail: true,
  noOneOnDutyAction: OnCallScheduleNoOneOnDutyAction.DO_NOTHING,
});
