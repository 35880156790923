import React, { useState } from 'react';
import { useAppDispatch } from '../app/store';
import PageHeader from '../components/page-header/pageHeader';
import PageWrapper from '../components/page-wrapper/pageWrapper';
import { OrganizationUserResponse, OrganizationUserRole, PlanFeature } from '@um/uptime-monitoring-shared';
import { Avatar, Box, Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Portal, Text } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import InviteOrganizationUserModal from '../components/invite-organization-user-modal/inviteOrganizationUserModal';
import RemoveUserAccessDialog from '../components/remove-user-access-dialog/removeUserAccessDialog';
import useFeatureQuotaReached from '../hooks/useFeatureQuotaReached';
import actions from '../app/actions';
import { UpgradeModalReason } from '../app/model/upgradeModalReason';
import { useSelectedOrganizationIdQuery } from '../query/organizationQueries';
import EmptyPageTopMenu from '../components/empty-page-header/emptyPageHeader';
import DataTable from '../components/data-table/dataTable';
import Routes from '../utils/routes';
import { useOrganizationUsersQuery } from '../query/userQueries';

const UsersPage = () => {
  const dispatch = useAppDispatch();
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState<OrganizationUserResponse>(null);

  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { data, isLoading, isFetched, refetch, isRefetching, isFetching } = useOrganizationUsersQuery(organizationId);
  const { result: users } = data || {};
  const [quotaReached] = useFeatureQuotaReached(PlanFeature.USERS_LIMIT, users?.length);
  const showLoading = (isFetching || isRefetching) && !isFetched;

  const onClickInviteUser = () => {
    if (quotaReached) {
      dispatch(actions.upgrade.showUpgradeModal(UpgradeModalReason.USER_QUOTA));
      return;
    }

    setShowInviteUserModal(true);
  };
  const noUsers = users?.length < 1;
  const breadcrumbs = [{ title: 'Settings', route: Routes.SETTINGS }];

  return (
    <Box width="100%">
      <RemoveUserAccessDialog
        onRemoved={() => {
          setUserToRemove(null);
          refetch();
        }}
        user={userToRemove}
        onClose={() => setUserToRemove(null)}
        open={!!userToRemove}
      />
      <InviteOrganizationUserModal
        open={showInviteUserModal}
        onClose={() => {
          setShowInviteUserModal(false);
          refetch();
        }}
      />
      <PageHeader title="Users" breadcrumbs={breadcrumbs}>
        <Button colorScheme="blue" onClick={onClickInviteUser}>
          Invite user
        </Button>
      </PageHeader>
      <EmptyPageTopMenu />
      <PageWrapper loading={isLoading} px={4}>
        <DataTable showLoading={!isFetched || isLoading}>
          {() => (
            <>
              {!noUsers && (
                <>
                  <DataTable.Head>
                    <DataTable.HeaderRow>
                      <DataTable.HeaderCell />
                      <DataTable.HeaderCell>First name</DataTable.HeaderCell>
                      <DataTable.HeaderCell>Last name</DataTable.HeaderCell>
                      <DataTable.HeaderCell>Email</DataTable.HeaderCell>
                      <DataTable.HeaderCell>Status</DataTable.HeaderCell>
                      <DataTable.HeaderCell>Role</DataTable.HeaderCell>
                      <DataTable.HeaderCell>Actions</DataTable.HeaderCell>
                    </DataTable.HeaderRow>
                  </DataTable.Head>
                  <DataTable.Body showLoading={showLoading}>
                    {users.map((user) => (
                      <>
                        <DataTable.Row key={user.id}>
                          <DataTable.Cell maxWidth="60px" tooltip={null}>
                            <Avatar name={[user.firstName, user.lastName].filter((item) => !!item).join(' ')} size="sm" />
                          </DataTable.Cell>
                          <DataTable.Cell maxWidth="220px">{user.firstName}</DataTable.Cell>
                          <DataTable.Cell maxWidth="220px">{user.lastName}</DataTable.Cell>
                          <DataTable.Cell maxWidth="220px">{user.email}</DataTable.Cell>
                          <DataTable.Cell>
                            {user.emailVerified ? <Text color="green.400">Active</Text> : <Text color="orange.400">Invited</Text>}
                          </DataTable.Cell>
                          <DataTable.Cell>
                            {user.role === OrganizationUserRole.OWNER ? 'Owner' : user.role === OrganizationUserRole.ADMIN ? 'Admin' : '-'}
                          </DataTable.Cell>
                          <DataTable.Cell tooltip={null}>
                            <Flex alignItems="center" justifyContent="center" minW="12">
                              <Menu>
                                <MenuButton>
                                  <Icon fontSize="xl" as={BsThreeDotsVertical} />
                                </MenuButton>
                                <Portal>
                                  <MenuList>
                                    <MenuItem onClick={() => setUserToRemove(user)}>
                                      <Text color="red.400" fontWeight="bold">
                                        Remove access
                                      </Text>
                                    </MenuItem>
                                  </MenuList>
                                </Portal>
                              </Menu>
                            </Flex>
                          </DataTable.Cell>
                        </DataTable.Row>
                      </>
                    ))}
                  </DataTable.Body>
                </>
              )}
              {noUsers && (
                <DataTable.Body>
                  <DataTable.Row>
                    <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                      <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                        <Box>Users will be displayed here.</Box>
                      </Flex>
                    </DataTable.Cell>
                  </DataTable.Row>
                </DataTable.Body>
              )}
            </>
          )}
        </DataTable>
      </PageWrapper>
    </Box>
  );
};

export default UsersPage;
