import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Flex, Icon, IconButton } from '@chakra-ui/react';
import Routes from '../../utils/routes';
import PageHeader from '../../components/page-header/pageHeader';
import { useNotificationPolicyMutation, useNotificationPolicyQuery } from '../../query/notificationPolicyQueries';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import EditNotificationPolicyForm from '../../components/edit-notification-policy-form/editNotificationPolicyForm';
import { BsTrash2Fill } from 'react-icons/bs';
import PageControls from '../../components/page-controls/pageControls';
import DeleteConfirmationModal from '../../components/delete-confirmation-modal/deleteConfirmationModal';
import { MutationAction } from '../../query/mutationAction';

const EditNotificationPolicyPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const notificationPolicyId = params.id;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { isFetched, data: notificationPolicy } = useNotificationPolicyQuery(notificationPolicyId, { refetchOnMount: true });
  const { mutateAsync, isLoading } = useNotificationPolicyMutation();

  const onUpdated = () => {
    navigate(Routes.NOTIFICATIONS_POLICIES);

    toast.success('Notification policy updated');
  };

  const breadcrumbs = [
    { title: 'Settings', route: Routes.SETTINGS },
    {
      title: 'Notification policies',
      route: Routes.NOTIFICATIONS_POLICIES,
    },
  ];

  const handleDelete = async () => {
    await mutateAsync(
      { notificationPolicyId, action: MutationAction.DELETE },
      {
        onSuccess: () => {
          toast.success('Notification policy deleted');
          navigate(Routes.NOTIFICATIONS_POLICIES);
        },
        onError: () => {
          setShowDeleteModal(false);
        },
      }
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!isFetched} title={notificationPolicy?.name} breadcrumbs={breadcrumbs} />
      <PageControls pr={0}>
        <IconButton
          colorScheme="red"
          aria-label="Delete notification policy"
          icon={<Icon as={BsTrash2Fill} boxSize={6} />}
          size="md"
          onClick={() => setShowDeleteModal(true)}
        />
      </PageControls>
      <PageWrapper loading={!isFetched} px={4}>
        <EditNotificationPolicyForm notificationPolicyId={notificationPolicyId} onUpdated={onUpdated} />
      </PageWrapper>
      <DeleteConfirmationModal
        title="Delete notification policy"
        content="Are you sure you want to delete this notification policy?"
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
        loading={isLoading}
      />
    </Flex>
  );
};

export default EditNotificationPolicyPage;
