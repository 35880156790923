import { createAction } from '@reduxjs/toolkit';
import { websocket } from '../reducer/websocket';
import { withPayloadType } from '../../utils/withPayloadType';
import { MonitorCheckResponse, MonitorCreatedEvent, MonitorUpdatedApplicationEvent } from '@um/uptime-monitoring-shared';

const initialize = createAction('websocket/initialize');
const onMonitorUpdatedEvent = createAction('websocket/onMonitorUpdatedEvent', withPayloadType<MonitorUpdatedApplicationEvent>());
const onMonitorCreatedEvent = createAction('websocket/onMonitorCreatedEvent', withPayloadType<MonitorCreatedEvent>());
const onMonitorCheckCreatedEvent = createAction('websocket/onMonitorCheckCreatedEvent', withPayloadType<MonitorCheckResponse>());
const onMonitorCheckUpdatedEvent = createAction('websocket/onMonitorCheckUpdatedEvent', withPayloadType<MonitorCheckResponse>());
const websocketClosed = createAction('websocket/websocketClosed');

const websocketActions = {
  ...websocket.actions,
  initialize,
  onMonitorUpdatedEvent,
  onMonitorCreatedEvent,
  onMonitorCheckCreatedEvent,
  onMonitorCheckUpdatedEvent,
  websocketClosed,
};

export default websocketActions;
