import { useController, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { prepareRules } from '../../utils/formValidationRules';
import FormFieldLabel from '../form-field-label/formFieldLabel';

export type FormSelectProps = {
  children: (props: any) => React.ReactElement;
  label?: string;
  className?: string;
  regularLabel?: boolean;
} & UseControllerProps<any>;

export const FormSelect: React.FC<FormSelectProps> = ({ children, label, className, rules, regularLabel, ...controls }) => {
  const finalRules = prepareRules(rules);
  const {
    field: { onChange, ref, ...field },
  } = useController({ ...controls, rules: finalRules });
  const handleChange = (item) => {
    let newValue = null;

    if (item && item.value) {
      newValue = item.value;
    } else if (item) {
      newValue = item;
    }

    onChange(newValue);
  };

  return (
    <div className={className}>
      {label && <FormFieldLabel regular={regularLabel}>{label}</FormFieldLabel>}
      {children({ ...field, onChange: handleChange })}
    </div>
  );
};
