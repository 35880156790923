import { PlanFeature } from '@um/uptime-monitoring-shared';
import { useSelectedOrganizationFeaturesQuery } from '../query/organizationQueries';
import { getFeatureInformation } from '../utils/getFeatureInformation';

const useFeatureInformation = (feature: PlanFeature, limit?: number) => {
  const { data: features } = useSelectedOrganizationFeaturesQuery();

  if (!features) {
    return { initialized: false, featureMissing: null, quotaReached: null };
  }

  const featureInformation = getFeatureInformation(feature, features);
  const { featureMissing, quota } = featureInformation;
  const quotaReached = Number.isInteger(limit) ? limit >= quota : false;

  return { initialized: true, featureMissing, quotaReached };
};

export default useFeatureInformation;
