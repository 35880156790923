import React from 'react';
import PageHeader from '../../components/page-header/pageHeader';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import { useAppSelector } from '../../app/store';
import { Box, Button, Flex } from '@chakra-ui/react';
import Routes from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import StatusPagesTable from '../../components/status-pages-table/statusPagesTable';
import actions from '../../app/actions';
import { UpgradeModalReason } from '../../app/model/upgradeModalReason';
import { useOrganizationStatusPagesQuery } from '../../query/statusPageQueries';
import useFeatureQuotaReached from '../../hooks/useFeatureQuotaReached';
import { PlanFeature } from '@um/uptime-monitoring-shared';
import { useDispatch } from 'react-redux';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import EmptyPageHeader from '../../components/empty-page-header/emptyPageHeader';
import FeatureQuotaExceededWarning from '../../components/feature-quota-exceeded-warning/featureQuotaExceededWarning';

const StatusPagesPage = () => {
  const { initialized } = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { data: statusPages } = useOrganizationStatusPagesQuery(organizationId);
  const statusPagesCount = statusPages?.length || 0;
  const [quotaReached] = useFeatureQuotaReached(PlanFeature.STATUS_PAGE_LIMIT, statusPagesCount);

  const onClickCreateStatusPage = () => {
    if (quotaReached) {
      dispatch(actions.upgrade.showUpgradeModal(UpgradeModalReason.STATUS_PAGE_LIMIT_REACHED));
    } else {
      navigate(Routes.CREATE_STATUS_PAGE);
    }
  };

  const breadcrumbs = [];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title="Status pages" breadcrumbs={breadcrumbs}>
        <Box>
          <Button colorScheme="blue" onClick={onClickCreateStatusPage}>
            Create
          </Button>
        </Box>
      </PageHeader>
      <PageWrapper loading={!initialized}>
        <EmptyPageHeader />
        {initialized && (
          <Box px={4}>
            <FeatureQuotaExceededWarning used={statusPagesCount} feature={PlanFeature.STATUS_PAGE_LIMIT} mb={4}>
              You have exceeded your current plan's status page quota therefore some of your status pages have been disabled.
            </FeatureQuotaExceededWarning>
            <StatusPagesTable organizationId={organizationId} />
          </Box>
        )}
      </PageWrapper>
    </Flex>
  );
};

export default StatusPagesPage;
