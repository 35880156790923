import { MutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import mutationDefaults from './mutationDefaults';
import { GetIncidentsRequest } from '../app/model/getIncidentsRequest';
import queryKeys from './queryKeys';

export interface IncidentAcknowledgmentMutationParameters {
  incidentId: string;
}

export const useIncidentAcknowledgmentMutation = (options: MutationOptions<any, any, any> = {}) => {
  return useMutation(
    async ({ incidentId }: IncidentAcknowledgmentMutationParameters) => {
      return await statusPingClient.protectedPostIncidentAcknowledge(incidentId);
    },
    {
      onError: mutationDefaults.onError,
      ...options,
    }
  );
};

export const useIncidentsQuery = (request: GetIncidentsRequest) => {
  return useQuery(queryKeys.incidents(request), () => statusPingClient.protectedGetIncidents(request), {
    enabled: Boolean(request),
    keepPreviousData: true,
    cacheTime: 3000,
  });
};

export const useIncidentQuery = (incidentId: string, options = {}) => {
  return useQuery(queryKeys.incident(incidentId), () => statusPingClient.protectedGetIncident(incidentId), {
    enabled: Boolean(incidentId),
    ...options,
  });
};
