import React from 'react';
import { StatusPage } from '@um/uptime-monitoring-shared';
import StatusIcon from '../status-icon/statusIcon';
import { statusPageStatusColor, statusPageStatusText } from '../../utils/statusPageStatus';

const StatusPageStatusIcon: React.FC<{ statusPage: StatusPage }> = ({ statusPage }) => {
  const color = statusPageStatusColor(statusPage.status);
  const statusText = statusPageStatusText(statusPage.status);

  return <StatusIcon color={color} text={statusText} paused={!statusPage.enabled} loading={false} pausedTooltip="Disabled" />;
};

export default StatusPageStatusIcon;
