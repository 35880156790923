import { useCallback, useEffect, useState } from 'react';
import { DowntimeStatisticsVO, MonitorStatus } from '@um/uptime-monitoring-shared';
import useRunningDuration from './useRunningDuration';

export enum MonitorUptimeStatisticsType {
  SEVEN_DAYS = 'SEVEN_DAYS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  NINETY_DAYS = 'NINETY_DAYS',
}

const getDowntimePeriod = (downtimeStats: DowntimeStatisticsVO, type: MonitorUptimeStatisticsType) => {
  const period =
    type === MonitorUptimeStatisticsType.SEVEN_DAYS
      ? downtimeStats?.downtime7DaysPeriod
      : type === MonitorUptimeStatisticsType.THIRTY_DAYS
      ? downtimeStats?.downtime30DaysPeriod
      : type === MonitorUptimeStatisticsType.NINETY_DAYS
      ? downtimeStats?.downtime90DaysPeriod
      : null;

  return period || 0;
};

const getDowntime = (downtimeStats: DowntimeStatisticsVO, type: MonitorUptimeStatisticsType) => {
  const downtime =
    type === MonitorUptimeStatisticsType.SEVEN_DAYS
      ? downtimeStats?.downtime7Days
      : type === MonitorUptimeStatisticsType.THIRTY_DAYS
      ? downtimeStats?.downtime30Days
      : type === MonitorUptimeStatisticsType.NINETY_DAYS
      ? downtimeStats?.downtime90Days
      : null;

  return downtime || 0;
};

const useMonitorUptimeStatistics = (
  status: MonitorStatus,
  downtimeStats: DowntimeStatisticsVO,
  type: MonitorUptimeStatisticsType = MonitorUptimeStatisticsType.SEVEN_DAYS
) => {
  const [overall, setOverall] = useState(0);
  const [uptimeOverall, setUptimeOverall] = useState(0);
  const [downtimeOverall, setDowntimeOverall] = useState(0);
  const [uptimePercentage, setUptimePercentage] = useState<number>(0);
  const [uptimePercentageText, setUptimePercentageText] = useState('');

  const recalculate = useCallback(() => {
    const downtimeOverall = getDowntime(downtimeStats, type);
    const newOverall = getDowntimePeriod(downtimeStats, type);
    const newUptimeOverall = newOverall - downtimeOverall;
    const newUptimePercentage = +((100 * newUptimeOverall) / newOverall).toFixed(2);

    setOverall(newOverall);
    setUptimeOverall(newUptimeOverall);
    setDowntimeOverall(downtimeOverall);
    setUptimePercentage(newUptimePercentage);
    setUptimePercentageText(`${newUptimePercentage.toFixed(2)}%`);
  }, [overall, uptimeOverall, downtimeOverall, uptimePercentage, downtimeStats]);

  useEffect(() => {
    recalculate();
  }, [recalculate]);

  useEffect(() => {
    recalculate();
  }, []);

  return [uptimePercentage, uptimePercentageText, overall, uptimeOverall, downtimeOverall];
};

export default useMonitorUptimeStatistics;
