import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import formValidationRules from '../../utils/formValidationRules';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { Box, Button, StackDivider, VStack } from '@chakra-ui/react';
import ControlledPushoverPrioritySelector from '../pushover-priority-selector/pushoverPrioritySelector';
import { PushoverPriority, PushoverSubscriptionMetadataEventVO } from '@um/uptime-monitoring-shared';
import { IntegrationFormModel } from './integrationForm';
import icons from '../../icons';
import { Icon } from '@chakra-ui/icons';
import popupCenter from '../../utils/popupCenter';
import { AppConfig } from '../../utils/config';
import tryParseJson from '../../utils/tryParseJson';

const { required } = formValidationRules;

const PushoverOptionsFormFields = ({ disabled = false }) => {
  const [subscribeInProgress, setSubscribeInProgress] = useState(false);
  const {
    unregister,
    setValue,
    getValues,
    watch,
    formState: { defaultValues },
  } = useFormContext<IntegrationFormModel>();
  const incidentAlertingPriorityPath = 'pushoverOptions.incidentAlertingPriority';
  const incidentResolvedPriorityPath = 'pushoverOptions.incidentResolvedPriority';
  const sslCertificateExpirationPriorityPath = 'pushoverOptions.sslCertificateExpirationPriority';
  const domainNameExpirationPriorityPath = 'pushoverOptions.domainNameExpirationPriority';
  const subscribedPath = 'pushoverOptions.subscribed';
  const subscriptionKeyPath = 'pushoverOptions.subscriptionKey';

  useEffect(() => {
    setValue(incidentAlertingPriorityPath, defaultValues?.pushoverOptions?.incidentAlertingPriority || PushoverPriority.NORMAL);
    setValue(incidentResolvedPriorityPath, defaultValues?.pushoverOptions?.incidentResolvedPriority || PushoverPriority.NORMAL);
    setValue(sslCertificateExpirationPriorityPath, defaultValues?.pushoverOptions?.sslCertificateExpirationPriority || PushoverPriority.NORMAL);
    setValue(domainNameExpirationPriorityPath, defaultValues?.pushoverOptions?.domainNameExpirationPriority || PushoverPriority.NORMAL);
    setValue(subscribedPath, defaultValues?.pushoverOptions?.subscribed || false);

    return () => {
      unregister(incidentAlertingPriorityPath);
      unregister(incidentResolvedPriorityPath);
      unregister(sslCertificateExpirationPriorityPath);
      unregister(domainNameExpirationPriorityPath);
      unregister(subscribedPath);
    };
  }, []);

  const onClickSubscribe = () => {
    setSubscribeInProgress(true);

    try {
      const subscriptionWindow = popupCenter({
        url: `${AppConfig.PushoverSubscribeUrl}?success=${AppConfig.PushoverSubscribeSuccessUrl}&failure=${AppConfig.PushoverSubscribeFailureUrl}`,
        title: 'Subscribe to Pushover',
        w: 500,
        h: 500,
      });
      const listener = (event) => {
        const result: PushoverSubscriptionMetadataEventVO = tryParseJson(event.data);

        if (result) {
          const metadata = result.metadata || [];
          const pushoverUserKeyEntry = metadata.find((item) => item[0] === 'pushover_user_key');
          const pushoverUserKey = pushoverUserKeyEntry ? pushoverUserKeyEntry[1] : null;

          if (pushoverUserKey) {
            console.log('pushoverUserKey', pushoverUserKey);
            setValue(subscriptionKeyPath, pushoverUserKey);
            return;
          }
        }

        setValue(subscriptionKeyPath, null);
        setValue(subscribedPath, false);
      };
      const timer = setInterval(function () {
        if (subscriptionWindow.closed) {
          clearInterval(timer);
          window.removeEventListener('message', listener, false);
          setSubscribeInProgress(false);
        }
      }, 1000);
      window.addEventListener('message', listener, false);
    } catch (e) {
      setSubscribeInProgress(false);
    }
  };

  const values = watch();
  const subscribed = Boolean(values.pushoverOptions?.subscribed) || Boolean(values.pushoverOptions?.subscriptionKey);

  return (
    <VStack divider={<StackDivider borderColor="gray.200" />} width="100%" spacing={4} align="stretch">
      <FormFieldWrapper title="Incident started priority" description="Priority of notifications delivered over Pushover when an incident starts">
        <ControlledPushoverPrioritySelector name={incidentAlertingPriorityPath} disabled={disabled} />
      </FormFieldWrapper>
      <FormFieldWrapper
        title="Incident resolved priority"
        description="Priority of notifications delivered over Pushover when an incident is resolved"
      >
        <ControlledPushoverPrioritySelector name={incidentResolvedPriorityPath} disabled={disabled} />
      </FormFieldWrapper>
      <FormFieldWrapper
        title="SSL certificate expiration priority"
        description="Priority of notifications delivered over Pushover about SSL certificate expiration"
      >
        <ControlledPushoverPrioritySelector name={sslCertificateExpirationPriorityPath} disabled={disabled} />
      </FormFieldWrapper>
      <FormFieldWrapper
        title="Domain name expiration priority"
        description="Priority of notifications delivered over Pushover about domain expiration"
      >
        <ControlledPushoverPrioritySelector name={domainNameExpirationPriorityPath} disabled={disabled} />
      </FormFieldWrapper>
      <FormFieldWrapper
        title="Pushover Subscription"
        description="Subscribe to Pushover through their subscription flow. This generates a token that we use to send notifications to you"
      >
        <Button
          leftIcon={<Icon as={icons.PushoverIcon} />}
          onClick={onClickSubscribe}
          isDisabled={subscribeInProgress}
          isLoading={subscribeInProgress}
        >
          {subscribed ? 'Update Pushover subscription' : 'Subscribe to Pushover'}
        </Button>
      </FormFieldWrapper>
    </VStack>
  );
};

export default PushoverOptionsFormFields;
