import React, { useMemo, useRef, useState } from 'react';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';
import { IncidentResponse, IncidentStatus } from '@um/uptime-monitoring-shared';
import { addMilliseconds } from 'date-fns';
import Routes from '../../utils/routes';
import { generatePath } from 'react-router-dom';
import useRunningDuration from '../../hooks/useRunningDuration';
import { DurationFormat } from '../../utils/durationFormat';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import DataTable from '../data-table/dataTable';
import useDynamicModal from '../../hooks/useDynamicModal';
import IncidentDetails from '../incident-details/incidentDetails';
import { useIncidentsQuery } from '../../query/incidentQueries';
import { AppConfig } from '../../utils/config';
import IncidentAcknowledgmentInformation from '../incident-acknowledgment-information/incidentAcknowledgmentInformation';
import IncidentAcknowledgmentTooltip from '../incident-acknowledgment-tooltip/incidentAcknowledgmentTooltip';

interface Props {
  monitorId?: string;
  monitorGroupId?: string;
  organizationId?: string;
  noMonitorLink?: boolean;
}

const colors: { [key: string]: string } = {
  [IncidentStatus.ALERTING]: 'red',
  [IncidentStatus.RESOLVED]: 'green',
};

const statusTexts: { [key: string]: string } = {
  [IncidentStatus.ALERTING]: 'Alerting',
  [IncidentStatus.RESOLVED]: 'Resolved',
};

const IncidentsTable: React.FC<Props> = ({ organizationId, monitorId, monitorGroupId, noMonitorLink }) => {
  const tableRef = useRef();
  const [previousPages, setPreviousPages] = useState([]);
  const [pageStartDate, setPageStartDate] = useState(null);

  const {
    data: incidentsResponse,
    isInitialLoading,
    isFetching,
    isRefetching,
    isFetched,
  } = useIncidentsQuery({
    monitorId,
    organizationId,
    monitorGroupId,
    start: pageStartDate,
  });
  const incidents = useMemo(() => incidentsResponse?.incidents || [], [incidentsResponse]);
  const hasNextPage = useMemo(() => incidentsResponse?.hasNextPage || false, [incidentsResponse]);
  const showLoading = (isFetching || isRefetching) && !isFetched;

  const handleNextPageClick = () => {
    const lastIncident = incidents[incidents.length - 1];

    setPreviousPages([...previousPages, { pageStartDate }]);
    setPageStartDate(lastIncident.createdAt);
  };

  const handlePreviousPageClick = () => {
    const lastPreviousPage = previousPages[previousPages.length - 1];

    setPreviousPages(previousPages.slice(0, previousPages.length - 1));
    setPageStartDate(lastPreviousPage.pageStartDate);
  };
  const hasPreviousPages = previousPages.length > 0;
  const noIncidents = useMemo(() => incidents?.length < 1, [incidents]);
  const {
    modal,
    setOpen: setModalOpen,
    setContext: setModalContext,
  } = useDynamicModal<{ incidentId: string }>({
    modalContent: ({ incidentId }) => <IncidentDetails incidentId={incidentId} onClose={() => setModalOpen(false)} />,
  });

  return (
    <div ref={tableRef}>
      {modal}
      <DataTable
        showLoading={isInitialLoading}
        showPagination={!noIncidents}
        paginationDisabled={showLoading}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPages}
        onNextPageClick={handleNextPageClick}
        onPreviousPageClick={handlePreviousPageClick}
      >
        {() => (
          <>
            {!noIncidents && (
              <>
                <DataTable.Head>
                  <DataTable.HeaderRow>
                    {!noMonitorLink && <DataTable.HeaderCell>Monitor / Group</DataTable.HeaderCell>}
                    <DataTable.HeaderCell>Started at</DataTable.HeaderCell>
                    {AppConfig.AcknowledgmentEnabled && <DataTable.HeaderCell>Acknowledgment</DataTable.HeaderCell>}
                    <DataTable.HeaderCell>Status</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Duration</DataTable.HeaderCell>
                    <DataTable.HeaderCell />
                  </DataTable.HeaderRow>
                </DataTable.Head>
                <DataTable.Body showLoading={showLoading}>
                  {incidents.map((item) => (
                    <DataTable.LinkRow
                      key={item.id}
                      onClick={() => {
                        setModalContext({ incidentId: item.id });
                        setModalOpen(true);
                      }}
                      to={generatePath(Routes.INCIDENT_DETAILS, { id: item.id })}
                    >
                      {!noMonitorLink && <DataTable.Cell>{item.monitorName || item.monitorGroupName}</DataTable.Cell>}
                      <DataTable.Cell>{item.startedAt ? formatToUtc(new Date(item.startedAt), DateFormats.FULL_SECONDS) : '-'}</DataTable.Cell>
                      {AppConfig.AcknowledgmentEnabled && (
                        <DataTable.Cell tooltip={<IncidentAcknowledgmentTooltip incident={item} />} overflow="visible">
                          <IncidentAcknowledgmentInformation incident={item} variant="MINIMAL" />
                        </DataTable.Cell>
                      )}
                      <DataTable.Cell>
                        <Box color={`${colors[item.status]}.500`}>{statusTexts[item.status]}</Box>
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <DowntimeElapsedCounter incident={item} />
                      </DataTable.Cell>
                      <DataTable.Cell tooltip={null}>
                        <Icon as={BsChevronRight} />
                      </DataTable.Cell>
                    </DataTable.LinkRow>
                  ))}
                </DataTable.Body>
              </>
            )}
            {noIncidents && (
              <DataTable.Body>
                <DataTable.Row>
                  <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                    <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                      <Box>Once there are any incidents they will be displayed here.</Box>
                    </Flex>
                  </DataTable.Cell>
                </DataTable.Row>
              </DataTable.Body>
            )}
          </>
        )}
      </DataTable>
    </div>
  );
};

const DowntimeElapsedCounter: React.FC<{ incident: IncidentResponse }> = ({ incident }) => {
  const endDate = useMemo(
    () => (incident.downtimeElapsed ? addMilliseconds(new Date(incident.startedAt), incident.downtimeElapsed) : null),
    [incident]
  );
  const [, formattedDuration] = useRunningDuration(incident.startedAt, DurationFormat.DHM, endDate);
  const [, formattedDurationSeconds] = useRunningDuration(incident.startedAt, DurationFormat.DHMS, endDate);

  return <>{formattedDuration ? formattedDuration : formattedDurationSeconds}</>;
};

export default IncidentsTable;
