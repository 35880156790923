import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioProps } from '@chakra-ui/react';
import React from 'react';

type Props = Omit<RadioProps, 'onChange'> & {
  name: string;
  value: string;
  onChange?: (checked: boolean) => boolean;
};

const ControlledRadio: React.FC<Props> = ({ name, onChange, value, ...rest }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return <Radio size="lg" isChecked={field.value === value} onChange={() => field.onChange(value)} {...rest} />;
      }}
    />
  );
};

export default ControlledRadio;
