"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageSectionEntryStatus = void 0;
var StatusPageSectionEntryStatus;
(function (StatusPageSectionEntryStatus) {
    StatusPageSectionEntryStatus["RESOLVED"] = "RESOLVED";
    StatusPageSectionEntryStatus["DEGRADED"] = "DEGRADED";
    StatusPageSectionEntryStatus["MONITORING"] = "MONITORING";
    StatusPageSectionEntryStatus["DOWNTIME"] = "DOWNTIME";
})(StatusPageSectionEntryStatus = exports.StatusPageSectionEntryStatus || (exports.StatusPageSectionEntryStatus = {}));
