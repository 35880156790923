import React from 'react';
import Spinner from '../spinner/spinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Box, BoxProps } from '@chakra-ui/react';

type Props = BoxProps & {
  title?: React.ReactNode;
  children?: React.ReactNode | (() => React.ReactNode);
  loading?: boolean;
  className?: string;
};

const PageWrapper: React.FC<Props> = ({ title, children, loading = false, ...rest }) => {
  const { initializing, initialized, initializationError } = useSelector((state: RootState) => state.app);

  return (
    <Box width="100%" {...rest}>
      {title && (
        <Box fontSize="3xl" fontWeight="bold" w="100%" mb={4}>
          {title}
        </Box>
      )}
      {(loading || initializing) && (
        <>
          <Spinner>
            {initializationError && (
              <Box color="red.500" fontWeight="bold">
                Error occurred. Will retry shortly...
              </Box>
            )}
          </Spinner>
        </>
      )}
      {!initializing && initialized && !loading && (typeof children === 'function' ? children() : children)}
    </Box>
  );
};

export default PageWrapper;
