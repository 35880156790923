"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationIntegrationCreatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class NotificationIntegrationCreatedMessage extends workerMessage_1.WorkerMessage {
    constructor(notificationIntegration) {
        super(model_1.QueueDefinitions.NOTIFICATION_INTEGRATION_CREATED);
        this.notificationIntegration = notificationIntegration;
    }
}
exports.NotificationIntegrationCreatedMessage = NotificationIntegrationCreatedMessage;
