import Bugsnag from '@bugsnag/js';
import { AppConfig } from './config';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AxiosError } from 'axios';

export const bugsnagClient = AppConfig.BugsnagApiKey
  ? Bugsnag.createClient({
      apiKey: AppConfig.BugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
    })
  : null;

export const logError = (error: any | AxiosError) => {
  if (!error) {
    return;
  }

  if (bugsnagClient) {
    if (!error.isAxiosError || (error.isAxiosError && error.response.status >= 500)) {
      bugsnagClient.notify(error);
    }
  }

  console.log(error);
};
