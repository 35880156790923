import { CreateStatusPageIncidentVO, StatusPageIncidentStatusPageVisibility } from '@um/uptime-monitoring-shared';

export type StatusPageIncidentFormModel = Omit<CreateStatusPageIncidentVO, 'startedAt' | 'statusPageId'>;

export const StatusPageIncidentFormModelDefaultValues: StatusPageIncidentFormModel = {
  status: null,
  message: '',
  timezone: null,
  statusPageVisibility: StatusPageIncidentStatusPageVisibility.VISIBLE,
  resources: [],
};
