import React from 'react';
import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { AiOutlineExclamation } from 'react-icons/ai';

const Fallback = ({ header, subheader = null }) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={'teal.500'}
          rounded={'50px'}
          w={'55px'}
          h={'55px'}
          textAlign="center"
        >
          <Icon boxSize={'40px'} color={'white'} as={AiOutlineExclamation} />
        </Flex>
      </Box>
      <Heading as="h2" size="lg" mt={6} mb={2}>
        {header}
      </Heading>
      {subheader && (
        <Text color={'gray.500'} fontSize="md">
          {subheader}
        </Text>
      )}
    </Box>
  );
};

type FallbackWrapperProps = {
  showFallback: boolean;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  children: any;
};

export const FallbackWrapper: React.FC<FallbackWrapperProps> = ({ showFallback, subtitle, title, children }) => {
  if (showFallback) {
    return <Fallback header={title} subheader={subtitle} />;
  }

  return children;
};

export default Fallback;
