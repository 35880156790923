import { useAppSelector } from '../../app/store';
import React from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import PageHeader from '../../components/page-header/pageHeader';
import { Box, Button, Flex, Grid, Icon } from '@chakra-ui/react';
import EmptyPageTopMenu from '../../components/empty-page-header/emptyPageHeader';
import Card from '../../components/card/card';
import { BsBuilding, BsCardChecklist, BsPeople } from 'react-icons/bs';
import { BiCollection } from 'react-icons/bi';
import { GrIntegration } from 'react-icons/gr';
import { MdContactPhone } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';
import Routes from '../../utils/routes';
import { AppConfig } from '../../utils/config';

const SettingsPage = () => {
  const { initialized } = useAppSelector((state) => state.app);

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title="Settings" />
      <EmptyPageTopMenu />
      <PageWrapper loading={!initialized} px={4}>
        <Card>
          <Grid templateColumns={{ base: '1fr', sm: '1fr 1fr' }} gap={8}>
            <SettingsBlock to={Routes.ORGANIZATION} title="Organization" description="Manage organization information" icon={BsBuilding} />
            <SettingsBlock
              to={Routes.NOTIFICATIONS_POLICIES}
              title="Notification policies"
              description="Set up custom notification flows that involve multiple integrations or on-call schedules"
              icon={BiCollection}
            />
            <SettingsBlock
              to={Routes.NOTIFICATION_INTEGRATIONS}
              title="Notification integrations"
              description="Manage integrations where you are notified of incidents"
              icon={GrIntegration}
            />
            <SettingsBlock to={Routes.USERS} title="Users" description="Manage your team on Status Ping" icon={BsPeople} />
            <SettingsBlock
              to={Routes.SUBSCRIPTION}
              title="Subscription"
              description="View and manage your organization's subscription"
              icon={BsCardChecklist}
            />
            {AppConfig.OnCallSchedulesEnabled && (
              <SettingsBlock
                to={Routes.ON_CALL_SCHEDULES}
                title="On-call schedules"
                description="Configure on-call duties of your teams"
                icon={MdContactPhone}
              />
            )}
          </Grid>
        </Card>
      </PageWrapper>
    </Flex>
  );
};

const SettingsBlock = ({ to, title, description, icon }) => {
  return (
    <Button as={RouterLink} to={to} size="xl" width="100%" p={4} whiteSpace="normal">
      <Flex flexDirection="column" gap={4} alignItems="center">
        <Icon as={icon} boxSize={12} />
        <Flex flexDirection="column" alignItems="center" gap={2}>
          <Box fontSize="xl" fontWeight="bold">
            {title}
          </Box>
          {description && (
            <Box color="gray.600" textAlign="center">
              {description}
            </Box>
          )}
        </Flex>
      </Flex>
    </Button>
  );
};

export default SettingsPage;
