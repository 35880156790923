import React from 'react';
import { Flex, Spinner as ChakraSpinner, SpinnerProps } from '@chakra-ui/react';

type Props = SpinnerProps & {
  hideLoading?: boolean;
};

const Spinner: React.FC<Props> = ({ size = 'xl', hideLoading = false, children, ...rest }) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
        <ChakraSpinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size={size} {...rest} />
        {children ? children : hideLoading ? null : <>Loading</>}
      </Flex>
    </Flex>
  );
};

export default Spinner;
