import { useLocation } from 'react-router-dom';

export const useRouteActive = (href: string, active: boolean | ((path: string) => boolean)): boolean => {
  const location = useLocation();

  if (typeof active === 'boolean') {
    return active;
  } else if (typeof active === 'function') {
    return active(location.pathname);
  }

  return location.pathname.startsWith(href);
};
