import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import EditNotificationIntegrationForm, {
  DeleteNotificationIntegrationModal,
} from '../../components/edit-notification-integration-form/editNotificationIntegrationForm';
import { operationWrapper } from '../../utils/operationWrapper';
import statusPingClient from '../../app/client/statusPingClient';
import { IntegrationResponse } from '@um/uptime-monitoring-shared';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { BsGear } from 'react-icons/bs';

const EditNotificationIntegrationPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notificationIntegration, setNotificationIntegration] = useState<IntegrationResponse>(null);
  const notificationIntegrationId = params.id;

  useEffect(() => {
    const notificationIntegration = operationWrapper(
      async () => {
        try {
          const response = await statusPingClient.protectedGetNotificationIntegration(notificationIntegrationId);

          setNotificationIntegration(response);
        } finally {
          setInitialized(true);
        }
      },
      { errorText: 'Error occurred. Please try again later' }
    );

    if (notificationIntegrationId) {
      notificationIntegration();
    }
  }, [notificationIntegrationId]);

  const onUpdated = () => {
    navigate(Routes.NOTIFICATION_INTEGRATIONS);

    toast.success('Notification integration updated');
  };

  const breadcrumbs = [
    {
      title: 'Settings',
      route: Routes.SETTINGS,
    },
    {
      title: 'Notification integrations',
      route: Routes.NOTIFICATION_INTEGRATIONS,
    },
  ];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title={notificationIntegration?.name} breadcrumbs={breadcrumbs} />
      <PageWrapper loading={!initialized}>
        <Tabs isLazy index={0} pb={4}>
          <TabList>
            <Flex justifyContent="flex-end" flexGrow={1}>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  <Icon as={BsGear} boxSize={6} fontWeight="bold" />
                </MenuButton>
                <MenuList>
                  <MenuItem color="red" fontWeight="bold" onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </TabList>

          <TabPanels></TabPanels>
        </Tabs>
        <DeleteNotificationIntegrationModal id={notificationIntegration?.id} open={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
        <EditNotificationIntegrationForm notificationIntegration={notificationIntegration} onUpdated={onUpdated} />
      </PageWrapper>
    </Flex>
  );
};

export default EditNotificationIntegrationPage;
