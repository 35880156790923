"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorCheckCreatedEvent = void 0;
const applicationEvent_1 = require("./applicationEvent");
class MonitorCheckCreatedEvent extends applicationEvent_1.ApplicationEvent {
    constructor(monitorCheck) {
        super();
        this.monitorCheck = monitorCheck;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.MONITOR_CHECK_CREATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.MONITOR_CHECK_CREATED;
    }
}
exports.MonitorCheckCreatedEvent = MonitorCheckCreatedEvent;
