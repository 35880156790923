import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Portal,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const InformationModal = ({ open, onClose, title, children }) => {
  return (
    <Modal isOpen={open} onClose={onClose} closeOnEsc size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="center">
            <Box fontSize="3xl">{title}</Box>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <span className="mdx">{children}</span>
        </ModalBody>
        <ModalFooter>
          <Flex alignItems="center" justifyContent="center" width="100%">
            <Button onClick={onClose} size="lg" colorScheme="blue">
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type UseInformationModalArguments<T> = {
  modalContent?: (context: T) => React.ReactNode;
  render?: (context: T) => React.ReactNode;
  title?: string;
} & Partial<ModalProps>;

export const useInformationModal = <T,>({ modalContent, title }: UseInformationModalArguments<T>) => {
  const [open, setOpen] = useState(false);
  const [context, setContext] = useState<T>(null);
  const onClose = () => setOpen(false);

  const modal = (
    <Portal>
      <InformationModal open={open} onClose={onClose} title={title}>
        {open && modalContent && modalContent(context)}
      </InformationModal>
    </Portal>
  );

  return { modal, setOpen, setContext };
};

export default InformationModal;
