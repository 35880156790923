import React, { useState } from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import { generatePath, useNavigate, useOutlet, useParams } from 'react-router-dom';
import { FallbackWrapper } from '../../components/fallback/fallback';
import AppLink from '../../components/app-link/appLink';
import { Flex, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { useStatusPageQuery } from '../../query/queries';
import PageControls from '../../components/page-controls/pageControls';
import { useStatusPageIncidentMutation, useStatusPageIncidentQuery } from '../../query/statusPageIncidentQueries';
import StatusPageIncidentHistory from '../../components/status-page-incident-history/statusPageIncidentHistory';
import { BsTrash2Fill } from 'react-icons/bs';
import DeleteConfirmationModal from '../../components/delete-confirmation-modal/deleteConfirmationModal';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';

const StatusPageIncidentPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { statusPageId, statusPageIncidentId } = params;
  const { data: statusPage, isLoading: statusPageLoading, isError: statusPageError } = useStatusPageQuery(statusPageId);
  const {
    data: statusPageIncident,
    isLoading: statusPageIncidentLoading,
    isError: statusPageIncidentError,
  } = useStatusPageIncidentQuery(statusPageIncidentId);
  const { mutateAsync, isLoading: mutating } = useStatusPageIncidentMutation();
  const handleDelete = async () => {
    await mutateAsync(
      { statusPageIncidentId, action: MutationAction.DELETE },
      {
        onSuccess: () => {
          toast.success('Status page incident deleted');
          navigate(generatePath(Routes.STATUS_PAGE_INCIDENTS, { id: statusPageId }));
        },
        onError: () => {
          setShowDeleteModal(false);
        },
      }
    );
  };

  const breadcrumbs = [
    { title: 'Status pages', route: Routes.STATUS_PAGES },
    {
      title: statusPage?.name,
      route: generatePath(Routes.STATUS_PAGE, {
        id: statusPageId,
      }),
    },
    {
      title: 'Incidents',
      route: generatePath(Routes.STATUS_PAGE_INCIDENTS, {
        id: statusPageId,
      }),
    },
  ];
  const isLoading = statusPageLoading || statusPageIncidentLoading;
  const isError = statusPageError || statusPageIncidentError;

  return (
    <Flex flexDirection="column" w="100%">
      <DeleteConfirmationModal
        title="Delete status page incident"
        content="If you delete this incident then any affected resource history will not be displayed in the status page. Are you sure you want to continue?"
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
        loading={mutating}
      />
      <PageHeader loading={isLoading} title="Incident" breadcrumbs={breadcrumbs} />

      <PageControls>
        <Tooltip label="Delete status page incident">
          <IconButton
            colorScheme="red"
            aria-label="Delete status page incident"
            icon={<Icon as={BsTrash2Fill} boxSize={6} />}
            size="md"
            onClick={() => setShowDeleteModal(true)}
          />
        </Tooltip>
      </PageControls>
      <PageWrapper loading={isLoading} px={4}>
        <FallbackWrapper
          showFallback={isError}
          title="Status page incident not found"
          subtitle={
            <>
              Go back to <AppLink to={Routes.STATUS_PAGES}>Status pages</AppLink>
            </>
          }
        >
          {!isLoading && <StatusPageIncidentHistory statusPageIncident={statusPageIncident.result} />}
        </FallbackWrapper>
      </PageWrapper>
    </Flex>
  );
};

export default StatusPageIncidentPage;
