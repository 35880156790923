import { BaseEvent, EventOptions, Result } from '@amplitude/analytics-types';
import { AppConfig } from './config';
import * as amplitude from '@amplitude/analytics-browser';

const trackEvent = async (
  eventInput: string | BaseEvent,
  eventProperties?: Record<string, any> | undefined,
  eventOptions?: EventOptions | undefined
): Promise<Result> => {
  if (AppConfig.AmplitudeApiKey && AppConfig.AmplitudeServerUrl) {
    return amplitude.track(eventInput, { ...(eventProperties || {}), app: 'status-ping-app' }, eventOptions).promise;
  }

  return null;
};

export default trackEvent;
