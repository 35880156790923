"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadImageToGoogleCloudMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class UploadImageToGoogleCloudMessage extends workerMessage_1.WorkerMessage {
    constructor(bucketName, path, content) {
        super(model_1.QueueDefinitions.UPLOAD_IMAGE_TO_GOOGLE_CLOUD_MESSAGE);
        this.bucketName = bucketName;
        this.path = path;
        this.content = content;
    }
}
exports.UploadImageToGoogleCloudMessage = UploadImageToGoogleCloudMessage;
