import { PlanFeature } from '@um/uptime-monitoring-shared';
import { getFeatureQuota } from '../utils/getFeatureQuota';
import { useSelectedOrganizationFeaturesQuery } from '../query/organizationQueries';

const useFeatureQuotaReached = (feature: PlanFeature, limit: number) => {
  const { data: features } = useSelectedOrganizationFeaturesQuery();
  const featureQuota = getFeatureQuota(feature, features);
  const quotaReached = limit >= featureQuota;

  return [quotaReached];
};

export default useFeatureQuotaReached;
