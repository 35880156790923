import { Avatar, Box, Flex } from '@chakra-ui/react';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';
import React from 'react';
import { combineUsersName } from '../../utils/combineUsersName';

const IncidentAcknowledgmentTooltip = ({ incident }) => {
  const name = combineUsersName(incident.acknowledgedByUser);

  if (!incident.acknowledgedByUserId || !incident.acknowledgedByUser?.id) {
    return null;
  }

  return (
    <Flex flexDirection="column" gap={2} p={2} alignItems="center">
      {incident.acknowledgedAt && <Box>Acknowledged on {formatToUtc(new Date(incident.acknowledgedAt), DateFormats.FULL_SECONDS)} by</Box>}
      <Flex alignItems="center" gap={2}>
        <Avatar name={name} size="sm" /> {name}
      </Flex>
    </Flex>
  );
};

export default IncidentAcknowledgmentTooltip;
