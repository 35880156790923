import React, { useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { createFilter } from 'react-select';
import { Timezones } from '@um/uptime-monitoring-shared';
import { FormSelector, FormSelectorProps } from '../form-select/formSelector';
import { prepareRules } from '../../utils/formValidationRules';

export type ControlledSelectProps = {
  control: Control<any>;
  name?: string;
  regularLabel?: boolean;
  rules?: any;
  error?: any;
} & Omit<TimezoneSelectSelectProps, 'value'>;

export type TimezoneSelectSelectProps = {
  error?: any;
  value: string;
  onChange?: (value: string) => void;
} & Omit<FormSelectorProps, 'value' | 'onChange'>;

export const TimezoneSelect: React.FC<TimezoneSelectSelectProps> = ({ onChange, value, ...rest }) => {
  const selectedOption = useMemo(() => Timezones.find((option) => option.value === value), [value]);
  const isOptionSelected = useCallback((option) => option.value === value, [selectedOption]);
  const handleChange = (option: { value: string }) => onChange(option.value);

  return (
    <FormSelector
      isSearchable
      options={Timezones}
      value={selectedOption}
      isOptionSelected={isOptionSelected}
      filterOption={createFilter({
        trim: true,
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
      })}
      onChange={handleChange}
      {...rest}
    />
  );
};

TimezoneSelect.displayName = 'TimezoneSelect';

export const ControlledTimezoneSelect: React.FC<ControlledSelectProps> = ({ control, name, rules, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return <TimezoneSelect error={error?.message} value={field.value} onChange={field.onChange} {...rest} />;
      }}
    />
  );
};

ControlledTimezoneSelect.displayName = 'ControlledTimezoneSelectSelect';
