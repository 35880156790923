import { useOnCallScheduleActiveOnCallDutyQuery, useOnCallScheduleQuery } from '../../query/onCallScheduleQueries';
import DataCard from '../data-card/dataCard';
import React from 'react';
import UserLabel from '../user-label/userLabel';
import { useOrganizationUsersQuery } from '../../query/userQueries';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { Box, Flex } from '@chakra-ui/react';
import { OnCallScheduleRotationNames, Timezones, WeekdayNames } from '@um/uptime-monitoring-shared';
import { formatInTimeZone, toDate } from 'date-fns-tz';
import Card from '../card/card';
import { OnCallScheduleCalendar } from '../on-call-schedule-calendar/formOnCallScheduleCalendar';

type Props = {
  onCallScheduleId: string;
};

const OnCallScheduleOverview: React.FC<Props> = ({ onCallScheduleId }) => {
  const { data: onCallSchedule, isLoading } = useOnCallScheduleQuery(onCallScheduleId);
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isFetched, data } = useOrganizationUsersQuery(organizationId);
  const { isFetched: onCallDutyFetched, data: onCallDuty } = useOnCallScheduleActiveOnCallDutyQuery(onCallScheduleId);
  const { result: users } = data || {};
  const initializing = isLoading || !isFetched || !onCallDutyFetched;

  return (
    <Flex flexDirection="column" gap={4} alignItems="center">
      <DataCard loading={initializing}>
        <DataCard.Row heading="Name">{onCallSchedule?.name}</DataCard.Row>
        {onCallSchedule?.description && <DataCard.Row heading="Description">{onCallSchedule.description}</DataCard.Row>}
        <DataCard.Row heading="Currently on-call">{onCallDuty ? <UserLabel user={onCallDuty} /> : '-'}</DataCard.Row>
        {onCallSchedule?.users?.length > 0 && users?.length > 0 && (
          <DataCard.Row heading="Users">
            <Flex flexDirection="column" gap={1}>
              {onCallSchedule.users
                .map((onCallUser) => {
                  return users.find((user) => user.id === onCallUser.userId);
                })
                .filter((user) => Boolean(user))
                .map((user) => (
                  <UserLabel key={user.id} user={user} />
                ))}
            </Flex>
          </DataCard.Row>
        )}
        {onCallSchedule?.timezone && (
          <DataCard.Row heading="Timezone">{Timezones.find((tz) => tz.value === onCallSchedule.timezone)?.label || '-'}</DataCard.Row>
        )}
        {onCallSchedule?.startDate && (
          <DataCard.Row heading="Schedule start date">
            {formatInTimeZone(onCallSchedule.startDate, onCallSchedule.timezone, 'yyyy-MM-dd HH:mm:ss zzz')}
          </DataCard.Row>
        )}
        {onCallSchedule?.scheduleRotation && (
          <DataCard.Row heading="Schedule rotation">{OnCallScheduleRotationNames[onCallSchedule.scheduleRotation]}</DataCard.Row>
        )}
        {onCallSchedule?.dailySchedule && (
          <DataCard.Row heading="Daily schedules">
            <Flex flexDirection="column" gap={1}>
              {onCallSchedule.dailySchedule
                .filter((schedule) => schedule.enabled)
                .map((schedule) => (
                  <Box key={schedule.weekday}>
                    {WeekdayNames[schedule.weekday]} from {schedule.timeOfDayStart} to {schedule.timeOfDayEnd}
                  </Box>
                ))}
            </Flex>
          </DataCard.Row>
        )}
      </DataCard>
      <Box fontWeight="bold" fontSize="3xl">
        Schedule calendar
      </Box>
      <Card>
        <OnCallScheduleCalendar onCallScheduleId={onCallScheduleId} />
      </Card>
    </Flex>
  );
};

export default OnCallScheduleOverview;
