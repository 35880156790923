import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

type Props = React.HTMLProps<HTMLAnchorElement> & {
  wrapperProps?: BoxProps;
};

const WebsiteLink: React.FC<Props> = ({ children, wrapperProps = {}, ...rest }) => {
  return (
    <Box as="span" textColor="blue.400" textDecoration="underline" fontWeight="bold" _hover={{ textColor: 'blue.600' }} {...wrapperProps}>
      <a {...rest}>{children}</a>
    </Box>
  );
};

export default WebsiteLink;
