"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageStatusValues = exports.StatusPageStatus = void 0;
var StatusPageStatus;
(function (StatusPageStatus) {
    StatusPageStatus["UP"] = "UP";
    StatusPageStatus["PARTIALLY_FAILING"] = "PARTIALLY_FAILING";
    StatusPageStatus["DEGRADED"] = "DEGRADED";
    StatusPageStatus["MONITORING"] = "MONITORING";
    StatusPageStatus["DOWN"] = "DOWN";
})(StatusPageStatus = exports.StatusPageStatus || (exports.StatusPageStatus = {}));
exports.StatusPageStatusValues = Object.values(StatusPageStatus);
