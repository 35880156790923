import { createAction } from '@reduxjs/toolkit';
import { monitorResponseStatistics } from '../reducer/monitorResponseStatistics';
import { withPayloadType } from '../../utils/withPayloadType';

const initialize = createAction('monitorResponseStatistics/initialize', withPayloadType<{ monitorId: string }>());

const monitorResponseStatisticsActions = {
  ...monitorResponseStatistics.actions,
  initialize,
};

export default monitorResponseStatisticsActions;
