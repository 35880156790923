import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { IntegrationFormModel } from '../../../components/integration-form/integrationForm';
import { SetNotificationIntegrationEnabledPayload, VerifyNotificationIntegrationPayload } from '../../actions/notifications';
import toast from 'react-hot-toast';
import { logError } from '../../../utils/logError';

function* createNotificationIntegration(action) {
  try {
    yield put(actions.createIntegration.setInProgress(true));

    const formData: IntegrationFormModel = action.payload;

    const integration = yield call(statusPingClient.protectedPostIntegration, formData);

    yield put(actions.createIntegration.setIntegration(integration));
    yield put(actions.createIntegration.setCreated(true));
  } catch (e: any) {
    yield put(actions.createIntegration.setInProgress(false));
    yield put(actions.createIntegration.setError(e));

    logError(e);
  }
}

function* verifyNotificationIntegration(action) {
  try {
    yield put(actions.createIntegration.setInProgress(true));

    const { notificationIntegrationId, verificationToken }: VerifyNotificationIntegrationPayload = action.payload;

    yield call(statusPingClient.protectedPostVerifyNotificationIntegration, notificationIntegrationId, verificationToken);

    toast.success('Notification integration verified');
  } catch (e) {
    toast.error('Unable to verify notification integration');

    logError(e);
  }
}
function* setNotificationIntegrationEnabled(action) {
  try {
    yield put(actions.createIntegration.setInProgress(true));

    const { id, enabled }: SetNotificationIntegrationEnabledPayload = action.payload;

    yield call(statusPingClient.protectedPatchNotificationIntegration, id, {
      enabled,
    });

    toast.success(`Notification integration ${enabled ? 'enabled' : 'disabled'}`);
  } catch (e) {
    toast.error('Unable to update notification integration');

    logError(e);
  }
}

export default function* integrationsSaga() {
  yield all([
    takeEvery(actions.notifications.verifyNotificationIntegration.type, verifyNotificationIntegration),
    takeEvery(actions.notifications.createNotificationIntegration.type, createNotificationIntegration),
    takeEvery(actions.notifications.setNotificationIntegrationEnabled.type, setNotificationIntegrationEnabled),
  ]);
}
