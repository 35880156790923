"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupDatabaseMessage = void 0;
const genericTaskMessage_1 = require("./genericTaskMessage");
class BackupDatabaseMessage extends genericTaskMessage_1.GenericTaskMessage {
    constructor() {
        super(genericTaskMessage_1.GenericTaskMessageType.BACKUP_DATABASE);
    }
}
exports.BackupDatabaseMessage = BackupDatabaseMessage;
