"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpireOverdueTrialsMessage = void 0;
const genericTaskMessage_1 = require("./genericTaskMessage");
class ExpireOverdueTrialsMessage extends genericTaskMessage_1.GenericTaskMessage {
    constructor() {
        super(genericTaskMessage_1.GenericTaskMessageType.EXPIRE_OVERDUE_TRIALS);
    }
}
exports.ExpireOverdueTrialsMessage = ExpireOverdueTrialsMessage;
