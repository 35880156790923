"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationTypeValues = exports.IntegrationType = void 0;
var IntegrationType;
(function (IntegrationType) {
    IntegrationType["EMAIL"] = "EMAIL";
    IntegrationType["SLACK"] = "SLACK";
    IntegrationType["SMS"] = "SMS";
    IntegrationType["MICROSOFT_TEAMS"] = "MICROSOFT_TEAMS";
    IntegrationType["PUSHOVER"] = "PUSHOVER";
})(IntegrationType = exports.IntegrationType || (exports.IntegrationType = {}));
exports.IntegrationTypeValues = Object.values(IntegrationType);
