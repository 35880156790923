import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DowntimeStatisticsVO,
  PeriodicMonitorResponseTimeStatisticsResponse,
  ProtectedMonitorDetailsResponse,
  ProtectedPeriodicMonitorDowntimeStatisticsResponse,
} from '@um/uptime-monitoring-shared';

type SliceType = {
  initialized: boolean;
  monitor: ProtectedMonitorDetailsResponse;
  downtimeStatistics: DowntimeStatisticsVO;
  responseTimeStatistics: PeriodicMonitorResponseTimeStatisticsResponse;
  downtimePeriodicStatistics: ProtectedPeriodicMonitorDowntimeStatisticsResponse;
};

const initialState: SliceType = {
  initialized: false,
  monitor: null,
  downtimeStatistics: null,
  responseTimeStatistics: null,
  downtimePeriodicStatistics: null,
};

export const monitorOverviewTab = createSlice({
  name: 'monitorOverviewTab',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      return state;
    },
    setMonitor: (state, action: PayloadAction<ProtectedMonitorDetailsResponse>) => {
      state.monitor = action.payload;
      return state;
    },
    setMonitorDowntimeStatistics: (state, action: PayloadAction<DowntimeStatisticsVO>) => {
      state.downtimeStatistics = action.payload;
      return state;
    },
    setResponseTimeStatistics: (state, action: PayloadAction<PeriodicMonitorResponseTimeStatisticsResponse>) => {
      state.responseTimeStatistics = action.payload;
      return state;
    },
    setDowntimePeriodicStatistics: (state, action: PayloadAction<ProtectedPeriodicMonitorDowntimeStatisticsResponse>) => {
      state.downtimePeriodicStatistics = action.payload;
      return state;
    },
  },
});

export default monitorOverviewTab.reducer;
