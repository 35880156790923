import { MutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import { MutationAction } from './mutationAction';
import mutationDefaults from './mutationDefaults';
import { useSelectedOrganizationIdQuery } from './organizationQueries';
import { MonitorFormModel } from '../models/monitorFormModel';
import queryKeys from './queryKeys';
import { useQueryInvalidator } from './queryInvalidatorContext';

export interface QuickMonitorMutationParameters {
  url: string;
  protocol: string;
  organizationId: string;
}

export interface BatchMonitorsMutationParameters {
  organizationId: string;
  monitorIds: string[];
  enabled?: boolean;
  action: MutationAction;
}

export interface MonitorMutationParameters {
  monitorId?: string;
  monitor?: MonitorFormModel;
  action: MutationAction;
}

export interface ResetMonitorMutationParameters {
  monitorId: string;
}

export const useQuickMonitorMutation = () => {
  const queryInvalidator = useQueryInvalidator();

  return useMutation(
    async ({ organizationId, url, protocol }: QuickMonitorMutationParameters) => {
      return await statusPingClient.protectedPostMonitorQuick(url, protocol, organizationId);
    },
    {
      onSuccess: (data: ProtectedMonitorDetailsResponse, variables: QuickMonitorMutationParameters) => {
        // queryClient.invalidateQueries(queryKeys.monitor(['monitors', data.id]));
        queryInvalidator.invalidateQueries(queryKeys.organizationMonitors(variables.organizationId));
      },
    }
  );
};

export const useBatchMonitorsMutation = (options: MutationOptions<any, any, any> = {}) => {
  const queryInvalidator = useQueryInvalidator();

  return useMutation(
    async ({ organizationId, monitorIds, enabled, action }: BatchMonitorsMutationParameters) => {
      if (action === MutationAction.PATCH) {
        return await statusPingClient.protectedPatchMonitors(organizationId, monitorIds, enabled);
      } else if (action === MutationAction.DELETE) {
        return await statusPingClient.protectedDeleteMonitors(organizationId, monitorIds);
      }
    },
    {
      ...options,
      onError: mutationDefaults.onError,
      onSuccess: (data, variables: BatchMonitorsMutationParameters, context) => {
        (variables.monitorIds || []).forEach((id) => queryInvalidator.invalidateQueries(queryKeys.monitor(id)));
        queryInvalidator.invalidateQueries(queryKeys.organizationMonitors(variables.organizationId));

        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useMonitorMutation = (options: MutationOptions<any, any, MonitorMutationParameters> = {}) => {
  const queryInvalidator = useQueryInvalidator();
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  return useMutation<any, any, MonitorMutationParameters>(
    async ({ monitorId, monitor, action }) => {
      if (action === MutationAction.PATCH) {
        return await statusPingClient.protectedPatchMonitor(monitor.id, monitor);
      } else if (action === MutationAction.DELETE) {
        return await statusPingClient.protectedDeleteMonitor(monitorId);
      } else if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostMonitor(monitor);
      }
    },
    {
      onError: mutationDefaults.onError,
      ...options,
      onSuccess: (data: ProtectedMonitorDetailsResponse, variables: MonitorMutationParameters, context) => {
        if (variables.monitor?.id) {
          queryInvalidator.invalidateQueries(queryKeys.monitor(variables.monitor?.id));
        } else if (data?.id) {
          queryInvalidator.invalidateQueries(queryKeys.monitor(data.id));
        }

        queryInvalidator.invalidateQueries(queryKeys.organizationMonitors(organizationId));

        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useResetMonitorMutation = (options: MutationOptions<any, any, ResetMonitorMutationParameters> = {}) => {
  const queryInvalidator = useQueryInvalidator();

  return useMutation<any, any, ResetMonitorMutationParameters>(
    async ({ monitorId }) => {
      return await statusPingClient.protectedPostResetMonitor(monitorId);
    },
    {
      onError: mutationDefaults.onError,
      ...options,
      onSuccess: (data: ProtectedMonitorDetailsResponse, variables: ResetMonitorMutationParameters, context) => {
        if (data?.id) {
          queryInvalidator.invalidateQueries(queryKeys.monitor(data.id));
        }

        if (data?.organizationId) {
          queryInvalidator.invalidateQueries(queryKeys.organizationMonitors(data?.organizationId));
        }

        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};
