"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnqueueOnCallSchedulesMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class EnqueueOnCallSchedulesMessage extends workerMessage_1.WorkerMessage {
    constructor() {
        super(model_1.QueueDefinitions.ENQUEUE_ON_CALL_SCHEDULES);
    }
}
exports.EnqueueOnCallSchedulesMessage = EnqueueOnCallSchedulesMessage;
