"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendLoginLinkMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class SendLoginLinkMessage extends workerMessage_1.WorkerMessage {
    constructor(verificationToken) {
        super(model_1.QueueDefinitions.SEND_LOGIN_LINK);
        this.verificationToken = verificationToken;
    }
}
exports.SendLoginLinkMessage = SendLoginLinkMessage;
