import { FormProvider, useForm } from 'react-hook-form';
import React, { FormHTMLAttributes, useEffect } from 'react';
import { PartialDeep } from 'type-fest';
import { Button, Flex, FlexProps, StackDivider, VStack } from '@chakra-ui/react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { UserNotificationSettingsModel } from '../user-notification-settings-form/userNotificationSettingsForm';
import UserNotificationSettingsFields from '../user-notification-settings-form/userNotificationSettingsFields';
import UserProfileFormFields from './userProfileFormFields';
import { FormFieldError } from '@um/uptime-monitoring-shared';

export type UserProfileFormModel = UserNotificationSettingsModel & {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  timezone: string;
};

const defaultValues: PartialDeep<UserProfileFormModel> = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  timezone: '',
};

type Props = {
  formValues?: PartialDeep<UserProfileFormModel>;
  mainActionText: string;
  disabled?: boolean;
  loading?: boolean;
  onSubmit: (data: UserProfileFormModel) => void;
  formErrors?: FormFieldError[];
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> &
  Omit<FlexProps, 'onSubmit'>;

const UserProfileForm = ({ formValues = defaultValues, mainActionText, disabled, loading, onSubmit, formErrors, ...rest }: Props) => {
  const formMethods = useForm<UserProfileFormModel>({
    defaultValues: formValues,
  });
  const { handleSubmit } = formMethods;

  useEffect(() => {
    if (formErrors && formErrors.length > 0) {
      for (const formError of formErrors) {
        formMethods.setError(formError.dataPath as any, {
          type: 'manual',
          message: formError.message,
        });
      }
    }
  }, [formErrors]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="column" gap={4} {...rest}>
          <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
            <UserProfileFormFields />
            <FormFieldWrapper title="Notification settings">
              <UserNotificationSettingsFields />
            </FormFieldWrapper>
          </VStack>
          <Button type="submit" isDisabled={disabled} isLoading={loading} colorScheme={'blue'} width="min-content" alignSelf="flex-end">
            {mainActionText}
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default UserProfileForm;
