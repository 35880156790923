"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageIncidentCreatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class StatusPageIncidentCreatedMessage extends workerMessage_1.WorkerMessage {
    constructor(statusPageIncident) {
        super(model_1.QueueDefinitions.STATUS_PAGE_INCIDENT_CREATED);
        this.statusPageIncident = statusPageIncident;
    }
}
exports.StatusPageIncidentCreatedMessage = StatusPageIncidentCreatedMessage;
