import { Box, Divider, Flex, Icon, IconButton } from '@chakra-ui/react';
import { BsCaretDownFill, BsCaretUpFill, BsClock, BsTrash2Fill } from 'react-icons/bs';
import SingleNotificationChannelSelector from '../single-notification-channel-selector/singleNotificationChannelSelector';
import { ControlledInput } from '../input/input';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { NotificationChannelOptionType } from '@um/uptime-monitoring-shared';
import formValidationRules from '../../utils/formValidationRules';
import DeleteConfirmationModal from '../delete-confirmation-modal/deleteConfirmationModal';
import { getFormErrorMessage } from '../../utils/getFormErrorMessage';
import NotificationPolicyStepOnCallScheduleConfigurationFields from './notificationPolicyStepOnCallScheduleConfigurationFields';
import { emptyOnCallScheduleConfiguration } from '../../models/notificationPolicyFormModel';

const { required } = formValidationRules;

const NotificationPolicyStepFormFields = ({ idx, path, first, last, showDelete, onDelete, onMoveUp, onMoveDown }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const stepFieldPath = `steps[${idx}]`;
  const notificationIntegrationIdPath = `${path}.notificationIntegrationId`;
  const onCallScheduleIdPath = `${path}.onCallScheduleId`;
  const onCallScheduleConfigurationPath = `${path}.onCallScheduleConfiguration`;
  const notificationIntegrationId = watch(notificationIntegrationIdPath);
  const onCallScheduleId = watch(onCallScheduleIdPath);
  const onCallScheduleConfiguration = watch(onCallScheduleConfigurationPath);

  useEffect(() => {
    if (!onCallScheduleId) {
      setValue(onCallScheduleConfigurationPath, null);
    } else if (!onCallScheduleConfiguration) {
      setValue(onCallScheduleConfigurationPath, emptyOnCallScheduleConfiguration());
    }
  }, [onCallScheduleId]);

  return (
    <>
      <DeleteConfirmationModal
        title="Remove step"
        content="Are you sure you want to remove this step?"
        onConfirm={onDelete}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
      />
      <Flex flexDirection="column" gap={2}>
        <Flex justifyContent="space-between">
          <Flex
            minH={8}
            minW={8}
            h={8}
            w={8}
            alignItems="center"
            justifyContent="center"
            background="gray.200"
            borderRadius="full"
            fontSize="xl"
            fontWeight="bold"
          >
            {idx + 1}
          </Flex>
          <Flex gap={2}>
            {!first && (
              <IconButton
                variant="outline"
                colorScheme="gray"
                aria-label="Move up"
                icon={<Icon as={BsCaretUpFill} boxSize={6} />}
                size="sm"
                onClick={onMoveUp}
              />
            )}
            {!last && (
              <IconButton
                variant="outline"
                colorScheme="gray"
                aria-label="Move down"
                icon={<Icon as={BsCaretDownFill} boxSize={6} />}
                size="sm"
                onClick={onMoveDown}
              />
            )}
            {showDelete && (
              <IconButton
                colorScheme="red"
                aria-label="Delete section"
                icon={<Icon as={BsTrash2Fill} boxSize={6} />}
                size="sm"
                onClick={() => setShowDeleteModal(true)}
              />
            )}
          </Flex>
        </Flex>
        <Flex gap={4}>
          <Flex flexDirection="column" alignItems="center" gap={2} w={8}>
            <Box flexGrow="1" w="1px" height="100%" backgroundColor="gray.200"></Box>
          </Flex>
          <Box width="100%">
            <Box fontSize="lg" color="gray.600" mb={4}>
              {idx === 0 ? 'When incident starts, immediately notify the following channel' : 'Notify the following channel'}
            </Box>
            <SingleNotificationChannelSelector
              onChange={(data) => {
                setValue(notificationIntegrationIdPath, data.notificationIntegrationId || null);
                setValue(onCallScheduleIdPath, data.onCallScheduleId || null);
                clearErrors(notificationIntegrationIdPath);
                clearErrors(onCallScheduleIdPath);
              }}
              value={{ notificationIntegrationId, onCallScheduleId }}
              types={[NotificationChannelOptionType.NOTIFICATION_INTEGRATION, NotificationChannelOptionType.ON_CALL_SCHEDULE]}
              error={getFormErrorMessage(errors, notificationIntegrationIdPath)}
            />
            {onCallScheduleId && <NotificationPolicyStepOnCallScheduleConfigurationFields mt={4} path={onCallScheduleConfigurationPath} />}
          </Box>
        </Flex>
      </Flex>
      {!last && <Divider my={4} borderColor="gray.200" />}
      {!last && (
        <Flex alignItems="center" gap={4}>
          <Icon as={BsClock} fill="gray.200" boxSize={8} />
          <Flex fontSize="lg" color="gray.600" alignItems="center" gap={2} wordBreak="break-word" flexWrap="wrap">
            If the incident is not acknowledged in
            <ControlledInput
              name={`${stepFieldPath}.nextStepAfter`}
              type="number"
              rules={{ required }}
              control={control}
              maxWidth="70px"
              maxHeight="30px"
            />
            minute(s), then move on to the next step
          </Flex>
        </Flex>
      )}
      {!last && <Divider my={4} borderColor="gray.200" />}
    </>
  );
};

export default NotificationPolicyStepFormFields;
