"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class SubscriptionUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(originalSubscription, updatedSubscription) {
        super(model_1.QueueDefinitions.SUBSCRIPTION_UPDATED_MESSAGE);
        this.originalSubscription = originalSubscription;
        this.updatedSubscription = updatedSubscription;
    }
}
exports.SubscriptionUpdatedMessage = SubscriptionUpdatedMessage;
