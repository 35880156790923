"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationIntegrationUpdatedEvent = void 0;
const applicationEvent_1 = require("./applicationEvent");
class NotificationIntegrationUpdatedEvent extends applicationEvent_1.ApplicationEvent {
    constructor(notificationIntegration) {
        super();
        this.notificationIntegration = notificationIntegration;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.NOTIFICATION_INTEGRATION_UPDATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.NOTIFICATION_INTEGRATION_UPDATED;
    }
}
exports.NotificationIntegrationUpdatedEvent = NotificationIntegrationUpdatedEvent;
