import { UserSettingsResponse } from '@um/uptime-monitoring-shared';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import UserNotificationSettingsFields from './userNotificationSettingsFields';
import { Button, Flex } from '@chakra-ui/react';
import statusPingClient from '../../app/client/statusPingClient';
import toast from 'react-hot-toast';
import { logError } from '../../utils/logError';
import * as queryString from 'query-string';

type Props = {
  userSettings: UserSettingsResponse;
};

export interface UserNotificationSettingsModel {
  subscribedToWeeklyMonitorReport: boolean;
  subscribedToProductNewsletter: boolean;
}

const UserNotificationSettingsForm: React.FC<Props> = ({ userSettings }) => {
  const parsed = queryString.parse(location.search);
  const token = parsed.token as string;
  const [inProgress, setInProgress] = useState(false);
  const formMethods = useForm<UserNotificationSettingsModel>({
    defaultValues: {
      subscribedToProductNewsletter: userSettings.subscribedToProductNewsletter,
      subscribedToWeeklyMonitorReport: userSettings.subscribedToWeeklyMonitorReport,
    },
  });
  const { handleSubmit } = formMethods;
  const submit = async (data: UserNotificationSettingsModel) => {
    setInProgress(true);

    try {
      await statusPingClient.protectedPatchUserSettings(data, token);

      toast.success('Notification settings updated');
    } catch (e) {
      toast.success('Error updating notification settings. Please try again later');
      logError(e);
    } finally {
      setInProgress(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submit)}>
        <UserNotificationSettingsFields />
        <Flex alignItems="center" justifyContent="center" mt={4}>
          <Button type="submit" isDisabled={inProgress} isLoading={inProgress} colorScheme={'blue'} width="min-content">
            Update notification settings
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default UserNotificationSettingsForm;
