"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationEventExchangeNameValues = exports.ApplicationEventHeader = exports.ApplicationEventExchangeName = exports.ApplicationEventType = exports.ApplicationEvent = void 0;
class ApplicationEvent {
    constructor(notifyOrganizationUsers = false, organizationId = null) {
        this.notifyOrganizationUsers = notifyOrganizationUsers;
        this.organizationId = organizationId;
    }
}
exports.ApplicationEvent = ApplicationEvent;
var ApplicationEventType;
(function (ApplicationEventType) {
    ApplicationEventType["MONITOR_CREATED"] = "MONITOR_CREATED";
    ApplicationEventType["MONITOR_UPDATED"] = "MONITOR_UPDATED";
    ApplicationEventType["MONITOR_CHECK_CREATED"] = "MONITOR_CHECK_CREATED";
    ApplicationEventType["MONITOR_CHECK_UPDATED"] = "MONITOR_CHECK_UPDATED";
    ApplicationEventType["NOTIFICATION_INTEGRATION_CREATED"] = "NOTIFICATION_INTEGRATION_CREATED";
    ApplicationEventType["NOTIFICATION_INTEGRATION_UPDATED"] = "NOTIFICATION_INTEGRATION_UPDATED";
    ApplicationEventType["STATUS_PAGE_UPDATED"] = "STATUS_PAGE_UPDATED";
    ApplicationEventType["STATUS_PAGE_INCIDENT_UPDATED"] = "STATUS_PAGE_INCIDENT_UPDATED";
    ApplicationEventType["STATUS_PAGE_INCIDENT_CREATED"] = "STATUS_PAGE_INCIDENT_CREATED";
    ApplicationEventType["INCIDENT_CREATED"] = "INCIDENT_CREATED";
    ApplicationEventType["INCIDENT_UPDATED"] = "INCIDENT_UPDATED";
})(ApplicationEventType = exports.ApplicationEventType || (exports.ApplicationEventType = {}));
var ApplicationEventExchangeName;
(function (ApplicationEventExchangeName) {
    ApplicationEventExchangeName["MONITOR_CREATED"] = "event-monitor-created.xch";
    ApplicationEventExchangeName["MONITOR_UPDATED"] = "event-monitor-updated.xch";
    ApplicationEventExchangeName["MONITOR_CHECK_CREATED"] = "event-monitor-check-created.xch";
    ApplicationEventExchangeName["MONITOR_CHECK_UPDATED"] = "event-monitor-check-updated.xch";
    ApplicationEventExchangeName["NOTIFICATION_INTEGRATION_CREATED"] = "event-notification-integration-created.xch";
    ApplicationEventExchangeName["NOTIFICATION_INTEGRATION_UPDATED"] = "event-notification-integration-updated.xch";
    ApplicationEventExchangeName["STATUS_PAGE_UPDATED"] = "event-status-page-updated.xch";
    ApplicationEventExchangeName["STATUS_PAGE_INCIDENT_UPDATED"] = "event_status_page_incident_updated.xch";
    ApplicationEventExchangeName["STATUS_PAGE_INCIDENT_CREATED"] = "event_status_page_incident_created.xch";
    ApplicationEventExchangeName["INCIDENT_CREATED"] = "event_incident_created.xch";
    ApplicationEventExchangeName["INCIDENT_UPDATED"] = "event_incident_updated.xch";
})(ApplicationEventExchangeName = exports.ApplicationEventExchangeName || (exports.ApplicationEventExchangeName = {}));
var ApplicationEventHeader;
(function (ApplicationEventHeader) {
    ApplicationEventHeader["MESSAGE_TYPE"] = "MESSAGE_TYPE";
})(ApplicationEventHeader = exports.ApplicationEventHeader || (exports.ApplicationEventHeader = {}));
exports.ApplicationEventExchangeNameValues = Object.values(ApplicationEventExchangeName);
