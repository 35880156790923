import { IntegrationResponse } from '@um/uptime-monitoring-shared';
import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const statusText = (integration: IntegrationResponse) => {
  if (!integration.verified) {
    return 'Not verified';
  }

  if (!integration.enabled) {
    return 'Paused';
  }

  return 'Active';
};

const statusTextColor = (integration: IntegrationResponse) => {
  if (!integration.verified) {
    return 'orange.400';
  }

  if (!integration.enabled) {
    return 'gray.400';
  }

  return 'green.400';
};

type Props = { integration: IntegrationResponse };

const IntegrationStatusText: React.FC<Props> = ({ integration }) => {
  const text = statusText(integration);
  const colorClass = statusTextColor(integration);

  return (
    <Box color={colorClass} fontWeight="bold" width="min-content" whiteSpace="nowrap">
      {text}
    </Box>
  );
};

export default IntegrationStatusText;
