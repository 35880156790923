import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import {
  CreateOnCallScheduleVO,
  OnCallScheduleCalendarEventsRequestVO,
  OnCallScheduleModelVO,
  UpdateOnCallScheduleVO,
} from '@um/uptime-monitoring-shared';
import stringHash from '../utils/stringHash';
import { MutationAction } from './mutationAction';
import { QueryResultWithHash, toQueryResultWithHash } from '../models/queryResultWithHash';

type OnCallScheduleMutationParameters = {
  onCallScheduleId?: string;
  updateRequest?: UpdateOnCallScheduleVO;
  createRequest?: CreateOnCallScheduleVO;
  action: MutationAction;
};

export const usePostOnCallScheduleCalendarEventsQuery = (
  startAt: string,
  endAt: string,
  request: OnCallScheduleCalendarEventsRequestVO,
  options: UseQueryOptions = {}
) => {
  return useQuery(
    ['onCallSchedule', 'calendarEvents', startAt, endAt, request],
    async () => {
      const events = await statusPingClient.protectedPostOnCallScheduleCalendarEvents(startAt, endAt, request);
      const hash = stringHash(JSON.stringify(events));

      return { hash, events };
    },
    {
      enabled: options.enabled !== false,
      refetchOnMount: true,
      cacheTime: 60000,
      keepPreviousData: true,
    }
  );
};

export const useGetOnCallScheduleCalendarEventsQuery = (startAt: string, endAt: string, onCallScheduleId: string, options: UseQueryOptions = {}) => {
  return useQuery(
    ['onCallSchedule', 'calendarEvents', startAt, endAt, onCallScheduleId],
    async () => {
      const events = await statusPingClient.protectedGetOnCallScheduleCalendarEvents(startAt, endAt, onCallScheduleId);
      const hash = stringHash(JSON.stringify(events));

      return { hash, events };
    },
    {
      enabled: options.enabled !== false,
      refetchOnMount: true,
      cacheTime: 60000,
      keepPreviousData: true,
    }
  );
};

export const useOnCallScheduleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<OnCallScheduleModelVO, any, OnCallScheduleMutationParameters>(
    async ({ onCallScheduleId, updateRequest, createRequest, action }: OnCallScheduleMutationParameters) => {
      if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostOnCallSchedule(createRequest);
      } else if (action === MutationAction.PUT) {
        return await statusPingClient.protectedPutOnCallSchedule(onCallScheduleId, updateRequest);
      } else if (action === MutationAction.DELETE) {
        await statusPingClient.protectedDeleteOnCallSchedule(onCallScheduleId);
      }

      return null;
    },
    {
      onSuccess: (data: OnCallScheduleModelVO, variables) => {
        const id = data?.id || variables.onCallScheduleId;

        queryClient.invalidateQueries(['onCallSchedule', 'organization']);
        if (id) {
          queryClient.invalidateQueries(['onCallSchedule', id]);
        }
      },
    }
  );
};

export const useOrganizationOnCallSchedulesQuery = (
  organizationId: string,
  options: UseQueryOptions<QueryResultWithHash<OnCallScheduleModelVO[]>> = {}
) => {
  return useQuery(
    ['onCallSchedule', 'organization', organizationId],
    () => toQueryResultWithHash(statusPingClient.protectedGetOnCallSchedules(organizationId)),
    {
      enabled: Boolean(organizationId),
      keepPreviousData: true,
      refetchOnMount: true,
      staleTime: 1000,
      ...options,
    }
  );
};

export const useOnCallScheduleQuery = (onCallScheduleId: string, options: UseQueryOptions = {}) => {
  return useQuery(['onCallSchedule', onCallScheduleId], () => statusPingClient.protectedGetOnCallSchedule(onCallScheduleId), {
    enabled: options.enabled !== false,
    refetchOnMount: true,
    keepPreviousData: true,
  });
};

export const useOnCallScheduleActiveOnCallDutyQuery = (onCallScheduleId: string, options: UseQueryOptions = {}) => {
  return useQuery(
    ['onCallSchedule', onCallScheduleId, 'activeOnCallDuty'],
    () => statusPingClient.protectedGetOnCallScheduleActiveOnCallDuty(onCallScheduleId),
    {
      enabled: Boolean(onCallScheduleId) && options.enabled !== false,
      refetchOnMount: true,
      keepPreviousData: true,
    }
  );
};
