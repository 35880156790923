"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class IncidentUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(incident, originalIncident) {
        super(model_1.QueueDefinitions.INCIDENT_UPDATED);
        this.incident = incident;
        this.originalIncident = originalIncident;
    }
}
exports.IncidentUpdatedMessage = IncidentUpdatedMessage;
