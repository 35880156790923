import { BsBell, BsBuilding, BsBullseye, BsCardChecklist, BsEnvelope, BsGear, BsPeople } from 'react-icons/bs';
import { FaLayerGroup } from 'react-icons/fa';
import { BiCollection, BiWindow } from 'react-icons/bi';
import Routes from '../utils/routes';
import { GrIntegration } from 'react-icons/gr';
import { MdContactPhone } from 'react-icons/md';
import { AppConfig } from '../utils/config';

export interface NavigationDefinitionProperties {
  label: string;
  to: string;
  icon?: any;
  admin?: boolean;
  active?: boolean | ((path: string) => boolean);
}

export interface NavigationDefinition extends NavigationDefinitionProperties {
  children?: NavigationDefinitionProperties[];
}

const navigationDefinitions: NavigationDefinition[] = [
  {
    label: 'Monitors',
    to: Routes.MONITORS,
    icon: BsBullseye,
    admin: false,
  },
  // {
  //   label: 'Monitor groups',
  //   to: Routes.MONITOR_GROUPS,
  //   icon: FaLayerGroup,
  //   admin: false,
  // },
  {
    label: 'Status pages',
    to: Routes.STATUS_PAGES,
    icon: BiWindow,
    admin: false,
  },
  {
    label: 'Incidents',
    to: Routes.INCIDENTS,
    icon: BsBell,
    admin: false,
  },
  {
    label: 'Notifications',
    to: Routes.NOTIFICATIONS_OVERVIEW,
    icon: BsEnvelope,
    admin: false,
    // active: (path: string) =>
    //   path.startsWith(Routes.NOTIFICATIONS) ||
    //   !!matchPath(Routes.NOTIFICATION_DETAILS, path) ||
    //   !!matchPath(Routes.NOTIFICATION_INTEGRATION, path) ||
    //   !!matchPath(Routes.NOTIFICATION_INTEGRATIONS, path) ||
    //   !!matchPath(Routes.NOTIFICATIONS_POLICIES, path),
    // children: [
    //   {
    //     label: 'Overview',
    //     to: Routes.NOTIFICATIONS_OVERVIEW,
    //     icon: BsListUl,
    //     active: (path: string) => path === Routes.NOTIFICATIONS_OVERVIEW || !!matchPath(Routes.NOTIFICATION_DETAILS, path),
    //   },
    //   {
    //     label: 'Integrations',
    //     icon: GrIntegration,
    //     to: Routes.NOTIFICATION_INTEGRATIONS,
    //   },
    // ].concat(
    //   AppConfig.NotificationPoliciesEnabled
    //     ? [
    //         {
    //           label: 'Policies',
    //           icon: BiCollection,
    //           to: Routes.NOTIFICATIONS_POLICIES,
    //         },
    //       ]
    //     : []
    // ),
  },
  {
    label: 'Settings',
    to: Routes.SETTINGS,
    icon: BsGear,
    admin: false,
    children: [
      { label: 'Organization', icon: BsBuilding, to: Routes.ORGANIZATION },
      {
        label: 'Notification policies',
        icon: BiCollection,
        to: Routes.NOTIFICATIONS_POLICIES,
        active: (path: string) => path.startsWith(Routes.NOTIFICATIONS_POLICIES),
      },
      {
        label: 'Notification integrations',
        icon: GrIntegration,
        to: Routes.NOTIFICATION_INTEGRATIONS,
        active: (path: string) => path.startsWith(Routes.NOTIFICATION_INTEGRATIONS),
      },
      { label: 'Users', icon: BsPeople, to: Routes.USERS },
      { label: 'Subscription', icon: BsCardChecklist, to: Routes.SUBSCRIPTION },
    ].concat(AppConfig.OnCallSchedulesEnabled ? [{ label: 'On-call schedules', icon: MdContactPhone, to: Routes.ON_CALL_SCHEDULES }] : []),
  },
];

export default navigationDefinitions;
