import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Skeleton,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { BsBuilding } from 'react-icons/bs';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOrganizationsQuery, useSelectedOrganizationQuery, useSelectOrganizationMutation } from '../../query/organizationQueries';

const OrganizationManagementMenu = () => {
  const ref = useRef();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { isLoading: organizationsLoading, data: organizations } = useOrganizationsQuery();
  const { isLoading: organizationLoading, data: organization } = useSelectedOrganizationQuery();

  const closeSubMenu = () => setOpen(false);

  useEffect(() => {
    setOpen(false);
  }, [location?.pathname, organization?.id]);

  useOutsideClick({
    ref,
    handler: closeSubMenu,
  });

  const showSwitchOrganization = organizations?.length > 0 && organizations?.some((org) => org.id !== organization?.id);

  if (organizationLoading || organizationsLoading) {
    return null;
  }

  return (
    <Box mb="2" ref={ref}>
      <Flex
        display="flex"
        gap="3"
        alignItems="center"
        color="black"
        background={'transparent'}
        onClick={() => setOpen(!open)}
        pl="4"
        py="2"
        borderRadius="md"
        justifyContent={{ base: 'center', md: 'flex-start' }}
      >
        <Flex gap={3} alignItems="center">
          <Icon w={6} h={6} fontWeight="bold" as={BsBuilding} />
          <Flex flexDirection="column" flexGrow={{ base: 0, md: 1 }} alignItems="flex-start" fontWeight="bold">
            <Box textTransform="uppercase" color="gray.600" fontWeight="bold" fontSize="sm">
              Organization
            </Box>
            <Box height="6" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" maxWidth="145px" width="100%">
              {organization?.name ?? <Skeleton height="6" />}
            </Box>
          </Flex>
        </Flex>
        {showSwitchOrganization && <SwitchOrganizationMenu organization={organization} organizations={organizations} />}
      </Flex>
    </Box>
  );
};

const SwitchOrganizationMenu = ({ organization, organizations }) => {
  const { mutate } = useSelectOrganizationMutation();

  return (
    <Menu>
      <MenuButton as={Button}>Switch</MenuButton>
      <MenuList>
        <MenuOptionGroup value={organization?.id} type="radio">
          {organizations.map((org) => (
            <MenuItemOption as={Box} key={org.id} value={org.id} overflow="hidden" onClick={() => mutate({ organizationId: org.id })}>
              <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {org.name}
              </Text>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default OrganizationManagementMenu;
