"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorStatisticsWindowValues = exports.MonitorStatisticsTimeframeValues = exports.MonitorStatisticsTypeValues = exports.MonitorStatisticsWindow = exports.MonitorStatisticsTimeframe = exports.MonitorStatisticsType = void 0;
var MonitorStatisticsType;
(function (MonitorStatisticsType) {
    MonitorStatisticsType["AVG_RESPONSE_TIME"] = "AVG_RESPONSE_TIME";
    MonitorStatisticsType["MIN_RESPONSE_TIME"] = "MIN_RESPONSE_TIME";
    MonitorStatisticsType["MAX_RESPONSE_TIME"] = "MAX_RESPONSE_TIME";
})(MonitorStatisticsType = exports.MonitorStatisticsType || (exports.MonitorStatisticsType = {}));
var MonitorStatisticsTimeframe;
(function (MonitorStatisticsTimeframe) {
    MonitorStatisticsTimeframe["DAY"] = "DAY";
    MonitorStatisticsTimeframe["WEEK"] = "WEEK";
    MonitorStatisticsTimeframe["THIRTY_DAYS"] = "THIRTY_DAYS";
    MonitorStatisticsTimeframe["NINETY_DAYS"] = "NINETY_DAYS";
})(MonitorStatisticsTimeframe = exports.MonitorStatisticsTimeframe || (exports.MonitorStatisticsTimeframe = {}));
var MonitorStatisticsWindow;
(function (MonitorStatisticsWindow) {
    MonitorStatisticsWindow["ONE_MINUTE"] = "ONE_MINUTE";
    MonitorStatisticsWindow["FIVE_MINUTES"] = "FIVE_MINUTES";
    MonitorStatisticsWindow["FIFTEEN_MINUTES"] = "FIFTEEN_MINUTES";
    MonitorStatisticsWindow["THIRTY_MINUTES"] = "THIRTY_MINUTES";
    MonitorStatisticsWindow["HOUR"] = "HOUR";
})(MonitorStatisticsWindow = exports.MonitorStatisticsWindow || (exports.MonitorStatisticsWindow = {}));
exports.MonitorStatisticsTypeValues = Object.values(MonitorStatisticsType);
exports.MonitorStatisticsTimeframeValues = Object.values(MonitorStatisticsTimeframe);
exports.MonitorStatisticsWindowValues = Object.values(MonitorStatisticsWindow);
