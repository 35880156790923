import React, { useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { createFilter } from 'react-select';
import { CheckInPeriodType, StatusPageIncidentStatus, StatusPageIncidentStatusValues, Timezones } from '@um/uptime-monitoring-shared';
import { FormSelector, FormSelectorProps } from '../form-select/formSelector';
import { prepareRules } from '../../utils/formValidationRules';
import { Box } from '@chakra-ui/react';
import StatusPageIncidentStatusBadge from '../status-page-incident-status-badge/statusPageIncidentStatusBadge';

export type ControlledSelectProps = {
  control: Control<any>;
  name?: string;
  regularLabel?: boolean;
  rules?: any;
  error?: any;
} & Omit<StatusPageIncidentStatusSelectorProps, 'value'>;

export type StatusPageIncidentStatusSelectorProps = {
  error?: any;
  value: string;
  onChange?: (value: string) => void;
  createMode?: boolean;
} & Omit<FormSelectorProps, 'value' | 'onChange'>;

const options = [
  {
    label: 'Unchanged',
    value: null,
  },
  ...StatusPageIncidentStatusValues.map((status) => ({
    label: <StatusPageIncidentStatusBadge status={status} />,
    value: status,
  })),
];

const createModeOptions = StatusPageIncidentStatusValues.filter((status) => status !== StatusPageIncidentStatus.RESOLVED).map((status) => ({
  label: <StatusPageIncidentStatusBadge status={status} />,
  value: status,
}));

export const StatusPageIncidentStatusSelector: React.FC<StatusPageIncidentStatusSelectorProps> = ({ onChange, value, createMode, ...rest }) => {
  const finalOptions = useMemo(() => (createMode ? createModeOptions : options), [createMode]);
  const selectedOption = useMemo(() => finalOptions.find((option) => option.value === value), [value]);
  const isOptionSelected = useCallback((option) => option.value === value, [selectedOption]);
  const handleChange = (option: { value: string }) => onChange(option.value);

  return (
    <FormSelector
      isSearchable
      options={finalOptions}
      value={selectedOption}
      isOptionSelected={isOptionSelected}
      filterOption={createFilter({
        trim: true,
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
      })}
      onChange={handleChange}
      {...rest}
    />
  );
};

StatusPageIncidentStatusSelector.displayName = 'StatusPageIncidentStatusSelector';

export const ControlledStatusPageIncidentStatusSelector: React.FC<ControlledSelectProps> = ({ control, name, rules, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return <StatusPageIncidentStatusSelector error={error?.message} value={field.value} onChange={field.onChange} {...rest} />;
      }}
    />
  );
};

ControlledStatusPageIncidentStatusSelector.displayName = 'ControlledStatusPageIncidentStatusSelector';
