import React, { useState } from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import PageHeader from '../../components/page-header/pageHeader';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';
import { SubscriptionStatus } from '@um/uptime-monitoring-shared';
import AppPricing from '../../components/pricing/appPricing';
import Card from '../../components/card/card';
import FormFieldWrapper from '../../components/form-field-wrapper/formFieldWrapper';
import statusPingClient from '../../app/client/statusPingClient';
import { toast } from 'react-hot-toast';
import { useSelectedOrganizationIdQuery, useSelectedOrganizationSubscriptionsQuery } from '../../query/organizationQueries';
import EmptyPageTopMenu from '../../components/empty-page-header/emptyPageHeader';
import Routes from '../../utils/routes';
import DataCard from '../../components/data-card/dataCard';

const SubscriptionPage = () => {
  const [inProgress, setInProgress] = useState(false);
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isLoading, data } = useSelectedOrganizationSubscriptionsQuery();

  const initialized = !isLoading;
  const activeSubscription = data?.activeSubscription;

  const active = activeSubscription?.status === SubscriptionStatus.ACTIVE;
  const canceled = activeSubscription?.status === SubscriptionStatus.CANCELED;

  const openCustomerPortal = async () => {
    try {
      setInProgress(true);

      const customerPortal = await statusPingClient.protectedGetSubscriptionCustomerPortalSession(organizationId);

      if (!customerPortal) {
        toast.error('Error occurred opening customer portal. Please try again later');
        return;
      }

      window.open(customerPortal.customerPortalUrl, '_blank');
    } finally {
      setInProgress(false);
    }
  };
  const breadcrumbs = [{ title: 'Settings', route: Routes.SETTINGS }];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title="Subscription" breadcrumbs={breadcrumbs} />
      <EmptyPageTopMenu />
      <PageWrapper loading={!initialized} p={4}>
        {() => (
          <>
            {initialized && activeSubscription && (
              <DataCard>
                <DataCard.Row heading="Plan name">
                  <Box textTransform="capitalize">{activeSubscription?.plan?.toLowerCase()}</Box>
                </DataCard.Row>

                {active && (
                  <DataCard.Row heading="Next bill date">{formatToUtc(activeSubscription?.nextBillDate, DateFormats.FULL_DAY)}</DataCard.Row>
                )}
                <DataCard.Row heading="Active until">{formatToUtc(activeSubscription?.nextBillDate, DateFormats.FULL_DAY)}</DataCard.Row>
                {active && (
                  <DataCard.Row heading="Status">
                    <Text color="green.500">Active</Text>
                  </DataCard.Row>
                )}
                {canceled && (
                  <DataCard.Row heading="Status">
                    <Text color="orange.500">Canceled</Text>
                  </DataCard.Row>
                )}
                {activeSubscription?.planPrice && (
                  <DataCard.Row heading="Plan price">
                    {Number(activeSubscription?.planPrice / 100).toFixed(2)} {activeSubscription?.currency?.toUpperCase()}
                  </DataCard.Row>
                )}
                {initialized && (
                  <DataCard.Row heading="Customer portal">
                    <Box color="gray.500" mb={2}>
                      Manage your subscription, billing and view invoices
                    </Box>
                    <Button colorScheme="blue" isDisabled={inProgress} onClick={openCustomerPortal} isLoading={inProgress}>
                      Customer portal
                    </Button>
                  </DataCard.Row>
                )}
              </DataCard>
            )}
            {initialized && !activeSubscription && (
              <Card>
                <AppPricing />
              </Card>
            )}
          </>
        )}
      </PageWrapper>
    </Flex>
  );
};

export default SubscriptionPage;
