import Select from 'react-select';

const httpMethodOptions = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'PUT', value: 'PUT' },
  { label: 'HEAD', value: 'HEAD' },
  { label: 'DELETE', value: 'DELETE' },
  { label: 'PATCH', value: 'PATCH' },
  { label: 'OPTIONS', value: 'OPTIONS' },
];

const HttpMethodDropdown = ({ value, ...rest }) => {
  const selectedOption = httpMethodOptions.find((option) => option.value === value);

  return <Select isSearchable={false} options={httpMethodOptions} value={selectedOption} {...rest} />;
};

export default HttpMethodDropdown;
