"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkerMessageType = exports.WorkerMessageHeader = exports.WorkerMessage = void 0;
class WorkerMessage {
    constructor(queue, genericTaskMessageType) {
        this.queue = queue;
        this.genericTaskMessageType = genericTaskMessageType;
        this.excludedRegions = [];
    }
    getDestinationExchangeName() {
        return this.queue.exchangeName;
    }
    getMessageType() {
        return this.queue.messageType;
    }
    getGenericTaskMessageType() {
        return this.genericTaskMessageType;
    }
    getRoutingKey() {
        return this.queue.routingKey;
    }
    excludeRegion(region) {
        this.excludedRegions.push(region);
        return this;
    }
}
exports.WorkerMessage = WorkerMessage;
var WorkerMessageHeader;
(function (WorkerMessageHeader) {
    WorkerMessageHeader["MESSAGE_TYPE"] = "MESSAGE_TYPE";
    WorkerMessageHeader["GENERIC_MESSAGE_TYPE"] = "GENERIC_MESSAGE_TYPE";
    WorkerMessageHeader["RETRY_ATTEMPT"] = "RETRY_ATTEMPT";
    WorkerMessageHeader["ORIGINAL_ROUTING_KEY"] = "ORIGINAL_ROUTING_KEY";
    WorkerMessageHeader["PROCESSING_QUEUE"] = "PROCESSING_QUEUE";
    WorkerMessageHeader["EXCLUDED_REGIONS"] = "EXCLUDED_REGIONS";
})(WorkerMessageHeader = exports.WorkerMessageHeader || (exports.WorkerMessageHeader = {}));
var WorkerMessageType;
(function (WorkerMessageType) {
    WorkerMessageType["EXECUTE_MONITOR"] = "EXECUTE_MONITOR";
    WorkerMessageType["NOTIFY_MONITOR_INTEGRATION"] = "NOTIFY_MONITOR_INTEGRATION";
    WorkerMessageType["ENQUEUE_MONITORS"] = "ENQUEUE_MONITORS";
    WorkerMessageType["MONITOR_EXECUTED"] = "MONITOR_EXECUTED";
    WorkerMessageType["INCIDENT_CREATED"] = "INCIDENT_CREATED";
    WorkerMessageType["INCIDENT_MONITOR_CHECK_CREATED"] = "INCIDENT_MONITOR_CHECK_CREATED";
    WorkerMessageType["INCIDENT_UPDATED"] = "INCIDENT_UPDATED";
    WorkerMessageType["NOTIFICATION_INTEGRATION_CREATED"] = "NOTIFICATION_INTEGRATION_CREATED";
    WorkerMessageType["NOTIFICATION_INTEGRATION_UPDATED"] = "NOTIFICATION_INTEGRATION_UPDATED";
    WorkerMessageType["SEND_RESET_PASSWORD_TOKEN_EMAIL"] = "SEND_RESET_PASSWORD_TOKEN_EMAIL";
    WorkerMessageType["USER_SIGNED_UP"] = "USER_SIGNED_UP";
    WorkerMessageType["SEND_LOGIN_LINK"] = "SEND_LOGIN_LINK";
    WorkerMessageType["USER_ADDED_TO_ORGANIZATION_MESSAGE"] = "USER_ADDED_TO_ORGANIZATION_MESSAGE";
    WorkerMessageType["SUBSCRIPTION_UPDATED_MESSAGE"] = "SUBSCRIPTION_UPDATED_MESSAGE";
    WorkerMessageType["SEND_VERIFICATION_TOKEN_NOTIFICATION"] = "SEND_VERIFICATION_TOKEN_NOTIFICATION";
    WorkerMessageType["GENERIC_TASK_MESSAGE"] = "GENERIC_TASK_MESSAGE";
    WorkerMessageType["ENQUEUE_WEEKLY_MONITOR_REPORTS"] = "ENQUEUE_WEEKLY_MONITOR_REPORTS";
    WorkerMessageType["SEND_WEEKLY_MONITOR_REPORT"] = "SEND_WEEKLY_MONITOR_REPORT";
    WorkerMessageType["NOTIFY_SSL_CERTIFICATE_EXPIRATION_MESSAGE"] = "NOTIFY_SSL_CERTIFICATE_EXPIRATION_MESSAGE";
    WorkerMessageType["CHECK_DOMAIN_NAME_EXPIRATION"] = "CHECK_DOMAIN_NAME_EXPIRATION";
    WorkerMessageType["CHECK_MONITOR_CERTIFICATE_EXPIRATION"] = "CHECK_MONITOR_CERTIFICATE_EXPIRATION";
    WorkerMessageType["EVALUATE_MONITOR_GROUP_STATUS"] = "EVALUATE_MONITOR_GROUP_STATUS";
    WorkerMessageType["MONITOR_UPDATED"] = "MONITOR_UPDATED";
    WorkerMessageType["STATUS_PAGE_CREATED"] = "STATUS_PAGE_CREATED";
    WorkerMessageType["STATUS_PAGE_UPDATED"] = "STATUS_PAGE_UPDATED";
    WorkerMessageType["STATUS_PAGE_DELETED"] = "STATUS_PAGE_DELETED";
    WorkerMessageType["EVALUATE_STATUS_PAGE_STATUS"] = "EVALUATE_STATUS_PAGE_STATUS";
    WorkerMessageType["UPLOAD_IMAGE_TO_GOOGLE_CLOUD_MESSAGE"] = "UPLOAD_IMAGE_TO_GOOGLE_CLOUD_MESSAGE";
    WorkerMessageType["ORGANIZATION_UPDATED"] = "ORGANIZATION_UPDATED";
    WorkerMessageType["USER_DELETED"] = "USER_DELETED";
    WorkerMessageType["ENQUEUE_NOTIFICATION_POLICY_STATES"] = "ENQUEUE_NOTIFICATION_POLICY_STATES";
    WorkerMessageType["PROCESS_NOTIFICATION_POLICY_STATE"] = "PROCESS_NOTIFICATION_POLICY_STATE";
    WorkerMessageType["NOTIFY_NOTIFICATION_CHANNEL"] = "NOTIFY_NOTIFICATION_CHANNEL";
    WorkerMessageType["ON_CALL_SCHEDULE_UPDATED"] = "ON_CALL_SCHEDULE_UPDATED";
    WorkerMessageType["ON_CALL_DUTY_UPDATED"] = "ON_CALL_DUTY_UPDATED";
    WorkerMessageType["PROCESS_ON_CALL_SCHEDULE"] = "PROCESS_ON_CALL_SCHEDULE";
    WorkerMessageType["ENQUEUE_ON_CALL_SCHEDULES"] = "ENQUEUE_ON_CALL_SCHEDULES";
    WorkerMessageType["NOTIFY_ON_CALL_SCHEDULE"] = "NOTIFY_ON_CALL_SCHEDULE";
    WorkerMessageType["STATUS_PAGE_INCIDENT_CREATED"] = "STATUS_PAGE_INCIDENT_CREATED";
    WorkerMessageType["STATUS_PAGE_INCIDENT_UPDATE_CREATED"] = "STATUS_PAGE_INCIDENT_UPDATE_CREATED";
    WorkerMessageType["STATUS_PAGE_INCIDENT_RESOURCE_CREATED"] = "STATUS_PAGE_INCIDENT_RESOURCE_CREATED";
    WorkerMessageType["STATUS_PAGE_INCIDENT_RESOURCE_STATUS_UPDATED"] = "STATUS_PAGE_INCIDENT_RESOURCE_STATUS_UPDATED";
    WorkerMessageType["INCIDENT_CREATED_STATUS_PAGE_INCIDENT"] = "INCIDENT_CREATED_STATUS_PAGE_INCIDENT";
    WorkerMessageType["INCIDENT_UPDATED_STATUS_PAGE_INCIDENT"] = "INCIDENT_UPDATED_STATUS_PAGE_INCIDENT";
})(WorkerMessageType = exports.WorkerMessageType || (exports.WorkerMessageType = {}));
