import { useMemo } from 'react';
import { duration } from '../utils/duration';
import { formatDuration } from 'date-fns';

const useFormattedDuration = (startDate: Date | string, endDate: Date | string, format: string[]) => {
  const formattedDuration = useMemo(() => {
    if (!startDate || !endDate) {
      return [null];
    }
    const elapsedDuration = duration(new Date(startDate), new Date(endDate));

    return formatDuration(elapsedDuration, { format });
  }, [startDate, endDate]);

  return [formattedDuration];
};

export default useFormattedDuration;
