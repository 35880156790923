import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { Plan } from '@um/uptime-monitoring-shared';
import { useOrganizationTrialMutation, useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import PlanSelectionCard from '../plan-selection-card/planSelectionCard';
import PlanDescriptions from '@um/uptime-monitoring-shared/dist/model/planDescriptions';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import WebsiteLink from '../website-link/websiteLink';
import trackEvent from '../../utils/trackEvent';

type Props = ButtonProps & {
  plan: Plan;
};

const StartTrialButton: React.FC<Props> = ({ children, plan, ...rest }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const [modalOpen, setModalOpen] = useState(false);
  const { isLoading, mutateAsync } = useOrganizationTrialMutation();

  const onClose = () => setModalOpen(false);
  const onStartTrial = useCallback(async () => {
    await mutateAsync(
      { organizationId, createRequest: { plan: Plan.TEAM }, action: MutationAction.POST },
      {
        onSuccess: () => toast.success('Your Team plan trial has started!'),
        onError: () => toast.error('Error occurred. Please try again later'),
      }
    );
  }, [organizationId]);

  const handleShowTrialModal = () => {
    trackEvent('Start Trial Modal Displayed');

    setModalOpen(true);
  };

  return (
    <>
      <Button colorScheme="gray" onClick={handleShowTrialModal} {...rest}>
        {children ? children : 'Start trial'}
      </Button>
      <Modal isOpen={modalOpen} onClose={onClose} size="xl" lockFocusAcrossFrames={false} autoFocus={false} trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center" justifyContent="center" textAlign="center" w="100%" fontSize="2xl" fontWeight="bold">
              Start trial
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems="center" justifyContent="center" textAlign="center" w="100%" color="gray.600" fontSize="xl" my={4}>
              Are you sure you want to start Team plan trial?
            </Flex>
            <PlanSelectionCard
              name={
                <Box>
                  <Box fontSize="2xl" lineHeight="1" fontWeight="bold">
                    {PlanDescriptions[plan].name} plan
                  </Box>
                </Box>
              }
              features={PlanDescriptions[plan].features}
              cursor="default"
              active
            />
            <Flex alignItems="center" justifyContent="center" width="100%" mt={4}>
              <WebsiteLink href="https://statusping.co/pricing#plan-comparison" target="_blank" wrapperProps={{ fontSize: 'xl' }}>
                Full plan overview
                <ExternalLinkIcon mx={1} />
              </WebsiteLink>
            </Flex>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button variant="outline" size="lg" disabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" size="lg" mr={3} onClick={onStartTrial} isLoading={isLoading} disabled={isLoading}>
              Start trial
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StartTrialButton;
