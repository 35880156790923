import { PlanFeature } from '@um/uptime-monitoring-shared';
import { getFeatureQuota } from '../utils/getFeatureQuota';
import { useSelectedOrganizationFeaturesQuery } from '../query/organizationQueries';

const useFeatureQuotaExceeded = (feature: PlanFeature, used: number) => {
  const { data: features } = useSelectedOrganizationFeaturesQuery();
  const quota = getFeatureQuota(feature, features);
  const quotaExceeded = used > quota;

  return [quotaExceeded];
};

export default useFeatureQuotaExceeded;
