import React, { useCallback, useEffect, useMemo } from 'react';
import {
  ApplicationEventType,
  NotificationChannelOptionType,
  NotificationChannelOptionVO,
  NotificationChannelReference,
  WebsocketApplicationEventMessage,
} from '@um/uptime-monitoring-shared';
import { applicationEventEmitter } from '../../app/saga/websocket/websocket';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { useOrganizationNotificationChannelOptionsQuery } from '../../query/notificationChannelQueries';
import Spinner from '../spinner/spinner';
import { FormSelector } from '../form-select/formSelector';
import { createFilter } from 'react-select';
import { IntegrationTypeOptions } from '../../models/integrationTypeOptions';
import { Flex, Heading, Icon } from '@chakra-ui/react';
import { notificationChannelOptionToReference } from '../../utils/notificationChannelOptionToReference';
import { prepareRules } from '../../utils/formValidationRules';
import { Control, Controller } from 'react-hook-form';
import { combineUsersName } from '../../utils/combineUsersName';

type Props = {
  value: NotificationChannelReference;
  onChange: (value: NotificationChannelReference) => void;
  types?: NotificationChannelOptionType[];
  error?: any;
};

export type ControlledSelectProps = {
  control: Control<any>;
  name: string;
  regularLabel?: boolean;
  rules?: any;
  error?: any;
} & Omit<Props, 'value' | 'onChange'>;

const formatOptionLabel = (notificationChannel: NotificationChannelOptionVO) => {
  const integrationTypeOption = IntegrationTypeOptions.find((item) => item.type === notificationChannel?.notificationIntegrationType);

  return (
    <Flex flexDirection="column" gap={1}>
      <Heading size="xs" textTransform="uppercase" color="gray.600">
        {Boolean(notificationChannel?.notificationPolicyId) && 'Notification policy'}
        {Boolean(notificationChannel?.notificationIntegrationId) && 'Notification integration'}
        {Boolean(notificationChannel?.onCallScheduleId) && 'On-call schedule'}
      </Heading>
      <Flex gap={1} alignItems="center">
        {integrationTypeOption && <Icon as={integrationTypeOption.icon} />}
        {notificationChannel?.name && notificationChannel.name}
      </Flex>
    </Flex>
  );
};

const SingleNotificationChannelSelector: React.FC<Props> = ({ value, types, onChange, error }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isFetched, data: notificationChannels, refetch } = useOrganizationNotificationChannelOptionsQuery(organizationId, types);

  const onMessage = useCallback((message: WebsocketApplicationEventMessage) => {
    if (
      message.messageType !== ApplicationEventType.NOTIFICATION_INTEGRATION_CREATED &&
      message.messageType !== ApplicationEventType.NOTIFICATION_INTEGRATION_UPDATED
    ) {
      return;
    }

    refetch();
  }, []);

  useEffect(() => {
    const listener = (message) => onMessage(message);
    applicationEventEmitter.addListener('message', listener);

    return () => {
      applicationEventEmitter.removeListener('message', listener);
    };
  }, []);

  const isChannelSelected = useCallback(
    (option: NotificationChannelOptionVO) => {
      return (
        (!types || types.length < 1 || types.includes(option.type)) &&
        ((value?.notificationIntegrationId && option.notificationIntegrationId === value?.notificationIntegrationId) ||
          (value?.notificationPolicyId && option.notificationPolicyId === value?.notificationPolicyId) ||
          (value?.onCallScheduleId && option.onCallScheduleId === value?.onCallScheduleId))
      );
    },
    [value]
  );

  const selectedOption = useMemo(() => {
    return (notificationChannels || []).find(isChannelSelected);
  }, [value, notificationChannels]);

  const handleChange = (option: NotificationChannelOptionVO) => {
    onChange(notificationChannelOptionToReference(option));
  };

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <FormSelector
      isSearchable
      options={notificationChannels}
      value={selectedOption}
      isOptionSelected={isChannelSelected}
      filterOption={createFilter({
        trim: true,
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: (option) => option.data.name,
      })}
      error={error}
      formatOptionLabel={formatOptionLabel}
      onChange={handleChange}
      styles={{
        control: (styles) => ({
          ...styles,
          minHeight: 50,
        }),
      }}
    />
  );
};

// todo not working properly
export const ControlledSingleNotificationChannelSelector: React.FC<ControlledSelectProps> = ({ control, name, rules, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        const value = {
          notificationIntegrationId: field.value?.notificationIntegrationId,
          notificationPolicyId: field.value?.notificationPolicyId,
          onCallScheduleId: field.value?.onCallScheduleId,
        };

        return <SingleNotificationChannelSelector error={error?.message} value={value} onChange={field.onChange} {...rest} />;
      }}
    />
  );
};

ControlledSingleNotificationChannelSelector.displayName = 'ControlledSingleNotificationChannelSelector';

export default SingleNotificationChannelSelector;
