import { Badge } from '@chakra-ui/react';
import React from 'react';
import { StatusPageIncidentBadgeStyles } from '@um/uptime-monitoring-shared';

const StatusPageIncidentStatusBadge = ({ status }) => {
  const badgeStyle = StatusPageIncidentBadgeStyles[status];

  return <Badge colorScheme={badgeStyle.colorScheme}>{badgeStyle.text}</Badge>;
};

export default StatusPageIncidentStatusBadge;
