import React, { useMemo } from 'react';
import { DowntimeStatisticsVO, MonitorStatus, MonitorTransport, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import { Box, BoxProps, Flex, Icon, IconButton, Skeleton, Tooltip } from '@chakra-ui/react';
import DataTable, { DataTableProps } from '../data-table/dataTable';
import { generatePath } from 'react-router-dom';
import Routes from '../../utils/routes';
import { formatMs } from '../../utils/formatMs';
import MonitorStatusIcon from '../monitor-status-icon/monitorStatusIcon';
import MonitorTransportLabel from '../monitor-transport-label/monitorTransportLabel';
import useMonitorUptimeStatistics from '../../hooks/useMonitorUptimeStatistics';
import { MdOutlineTimer } from 'react-icons/md';
import { stopPropagate } from '../../utils/stopPropagate';
import useDynamicModal from '../../hooks/useDynamicModal';
import IncidentDetails from '../incident-details/incidentDetails';
import CheckInMonitorInformation from '../monitor-overview-tab/checkInMonitorInformation';

type MonitorOverviewTableProps = Omit<DataTableProps, 'children'> & {
  monitors: ProtectedMonitorDetailsResponse[];
  downtimeStatistics: DowntimeStatisticsVO[];
  allowSelection?: boolean;
};

type MonitorOverviewTableRowProps = {
  monitor: ProtectedMonitorDetailsResponse;
  downtimeStatistics: DowntimeStatisticsVO[];
  hasCheckInMonitors: boolean;
} & BoxProps;

const MonitorOverviewTable: React.FC<MonitorOverviewTableProps> = ({ monitors, downtimeStatistics, ...rest }) => {
  const noMonitors = monitors?.length < 1;
  const hasCheckInMonitors = useMemo(() => (monitors || []).some((monitor) => monitor.transport === MonitorTransport.CHECK_IN), [monitors]);

  return (
    <DataTable {...rest}>
      {() => (
        <>
          {!noMonitors && (
            <>
              <DataTable.Head>
                <DataTable.HeaderRow>
                  <DataTable.HeaderCell px={0}>Status</DataTable.HeaderCell>
                  <DataTable.HeaderCell>Name</DataTable.HeaderCell>
                  <DataTable.HeaderCell>Type</DataTable.HeaderCell>
                  <DataTable.HeaderCell>
                    <Tooltip label="Average response time over the whole period while the monitor is active">
                      <Flex whiteSpace="nowrap" justifyContent="center" alignItems="center">
                        Response time
                      </Flex>
                    </Tooltip>
                  </DataTable.HeaderCell>
                  <DataTable.HeaderCell>
                    <Tooltip label="Average uptime over the last 7 days">
                      <Flex whiteSpace="nowrap" alignItems="center" justifyContent="center">
                        Uptime (7d)
                      </Flex>
                    </Tooltip>
                  </DataTable.HeaderCell>
                  {hasCheckInMonitors && (
                    <>
                      <DataTable.HeaderCell maxWidth="40px" width="40px" py={3} />
                      <DataTable.HeaderCell maxWidth="10px" width="10px" p={0} />
                    </>
                  )}
                </DataTable.HeaderRow>
              </DataTable.Head>
              <DataTable.Body>
                {monitors.map((monitor) => (
                  <MonitorOverviewTableRow
                    key={monitor.id}
                    monitor={monitor}
                    downtimeStatistics={downtimeStatistics}
                    hasCheckInMonitors={hasCheckInMonitors}
                  />
                ))}
              </DataTable.Body>
            </>
          )}
          {noMonitors && (
            <DataTable.Body>
              <DataTable.Row>
                <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                  <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                    <Box>Monitors will be displayed here.</Box>
                  </Flex>
                </DataTable.Cell>
              </DataTable.Row>
            </DataTable.Body>
          )}
        </>
      )}
    </DataTable>
  );
};

const MonitorOverviewTableRow: React.FC<MonitorOverviewTableRowProps> = ({ monitor, downtimeStatistics, hasCheckInMonitors }) => {
  const href = useMemo(
    () =>
      generatePath(Routes.MONITOR_DETAILS_OVERVIEW, {
        id: monitor.id,
      }),
    []
  );
  const checking = monitor.status === MonitorStatus.NONE;
  const avgResponseTime = monitor?.responseTimeStats.averageResponseTime ? formatMs(monitor?.responseTimeStats.averageResponseTime) : null;
  const monitorStatistics = useMemo(() => {
    return downtimeStatistics.find((item) => item.monitorId === monitor.id);
  }, [downtimeStatistics]);
  const checkInMonitor = monitor.transport === MonitorTransport.CHECK_IN;
  const { modal, setOpen: setModalOpen } = useDynamicModal({
    render: () => <CheckInMonitorInformation monitor={monitor} showCard={false} />,
    size: '2xl',
    title: 'Check-in information',
  });

  const [uptimePercentage, uptimePercentageText] = useMonitorUptimeStatistics(monitor.status, monitorStatistics);
  return (
    <DataTable.LinkRow to={href} itemId={monitor.id}>
      {modal}
      <DataTable.Cell tooltip={null} overflow="visible" maxWidth="60px">
        <MonitorStatusIcon monitor={monitor} />
      </DataTable.Cell>
      <DataTable.Cell>{monitor.name}</DataTable.Cell>
      <DataTable.Cell tooltip={null}>
        <MonitorTransportLabel transport={monitor.transport} />
      </DataTable.Cell>
      <DataTable.Cell tooltip={checkInMonitor ? null : undefined}>
        {checkInMonitor ? '-' : checking ? <CheckingPlaceholder /> : avgResponseTime ? avgResponseTime : '-'}
      </DataTable.Cell>
      <DataTable.Cell tooltip={checkInMonitor || checking ? null : undefined} overflow="visible">
        {checking || isNaN(Number(uptimePercentage)) ? <CheckingPlaceholder /> : uptimePercentageText}
      </DataTable.Cell>
      {hasCheckInMonitors && (
        <>
          <DataTable.Cell tooltip={null} overflow="visible" maxWidth="40px" p={0} noHover>
            {checkInMonitor && (
              <Tooltip label="Check-in information">
                <IconButton
                  variant="outline"
                  colorScheme="gray"
                  aria-label="Check-in information"
                  icon={<Icon as={MdOutlineTimer} boxSize={6} />}
                  onClick={stopPropagate(() => setModalOpen(true))}
                />
              </Tooltip>
            )}
          </DataTable.Cell>
          <DataTable.Cell tooltip={null} overflow="visible" maxWidth="10px" minWidth="10px" p={0} />
        </>
      )}
    </DataTable.LinkRow>
  );
};

const CheckingPlaceholder = () => <Skeleton h={6} w={24} margin="auto" />;

export default MonitorOverviewTable;
