"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorTransportValues = exports.MonitorTransport = void 0;
var MonitorTransport;
(function (MonitorTransport) {
    MonitorTransport["HTTP"] = "HTTP";
    MonitorTransport["ICMP_PING"] = "ICMP_PING";
    MonitorTransport["CHECK_IN"] = "CHECK_IN";
})(MonitorTransport = exports.MonitorTransport || (exports.MonitorTransport = {}));
exports.MonitorTransportValues = Object.values(MonitorTransport);
