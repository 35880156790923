import { Flex, StackDivider, VStack } from '@chakra-ui/react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { FormSelect } from '../form-controller/formController';
import HttpMethodDropdown from '../http-method-dropdown/httpMethodDropdown';
import { ControlledInput } from '../input/input';
import ClickableText from '../clickable-text/clickableText';
import { ControlledTextArea } from '../text-area/textArea';
import { ControlledToggle } from '../toggle/toggle';
import { ControlledTimeoutSelector } from '../timeout-selector/timeoutSelector';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import formValidationRules from '../../utils/formValidationRules';

const { required } = formValidationRules;

const HttpRequestSettingsSubform = () => {
  const { control, watch } = useFormContext();
  const headers = useFieldArray({
    control,
    name: 'httpMonitorOptions.headers',
  });
  const {
    httpMonitorOptions: { method },
  } = watch();

  return (
    <VStack spacing={4} align="stretch" divider={<StackDivider borderColor="gray.200" />}>
      <FormFieldWrapper title="HTTP request method">
        <FormSelect name="httpMonitorOptions.method" rules={{ required }} control={control}>
          {(field) => <HttpMethodDropdown placeholder="Request method" value={method} {...field} />}
        </FormSelect>
      </FormFieldWrapper>
      <FormFieldWrapper title="Headers">
        <VStack spacing={2} alignItems="flex-start">
          {headers.fields.length > 0 &&
            headers.fields.map((header, idx) => {
              return (
                <Flex key={idx} gap={2}>
                  <ControlledInput
                    width="100%"
                    margin={0}
                    name={`httpMonitorOptions.headers[${idx}].name`}
                    control={control}
                    placeholder="Header name"
                    required
                  />
                  <ControlledInput
                    width="100%"
                    margin={0}
                    name={`httpMonitorOptions.headers[${idx}].value`}
                    control={control}
                    placeholder="Header value"
                    required
                  />
                  <ClickableText mt={2} color="red" size="md" onClick={() => headers.remove(idx)}>
                    Remove
                  </ClickableText>
                </Flex>
              );
            })}
          {headers.fields.length < 1 && <div>No headers defined</div>}
          <ClickableText size="lg" onClick={() => headers.append({})}>
            Add header
          </ClickableText>
        </VStack>
      </FormFieldWrapper>
      <FormFieldWrapper title="Request body">
        <ControlledTextArea w="100%" name={`httpMonitorOptions.requestBody`} control={control} placeholder="Request body" />
      </FormFieldWrapper>
      <FormFieldWrapper title="Follow redirects" description="If enabled will follow up to 10 redirects">
        <ControlledToggle w="100%" name="httpMonitorOptions.followRedirects" control={control} />
      </FormFieldWrapper>
      <FormFieldWrapper
        title="Request timeout (milliseconds)"
        description="If response time exceeds the timeout specified then the monitor will fail"
      >
        <ControlledTimeoutSelector control={control} name="httpMonitorOptions.timeout" rules={{ required }} />
      </FormFieldWrapper>
    </VStack>
  );
};

export default HttpRequestSettingsSubform;
