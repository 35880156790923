"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentMonitorCheckCreatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class IncidentMonitorCheckCreatedMessage extends workerMessage_1.WorkerMessage {
    constructor(incidentMonitorCheck) {
        super(model_1.QueueDefinitions.INCIDENT_MONITOR_CHECK_CREATED);
        this.incidentMonitorCheck = incidentMonitorCheck;
    }
}
exports.IncidentMonitorCheckCreatedMessage = IncidentMonitorCheckCreatedMessage;
