import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

type Props = BoxProps & {
  regular?: boolean;
};

const FormFieldLabel: React.FC<Props> = ({ regular = false, children, ...rest }) => {
  return (
    <Box as="label" fontWeight="bold" mb={1} fontSize={regular ? 'md' : 'lg'} {...rest}>
      {children}
    </Box>
  );
};

export default FormFieldLabel;
