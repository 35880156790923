import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../error-fallback/errorFallback';
import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';
import ClickableText from '../clickable-text/clickableText';

const DataDisplayErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => {
        return (
          <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Error occurred when displaying information
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              Please{' '}
              <ClickableText onClick={resetErrorBoundary} size="md">
                try again
              </ClickableText>{' '}
              later
            </AlertDescription>
          </Alert>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default DataDisplayErrorBoundary;
