import React from 'react';
import { MonitorGroupResponse, MonitorGroupStatus } from '@um/uptime-monitoring-shared';
import StatusIcon from '../status-icon/statusIcon';
import { monitorGroupStatusColor, monitorGroupStatusText } from '../../utils/monitorGroupStatus';

const MonitorGroupStatusIcon: React.FC<{ monitorGroup: MonitorGroupResponse }> = ({ monitorGroup }) => {
  const color = monitorGroupStatusColor(monitorGroup.status);
  const statusText = monitorGroupStatusText(monitorGroup.status);

  return <StatusIcon color={color} text={statusText} paused={false} loading={monitorGroup.status === MonitorGroupStatus.NONE} />;
};

export default MonitorGroupStatusIcon;
