import React from 'react';
import { ControlledInput } from '../input/input';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertIcon, Box, Button, Flex } from '@chakra-ui/react';
import Card from '../card/card';

export interface ResetPasswordFormData {
  password: string;
}

const defaultValues: ResetPasswordFormData = {
  password: '',
};

type Props = {
  onResetPassword: (data: ResetPasswordFormData) => void;
  disabled: boolean;
  loading: boolean;
  error?: string;
};

export const ResetPasswordForm: React.FC<Props> = ({ onResetPassword, disabled, loading, error, ...rest }) => {
  const formMethods = useForm<ResetPasswordFormData>({ defaultValues });
  const { handleSubmit, control } = formMethods;

  return (
    <Flex alignItems="center" minH="100%" justifyContent="center">
      <Flex flexDirection="column" maxW="container.md" w="100%" gap={2}>
        <Card>
          <div>
            <Box as="h2" fontSize="3xl" textAlign="center" fontWeight="bold">
              Reset password
            </Box>
          </div>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <FormProvider {...formMethods}>
            <Flex as="form" flexDirection="column" gap={6} {...rest} onSubmit={handleSubmit(onResetPassword)}>
              <input type="hidden" name="remember" defaultValue="true" />
              <Box borderRadius="md" boxShadow="sm">
                <div>
                  <ControlledInput placeholder="Password" name="password" type="password" isDisabled={disabled} control={control} />
                </div>
              </Box>

              <div>
                <Button type="submit" colorScheme="blue" w="100%" isDisabled={disabled} isLoading={loading}>
                  Submit
                </Button>
              </div>
            </Flex>
          </FormProvider>
        </Card>
      </Flex>
    </Flex>
  );
};

export default ResetPasswordForm;
