import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormErrorMessage, Textarea, TextareaProps } from '@chakra-ui/react';
import formValidationRules from '../../utils/formValidationRules';
import FormFieldLabel from '../form-field-label/formFieldLabel';

interface Props extends Omit<TextareaProps, 'error'> {
  label?: string;
  error?: string;
}
type ControlledProps = { control: Control<any>; required?: boolean } & Props;

const TextArea: React.FC<Props> = ({ placeholder, label, error, children, ...rest }) => {
  const hasError = !!error;

  return (
    <div>
      {label && <FormFieldLabel>{label}</FormFieldLabel>}
      <Textarea placeholder={placeholder} error={hasError} fluid {...rest} />
      {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
      {children}
    </div>
  );
};

TextArea.displayName = 'TextArea';

export const ControlledTextArea = ({ control, name, required = false, ...rest }: ControlledProps) => {
  const rules = {
    ...(required ? { required: formValidationRules.required } : {}),
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => <TextArea error={error?.message} {...rest} {...field} />}
    />
  );
};

ControlledTextArea.displayName = 'ControlledTextArea';

export default TextArea;
