import { useFormContext } from 'react-hook-form';
import React from 'react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import ControlledCheckbox from '../checkbox/checkbox';
import { VStack } from '@chakra-ui/react';
import { MonitorFormModel } from '../../models/monitorFormModel';

const CertificateMonitorOptionsFormFields: React.FC = () => {
  const { watch, setValue } = useFormContext<MonitorFormModel>();

  const notify1DayBeforeExpirationField = 'httpMonitorOptions.certificateMonitorOptions.notify1DayBeforeExpiration';
  const notify7DaysBeforeExpirationField = 'httpMonitorOptions.certificateMonitorOptions.notify7DaysBeforeExpiration';
  const notify14DaysBeforeExpirationField = 'httpMonitorOptions.certificateMonitorOptions.notify14DaysBeforeExpiration';
  const enabledFieldName = 'httpMonitorOptions.monitorCertificate';
  const enabled = watch(enabledFieldName);

  const handleEnabledChange = (checked: boolean) => {
    setValue(notify1DayBeforeExpirationField, checked);
    setValue(notify7DaysBeforeExpirationField, checked);
    setValue(notify14DaysBeforeExpirationField, checked);

    return true;
  };

  return (
    <FormFieldWrapper title="SSL certificate monitoring" description="Get notified when your website's SSL certificate is about to expire">
      <ControlledCheckbox name={enabledFieldName} fontWeight="bold" onChange={handleEnabledChange}>
        Monitor SSL certificate expiration
      </ControlledCheckbox>
      <VStack pl={8} mt={4} alignItems="flex-start">
        <ControlledCheckbox isDisabled={!enabled} name={notify1DayBeforeExpirationField}>
          Notify 1 day before expiration
        </ControlledCheckbox>
        <ControlledCheckbox isDisabled={!enabled} name={notify7DaysBeforeExpirationField}>
          Notify 7 days before expiration
        </ControlledCheckbox>
        <ControlledCheckbox isDisabled={!enabled} name={notify14DaysBeforeExpirationField}>
          Notify 14 days before expiration
        </ControlledCheckbox>
      </VStack>
    </FormFieldWrapper>
  );
};

export default CertificateMonitorOptionsFormFields;
