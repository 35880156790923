import { PlanFeature } from '@um/uptime-monitoring-shared';
import { useSelectedOrganizationFeaturesQuery } from '../query/organizationQueries';

const useHasFeature = (feature: PlanFeature) => {
  const { data: features, isLoading } = useSelectedOrganizationFeaturesQuery();

  return !isLoading && !!features[feature];
};

export default useHasFeature;
