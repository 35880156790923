import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '../../utils/withPayloadType';
import { editMonitor as editMonitorReducer } from '../reducer/editMonitor';
import { MonitorFormModel } from '../../models/monitorFormModel';

const initialize = createAction('editMonitor/initialize', withPayloadType<string>());
const editMonitor = createAction('editMonitor/edit', withPayloadType<MonitorFormModel>());

const editMonitorActions = {
  ...editMonitorReducer.actions,
  initialize,
  editMonitor,
};

export default editMonitorActions;
