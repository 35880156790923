import { AppConfig } from './config';

export const RouteSlugs = {
  OVERVIEW: 'overview',
  INTEGRATIONS: 'integrations',
  POLICIES: 'notification-policy',
  SETTINGS: 'settings',
  INCIDENTS: 'incidents',
};

const ON_CALL_SCHEDULE = '/settings/on-call-schedules/:id';
const Routes = {
  HOME: '/',
  MONITORS: '/monitors',
  INCIDENTS: '/incidents',
  INCIDENT_DETAILS: '/incidents/:id',
  MONITOR_DETAILS: '/monitors/:id',
  MONITOR_DETAILS_OVERVIEW: '/monitors/:id/overview',
  MONITOR_SETTINGS: '/monitors/:id/settings',
  MONITOR_CHECK_DETAILS: '/monitor-checks/:id',
  CREATE_MONITOR: '/monitors/create',
  NOTIFICATIONS: '/notifications',

  SETTINGS: '/settings',
  NOTIFICATION_INTEGRATION: `/settings/notification-integrations/:id`,
  NOTIFICATION_INTEGRATIONS: `/settings/notification-integrations`,
  CREATE_NOTIFICATION_INTEGRATION: '/settings/notification-integrations/create',

  NOTIFICATIONS_POLICIES: '/settings/notification-policy',
  NOTIFICATIONS_POLICY_DETAILS: '/settings/notification-policy/:id',
  CREATE_NOTIFICATION_POLICY: '/settings/notification-policy/create',

  ON_CALL_SCHEDULES: '/settings/on-call-schedules',
  CREATE_ON_CALL_SCHEDULE: '/settings/on-call-schedules/create',
  ON_CALL_SCHEDULE,
  ON_CALL_SCHEDULE_OVERVIEW: `${ON_CALL_SCHEDULE}/overview`,
  ON_CALL_SCHEDULE_SETTINGS: `${ON_CALL_SCHEDULE}/settings`,

  USERS: '/settings/users',

  SUBSCRIPTION: '/settings/subscription',

  ORGANIZATION: '/settings/organization',

  NOTIFICATIONS_OVERVIEW: '/notifications/overview',
  NOTIFICATION: '/notification',
  NOTIFICATION_DETAILS: '/notification/:id',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL_ADDRESS: '/verify-email-address',
  USER_NOTIFICATION_SETTINGS: '/user-notification-settings',
  USER: '/user',
  MONITOR_GROUPS: '/monitor-groups',
  MONITOR_GROUP: '/monitor-groups/:id',
  MONITOR_GROUP_OVERVIEW: '/monitor-groups/:id/overview',
  MONITOR_GROUP_SETTINGS: '/monitor-groups/:id/settings',
  CREATE_MONITOR_GROUP: '/monitor-groups/create',
  GOOGLE_AUTH: `${AppConfig.ApiBaseUrl}/v1/public/google-auth`,
  STATUS_PAGES: '/status-pages',
  STATUS_PAGE_OVERVIEW: '/status-pages/:id/overview',
  STATUS_PAGE: '/status-pages/:id',
  STATUS_PAGE_SETTINGS: '/status-pages/:id/settings',
  STATUS_PAGE_INCIDENTS: '/status-pages/:id/incidents',
  STATUS_PAGE_INCIDENT: '/status-pages/:statusPageId/incidents/:statusPageIncidentId',
  CREATE_STATUS_PAGE_INCIDENT: '/status-pages/:statusPageId/incidents/create',
  CREATE_STATUS_PAGE: '/status-pages/create',
};

export default Routes;
