import React from 'react';
import Spinner from '../spinner/spinner';
import { useStatusPageQuery } from '../../query/queries';
import EditStatusPageForm from '../edit-status-page-form/editStatusPageForm';

export type Props = {
  statusPageId: string;
};

const EditStatusPageTab = ({ statusPageId }: Props) => {
  const { isLoading } = useStatusPageQuery(statusPageId);

  return (
    <div>
      {isLoading && <Spinner />}
      {!isLoading && <EditStatusPageForm statusPageId={statusPageId} />}
    </div>
  );
};

export default EditStatusPageTab;
