import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React, { FormHTMLAttributes, useEffect, useLayoutEffect, useState } from 'react';
import { PartialDeep } from 'type-fest';
import { FormFieldError } from '@um/uptime-monitoring-shared';
import { Box, Button, Divider, Flex, Icon, StackDivider, VStack } from '@chakra-ui/react';
import Card from '../card/card';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import {
  emptyNotificationPolicyStep,
  NotificationPolicyFormModel,
  NotificationPolicyFormModelDefaultValues,
} from '../../models/notificationPolicyFormModel';
import { AiFillPlusCircle } from 'react-icons/ai';
import NotificationPolicyStepFormFields from './notificationPolicyStepFormFields';
import { onSubmitWrapper } from '../../utils/onSubmitWrapper';

type Props = {
  formValues?: PartialDeep<NotificationPolicyFormModel>;
  mainActionText: string;
  disabled: boolean;
  onSubmit: (data: NotificationPolicyFormModel) => void;
  hideEnabledToggle?: boolean;
  formErrors?: FormFieldError[];
  showCards?: boolean;
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>;

const required = { value: true, message: 'This field is required' };

const NotificationPolicyForm = ({
  formValues = NotificationPolicyFormModelDefaultValues,
  mainActionText,
  className,
  disabled,
  onSubmit,
  formErrors,
  showCards = true,
  ...rest
}: Props) => {
  const formMethods = useForm<NotificationPolicyFormModel>({ defaultValues: formValues });
  const [formErrorsProcessed, setFormErrorsProcessed] = useState(0);
  const { handleSubmit, control, setError } = formMethods;
  const steps = useFieldArray({
    control,
    name: 'steps',
  });

  useEffect(() => {
    if (formErrors && formErrors.length > 0) {
      for (const formError of formErrors) {
        setError(formError.dataPath as any, {
          type: 'manual',
          message: formError.message,
        });
      }

      setFormErrorsProcessed(formErrorsProcessed + 1);
    }
  }, [formErrors]);

  useLayoutEffect(() => {
    const erroneousElement = document.querySelector('.ui.error');

    if (erroneousElement) {
      erroneousElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formErrorsProcessed]);

  const submit = (data: NotificationPolicyFormModel) => {
    onSubmit({
      ...data,
      steps: (data.steps || []).map((step, idx) => ({
        ...step,
        order: idx + 1,
        nextStepAfter: isNaN(parseInt(step.nextStepAfter as any)) ? 5 : parseInt(step.nextStepAfter as any),
      })),
    });
  };
  const appendStep = () => {
    steps.append(emptyNotificationPolicyStep(steps.fields.length + 1));
  };
  const WrapperComponent = showCards ? Card : Box;

  return (
    <FormProvider {...formMethods}>
      <form className={className} {...rest} onSubmit={onSubmitWrapper(handleSubmit(submit))}>
        <VStack spacing={4}>
          <WrapperComponent width="100%">
            <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
              <FormFieldWrapper title="Name" description="Simple name that will help you easily identify this notification policy">
                <ControlledInput name="name" control={control} placeholder="Name" rules={{ required }} />
              </FormFieldWrapper>
              <FormFieldWrapper title="Description">
                <ControlledInput name="description" control={control} placeholder="Description" />
              </FormFieldWrapper>
            </VStack>
          </WrapperComponent>

          <Flex alignItems="center" width="100%">
            <Box fontWeight="bold" fontSize="xl">
              Notification steps
            </Box>
          </Flex>
          <WrapperComponent width="100%">
            <VStack spacing={4} align="stretch" shouldWrapChildren>
              {steps.fields.map((entry, idx) => (
                <NotificationPolicyStepFormFields
                  key={entry.id}
                  idx={idx}
                  path={`steps[${idx}]`}
                  onMoveUp={() => steps.swap(idx, idx - 1)}
                  onMoveDown={() => steps.swap(idx, idx + 1)}
                  onDelete={() => steps.remove(idx)}
                  showDelete={steps.fields.length > 1}
                  first={idx === 0}
                  last={idx === steps.fields.length - 1}
                />
              ))}
              <Divider borderColor="gray.200" />
              <Button colorScheme="blue" variant="outline" size="lg" w="100%" onClick={appendStep}>
                <Flex alignItems="center" gap={1}>
                  <Icon boxSize={6} as={AiFillPlusCircle} />
                  <Box fontSize="xl">Add step</Box>
                </Flex>
              </Button>
            </VStack>
          </WrapperComponent>

          <Button type="submit" disabled={disabled} colorScheme="blue" width="min-content" size="lg">
            {mainActionText}
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default NotificationPolicyForm;
