import React from 'react';
import { HttpMonitorAssertionOperationType } from '@um/uptime-monitoring-shared';
import Select from 'react-select';

const operationOptions = [
  { label: 'equals', value: HttpMonitorAssertionOperationType.EQUALS },
  { label: 'not equals', value: HttpMonitorAssertionOperationType.NOT_EQUALS },
  {
    label: 'greater than',
    value: HttpMonitorAssertionOperationType.GREATER_THAN,
  },
  {
    label: 'greater than or equal',
    value: HttpMonitorAssertionOperationType.GREATER_THAN_OR_EQUAL,
  },
  { label: 'less than', value: HttpMonitorAssertionOperationType.LESS_THAN },
  {
    label: 'less than or equal',
    value: HttpMonitorAssertionOperationType.LESS_THAN_OR_EQUAL,
  },
];

const HttpMonitorAssertionOperationDropdown = ({ value, ...rest }) => {
  const selectedOption = operationOptions.find((option) => option.value === value);

  return <Select isSearchable={false} {...rest} options={operationOptions} value={selectedOption} />;
};

export default HttpMonitorAssertionOperationDropdown;
