export const formatMs = (ms: number) => {
  if (!isFinite(ms)) {
    return ms;
  }

  const seconds = Math.floor(ms / 1000);
  const milliseconds = ms % 1000;

  const parts = [seconds >= 1 ? seconds + 's' : '', milliseconds ? milliseconds.toFixed(0) + 'ms' : ''];

  return parts.join(' ');
};
