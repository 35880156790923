import { createAction } from '@reduxjs/toolkit';
import { IntegrationFormModel } from '../../components/integration-form/integrationForm';
import { withPayloadType } from '../../utils/withPayloadType';

export interface VerifyNotificationIntegrationPayload {
  notificationIntegrationId: string;
  verificationToken: string;
}

export interface SetNotificationIntegrationEnabledPayload {
  id: string;
  enabled: boolean;
}

const createNotificationIntegration = createAction('notifications/createIntegration', withPayloadType<IntegrationFormModel>());
const verifyNotificationIntegration = createAction(
  'notifications/verifyNotificationIntegration',
  withPayloadType<VerifyNotificationIntegrationPayload>()
);
const setNotificationIntegrationEnabled = createAction(
  'notifications/setNotificationIntegrationEnabled',
  withPayloadType<SetNotificationIntegrationEnabledPayload>()
);

const notificationsActions = {
  createNotificationIntegration,
  verifyNotificationIntegration,
  setNotificationIntegrationEnabled,
};

export default notificationsActions;
