import React from 'react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { ControlledInput } from '../input/input';
import { useFormContext } from 'react-hook-form';
import formValidationRules from '../../utils/formValidationRules';
import { VStack } from '@chakra-ui/react';
import { ControlledPhoneNumberInput } from '../phone-number-input/phoneNumberInput';
import useTimezoneCountry from '../../hooks/useTimezoneCountry';
import { ControlledTimezoneSelect } from '../timezone-select/timezoneSelect';

const { required } = formValidationRules;

const UserProfileFormFields = () => {
  const { control } = useFormContext();

  const defaultCountry = useTimezoneCountry();

  return (
    <VStack width="100%" spacing={4} align="stretch">
      <FormFieldWrapper title="First name" description="We use your name when communicating with you">
        <ControlledInput name="firstName" control={control} placeholder="First name" helperText="Required" rules={{ required }} />
      </FormFieldWrapper>
      <FormFieldWrapper title="Last name" description="We use your last name when communicating with you">
        <ControlledInput name="lastName" control={control} placeholder="Last name" helperText="Required" rules={{ required }} />
      </FormFieldWrapper>
      <FormFieldWrapper title="Timezone">
        <ControlledTimezoneSelect name="timezone" control={control} placeholder="Timezone" helperText="Required" rules={{ required }} />
      </FormFieldWrapper>
      <FormFieldWrapper title="Phone number">
        <ControlledPhoneNumberInput
          name="phoneNumber"
          control={control}
          placeholder="Phone number"
          helperText="Optional"
          countryCode={defaultCountry}
        />
      </FormFieldWrapper>
    </VStack>
  );
};

export default UserProfileFormFields;
