import { Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Flex } from '@chakra-ui/react';
import { PlanFeature } from '@um/uptime-monitoring-shared';
import React from 'react';
import useFeatureQuotaExceeded from '../../hooks/useFeatureQuotaExceeded';

type Props = AlertProps & {
  used: number;
  feature: PlanFeature;
};

const FeatureQuotaExceededWarning: React.FC<Props> = ({ feature, used, children, ...rest }) => {
  const [exceeded] = useFeatureQuotaExceeded(feature, used);

  if (!exceeded) {
    return null;
  }

  return (
    <Alert status="warning" flexDirection="column" {...rest}>
      <Flex>
        <AlertIcon />
        <AlertTitle whiteSpace="nowrap">Quota exceeded.</AlertTitle>
      </Flex>
      <AlertDescription fontSize="lg" mt={2}>
        {children}
      </AlertDescription>
    </Alert>
  );
};

export default FeatureQuotaExceededWarning;
