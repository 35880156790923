import { useQuery } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { NotificationChannelAssignmentEntity, NotificationChannelOptionType } from '@um/uptime-monitoring-shared';

export const useOrganizationNotificationChannelOptionsQuery = (organizationId: string, types: NotificationChannelOptionType[] = [], options = {}) => {
  return useQuery(
    ['notificationChannels', 'organization', organizationId, types],
    async () => {
      return await statusPingClient.protectedGetNotificationChannelOptions(organizationId, types);
    },
    {
      enabled: Boolean(organizationId),
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useEntityNotificationChannelAssignmentsQuery = (entity: NotificationChannelAssignmentEntity, options = {}) => {
  return useQuery(
    ['notificationChannels', 'entity', entity],
    async () => {
      return await statusPingClient.protectedGetNotificationChannelAssignments(entity);
    },
    {
      enabled: Boolean(entity),
      keepPreviousData: true,
      ...options,
    }
  );
};
