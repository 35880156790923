import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApplicationEventType,
  NotificationChannelOptionVO,
  NotificationChannelReference,
  PlanFeature,
  WebsocketApplicationEventMessage,
} from '@um/uptime-monitoring-shared';
import { applicationEventEmitter } from '../../app/saga/websocket/websocket';
import { Box, Checkbox, Flex, Icon, VStack } from '@chakra-ui/react';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { useOrganizationNotificationChannelOptionsQuery } from '../../query/notificationChannelQueries';
import { IntegrationTypeOptions } from '../../models/integrationTypeOptions';
import Spinner from '../spinner/spinner';
import { notificationChannelOptionToReference } from '../../utils/notificationChannelOptionToReference';
import { CreateIntegrationFormModal } from '../create-integration-form/createIntegrationForm';
import ClickableText from '../clickable-text/clickableText';
import { CreateNotificationPolicyFormModal } from '../create-notification-policy-form/createNotificationPolicyForm';
import { AppConfig } from '../../utils/config';
import useFeatureInformation from '../../hooks/useFeatureInformation';

type Props = {
  value: NotificationChannelReference | NotificationChannelReference[];
  onChange: (value: NotificationChannelReference | NotificationChannelReference[]) => void;
};

const MultipleNotificationChannelSelector: React.FC<Props> = ({ value, onChange }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isFetched, data: notificationChannels, refetch } = useOrganizationNotificationChannelOptionsQuery(organizationId);
  const [createIntegrationModalOpen, setCreateIntegrationModalOpen] = useState(false);
  const [createPolicyModalOpen, setCreatePolicyModalOpen] = useState(false);
  const { initialized: featureInitialized, featureMissing } = useFeatureInformation(PlanFeature.ON_CALL_SCHEDULE_LIMIT);

  const onMessage = useCallback((message: WebsocketApplicationEventMessage) => {
    if (
      message.messageType !== ApplicationEventType.NOTIFICATION_INTEGRATION_CREATED &&
      message.messageType !== ApplicationEventType.NOTIFICATION_INTEGRATION_UPDATED
    ) {
      return;
    }

    refetch();
  }, []);

  useEffect(() => {
    const listener = (message) => onMessage(message);
    applicationEventEmitter.addListener('message', listener);

    return () => {
      applicationEventEmitter.removeListener('message', listener);
    };
  }, []);

  const optionMatcher = (value: NotificationChannelReference, option: NotificationChannelOptionVO) => {
    return (
      (value.notificationIntegrationId && option.notificationIntegrationId && value.notificationIntegrationId === option.notificationIntegrationId) ||
      (value.notificationPolicyId && option.notificationPolicyId && value.notificationPolicyId === option.notificationPolicyId) ||
      (value.onCallScheduleId && option.onCallScheduleId && value.onCallScheduleId === option.onCallScheduleId)
    );
  };

  const isChannelSelected = useCallback(
    (option: NotificationChannelOptionVO) => {
      return Array.isArray(value) ? value.some((item) => optionMatcher(item, option)) : optionMatcher(value, option);
    },
    [value]
  );

  const handleToggleSelection = useCallback(
    (channel: NotificationChannelOptionVO) => {
      const selected = isChannelSelected(channel);
      const option = notificationChannelOptionToReference(channel);

      if (selected) {
        onChange(Array.isArray(value) ? value.filter((item) => !optionMatcher(item, channel)) : null);
      } else {
        onChange(Array.isArray(value) ? value.concat(option) : option);
      }
    },
    [value, isChannelSelected]
  );
  const allOptionsSelected = useMemo(() => notificationChannels?.every((item) => isChannelSelected(item)), [notificationChannels, isChannelSelected]);
  const someOptionsSelected = useMemo(() => notificationChannels?.some((item) => isChannelSelected(item)), [notificationChannels, isChannelSelected]);

  const handleToggleAll = useCallback(() => {
    if (allOptionsSelected) {
      onChange([]);
    } else {
      onChange(notificationChannels.map(notificationChannelOptionToReference));
    }
  }, [allOptionsSelected]);

  const notificationIntegrations = useMemo(
    () => (notificationChannels || []).filter((item) => item.notificationIntegrationId),
    [notificationChannels]
  );
  const notificationPolicies = useMemo(() => (notificationChannels || []).filter((item) => item.notificationPolicyId), [notificationChannels]);
  const onCallSchedules = useMemo(() => (notificationChannels || []).filter((item) => item.onCallScheduleId), [notificationChannels]);

  if (!isFetched || !featureInitialized) {
    return <Spinner />;
  }

  return (
    <Box>
      <CreateIntegrationFormModal
        open={createIntegrationModalOpen}
        onClose={() => setCreateIntegrationModalOpen(false)}
        onCreated={() => {
          setCreateIntegrationModalOpen(false);
          refetch();
        }}
      />
      <CreateNotificationPolicyFormModal
        open={createPolicyModalOpen}
        onClose={() => setCreatePolicyModalOpen(false)}
        onCreated={() => {
          setCreatePolicyModalOpen(false);
          refetch();
        }}
      />
      <Flex gap={2} mb={4} width="fit-content" alignItems="center" cursor="pointer">
        <Checkbox size="lg" isChecked={allOptionsSelected} isIndeterminate={!allOptionsSelected && someOptionsSelected} onChange={handleToggleAll}>
          <Flex gap={1} alignItems="center">
            All channels
          </Flex>
        </Checkbox>
      </Flex>
      <VStack alignItems="flex-start" pl={4} gap={4}>
        <VStack alignItems="flex-start">
          <Flex gap={1} fontWeight="bold" alignItems="center">
            <Box>Notification policies</Box>
            <ClickableText onClick={() => setCreatePolicyModalOpen(true)}>Create</ClickableText>
          </Flex>
          {notificationPolicies.map((channel) => (
            <NotificationChannelRow
              key={`${channel.notificationIntegrationId}-${channel.notificationPolicyId}-${channel.onCallScheduleId}`}
              channel={channel}
              onToggle={handleToggleSelection}
              isChannelSelected={isChannelSelected}
            />
          ))}
          {notificationPolicies.length < 1 && <Box>You do not have any notification policies</Box>}
        </VStack>
        <VStack alignItems="flex-start">
          <Flex gap={1} fontWeight="bold" alignItems="center">
            <Box>Notification integrations</Box>
            <ClickableText onClick={() => setCreateIntegrationModalOpen(true)}>Create</ClickableText>
          </Flex>
          {notificationIntegrations.length < 1 && <Box>You do not have any notification integrations</Box>}
          {notificationIntegrations.map((channel) => (
            <NotificationChannelRow
              key={`${channel.notificationIntegrationId}-${channel.notificationPolicyId}-${channel.onCallScheduleId}`}
              channel={channel}
              onToggle={handleToggleSelection}
              isChannelSelected={isChannelSelected}
            />
          ))}
        </VStack>
        {AppConfig.OnCallSchedulesEnabled && !featureMissing && (
          <VStack alignItems="flex-start">
            <Flex gap={1} fontWeight="bold" alignItems="center">
              <Box>On-call schedules</Box>
            </Flex>
            {onCallSchedules.length < 1 && <Box>You do not have any on-call schedules</Box>}
            {onCallSchedules.map((channel) => (
              <NotificationChannelRow
                key={`${channel.notificationIntegrationId}-${channel.notificationPolicyId}-${channel.onCallScheduleId}`}
                channel={channel}
                onToggle={handleToggleSelection}
                isChannelSelected={isChannelSelected}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

const NotificationChannelRow = ({ channel, onToggle, isChannelSelected }) => {
  const isChecked = isChannelSelected(channel);
  const integrationTypeOption = IntegrationTypeOptions.find((item) => item.type === channel?.notificationIntegrationType);
  // const onCallScheduleChannel = Boolean(channel.onCallScheduleId);
  // const { notifySms, notifyEmail } = channel.onCallScheduleConfiguration || {};

  return (
    <Flex gap={2} alignItems="flex-start" cursor="pointer" flexDirection="column">
      <Checkbox size="lg" onChange={() => onToggle(channel)} isChecked={isChecked}>
        <Flex gap={1} alignItems="center">
          {integrationTypeOption && <Icon as={integrationTypeOption.icon} />}
          {channel.name && channel.name}
        </Flex>
      </Checkbox>
      {/*{onCallScheduleChannel && isChecked && (*/}
      {/*  <Flex gap={2} alignItems="flex-start" cursor="pointer" flexDirection="column" ml={4}>*/}
      {/*    <Checkbox size="lg" isChecked={notifyEmail}>*/}
      {/*      Email*/}
      {/*    </Checkbox>*/}
      {/*    <Checkbox size="lg" isChecked={notifySms}>*/}
      {/*      SMS*/}
      {/*    </Checkbox>*/}
      {/*  </Flex>*/}
      {/*)}*/}
    </Flex>
  );
};

export default MultipleNotificationChannelSelector;
