import getBrowserTimezone from '../utils/getBrowserTimezone';
import ct from 'countries-and-timezones';
import { Timezones } from '@um/uptime-monitoring-shared';
import { useMemo } from 'react';

const useDefaultUserTimezone = () => {
  const browserTimezone = useMemo(() => getBrowserTimezone(), []);
  const timezones = useMemo(() => Timezones.map((item) => ct.getTimezone(item.value)).filter((item) => Boolean(item)), []);

  if (!browserTimezone) {
    return null;
  }

  const browserTimezoneMetadata = ct.getTimezone(browserTimezone);

  if (!browserTimezoneMetadata) {
    return null;
  }

  return timezones.find((item) => item.utcOffset === browserTimezoneMetadata.utcOffset && item.dstOffset === browserTimezoneMetadata.dstOffset);
};

export default useDefaultUserTimezone;
