import { MonitorStatus } from '@um/uptime-monitoring-shared';

const colors: { [key: string]: string } = {
  [MonitorStatus.NONE]: 'gray.500',
  [MonitorStatus.SUCCESS]: 'green.500',
  [MonitorStatus.ERROR]: 'orange.500',
  [MonitorStatus.GRACE_PERIOD]: 'orange.500',
  [MonitorStatus.ALERTING]: 'red.500',
  [MonitorStatus.IN_PROGRESS]: 'blue.500',
  [MonitorStatus.IDLE]: 'gray.500',
};

export const monitorStatusColor = (status: MonitorStatus) => colors[status] || colors[MonitorStatus.NONE];
