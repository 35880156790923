import React from 'react';
import PageHeader from '../../components/page-header/pageHeader';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import { useAppSelector } from '../../app/store';
import { Box, Button, Flex } from '@chakra-ui/react';
import MonitorGroupsTable from '../../components/monitor-groups-table/monitorGroupsTable';
import Routes from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import MonitorGroupsPageHeader from './monitorGroupsPageHeader';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';

const MonitorGroupsPage = () => {
  const { initialized } = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  const onClickCreateMonitorGroup = () => {
    navigate(Routes.CREATE_MONITOR_GROUP);
  };
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const breadcrumbs = [];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title="Monitor groups" breadcrumbs={breadcrumbs}>
        <Box>
          <Button colorScheme="blue" onClick={onClickCreateMonitorGroup}>
            Create
          </Button>
        </Box>
      </PageHeader>
      <PageWrapper loading={!initialized}>
        <MonitorGroupsPageHeader />
        {initialized && (
          <Box px={4}>
            <MonitorGroupsTable organizationId={organizationId} />
          </Box>
        )}
      </PageWrapper>
    </Flex>
  );
};

export default MonitorGroupsPage;
