import { ErrorResponse } from '@um/uptime-monitoring-shared';

export const fillFormErrors = (error: ErrorResponse, { setError, clearErrors }) => {
  if (error && error.errors?.length > 0) {
    error.errors.forEach((item) =>
      setError(item.dataPath as any, {
        type: 'manual',
        message: item.message,
      })
    );
  } else {
    clearErrors();
  }
};
