import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import React from 'react';
import { Box } from '@chakra-ui/react';
import CreateMonitorGroupForm from '../../components/create-monitor-group-form/createMonitorGroupForm';
import { useAppSelector } from '../../app/store';

const CreateMonitorGroupPage = () => {
  const { initialized } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  return (
    <Box w="100%">
      <PageHeader
        title="Create monitor group"
        breadcrumbs={[
          {
            title: 'Monitor groups',
            route: Routes.MONITOR_GROUPS,
          },
        ]}
      />
      <PageWrapper loading={!initialized} px={4}>
        {initialized && <CreateMonitorGroupForm onCreated={() => navigate(Routes.MONITOR_GROUPS)} />}
      </PageWrapper>
    </Box>
  );
};

export default CreateMonitorGroupPage;
