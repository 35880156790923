"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendProductNewsletterMessage = void 0;
const genericTaskMessage_1 = require("./genericTaskMessage");
class SendProductNewsletterMessage extends genericTaskMessage_1.GenericTaskMessage {
    constructor(user, templateId) {
        super(genericTaskMessage_1.GenericTaskMessageType.SEND_PRODUCT_NEWSLETTER_MESSAGE);
        this.user = user;
        this.templateId = templateId;
    }
}
exports.SendProductNewsletterMessage = SendProductNewsletterMessage;
