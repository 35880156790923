import toast from 'react-hot-toast';
import { dismissibleToast } from '../utils/dismissibleToast';

const onError = (error: any, variables, context, onFormErrors?, onError?) => {
  const errorData = error?.response?.data || error;
  if (errorData && errorData.errorType !== 'VALIDATION_FAILED') {
    toast.error(errorData.message || 'Error occurred. Please try again later');
    onError && onError(errorData);
  } else if (errorData && errorData.errorType === 'VALIDATION_FAILED') {
    toast.error(dismissibleToast(errorData.message || 'Please fix errors in the form'), {
      duration: 10000,
    });

    onFormErrors && onFormErrors(errorData.errors);
  }
};

const onErrorWithFormHandling = (onFormErrors?, onErrorCb?) => (error: any, variables, context) => {
  return onError(error, variables, context, onFormErrors, onErrorCb);
};

const mutationDefaults = { onError, onErrorWithFormHandling };

export default mutationDefaults;
