import Card from '../card/card';
import { Box, Flex, FlexProps, Icon, Radio, VStack } from '@chakra-ui/react';
import { BsDash, BsFillCheckCircleFill } from 'react-icons/bs';
import React from 'react';
import { PlanFeatureDescription } from '@um/uptime-monitoring-shared';

type Props = FlexProps & {
  name: React.ReactNode;
  features: PlanFeatureDescription[];
  footer?: React.ReactNode;
  onSelected?: () => void;
  active?: boolean;
};

const PlanSelectionCard: React.FC<Props> = ({ name, features, footer = null, onSelected = null, active = false, ...rest }) => {
  return (
    <Card onClick={onSelected} cursor="pointer" boxShadow={active ? '0 0 0 5px rgb(49 130 206)' : 'xs'} {...rest}>
      <Flex flexDirection="column" alignItems="flex-start" w="100%" h="100%" justifyContent="space-between">
        <VStack alignItems="flex-start" w="100%">
          <Flex width="100%" alignItems="center" gap={2} mb={2} justifyContent="center" textAlign="center">
            {onSelected && (
              <Box>
                <Radio size="lg" isChecked={active} />
              </Box>
            )}
            {name}
          </Flex>
          {features.map((feature) => (
            <Flex key={feature.description} gap={2} alignItems="center">
              <Icon as={feature.available ? BsFillCheckCircleFill : BsDash} fill={feature.available ? 'darkGreen.400' : 'gray.600'} h={6} w={6} />
              <Box fontSize="lg">{feature.description}</Box>
            </Flex>
          ))}
        </VStack>
        {footer}
      </Flex>
    </Card>
  );
};

export default PlanSelectionCard;
