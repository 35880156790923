import PageHeader from '../../components/page-header/pageHeader';
import { useAppSelector } from '../../app/store';
import React from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import NotificationIntegrationsList from '../../components/notification-integrations-list/notificationIntegrationsList';
import { Box, Button } from '@chakra-ui/react';
import Routes from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import EmptyPageTopMenu from '../../components/empty-page-header/emptyPageHeader';

const IntegrationsPage = () => {
  const navigate = useNavigate();
  const {
    app: { initialized },
  } = useAppSelector((state) => state);

  const breadcrumbs = [{ title: 'Settings', route: Routes.SETTINGS }];

  return (
    <Box w="100%">
      <PageHeader title="Notification integrations" loading={!initialized} breadcrumbs={breadcrumbs}>
        <div>
          <Button colorScheme="blue" onClick={() => navigate(Routes.CREATE_NOTIFICATION_INTEGRATION)}>
            Create
          </Button>
        </div>
      </PageHeader>
      <EmptyPageTopMenu />
      <PageWrapper loading={!initialized} px={4}>
        <NotificationIntegrationsList />
      </PageWrapper>
    </Box>
  );
};

export default IntegrationsPage;
