import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Box, Breadcrumb, BreadcrumbItem, Flex, Skeleton } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import AppLink from '../app-link/appLink';

type HeaderProps = {
  title: string;
  breadcrumbs?: any[];
  children?: React.ReactNode;
  loading?: boolean;
};

const PageHeader = ({ title, breadcrumbs, children, loading }: HeaderProps) => {
  const { initializing } = useSelector((state: RootState) => state.app);
  const showLoading = loading || initializing;

  return (
    <div>
      <Flex justifyContent="space-between" alignItems="center" p={0} m={4} minH={10}>
        {showLoading && <Skeleton height="8" w={60} />}
        {!showLoading && (
          <>
            <Breadcrumb
              fontWeight="bold"
              fontSize="xl"
              overflowX="auto"
              overflowY="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              separator={<ChevronRightIcon color="gray.500" />}
              mr={children ? 2 : 0}
              __css={{ '&::-webkit-scrollbar': { display: 'none' }, scrollbarWidth: 'none', msOverflowStyle: 'none' }}
            >
              {breadcrumbs?.map((breadcrumb, idx) => {
                const href = breadcrumb.route;

                return (
                  <BreadcrumbItem key={idx} fontSize="2xl" textDecoration={href ? 'underline' : 'none'}>
                    {href && (
                      <AppLink fontSize="2xl" to={href} underline>
                        {breadcrumb.title}
                      </AppLink>
                    )}
                    {!href && <Box>{breadcrumb.title}</Box>}
                  </BreadcrumbItem>
                );
              })}
              <BreadcrumbItem>
                <Box fontSize="2xl">{title}</Box>
              </BreadcrumbItem>
            </Breadcrumb>
            {children}
          </>
        )}
      </Flex>
    </div>
  );
};

export default PageHeader;
