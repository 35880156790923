import { useQuery } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { ProtectedGetIncidentsRequest } from '@um/uptime-monitoring-shared';

export const useSelectedOrganizationNotificationHistoryQuery = (request: ProtectedGetIncidentsRequest, options = {}) => {
  return useQuery(
    ['notificationsHistory', 'organization', request],
    async () => {
      return await statusPingClient.protectedGetNotificationsHistory(request);
    },
    {
      enabled: Boolean(request?.organizationId),
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useNotificationHistoryQuery = (id: string, options = {}) => {
  return useQuery(
    ['notificationsHistory', id],
    async () => {
      return await statusPingClient.protectedGetNotificationHistory(id);
    },
    {
      enabled: Boolean(id),
      keepPreviousData: true,
      ...options,
    }
  );
};
