import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '../../utils/withPayloadType';
import { monitorCheckDetails } from '../reducer/monitorCheckDetails';

const initialize = createAction('monitorCheckDetails/initialize', withPayloadType<string>());

const monitorExecutionDetailsActions = {
  ...monitorCheckDetails.actions,
  initialize,
};

export default monitorExecutionDetailsActions;
