import { PartialDeep } from 'type-fest';
import {
  CheckInMonitorOptions,
  CheckInPeriodType,
  HttpMonitorAssertionType,
  HttpMonitorOptions,
  IcmpPingMonitorOptions,
  MonitorTransport,
  NotificationChannelReference,
} from '@um/uptime-monitoring-shared';

export type MonitorFormModel = {
  id?: string;
  name: string;
  description?: string;
  enabled: boolean;
  schedule: number;
  gracePeriod: number;
  transport: MonitorTransport;
  httpMonitorOptions?: HttpMonitorOptions;
  icmpPingMonitorOptions?: IcmpPingMonitorOptions;
  checkInMonitorOptions?: CheckInMonitorOptions;
  notificationChannels: NotificationChannelReference[];
  organizationId?: string;
};

export const emptyCheckInMonitorOptions = (): CheckInMonitorOptions => ({
  timezone: 'UTC',
  periodType: CheckInPeriodType.SIMPLE,
  periodMinutes: 1,
  periodDays: 0,
  periodHours: 0,
  gracePeriod: 1,
  periodCronExpression: '* * * * *',
  slug: null,
});

export const MonitorFormDefaultValues: PartialDeep<MonitorFormModel> = {
  transport: MonitorTransport.HTTP,
  schedule: 5,
  notificationChannels: [],
  enabled: true,
  gracePeriod: 0,
  httpMonitorOptions: {
    method: 'GET',
    protocol: 'https://',
    assertions: [
      {
        type: HttpMonitorAssertionType.UNAVAILABLE,
        operation: '' as any,
        property: '',
        value: '',
      },
    ],
    timeout: 10000,
    followRedirects: true,
    ignoreCertificateIssues: true,
  },
};
