import React, { useEffect, useState } from 'react';
import { operationWrapper } from '../../utils/operationWrapper';
import statusPingClient from '../../app/client/statusPingClient';
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import Input from '../input/input';
import Toggle from '../toggle/toggle';

const RunMonitorModal = ({ open, monitorId, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [times, setTimes] = useState('1');
  const [ignoreInProgress, setIgnoreInProgress] = useState(true);

  const handleConfirm = operationWrapper(
    async () => {
      setLoading(true);
      await statusPingClient.privatePostRunMonitorCheck(monitorId, times, ignoreInProgress);
    },
    {
      successText: 'Monitor check scheduled',
      errorText: 'Error occurred. Please try again later',
      successCallback: onClose,
      errorCallback: onClose,
    }
  );

  useEffect(() => {
    setLoading(false);
    setTimes('1');
  }, [open]);

  return (
    <Modal isOpen={open} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Administration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection="column" gap={4}>
            <Input type="number" label="Times" value={times} onChange={(evt) => setTimes(evt.target.value)} />
            <Flex gap={2} alignItems="center">
              <Toggle value={ignoreInProgress} onChange={() => setIgnoreInProgress(!ignoreInProgress)} />
              <Box>Ignore in progress monitors</Box>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} isDisabled={loading} mr={3}>
            Cancel
          </Button>
          <Button colorScheme="blue" isLoading={loading} isDisabled={loading} onClick={handleConfirm}>
            Run check
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RunMonitorModal;
