"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class MonitorUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(updatedMonitor, originalMonitor) {
        super(model_1.QueueDefinitions.MONITOR_UPDATED);
        this.updatedMonitor = updatedMonitor;
        this.originalMonitor = originalMonitor;
    }
}
exports.MonitorUpdatedMessage = MonitorUpdatedMessage;
