"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EvaluateMonitorGroupStatusMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class EvaluateMonitorGroupStatusMessage extends workerMessage_1.WorkerMessage {
    constructor(monitorGroupId) {
        super(model_1.QueueDefinitions.EVALUATE_MONITOR_GROUP_STATUS);
        this.monitorGroupId = monitorGroupId;
    }
    getRoutingKey() {
        return this.monitorGroupId;
    }
}
exports.EvaluateMonitorGroupStatusMessage = EvaluateMonitorGroupStatusMessage;
