import Card from '../card/card';
import { SectionTitle } from '../common-components/informationLayoutComponents';
import { Box, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { MonitorTransport, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import getCertificateCheckStatus, { CertificateCheckStatus } from '../../utils/getCertificateCheckStatus';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';

type Props = { monitor: ProtectedMonitorDetailsResponse };

const colors = {
  text: {
    [CertificateCheckStatus.NOT_CHECKED]: 'gray.400',
    [CertificateCheckStatus.VALID]: 'darkGreen.200',
    [CertificateCheckStatus.EXPIRES_SOON]: 'orange.300',
    [CertificateCheckStatus.EXPIRED]: 'red.300',
  },
};

const CertificateStatusMonitorCard: React.FC<Props> = ({ monitor }) => {
  if (!monitor || !monitor.monitorCertificate || monitor.transport !== MonitorTransport.HTTP) {
    return null;
  }

  const { lastChecked, certificateExpiryDate } = monitor.certificateMonitorMetadata || {};
  const certificateCheckStatus = useMemo(() => getCertificateCheckStatus(certificateExpiryDate), [certificateExpiryDate]);
  const textColor = useMemo(() => colors.text[certificateCheckStatus], [certificateCheckStatus]);
  const lastCheckedFormatted = lastChecked ? formatToUtc(new Date(lastChecked), DateFormats.FULL_MINUTES) : null;
  const certificateExpiryDateFormatted = certificateExpiryDate ? formatToUtc(new Date(certificateExpiryDate), DateFormats.FULL_DAY) : null;

  return (
    <Card gap={2} alignItems="center" m={0}>
      <SectionTitle>SSL Certificate</SectionTitle>
      <Flex flexDirection="column" alignItems="center" justifyContent="cente" fontWeight="bold" fontSize="md">
        <Box color={textColor}>
          {certificateCheckStatus === CertificateCheckStatus.VALID && 'Valid'}
          {certificateCheckStatus === CertificateCheckStatus.EXPIRES_SOON && 'Expires soon'}
          {certificateCheckStatus === CertificateCheckStatus.EXPIRED && 'Expired'}
          {certificateCheckStatus === CertificateCheckStatus.NOT_CHECKED && 'SSL Certificate has not been checked yet'}
        </Box>
        {certificateCheckStatus !== CertificateCheckStatus.NOT_CHECKED && <Box>Expires {certificateExpiryDateFormatted}</Box>}
        {certificateCheckStatus !== CertificateCheckStatus.NOT_CHECKED && <Box>Checked {lastCheckedFormatted}</Box>}
      </Flex>
    </Card>
  );
};

export default CertificateStatusMonitorCard;
