import { Box, Button, Card, CardBody, CloseButton, Flex, Icon } from '@chakra-ui/react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import Cookies from 'js-cookie';
import WebsiteLink from '../website-link/websiteLink';
import { AppConfig } from '../../utils/config';
import { addMonths } from 'date-fns';

export type CookieConsentContextState = {
  allCookiesConsentGiven?: boolean;
  cookieConsentSubmitted?: boolean;
  setCookieConsentType?: (type: string) => void;
};

const initialState: CookieConsentContextState = {
  allCookiesConsentGiven: false,
  cookieConsentSubmitted: false,
  setCookieConsentType: () => {},
};

export const isAllCookiesConsentGiven = () => typeof window !== 'undefined' && Cookies.get('sp-cookie-consent') === 'all';

export const CookieConsentContext = createContext<CookieConsentContextState>(initialState);

export const CookieConsentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [allCookiesConsentGiven, setAllCookiesConsentGiven] = useState(false);
  const [cookieConsentSubmitted, setCookieConsentSubmitted] = useState(true);

  const setCookieConsentType = (type: string) => {
    Cookies.set('sp-cookie-consent', type, { expires: addMonths(new Date(), 3), domain: AppConfig.CookieDomain });

    setAllCookiesConsentGiven(type === 'all');
    setCookieConsentSubmitted(true);
  };

  useEffect(() => {
    const consent = Cookies.get('sp-cookie-consent');

    if (!consent) {
      setCookieConsentSubmitted(false);
    } else {
      setAllCookiesConsentGiven(consent === 'all');
    }
  }, []);

  return (
    <CookieConsentContext.Provider value={{ setCookieConsentType, allCookiesConsentGiven, cookieConsentSubmitted }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

const CookieConsentBanner = () => {
  const { setCookieConsentType, cookieConsentSubmitted } = useContext(CookieConsentContext);
  const [showBanner, setShowBanner] = useState(false);

  const handleConsentClick = (type: string) => {
    setCookieConsentType!(type);
  };

  useEffect(() => {
    setShowBanner(!cookieConsentSubmitted!);
  }, [cookieConsentSubmitted]);

  if (!showBanner) {
    return null;
  }

  return (
    <Card position="fixed" bottom="15px" left="15px" variant="outline" maxWidth="360px" background="white" zIndex={9999999}>
      <CardBody fontSize="sm" position="relative">
        <Flex flexDirection="column" gap={4}>
          <Flex>
            <Flex gap={2} flexDirection="column">
              <Box>
                We use cookies to enhance your browsing experience, understand product usage, and analyze our traffic. By clicking "Accept All", you
                consent to our use of cookies.{' '}
                <WebsiteLink href="https://statusping.co/cookies" target="_blank" wrapperProps={{ fontSize: 'xs' }}>
                  <span>
                    Cookie Policy
                    <Icon as={BiLinkExternal} />
                  </span>
                </WebsiteLink>
              </Box>
            </Flex>
            <CloseButton size="sm" onClick={() => setShowBanner(false)} />
          </Flex>
          <Flex gap={2} width="100%" justifyContent="flex-end" flexDirection="column">
            <Button size="sm" onClick={() => handleConsentClick('strictly-necessary')} width="100%">
              Only strictly necessary cookies
            </Button>
            <Button colorScheme="blue" size="sm" onClick={() => handleConsentClick('all')} width="100%">
              Accept All
            </Button>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default CookieConsentBanner;
