import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';

type SliceType = {
  inProgress: boolean;
  created: boolean;
  error: any;
  monitor: ProtectedMonitorDetailsResponse;
};

const initialState: SliceType = {
  inProgress: false,
  created: false,
  error: null,
  monitor: null,
};

export const createMonitor = createSlice({
  name: 'createMonitor',
  initialState,
  reducers: {
    reset: () => initialState,
    setInProgress: (state, action: PayloadAction<boolean>) => {
      state.inProgress = action.payload;
      return state;
    },
    setCreated: (state, action: PayloadAction<boolean>) => {
      state.created = action.payload;
      return state;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      return state;
    },
    setMonitor: (state, action: PayloadAction<ProtectedMonitorDetailsResponse>) => {
      state.monitor = action.payload;
      return state;
    },
  },
});

export default createMonitor.reducer;
