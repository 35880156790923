import { createAction } from '@reduxjs/toolkit';
import { app } from '../reducer/app';
import { withPayloadType } from '../../utils/withPayloadType';

const initialize = createAction('app/initialize');
const loggedIn = createAction('app/loggedIn');
const selectOrganization = createAction('app/selectOrganization', withPayloadType<string>());

const appActions = { ...app.actions, initialize, loggedIn, selectOrganization };

export default appActions;
