import { StatusPageIncidentModelVO } from '@um/uptime-monitoring-shared';
import { Box, Flex, Icon } from '@chakra-ui/react';
import DataCard from '../data-card/dataCard';
import React, { useMemo } from 'react';
import StatusPageIncidentStatusBadge from '../status-page-incident-status-badge/statusPageIncidentStatusBadge';
import { BsClock } from 'react-icons/bs';
import { formatInTimeZone } from 'date-fns-tz';
import { DateFormats } from '../../utils/formatToUtc';
import CreateStatusPageIncidentUpdateForm from '../create-status-page-incident-update-form/createStatusPageIncidentUpdateForm';
import StatusPageIncidentInformation from '../stauts-page-incident-information/statusPageIncidentInformation';

type Props = {
  statusPageIncident: StatusPageIncidentModelVO;
};

const StatusPageIncidentHistory: React.FC<Props> = ({ statusPageIncident }) => {
  const { timezone, resources, statusPageId, id: statusPageIncidentId } = statusPageIncident;
  const resourceNames = useMemo(() => {
    return resources.reduce(
      (agg, resource) => ({
        ...agg,
        [resource.statusPageResourceId]: resource.resourceName,
      }),
      {}
    );
  }, [resources]);

  return (
    <Box>
      <Flex flexDirection="column" gap={4}>
        <Box fontSize="xl" fontWeight="bold">
          Incident information
        </Box>
        <StatusPageIncidentInformation statusPageIncidentId={statusPageIncidentId} />
        <Box fontSize="xl" fontWeight="bold">
          Publish incident update
        </Box>
        <CreateStatusPageIncidentUpdateForm statusPageId={statusPageId} statusPageIncidentId={statusPageIncidentId} />
        <Box fontSize="xl" fontWeight="bold" mt={4}>
          Past incident updates
        </Box>
        <Flex flexDirection="column">
          {statusPageIncident.updates.map((update) => {
            const { incidentStatus, message, resourceStatuses } = update;
            const messageText = message?.text;
            const resourceChanges = resourceStatuses?.resources;
            const resourceStatusesChanged = resourceChanges?.length > 0;

            return (
              <Flex flexDirection="column">
                <Flex gap={4} alignItems="center">
                  <Icon as={BsClock} fill="gray.400" boxSize={8} />
                  <Box fontWeight="bold">Published at {formatInTimeZone(update.createdAt, timezone, DateFormats.FULL_SECONDS_WITH_TZ)}</Box>
                </Flex>
                <Flex gap={4}>
                  <Flex w={8} justifyContent="center">
                    <Box w="1px" height="100%" backgroundColor="gray.400" />
                  </Flex>
                  <Box py={4} width="100%">
                    <DataCard>
                      {incidentStatus && (
                        <DataCard.Row heading="Incident status">
                          <StatusPageIncidentStatusBadge status={incidentStatus} />
                        </DataCard.Row>
                      )}
                      {resourceStatusesChanged && (
                        <DataCard.Row heading="Resource statuses">
                          <Flex flexDirection="column" gap={1}>
                            {resourceChanges.map((resource) => {
                              return (
                                <Box>
                                  {resourceNames[resource.statusPageResourceId] || '-'} <StatusPageIncidentStatusBadge status={resource.status} />
                                </Box>
                              );
                            })}
                          </Flex>
                        </DataCard.Row>
                      )}
                      {messageText && <DataCard.Row heading="Message">{messageText}</DataCard.Row>}
                    </DataCard>
                  </Box>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
};
export default StatusPageIncidentHistory;
