import { toast } from 'react-hot-toast';

export const operationWrapper =
  (
    fn: () => any,
    options: {
      successText?: string;
      errorText?: string;
      successCallback?: () => any;
      errorCallback?: (error?) => any;
      finallyCallback?: () => any;
    }
  ) =>
  async () => {
    try {
      await fn();

      if (options?.successText) {
        toast.success(options.successText);
      }

      if (options.successCallback) {
        options.successCallback();
      }
    } catch (e) {
      console.log(e);

      if (options?.errorText) {
        toast.error(options.errorText);
      }

      if (options.errorCallback) {
        options.errorCallback(e);
      }
    } finally {
      if (options.finallyCallback) {
        options.finallyCallback();
      }
    }
  };
