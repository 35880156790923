import useRunningDuration from '../../hooks/useRunningDuration';
import { DurationFormat } from '../../utils/durationFormat';
import useFormattedDuration from '../../hooks/useFormattedDuration';
import DataCard from '../data-card/dataCard';
import { Box, Flex, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';
import AppLink from '../app-link/appLink';
import { generatePath } from 'react-router-dom';
import Routes from '../../utils/routes';
import React from 'react';
import { IncidentStatus, IncidentWithMetadata } from '@um/uptime-monitoring-shared';
import { useIncidentQuery } from '../../query/incidentQueries';
import Spinner from '../spinner/spinner';
import IncidentAcknowledgmentInformation from '../incident-acknowledgment-information/incidentAcknowledgmentInformation';
import { AppConfig } from '../../utils/config';
import { BsLink45Deg } from 'react-icons/bs';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';

type IncidentDetailsProps = {
  incident?: IncidentWithMetadata;
  incidentId?: string;
  onClose?: () => void;
};

const colors: { [key: string]: string } = {
  [IncidentStatus.ALERTING]: 'red',
  [IncidentStatus.RESOLVED]: 'green',
};

const statusTexts: { [key: string]: string } = {
  [IncidentStatus.ALERTING]: 'Ongoing',
  [IncidentStatus.RESOLVED]: 'Resolved',
};

const IncidentDetails: React.FC<IncidentDetailsProps> = ({ incident, incidentId, onClose }) => {
  const { isFetched, data: fetchedIncident } = useIncidentQuery(incidentId, { enabled: Boolean(!incident && incidentId) });
  const finalIncident = incident || fetchedIncident;
  const [, formattedDuration] = useRunningDuration(finalIncident?.startedAt, DurationFormat.DHMS);
  const incidentDuration = useFormattedDuration(finalIncident?.startedAt, finalIncident?.resolvedAt, DurationFormat.DHMS);
  const initialized = finalIncident || isFetched;
  const handleCopyPermalink = () => {
    const url = window.location.origin + generatePath(Routes.INCIDENT_DETAILS, { id: incidentId });
    copy(url);
    toast.success('Copied to clipboard');
  };

  if (!initialized) {
    return <Spinner hideLoading my={4} />;
  }

  return (
    <DataCard
      header={
        <DataCard.Header onClose={onClose}>
          <Flex gap={2} alignItems="center">
            <Box>Incident details</Box>
            <Tooltip label="Copy permalink">
              <IconButton
                variant="ghost"
                colorScheme="gray"
                aria-label="Move down"
                icon={<Icon as={BsLink45Deg} boxSize={6} />}
                onClick={handleCopyPermalink}
              />
            </Tooltip>
          </Flex>
        </DataCard.Header>
      }
    >
      <DataCard.Row heading="Status">
        <Box color={`${colors[finalIncident.status]}.500`} textTransform="uppercase">
          {statusTexts[finalIncident.status]}
        </Box>
      </DataCard.Row>
      {AppConfig.AcknowledgmentEnabled && (
        <DataCard.Row heading="Acknowledgment">
          <IncidentAcknowledgmentInformation incident={finalIncident} />
        </DataCard.Row>
      )}
      <DataCard.Row heading="Duration">{finalIncident.resolvedAt ? incidentDuration : formattedDuration}</DataCard.Row>
      <DataCard.Row heading="Started at">{formatToUtc(finalIncident.startedAt, DateFormats.FULL_SECONDS)}</DataCard.Row>
      <DataCard.Row heading="Resolved at">
        {finalIncident.resolvedAt ? formatToUtc(finalIncident.resolvedAt, DateFormats.FULL_SECONDS) : '-'}
      </DataCard.Row>
      {finalIncident.monitorId && (
        <DataCard.Row heading="Monitor">
          <AppLink
            underline
            to={generatePath(Routes.MONITOR_DETAILS_OVERVIEW, {
              id: finalIncident.monitorId,
            })}
          >
            {finalIncident.monitorName}
          </AppLink>
        </DataCard.Row>
      )}
      {finalIncident.monitorGroupId && (
        <DataCard.Row heading="Monitor group">
          <AppLink
            underline
            to={generatePath(Routes.MONITOR_GROUP_OVERVIEW, {
              id: finalIncident.monitorGroupId,
            })}
          >
            {finalIncident.monitorGroupName}
          </AppLink>
        </DataCard.Row>
      )}
      {finalIncident.startedMonitorCheckId && (
        <DataCard.Row heading="Started by">
          <AppLink underline to={generatePath(Routes.MONITOR_CHECK_DETAILS, { id: finalIncident.startedMonitorCheckId })}>
            Monitor check
          </AppLink>
        </DataCard.Row>
      )}
      {finalIncident.resolvedMonitorCheckId && (
        <DataCard.Row heading="Resolved by">
          <AppLink underline to={generatePath(Routes.MONITOR_CHECK_DETAILS, { id: finalIncident.resolvedMonitorCheckId })}>
            Monitor check
          </AppLink>
        </DataCard.Row>
      )}
    </DataCard>
  );
};

export default IncidentDetails;
