import { MutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import {
  CreateStatusPageIncidentUpdateVO,
  CreateStatusPageIncidentVO,
  ProtectedPatchStatusPageIncidentRequest,
  StatusPageIncidentModelVO,
  StatusPageIncidentUpdate,
} from '@um/uptime-monitoring-shared';
import { toQueryResultWithHash } from '../models/queryResultWithHash';
import queryDefaults from './queryDefaults';
import { MutationAction } from './mutationAction';
import queryKeys from './queryKeys';

export interface StatusPageIncidentUpdateMutationParameters {
  statusPageIncidentId: string;
  createRequest: CreateStatusPageIncidentUpdateVO;
  action: MutationAction;
}

export interface StatusPageIncidentMutationParameters {
  statusPageIncidentId?: string;
  createRequest?: CreateStatusPageIncidentVO;
  updateRequest?: ProtectedPatchStatusPageIncidentRequest;
  action: MutationAction;
}

export const useStatusPageIncidentsQuery = (statusPageId: string, options = {}) => {
  return useQuery(
    queryKeys.statusPageIncidents(statusPageId),
    () => toQueryResultWithHash(statusPingClient.protectedGetStatusPageIncidents(statusPageId)),
    {
      enabled: Boolean(statusPageId),
      staleTime: 2000,
      ...options,
    }
  );
};

export const useStatusPageIncidentQuery = (statusPageIncidentId: string, options = {}) => {
  return useQuery(
    queryKeys.statusPageIncident(statusPageIncidentId),
    () => toQueryResultWithHash(statusPingClient.protectedGetStatusPageIncident(statusPageIncidentId)),
    {
      ...queryDefaults,
      enabled: Boolean(statusPageIncidentId),
      staleTime: 2000,
      ...options,
    }
  );
};

export const useStatusPageIncidentMutation = (options: MutationOptions<any, any, StatusPageIncidentMutationParameters> = {}) => {
  return useMutation<StatusPageIncidentModelVO | void, any, StatusPageIncidentMutationParameters>(
    async ({ statusPageIncidentId, action, createRequest, updateRequest }) => {
      if (action === MutationAction.DELETE) {
        return await statusPingClient.protectedDeleteStatusPageIncident(statusPageIncidentId);
      } else if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostStatusPageIncident(createRequest);
      } else if (action === MutationAction.PATCH) {
        return await statusPingClient.protectedPatchStatusPageIncident(statusPageIncidentId, updateRequest);
      }

      return null;
    },
    options
  );
};

export const useStatusPageIncidentUpdateMutation = (options: MutationOptions<any, any, StatusPageIncidentUpdateMutationParameters> = {}) => {
  return useMutation<StatusPageIncidentUpdate, any, StatusPageIncidentUpdateMutationParameters>(
    async ({ statusPageIncidentId, createRequest, action }) => {
      if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostStatusPageIncidentUpdate(statusPageIncidentId, createRequest);
      }

      return null;
    },
    options
  );
};
