const CalendarEventColors = [
  '#388a75',
  '#0414ba',
  '#c22ca2',
  '#d5c549',
  '#730123',
  '#e44061',
  '#ea88b4',
  '#62df6f',
  '#903e5c',
  '#9fad76',
  '#0a414b',
  '#76c5d1',
  '#52ff9c',
  '#8ccacc',
  '#be5a73',
  '#71d1f7',
  '#e56bdc',
  '#1b4f84',
  '#d3243b',
  '#bfeae8',
  '#63be8f',
  '#b42338',
  '#267962',
  '#eda299',
  '#68331f',
  '#466639',
  '#47d4da',
  '#d929f4',
  '#4322c5',
  '#750083',
  '#784fee',
  '#ef0452',
  '#72290d',
  '#58bfe7',
];

export default CalendarEventColors;
