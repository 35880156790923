"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorCheckStatusValues = exports.MonitorExecutionStatusValues = exports.MonitorCheckStatus = void 0;
var MonitorCheckStatus;
(function (MonitorCheckStatus) {
    MonitorCheckStatus["IN_PROGRESS"] = "IN_PROGRESS";
    MonitorCheckStatus["SUCCESS"] = "SUCCESS";
    MonitorCheckStatus["FAILURE"] = "FAILURE";
    MonitorCheckStatus["ERROR"] = "ERROR";
    MonitorCheckStatus["SERVER_TIMEOUT"] = "SERVER_TIMEOUT";
})(MonitorCheckStatus = exports.MonitorCheckStatus || (exports.MonitorCheckStatus = {}));
exports.MonitorExecutionStatusValues = Object.values(MonitorCheckStatus);
exports.MonitorCheckStatusValues = Object.values(MonitorCheckStatus);
