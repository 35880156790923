import React, { useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { createFilter } from 'react-select';
import { CheckInPeriodType, Timezones } from '@um/uptime-monitoring-shared';
import { FormSelector, FormSelectorProps } from '../form-select/formSelector';
import { prepareRules } from '../../utils/formValidationRules';
import { Box } from '@chakra-ui/react';

export type ControlledSelectProps = {
  control: Control<any>;
  name?: string;
  regularLabel?: boolean;
  rules?: any;
  error?: any;
} & Omit<CheckInPeriodTypeSelectorProps, 'value'>;

export type CheckInPeriodTypeSelectorProps = {
  error?: any;
  value: string;
  onChange?: (value: string) => void;
} & Omit<FormSelectorProps, 'value' | 'onChange'>;

const options = [
  {
    label: 'Simple period expression',
    value: CheckInPeriodType.SIMPLE,
    description:
      'The starting point of monitoring will be the first check-in we receive for this monitor. After the first one is received we will be expecting subsequent check-ins at the specified intervals.',
  },
  {
    label: 'CRON period expression',
    value: CheckInPeriodType.CRON,
    description: 'We will be expecting check-ins at the times generated by the specified CRON expression.',
  },
];

export const CheckInPeriodTypeSelector: React.FC<CheckInPeriodTypeSelectorProps> = ({ onChange, value, ...rest }) => {
  const selectedOption = useMemo(() => options.find((option) => option.value === value), [value]);
  const isOptionSelected = useCallback((option) => option.value === value, [selectedOption]);
  const handleChange = (option: { value: string }) => onChange(option.value);

  return (
    <Box>
      <FormSelector
        isSearchable
        options={options}
        value={selectedOption}
        isOptionSelected={isOptionSelected}
        filterOption={createFilter({
          trim: true,
          ignoreCase: true,
          ignoreAccents: true,
          matchFrom: 'any',
        })}
        onChange={handleChange}
        {...rest}
      />
      <Box mt={2} fontSize="md" color="gray.500">
        {selectedOption ? selectedOption.description : null}
      </Box>
    </Box>
  );
};

CheckInPeriodTypeSelector.displayName = 'CheckInPeriodTypeSelector';

export const ControlledCheckInPeriodTypeSelector: React.FC<ControlledSelectProps> = ({ control, name, rules, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return <CheckInPeriodTypeSelector error={error?.message} value={field.value} onChange={field.onChange} {...rest} />;
      }}
    />
  );
};

ControlledCheckInPeriodTypeSelector.displayName = 'ControlledCheckInPeriodTypeSelector';
