import React from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  NumberInput as ChakraNumberInput,
  InputGroup,
  InputLeftAddon,
  NumberInputFieldProps as ChakraInputProps,
  InputRightAddon,
  VStack,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
} from '@chakra-ui/react';
import { prepareRules } from '../../utils/formValidationRules';
import FormFieldLabel from '../form-field-label/formFieldLabel';

export interface AppNumberInputProps extends Omit<NumberInputProps, 'error' | 'size'> {
  label?: string;
  error?: string;
  inputLabel?: React.ReactNode;
  inputLeftAddon?: React.ReactNode;
  inputRightAddon?: React.ReactNode;
  regularLabel?: boolean;
  rules?: any;
  helperText?: string;
  inputWrapperProps?: NumberInputProps;
}

export type ControlledInputProps = {
  control: Control<any>;
  required?: boolean;
  regularLabel?: boolean;
  inputLabel?: React.ReactNode;
  inputLeftAddon?: React.ReactNode;
  inputRightAddon?: React.ReactNode;
} & AppNumberInputProps;

const NumberInput: React.FC<AppNumberInputProps> = ({
  placeholder,
  className,
  label,
  error,
  children,
  regularLabel,
  inputLabel,
  inputLeftAddon,
  inputRightAddon,
  helperText,
  value,
  inputWrapperProps = {},
  ...rest
}) => {
  const hasError = !!error;

  return (
    <VStack>
      <FormControl isInvalid={hasError}>
        <InputGroup size="lg">
          {label && <FormFieldLabel regular={regularLabel}>{label}</FormFieldLabel>}
          {inputLeftAddon && <InputLeftAddon p={0}>{inputLeftAddon}</InputLeftAddon>}
          <ChakraNumberInput {...inputWrapperProps} value={value as number} label={inputLabel} placeholder={placeholder} {...rest}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </ChakraNumberInput>
          {inputRightAddon && <InputRightAddon p={0}>{inputRightAddon}</InputRightAddon>}
        </InputGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
      {children}
    </VStack>
  );
};

NumberInput.displayName = 'NumberInput';

export const ControlledNumberInput = ({ control, name, rules, inputLabel, ...rest }: ControlledInputProps) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return <NumberInput error={error?.message} inputLabel={inputLabel} {...rest} {...field} />;
      }}
    />
  );
};

ControlledNumberInput.displayName = 'ControlledNumberInput';

export default NumberInput;
