"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorExecutedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class MonitorExecutedMessage extends workerMessage_1.WorkerMessage {
    constructor(monitorExecution) {
        super(model_1.QueueDefinitions.MONITOR_EXECUTED);
        this.monitorExecution = monitorExecution;
    }
}
exports.MonitorExecutedMessage = MonitorExecutedMessage;
