import React from 'react';
import EditMonitorForm from '../../components/edit-monitor-form/editMonitorForm';

export type Props = {
  monitorId: string;
};

const EditMonitorTab = ({ monitorId }: Props) => {
  return <EditMonitorForm monitorId={monitorId} />;
};

export default EditMonitorTab;
