import React, { useState } from 'react';
import Routes from '../../utils/routes';
import AppLink from '../app-link/appLink';
import Card from '../card/card';
import { Alert, AlertIcon, Box, Button, Flex, Icon, Input } from '@chakra-ui/react';
import { AiOutlineGoogle } from 'react-icons/ai';

type Props = {
  onLogin: (username: string, password: string) => void;
  onLoginUsingGoogle: () => void;
  disabled: boolean;
  loading: boolean;
  error?: string;
  success?: string;
};

export const LoginForm: React.FC<Props> = ({ onLogin, onLoginUsingGoogle, disabled, loading, error, success }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();

    onLogin(email, password);
  };

  return (
    <Flex minH="100vh" alignItems="center" justifyContent="center" py={12} px={4} backgroundColor="gray.200">
      <Flex maxW="container.xs" width="100%" flexDirection="column" gap={6}>
        <Box as="img" mx={'auto'} w={'auto'} maxHeight="64px" src="/logo.png" alt="Status Ping" />
        <Card>
          <Box mb={2}>
            <Box as="h2" fontSize="3xl" textAlign="center" fontWeight="bold">
              Sign in to your account
            </Box>
          </Box>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          {success && (
            <Alert status="success">
              <AlertIcon />
              {success}
            </Alert>
          )}
          <Button w="100%" size="lg" colorScheme={'red'} onClick={onLoginUsingGoogle} isDisabled={disabled} isLoading={loading}>
            <Icon as={AiOutlineGoogle} boxSize={6} mr={2} />
            Sign in using Google
          </Button>
          <Box textAlign="center" fontSize="lg" my={4}>
            or using email and password
          </Box>
          <Flex as="form" flexDirection="column" gap={6} onSubmit={handleSubmit}>
            <Flex flexDirection="column" gap={2} borderRadius="md" boxShadow={'sm'}>
              <div>
                <Input placeholder="Email address" type="email" onChange={(evt) => setEmail(evt.target.value)} value={email} />
              </div>
              <div>
                <Input placeholder="Password" type="password" onChange={(evt) => setPassword(evt.target.value)} value={password} />
              </div>
            </Flex>

            <Flex alignItems="center" justifyContent="flex-end">
              <AppLink to={Routes.FORGOT_PASSWORD}>Forgot your password?</AppLink>
            </Flex>

            <div>
              <Button type="submit" size="lg" w="100%" colorScheme="blue" isDisabled={disabled} isLoading={loading}>
                Sign in
              </Button>
            </div>
          </Flex>
          <Box textAlign="center" mt={4}>
            {`Don't have an account? `}
            <AppLink to={Routes.SIGN_UP}>Sign up</AppLink>
          </Box>
        </Card>
      </Flex>
    </Flex>
  );
};

export default LoginForm;
