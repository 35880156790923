import React, { useCallback, useEffect, useState } from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import { generatePath, Outlet, useMatch, useNavigate, useOutlet, useOutletContext, useParams } from 'react-router-dom';
import { FallbackWrapper } from '../../components/fallback/fallback';
import AppLink from '../../components/app-link/appLink';
import { Box, Button, Flex, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { useStatusPageMutation, useStatusPageQuery } from '../../query/queries';
import { BsTrash2Fill } from 'react-icons/bs';
import DeleteConfirmationModal from '../../components/delete-confirmation-modal/deleteConfirmationModal';
import toast from 'react-hot-toast';
import EditStatusPageTab from '../../components/edit-status-page-tab/editStatusPageTab';
import { MutationAction } from '../../query/mutationAction';
import PageControls from '../../components/page-controls/pageControls';
import StatusPageIncidentsTable from '../../components/status-page-incidents-table/statusPageIncidentsTable';

const StatusPagePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const statusPageId = params.id ? params.id : null;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const { data: statusPage, isLoading, isError } = useStatusPageQuery(statusPageId, { enabled: !deleteInProgress });
  const { mutateAsync } = useStatusPageMutation();

  const settingsActive = Boolean(useMatch(Routes.STATUS_PAGE_SETTINGS));
  const incidentsActive = Boolean(useMatch(Routes.STATUS_PAGE_INCIDENTS));

  const handleDelete = useCallback(async () => {
    setDeleteInProgress(true);

    await mutateAsync(
      { statusPageId, action: MutationAction.DELETE },
      {
        onSuccess: () => {
          toast.success('Status page deleted');
          navigate(Routes.STATUS_PAGES);
        },
        onError: () => {
          toast.error('Error occurred. Please try again later');
          setDeleteInProgress(false);
        },
      }
    );
  }, [statusPageId]);

  useEffect(() => {
    if (!outlet) {
      navigate(
        generatePath(Routes.STATUS_PAGE_SETTINGS, {
          id: statusPageId,
        }),
        { replace: true }
      );
    }
  }, [outlet]);

  const breadcrumbs = [{ title: 'Status pages', route: Routes.STATUS_PAGES }, { title: statusPage?.name || '-' }];

  return (
    <Flex flexDirection="column" w="100%">
      <DeleteConfirmationModal
        title="Delete status page"
        content="Are you sure you want to delete this status page? This action is not reversible"
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        loading={deleteInProgress}
        open={showDeleteModal}
      />
      <PageHeader loading={isLoading} title={incidentsActive ? 'Incidents' : settingsActive ? 'Settings' : null} breadcrumbs={breadcrumbs}>
        {incidentsActive && (
          <Box>
            <Button colorScheme="blue" onClick={() => navigate(generatePath(Routes.CREATE_STATUS_PAGE_INCIDENT, { statusPageId }))}>
              Create incident
            </Button>
          </Box>
        )}
      </PageHeader>

      <PageControls
        tabs={
          <>
            <PageControls.Tab
              isActive={settingsActive}
              onClick={() =>
                navigate(
                  generatePath(Routes.STATUS_PAGE_SETTINGS, {
                    id: statusPageId,
                  })
                )
              }
            >
              Settings
            </PageControls.Tab>
            <PageControls.Tab
              isActive={incidentsActive}
              onClick={() =>
                navigate(
                  generatePath(Routes.STATUS_PAGE_INCIDENTS, {
                    id: statusPageId,
                  })
                )
              }
            >
              Incidents
            </PageControls.Tab>
          </>
        }
      >
        <Tooltip label="Delete status page">
          <IconButton
            colorScheme="red"
            aria-label="Delete status page"
            icon={<Icon as={BsTrash2Fill} boxSize={6} />}
            size="md"
            onClick={() => setShowDeleteModal(true)}
            visibility={settingsActive ? 'visible' : 'hidden'}
          />
        </Tooltip>
      </PageControls>
      <PageWrapper loading={isLoading} px={4}>
        <FallbackWrapper
          showFallback={isError}
          title="Status page not found"
          subtitle={
            <>
              Go back to <AppLink to={Routes.STATUS_PAGES}>Status pages</AppLink>
            </>
          }
        >
          <Outlet context={{ statusPageId }} />
        </FallbackWrapper>
      </PageWrapper>
    </Flex>
  );
};

export const StatusPagePageSettingsOutlet = () => {
  const { statusPageId } = useOutletContext<{ statusPageId: string }>();

  return <EditStatusPageTab statusPageId={statusPageId} />;
};

export const StatusPagePageIncidentsOutlet = () => {
  const { statusPageId } = useOutletContext<{ statusPageId: string }>();

  return <StatusPageIncidentsTable statusPageId={statusPageId} />;
};

export default StatusPagePage;
