import React, { useEffect, useState } from 'react';
import EmailIntegrationListSegment from './emailIntegrationListSegment';
import SlackIntegrationListSegment from './slackIntegrationListSegment';
import { useAppDispatch } from '../../app/store';
import actions from '../../app/actions';
import statusPingClient from '../../app/client/statusPingClient';
import Spinner from '../spinner/spinner';
import { IntegrationType } from '@um/uptime-monitoring-shared';
import { Box, Grid } from '@chakra-ui/react';
import SmsIntegrationListSegment from './smsIntegrationListSegment';
import MicrosoftTeamsIntegrationListSegment from './microsoftTeamsIntegrationListSegment';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import PushoverIntegrationListSegment from './pushoverIntegrationListSegment';

const NotificationIntegrationsList = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [notificationIntegrations, setNotificationIntegrations] = useState([]);
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  useEffect(() => {
    const fetchNotificationIntegrations = async () => {
      setLoading(true);

      const response = await statusPingClient.protectedGetNotificationIntegrations({ organizationId });

      setNotificationIntegrations(response);

      setLoading(false);
    };

    if (organizationId) {
      fetchNotificationIntegrations();
    }
  }, [organizationId]);

  const hasIntegrations = notificationIntegrations?.length > 0;

  const handleNotificationIntegrationEnabled = (evt, id: string, enabled: boolean) => {
    evt?.stopPropagation();
    evt?.preventDefault();

    dispatch(actions.notifications.setNotificationIntegrationEnabled({ id, enabled }));
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Grid templateColumns={{ base: 'minmax(0, 1fr)', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
          {hasIntegrations &&
            notificationIntegrations.map((integration) => {
              return (
                <Box key={integration.id}>
                  {integration.type === IntegrationType.EMAIL && (
                    <EmailIntegrationListSegment integration={integration} onToggleIntegration={handleNotificationIntegrationEnabled} />
                  )}
                  {integration.type === IntegrationType.SLACK && (
                    <SlackIntegrationListSegment integration={integration} onToggleIntegration={handleNotificationIntegrationEnabled} />
                  )}
                  {integration.type === IntegrationType.SMS && (
                    <SmsIntegrationListSegment integration={integration} onToggleIntegration={handleNotificationIntegrationEnabled} />
                  )}
                  {integration.type === IntegrationType.MICROSOFT_TEAMS && (
                    <MicrosoftTeamsIntegrationListSegment integration={integration} onToggleIntegration={handleNotificationIntegrationEnabled} />
                  )}
                  {integration.type === IntegrationType.PUSHOVER && (
                    <PushoverIntegrationListSegment integration={integration} onToggleIntegration={handleNotificationIntegrationEnabled} />
                  )}
                </Box>
              );
            })}
        </Grid>
      )}
    </>
  );
};

export default NotificationIntegrationsList;
