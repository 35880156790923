export const prepareRequestParams = (params) => {
  if (!params) {
    return params;
  }

  if (typeof params === 'object') {
    const keys = Object.keys(params);

    return keys.reduce((agg, key) => {
      const param = params[key];
      if (param === null || param === undefined) {
        return agg;
      }

      return { ...agg, [key]: param };
    }, {});
  }

  return params;
};
