"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessOnCallScheduleMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class ProcessOnCallScheduleMessage extends workerMessage_1.WorkerMessage {
    constructor(onCallScheduleId) {
        super(model_1.QueueDefinitions.PROCESS_ON_CALL_SCHEDULE);
        this.onCallScheduleId = onCallScheduleId;
    }
}
exports.ProcessOnCallScheduleMessage = ProcessOnCallScheduleMessage;
