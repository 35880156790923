import { Box, Flex, FlexProps, Icon, Link } from '@chakra-ui/react';
import { FiHelpCircle } from 'react-icons/fi';

const NeedHelp: React.FC<FlexProps> = ({ ...rest }) => {
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={2} {...rest}>
      <Icon as={FiHelpCircle} boxSize={8} fontWeight="bold" />
      <Box fontSize="lg">
        Need help?{' '}
        <Link href="https://dataflix.com/contact/" target="_blank" color="darkGreen.500">
          Contact us!
        </Link>
      </Box>
    </Flex>
  );
};

export default NeedHelp;
