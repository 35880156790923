import { TabList, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';

const EmptyPageHeader = ({ ...rest }) => {
  return (
    <Tabs isLazy index={0} pb={4} {...rest}>
      <TabList pb={2}></TabList>

      <TabPanels></TabPanels>
    </Tabs>
  );
};

export default EmptyPageHeader;
