import { MonitorCheckStatus } from '@um/uptime-monitoring-shared';
import Indicator, { IndicatorProps, IndicatorStatus } from './indicator';
import React from 'react';

type MonitorCheckStatusIndicatorProps = Omit<IndicatorProps, 'status'> & {
  status?: MonitorCheckStatus;
};

const statusMap = {
  [MonitorCheckStatus.ERROR]: IndicatorStatus.WARNING,
  [MonitorCheckStatus.SUCCESS]: IndicatorStatus.SUCCESS,
  [MonitorCheckStatus.IN_PROGRESS]: IndicatorStatus.LOADING,
  [MonitorCheckStatus.FAILURE]: IndicatorStatus.ERROR,
};

const MonitorCheckStatusIndicator: React.FC<MonitorCheckStatusIndicatorProps> = ({ status, height = 2, width = 4, ...rest }) => {
  const finalStatus = (status ? statusMap[status] : null) || IndicatorStatus.STALE;

  return <Indicator height={height} width={width} status={finalStatus} {...rest} />;
};

export default MonitorCheckStatusIndicator;
