import { IntegrationType } from '@um/uptime-monitoring-shared';
import icons from '../icons';

export const IntegrationTypeOptions = [
  {
    type: IntegrationType.EMAIL,
    name: 'Email',
    description: 'Get notified through email',
    icon: icons.EmailIcon,
  },
  {
    type: IntegrationType.SLACK,
    name: 'Slack',
    description: 'Get notified through Slack',
    icon: icons.SlackIcon,
  },
  {
    type: IntegrationType.SMS,
    name: 'SMS message',
    description: 'Get notified through SMS messages',
    icon: icons.MessagesIcon,
  },
  {
    type: IntegrationType.MICROSOFT_TEAMS,
    name: 'Microsoft Teams',
    description: 'Get notified through Microsoft Teams',
    icon: icons.MicrosoftTeamsIcon,
  },
  {
    type: IntegrationType.PUSHOVER,
    name: 'Pushover',
    description: 'Get notified through Pushover',
    icon: icons.PushoverIcon,
  },
];
