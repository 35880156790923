import { Box, Flex, Icon } from '@chakra-ui/react';

export const selectOptionWithDescription = (value: string, name: React.ReactNode, description: string, icon?) => {
  return {
    label: (
      <Flex gap={4} alignItems="center">
        <Flex flexDirection="column" gap={2}>
          <Flex gap={2} alignItems="center" fontWeight="bold">
            {icon && <Icon as={icon} boxSize={6} />} {name}
          </Flex>
          <Box fontSize="md">{description}</Box>
        </Flex>
      </Flex>
    ),
    chipLabel: (
      <Flex gap={2} alignItems="center" fontSize="md" fontWeight="bold">
        {icon && <Icon as={icon} boxSize={6} />} {name}
      </Flex>
    ),
    description,
    value,
  };
};
