"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueDefinitions = exports.QueueDefinition = void 0;
const worker_message_1 = require("../worker-message");
class QueueDefinition {
    constructor({ name, routingKey, messageType, noPermanentDeadletter, noDeadletter, prefetchCount, consistentHashExchange, consistentHashQueues, }) {
        const finalName = name || messageType.toLowerCase();
        this.queueName = `${finalName}.q`;
        this.exchangeName = `${finalName}.xch`;
        this.messageType = messageType;
        this.consistentHashExchange = consistentHashExchange;
        this.consistentHashQueues = consistentHashQueues;
        if (!noDeadletter) {
            this.deadletterQueueName = `${finalName}.dlq`;
            this.deadletterExchangeName = `${finalName}.dlxch`;
        }
        if (!noPermanentDeadletter) {
            this.permanentDeadletterQueueName = `${finalName}.permadlq`;
            this.permanentDeadletterExchangeName = `${finalName}.permadlxch`;
        }
        this.routingKey = routingKey || messageType;
        this.prefetchCount = prefetchCount;
    }
}
exports.QueueDefinition = QueueDefinition;
exports.QueueDefinitions = {
    ENQUEUE_MONITORS: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.ENQUEUE_MONITORS,
        prefetchCount: 10,
        noDeadletter: true,
        noPermanentDeadletter: true,
    }),
    EXECUTE_MONITOR: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.EXECUTE_MONITOR,
        prefetchCount: 50,
    }),
    INCIDENT_CREATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.INCIDENT_CREATED,
        prefetchCount: 10,
    }),
    INCIDENT_MONITOR_CHECK_CREATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.INCIDENT_MONITOR_CHECK_CREATED,
        prefetchCount: 10,
    }),
    INCIDENT_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.INCIDENT_UPDATED,
        prefetchCount: 10,
    }),
    MONITOR_EXECUTED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.MONITOR_EXECUTED,
        prefetchCount: 50,
    }),
    NOTIFICATION_INTEGRATION_CREATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.NOTIFICATION_INTEGRATION_CREATED,
        prefetchCount: 10,
    }),
    NOTIFICATION_INTEGRATION_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.NOTIFICATION_INTEGRATION_UPDATED,
        prefetchCount: 10,
    }),
    NOTIFY_MONITOR_INTEGRATION: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.NOTIFY_MONITOR_INTEGRATION,
        prefetchCount: 50,
    }),
    SEND_RESET_PASSWORD_TOKEN_EMAIL: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.SEND_RESET_PASSWORD_TOKEN_EMAIL,
        prefetchCount: 10,
    }),
    USER_SIGNED_UP: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.USER_SIGNED_UP,
        prefetchCount: 10,
    }),
    SEND_LOGIN_LINK: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.SEND_LOGIN_LINK,
        prefetchCount: 10,
    }),
    USER_ADDED_TO_ORGANIZATION_MESSAGE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.USER_ADDED_TO_ORGANIZATION_MESSAGE,
        prefetchCount: 10,
    }),
    SUBSCRIPTION_UPDATED_MESSAGE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.SUBSCRIPTION_UPDATED_MESSAGE,
        prefetchCount: 10,
    }),
    SEND_VERIFICATION_TOKEN_NOTIFICATION: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.SEND_VERIFICATION_TOKEN_NOTIFICATION,
        prefetchCount: 10,
    }),
    GENERIC_TASK_MESSAGE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.GENERIC_TASK_MESSAGE,
        prefetchCount: 10,
    }),
    ENQUEUE_WEEKLY_MONITOR_REPORTS: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.ENQUEUE_WEEKLY_MONITOR_REPORTS,
        prefetchCount: 10,
    }),
    SEND_WEEKLY_MONITOR_REPORT: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.SEND_WEEKLY_MONITOR_REPORT,
        prefetchCount: 1,
    }),
    NOTIFY_SSL_CERTIFICATE_EXPIRATION_MESSAGE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.NOTIFY_SSL_CERTIFICATE_EXPIRATION_MESSAGE,
        prefetchCount: 10,
    }),
    CHECK_DOMAIN_NAME_EXPIRATION: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.CHECK_DOMAIN_NAME_EXPIRATION,
        prefetchCount: 10,
    }),
    CHECK_MONITOR_CERTIFICATE_EXPIRATION: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.CHECK_MONITOR_CERTIFICATE_EXPIRATION,
        prefetchCount: 10,
    }),
    EVALUATE_MONITOR_GROUP_STATUS: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.EVALUATE_MONITOR_GROUP_STATUS,
        prefetchCount: 1,
        consistentHashExchange: true,
        consistentHashQueues: 5,
    }),
    MONITOR_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.MONITOR_UPDATED,
        prefetchCount: 50,
    }),
    STATUS_PAGE_CREATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.STATUS_PAGE_CREATED,
        prefetchCount: 10,
    }),
    STATUS_PAGE_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.STATUS_PAGE_UPDATED,
        prefetchCount: 10,
    }),
    STATUS_PAGE_DELETED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.STATUS_PAGE_DELETED,
        prefetchCount: 10,
    }),
    EVALUATE_STATUS_PAGE_STATUS: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.EVALUATE_STATUS_PAGE_STATUS,
        prefetchCount: 1,
        consistentHashExchange: true,
        consistentHashQueues: 5,
    }),
    ORGANIZATION_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.ORGANIZATION_UPDATED,
        prefetchCount: 1,
        consistentHashExchange: true,
        consistentHashQueues: 5,
    }),
    UPLOAD_IMAGE_TO_GOOGLE_CLOUD_MESSAGE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.UPLOAD_IMAGE_TO_GOOGLE_CLOUD_MESSAGE,
        prefetchCount: 10,
    }),
    USER_DELETED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.USER_DELETED,
        prefetchCount: 10,
    }),
    ENQUEUE_NOTIFICATION_POLICY_STATES: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.ENQUEUE_NOTIFICATION_POLICY_STATES,
        prefetchCount: 10,
        noDeadletter: true,
        noPermanentDeadletter: true,
    }),
    PROCESS_NOTIFICATION_POLICY_STATE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.PROCESS_NOTIFICATION_POLICY_STATE,
        prefetchCount: 50,
    }),
    NOTIFY_NOTIFICATION_CHANNEL: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.NOTIFY_NOTIFICATION_CHANNEL,
        prefetchCount: 50,
    }),
    ON_CALL_SCHEDULE_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.ON_CALL_SCHEDULE_UPDATED,
        prefetchCount: 50,
    }),
    ON_CALL_DUTY_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.ON_CALL_DUTY_UPDATED,
        prefetchCount: 50,
    }),
    PROCESS_ON_CALL_SCHEDULE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.PROCESS_ON_CALL_SCHEDULE,
        prefetchCount: 50,
    }),
    ENQUEUE_ON_CALL_SCHEDULES: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.ENQUEUE_ON_CALL_SCHEDULES,
        prefetchCount: 10,
        noDeadletter: true,
        noPermanentDeadletter: true,
    }),
    NOTIFY_ON_CALL_SCHEDULE: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.NOTIFY_ON_CALL_SCHEDULE,
        prefetchCount: 50,
    }),
    STATUS_PAGE_INCIDENT_CREATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.STATUS_PAGE_INCIDENT_CREATED,
        prefetchCount: 10,
    }),
    STATUS_PAGE_INCIDENT_UPDATE_CREATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.STATUS_PAGE_INCIDENT_UPDATE_CREATED,
        prefetchCount: 10,
    }),
    STATUS_PAGE_INCIDENT_RESOURCE_CREATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.STATUS_PAGE_INCIDENT_RESOURCE_CREATED,
        prefetchCount: 10,
    }),
    STATUS_PAGE_INCIDENT_RESOURCE_STATUS_UPDATED: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.STATUS_PAGE_INCIDENT_RESOURCE_STATUS_UPDATED,
        prefetchCount: 10,
    }),
    INCIDENT_CREATED_STATUS_PAGE_INCIDENT: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.INCIDENT_CREATED_STATUS_PAGE_INCIDENT,
        prefetchCount: 10,
    }),
    INCIDENT_UPDATED_STATUS_PAGE_INCIDENT: new QueueDefinition({
        messageType: worker_message_1.WorkerMessageType.INCIDENT_UPDATED_STATUS_PAGE_INCIDENT,
        prefetchCount: 10,
    }),
};
