import { format, utcToZonedTime } from 'date-fns-tz';

export enum DateFormats {
  FULL_DAY = 'yyyy-MM-dd',
  FULL_MINUTES = 'yyyy-MM-dd HH:mm',
  FULL_SECONDS = 'yyyy-MM-dd HH:mm:ss',
  ISO = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
  FULL_MINUTES_WITH_TZ = 'yyyy-MM-dd HH:mm zzz',
  FULL_SECONDS_WITH_TZ = 'yyyy-MM-dd HH:mm:ss zzz',
}

export const formatToUtc = (date: Date | string, pattern: string) => {
  if (!date) {
    return null;
  }

  return format(utcToZonedTime(date, 'UTC'), pattern);
};
