import { StatusPageIncidentStatusPageVisibility } from '@um/uptime-monitoring-shared';

const text = {
  [StatusPageIncidentStatusPageVisibility.VISIBLE]: 'Visible',
  [StatusPageIncidentStatusPageVisibility.HIDDEN]: 'Hidden',
};

const useStatusPageVisibilityText = (statusPageVisibility: StatusPageIncidentStatusPageVisibility) => {
  return text[statusPageVisibility] || statusPageVisibility;
};

export default useStatusPageVisibilityText;
