import { MonitorStatus, MonitorTransport, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';

const statusText: { [key: string]: string } = {
  [MonitorStatus.NONE]: 'Checking',
  [MonitorStatus.SUCCESS]: 'Healthy',
  [MonitorStatus.ERROR]: 'Error',
  [MonitorStatus.GRACE_PERIOD]: 'Grace period',
  [MonitorStatus.ALERTING]: 'Down',
  [MonitorStatus.IN_PROGRESS]: 'Checking',
  [MonitorStatus.IDLE]: 'Idle',
};

export const monitorStatusText = (monitor: ProtectedMonitorDetailsResponse) => {
  const { transport, status } = monitor;
  const checkInMonitor = transport === MonitorTransport.CHECK_IN;

  if (checkInMonitor && status === MonitorStatus.IDLE) {
    return 'Idle - awaiting first check-in';
  }

  return statusText[status] || statusText[MonitorStatus.NONE];
};
