import monitorsActions from './monitors';
import monitorDetailsActions from './monitorDetails';
import monitorCheckDetailsActions from './monitorCheckDetails';
import appActions from './app';
import notificationsActions from './notifications';
import createIntegrationActions from './createIntegration';
import createMonitorActions from './createMonitor';
import editMonitorActions from './editMonitor';
import monitorOverviewTabActions from './monitorOverviewTab';
import websocketActions from './websocket';
import monitorResponseStatisticsActions from './monitorResponseStatistics';
import notificationsOverviewTabActions from './notificationsOverviewTab';
import upgradeActions from './upgrade';

const actions = {
  monitors: monitorsActions,
  monitorDetails: monitorDetailsActions,
  monitorCheckDetails: monitorCheckDetailsActions,
  app: appActions,
  notifications: notificationsActions,
  createIntegration: createIntegrationActions,
  createMonitor: createMonitorActions,
  editMonitor: editMonitorActions,
  monitorOverviewTab: monitorOverviewTabActions,
  websocket: websocketActions,
  monitorResponseStatistics: monitorResponseStatisticsActions,
  notificationsOverviewTab: notificationsOverviewTabActions,
  upgrade: upgradeActions,
};

export default actions;
