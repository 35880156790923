import { Box, Radio, VStack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OnCallScheduleNoOneOnDutyAction } from '@um/uptime-monitoring-shared';
import formValidationRules from '../../utils/formValidationRules';
import ControlledCheckbox from '../checkbox/checkbox';
import ControlledRadio from '../controlled-radio/controlledRadio';

const { required } = formValidationRules;

const NotificationPolicyStepOnCallScheduleConfigurationFields = ({ path, disabled = false, ...rest }) => {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const notifySmsPath = `${path}.notifySms`;
  const notifyEmailPath = `${path}.notifyEmail`;
  const noOneOnDutyActionPath = `${path}.noOneOnDutyAction`;

  return (
    <Box width="100%" {...rest}>
      <Box fontSize="lg" color="gray.600" mb={4}>
        Notify on-call person through
      </Box>
      <VStack alignItems="flex-start">
        <ControlledCheckbox isDisabled={disabled} name={notifyEmailPath}>
          Email
        </ControlledCheckbox>
        <ControlledCheckbox isDisabled={disabled} name={notifySmsPath}>
          SMS (person must have phone number set in profile)
        </ControlledCheckbox>
      </VStack>
      <Box fontSize="lg" color="gray.600" my={4}>
        If no one is on-call duty then
      </Box>
      <VStack alignItems="flex-start">
        <ControlledRadio name={noOneOnDutyActionPath} value={OnCallScheduleNoOneOnDutyAction.DO_NOTHING}>
          Do nothing
        </ControlledRadio>
        <ControlledRadio name={noOneOnDutyActionPath} value={OnCallScheduleNoOneOnDutyAction.IMMEDIATELY_SKIP_TO_THE_NEXT_STEP}>
          Immediately skip to the next step
        </ControlledRadio>
      </VStack>
    </Box>
  );
};

export default NotificationPolicyStepOnCallScheduleConfigurationFields;
