import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationHistoryResponse } from '@um/uptime-monitoring-shared';

type SliceType = {
  initialized: boolean;
  notificationsHistory: NotificationHistoryResponse[];
};

const initialState: SliceType = {
  initialized: false,
  notificationsHistory: [],
};

export const notificationsOverviewTab = createSlice({
  name: 'notificationsOverviewTab',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      return state;
    },
    setNotificationsHistory: (state, action: PayloadAction<NotificationHistoryResponse[]>) => {
      state.notificationsHistory = action.payload;
      return state;
    },
  },
});

export default notificationsOverviewTab.reducer;
