"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanFeatureValues = exports.Plans = exports.PlanFeature = exports.PlanValues = exports.Plan = void 0;
const quotaUsage_1 = require("./quotaUsage");
var Plan;
(function (Plan) {
    Plan["FREE"] = "FREE";
    Plan["STARTER"] = "STARTER";
    Plan["SOLO"] = "SOLO";
    Plan["TEAM"] = "TEAM";
    Plan["BUSINESS"] = "BUSINESS";
})(Plan = exports.Plan || (exports.Plan = {}));
exports.PlanValues = Object.values(Plan);
var PlanFeature;
(function (PlanFeature) {
    PlanFeature["MONITORS_LIMIT"] = "monitors";
    PlanFeature["USERS_LIMIT"] = "users";
    PlanFeature["SMS_NOTIFICATION_INTEGRATION"] = "SMS_NOTIFICATION_INTEGRATION";
    PlanFeature["SMS_NOTIFICATIONS"] = "SMS_NOTIFICATIONS";
    PlanFeature["DOMAIN_NAME_MONITORING"] = "DOMAIN_NAME_MONITORING";
    PlanFeature["STATUS_PAGE_LIMIT"] = "STATUS_PAGE_LIMIT";
    PlanFeature["STATUS_PAGE_CUSTOM_DOMAIN"] = "STATUS_PAGE_CUSTOM_DOMAIN";
    PlanFeature["STATUS_PAGE_PASSWORD_PROTECTED"] = "STATUS_PAGE_PASSWORD_PROTECTED";
    PlanFeature["STATUS_PAGE_DISABLE_BRANDING"] = "STATUS_PAGE_DISABLE_BRANDING";
    PlanFeature["NOTIFICATION_POLICY_LIMIT"] = "NOTIFICATION_POLICY_LIMIT";
    PlanFeature["ON_CALL_SCHEDULE_LIMIT"] = "ON_CALL_SCHEDULE_LIMIT";
    PlanFeature["ONE_MINUTE_CHECK_INTERVAL"] = "ONE_MINUTE_CHECK_INTERVAL";
})(PlanFeature = exports.PlanFeature || (exports.PlanFeature = {}));
exports.Plans = {
    [Plan.FREE]: {
        key: Plan.FREE,
        name: 'Free',
        features: [
            { key: PlanFeature.MONITORS_LIMIT, quota: 5 },
            { key: PlanFeature.USERS_LIMIT, quota: 1 },
            { key: PlanFeature.STATUS_PAGE_LIMIT, quota: 1 },
            { key: PlanFeature.NOTIFICATION_POLICY_LIMIT, quota: 1 },
        ],
    },
    [Plan.STARTER]: {
        key: Plan.STARTER,
        name: 'STARTER',
        features: [
            { key: PlanFeature.MONITORS_LIMIT, quota: 50 },
            { key: PlanFeature.USERS_LIMIT, quota: 3 },
            { key: PlanFeature.SMS_NOTIFICATION_INTEGRATION },
            { key: PlanFeature.SMS_NOTIFICATIONS, quota: 60, quotaPeriod: quotaUsage_1.QuotaUsagePeriodType.MONTH },
            { key: PlanFeature.DOMAIN_NAME_MONITORING },
            { key: PlanFeature.STATUS_PAGE_LIMIT, quota: 10 },
            { key: PlanFeature.STATUS_PAGE_CUSTOM_DOMAIN },
            { key: PlanFeature.STATUS_PAGE_PASSWORD_PROTECTED },
            { key: PlanFeature.STATUS_PAGE_DISABLE_BRANDING },
            { key: PlanFeature.NOTIFICATION_POLICY_LIMIT },
            { key: PlanFeature.ON_CALL_SCHEDULE_LIMIT },
            { key: PlanFeature.ONE_MINUTE_CHECK_INTERVAL },
        ],
    },
    [Plan.SOLO]: {
        key: Plan.SOLO,
        name: 'SOLO',
        features: [
            { key: PlanFeature.MONITORS_LIMIT, quota: 30 },
            { key: PlanFeature.USERS_LIMIT, quota: 1 },
            { key: PlanFeature.SMS_NOTIFICATION_INTEGRATION },
            { key: PlanFeature.SMS_NOTIFICATIONS, quota: 30, quotaPeriod: quotaUsage_1.QuotaUsagePeriodType.MONTH },
            { key: PlanFeature.DOMAIN_NAME_MONITORING },
            { key: PlanFeature.STATUS_PAGE_LIMIT, quota: 2 },
            { key: PlanFeature.STATUS_PAGE_CUSTOM_DOMAIN },
            { key: PlanFeature.STATUS_PAGE_PASSWORD_PROTECTED },
            { key: PlanFeature.STATUS_PAGE_DISABLE_BRANDING },
            { key: PlanFeature.NOTIFICATION_POLICY_LIMIT, quota: 1 },
            { key: PlanFeature.ONE_MINUTE_CHECK_INTERVAL },
        ],
    },
    [Plan.TEAM]: {
        key: Plan.TEAM,
        name: 'TEAM',
        features: [
            { key: PlanFeature.MONITORS_LIMIT, quota: 100 },
            { key: PlanFeature.USERS_LIMIT, quota: 5 },
            { key: PlanFeature.SMS_NOTIFICATION_INTEGRATION },
            { key: PlanFeature.SMS_NOTIFICATIONS, quota: 100, quotaPeriod: quotaUsage_1.QuotaUsagePeriodType.MONTH },
            { key: PlanFeature.DOMAIN_NAME_MONITORING },
            { key: PlanFeature.STATUS_PAGE_LIMIT, quota: 10 },
            { key: PlanFeature.STATUS_PAGE_CUSTOM_DOMAIN },
            { key: PlanFeature.STATUS_PAGE_PASSWORD_PROTECTED },
            { key: PlanFeature.STATUS_PAGE_DISABLE_BRANDING },
            { key: PlanFeature.NOTIFICATION_POLICY_LIMIT },
            { key: PlanFeature.ON_CALL_SCHEDULE_LIMIT },
            { key: PlanFeature.ONE_MINUTE_CHECK_INTERVAL },
        ],
    },
    [Plan.BUSINESS]: {
        key: Plan.BUSINESS,
        name: 'BUSINESS',
        features: [
            { key: PlanFeature.MONITORS_LIMIT, quota: 300 },
            { key: PlanFeature.USERS_LIMIT, quota: 6 },
            { key: PlanFeature.SMS_NOTIFICATION_INTEGRATION },
            { key: PlanFeature.SMS_NOTIFICATIONS, quota: 250, quotaPeriod: quotaUsage_1.QuotaUsagePeriodType.MONTH },
            { key: PlanFeature.DOMAIN_NAME_MONITORING },
            { key: PlanFeature.STATUS_PAGE_LIMIT },
            { key: PlanFeature.STATUS_PAGE_CUSTOM_DOMAIN },
            { key: PlanFeature.STATUS_PAGE_PASSWORD_PROTECTED },
            { key: PlanFeature.STATUS_PAGE_DISABLE_BRANDING },
            { key: PlanFeature.NOTIFICATION_POLICY_LIMIT },
            { key: PlanFeature.ON_CALL_SCHEDULE_LIMIT },
            { key: PlanFeature.ONE_MINUTE_CHECK_INTERVAL },
        ],
    },
};
exports.PlanFeatureValues = Object.values(PlanFeature);
