import { all } from 'redux-saga/effects';
import monitorsSaga from './monitors/monitors';
import monitorDetails from './monitor-details/monitorDetails';
import monitorExecutionDetails from './monitor-execution-details/monitorExecutionDetails';
import app from './app/app';
import notifications from './notifications/notifications';
import createMonitor from './createMonitor';
import editMonitor from './edit-monitor/editMonitor';
import monitorOverviewTab from './monitor-overview-tab/monitorOverviewTab';
import websocket from './websocket/websocket';
import monitorResponseStatistics from './monitor-response-statistics/monitorResponseStatistics';
import notificationsOverviewTab from './notifications-overview-tab/notificationsOverviewTab';

export default function* rootSaga() {
  yield all([
    monitorsSaga(),
    monitorDetails(),
    monitorExecutionDetails(),
    app(),
    notifications(),
    createMonitor(),
    editMonitor(),
    monitorOverviewTab(),
    websocket(),
    monitorResponseStatistics(),
    notificationsOverviewTab(),
  ]);
}
