import React, { lazy, Suspense, useCallback, useState } from 'react';
import { useStatusPageMutation, useStatusPageQuery } from '../../query/queries';
import toast from 'react-hot-toast';
import Spinner from '../spinner/spinner';
import { StatusPageFormModel } from '../status-page-form/statusPageFormModel';
import { MutationAction } from '../../query/mutationAction';
import mutationDefaults from '../../query/mutationDefaults';

const StatusPageForm = lazy(() => import('../status-page-form/statusPageForm'));

export type EditStatusPageFormProps = {
  statusPageId: string;
  onEdited?: (data?: any) => any;
  onError?: (err?: any) => any;
};

const EditStatusPageForm: React.FC<EditStatusPageFormProps> = ({ statusPageId, onEdited, onError }) => {
  const { isFetched, data: statusPage } = useStatusPageQuery(statusPageId);
  const { isLoading: mutationLoading, mutateAsync } = useStatusPageMutation();
  const [formErrors, setFormErrors] = useState([]);
  const initialized = isFetched;
  const loading = mutationLoading || !isFetched;

  const handleSubmit = useCallback(
    async (data: StatusPageFormModel) => {
      const { id, ...rest } = data;
      const finalStatusPage: StatusPageFormModel = {
        ...rest,
        sections: (data.sections || []).map((section, idx) => ({
          ...section,
          order: idx + 1,
          entries: (section.entries || []).map(({ monitorId, monitorGroupId, ...entry }, entryIdx) => ({
            ...entry,
            ...(monitorId ? { monitorId } : {}),
            ...(monitorGroupId ? { monitorGroupId } : {}),
            order: entryIdx + 1,
          })),
        })),
      };
      await mutateAsync(
        { statusPageId, updateRequest: finalStatusPage, action: MutationAction.PUT },
        {
          onSuccess: (data) => {
            toast.success('Status page updated');
            onEdited && onEdited(data);
          },
          onError: mutationDefaults.onErrorWithFormHandling(setFormErrors, onError),
        }
      );
    },
    [statusPageId]
  );

  const formValues: StatusPageFormModel = {
    ...statusPage,
  };

  if (!initialized) {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <StatusPageForm formValues={formValues} formErrors={formErrors} mainActionText="Save" disabled={loading} onSubmit={handleSubmit} />
    </Suspense>
  );
};

export default EditStatusPageForm;
