import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as MessagesIcon } from './messages.svg';
import { ReactComponent as MicrosoftTeamsIcon } from './microsoft-teams.svg';
import { ReactComponent as SlackIcon } from './slack.svg';
import { ReactComponent as PushoverIcon } from './pushover.svg';

const icons = {
  EmailIcon,
  MessagesIcon,
  MicrosoftTeamsIcon,
  SlackIcon,
  PushoverIcon,
};

export default icons;
