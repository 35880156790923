"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckMonitorCertificateExpirationMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class CheckMonitorCertificateExpirationMessage extends workerMessage_1.WorkerMessage {
    constructor(monitorId) {
        super(model_1.QueueDefinitions.CHECK_MONITOR_CERTIFICATE_EXPIRATION);
        this.monitorId = monitorId;
    }
}
exports.CheckMonitorCertificateExpirationMessage = CheckMonitorCertificateExpirationMessage;
