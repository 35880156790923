export const AppConfig = {
  ApiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  AppBaseUrl: process.env.REACT_APP_APP_BASE_URL,
  WebsocketHost: process.env.REACT_APP_WEBSOCKET_HOST,
  BugsnagApiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  DocsBaseUrl: process.env.REACT_APP_DOCS_BASE_URL,
  GoogleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  StarterPlanProductId: process.env.REACT_APP_STARTER_PLAN_PRODUCT_ID,
  PaddleVendorId: process.env.REACT_APP_PADDLE_VENDOR_ID,
  Environment: process.env.REACT_APP_ENVIRONMENT,
  StatusPagesDomain: process.env.REACT_APP_STATUS_PAGES_DOMAIN,
  StatusPagesDomainFull: process.env.REACT_APP_STATUS_PAGES_DOMAIN_FULL,
  AcknowledgmentEnabled: process.env.REACT_APP_ACKNOWLEDGMENT_ENABLED === 'true',
  NotificationPoliciesEnabled: process.env.REACT_APP_NOTIFICATION_POLICIES_ENABLED === 'true',
  OnCallSchedulesEnabled: process.env.REACT_APP_ON_CALL_SCHEDULES_ENABLED === 'true',
  CheckInHostname: process.env.REACT_APP_CHECK_IN_HOSTNAME,
  PushoverSubscribeUrl: process.env.REACT_APP_PUSHOVER_SUBSCRIBE_URL,
  PushoverSubscribeSuccessUrl: process.env.REACT_APP_PUSHOVER_SUBSCRIBE_SUCCESS_URL,
  PushoverSubscribeFailureUrl: process.env.REACT_APP_PUSHOVER_SUBSCRIBE_FAILURE_URL,
  AmplitudeApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  AmplitudeServerUrl: process.env.REACT_APP_AMPLITUDE_SERVER_URL,
  CookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
};
