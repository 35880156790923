import PageHeader from '../../components/page-header/pageHeader';
import { useAppSelector } from '../../app/store';
import React, { useCallback } from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import { Box, Button, Flex, Icon, MenuButton } from '@chakra-ui/react';
import Routes from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import EmptyPageTopMenu from '../../components/empty-page-header/emptyPageHeader';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import OnCallSchedulesTable from '../../components/on-call-schedules-table/onCallSchedulesTable';
import useFeatureInformation from '../../hooks/useFeatureInformation';
import { PlanFeature } from '@um/uptime-monitoring-shared';
import { useOrganizationOnCallSchedulesQuery } from '../../query/onCallScheduleQueries';
import actions from '../../app/actions';
import { UpgradeModalReason } from '../../app/model/upgradeModalReason';
import { useDispatch } from 'react-redux';
import PageControls from '../../components/page-controls/pageControls';
import { InfoIcon } from '@chakra-ui/icons';
import OnCallSchedules from './onCallSchedules.mdx';
import useDynamicModal from '../../hooks/useDynamicModal';
import NotificationDetails from '../../components/notification-details/notificationDetails';
import { useInformationModal } from '../../components/information-modal/informationModal';

const OnCallSchedulesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    app: { initialized },
  } = useAppSelector((state) => state);
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  const { data: onCallSchedulesData } = useOrganizationOnCallSchedulesQuery(organizationId);
  const breadcrumbs = [{ title: 'Settings', route: Routes.SETTINGS }];
  const {
    initialized: featureInitialized,
    featureMissing,
    quotaReached,
  } = useFeatureInformation(PlanFeature.ON_CALL_SCHEDULE_LIMIT, onCallSchedulesData?.result?.length);

  const handleClickCreate = useCallback(() => {
    if (featureMissing) {
      dispatch(actions.upgrade.showUpgradeModal(UpgradeModalReason.ON_CALL_SCHEDULES_NOT_AVAILABLE));
    } else if (quotaReached) {
      dispatch(actions.upgrade.showUpgradeModal(UpgradeModalReason.ON_CALL_SCHEDULES_QUOTA));
    } else {
      navigate(Routes.CREATE_ON_CALL_SCHEDULE);
    }
  }, [featureMissing, quotaReached]);
  const { modal, setOpen: setModalOpen } = useInformationModal({
    modalContent: () => <OnCallSchedules />,
    title: 'On-call schedules',
  });

  return (
    <Box w="100%">
      {modal}
      <PageHeader title="On-call schedules" loading={!initialized || !featureInitialized} breadcrumbs={breadcrumbs}>
        <div>
          <Button colorScheme="blue" onClick={handleClickCreate}>
            Create
          </Button>
        </div>
      </PageHeader>
      <PageControls
        leftChildren={
          <Button onClick={() => setModalOpen(true)}>
            <Flex gap={2} alignItems="center">
              <Icon as={InfoIcon} boxSize={6} fontWeight="bold" />
              Learn more
            </Flex>
          </Button>
        }
      ></PageControls>
      <PageWrapper loading={!initialized || !featureInitialized} px={4}>
        <OnCallSchedulesTable organizationId={organizationId} />
      </PageWrapper>
    </Box>
  );
};

export default OnCallSchedulesPage;
