import React from 'react';
import { Control, Controller, ControllerProps, ControllerRenderProps } from 'react-hook-form';
import { prepareRules } from '../../utils/formValidationRules';
import Slider from 'rc-slider';
import FormFieldLabel from '../form-field-label/formFieldLabel';
import { Box, Flex, InputGroup, InputProps, InputRightAddon, Tooltip } from '@chakra-ui/react';
import Input from '../input/input';

type Props = {
  label?: string;
  error?: string;
} & ControllerRenderProps<any> &
  Omit<InputProps, 'error' | 'onChange'>;

type ControlledProps = { control: Control<any> } & Partial<Omit<ControllerProps, 'control' | 'render'>>;

const marks = {
  300: { label: '5m' },
  600: { label: '10m' },
  1800: { label: '30m' },
  3600: { label: '1h' },
};

const MonitorGracePeriodSelector: React.FC<Props> = ({ placeholder, className, label, error, children, onChange, value }) => {
  return (
    <div className={className}>
      <FormFieldLabel>{label}</FormFieldLabel>
      <Flex gap={4}>
        <Box flexGrow={1} w="100%">
          <Slider
            min={0}
            marks={marks}
            step={30}
            included
            defaultValue={0}
            max={3600}
            value={value as number}
            onChange={(value) => onChange(value)}
          />
        </Box>
        <Box maxW="120px">
          <InputGroup size="md">
            <Input placeholder={placeholder} error={error} onChange={onChange} type="number" value={value} />
            <InputRightAddon>
              <Tooltip label="seconds">s</Tooltip>
            </InputRightAddon>
          </InputGroup>
        </Box>
      </Flex>
      {children}
    </div>
  );
};

MonitorGracePeriodSelector.displayName = 'MonitorGracePeriodSelector';

export const ControlledMonitorGracePeriodSelector: React.FC<ControlledProps> = ({ control, name, rules, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => (
        <MonitorGracePeriodSelector error={error?.message} onChange={(value) => field.onChange(value)} {...rest} {...field} />
      )}
    />
  );
};

ControlledMonitorGracePeriodSelector.displayName = 'ControlledMonitorGracePeriodSelector';

export default MonitorGracePeriodSelector;
