import React from 'react';
import { ControlledInput } from '../input/input';
import Routes from '../../utils/routes';
import { FormProvider, useForm } from 'react-hook-form';
import AppLink from '../app-link/appLink';
import { Alert, AlertIcon, Box, Button, Flex } from '@chakra-ui/react';
import Card from '../card/card';

export interface RequestResetPasswordFormData {
  email: string;
}

const defaultValues: RequestResetPasswordFormData = {
  email: '',
};

type Props = {
  onRequestResetPassword: (data: RequestResetPasswordFormData) => void;
  disabled: boolean;
  loading: boolean;
  error?: string;
};

export const RequestResetPasswordForm: React.FC<Props> = ({ onRequestResetPassword, disabled, loading, error, ...rest }) => {
  const formMethods = useForm<RequestResetPasswordFormData>({ defaultValues });
  const { handleSubmit, control } = formMethods;

  return (
    <Flex alignItems="center" minH="100%" justifyContent="center">
      <Flex flexDirection="column" maxW="container.md" w="100%" gap={2}>
        <Card>
          <div>
            <Box as="h2" fontSize="3xl" textAlign="center" fontWeight="bold">
              Reset password
            </Box>
          </div>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <FormProvider {...formMethods}>
            <Flex as="form" flexDirection="column" gap={4} {...rest} onSubmit={handleSubmit(onRequestResetPassword)}>
              <input type="hidden" name="remember" defaultValue="true" />
              <ControlledInput placeholder="Email address" name="email" type="email" isDisabled={disabled} control={control} />
              <Box textAlign="center" fontSize="lg">
                We will send you an email with instructions on how to reset your password
              </Box>
              <Button type="submit" colorScheme="blue" w="100%" isDisabled={disabled} isLoading={loading}>
                Submit
              </Button>
            </Flex>
          </FormProvider>
          <Flex alignItems="center" justifyContent="center" fontSize="lg">
            <AppLink to={Routes.LOGIN}>Back to login</AppLink>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default RequestResetPasswordForm;
