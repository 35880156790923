"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageIncidentUpdateCreatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class StatusPageIncidentUpdateCreatedMessage extends workerMessage_1.WorkerMessage {
    constructor(statusPageIncidentUpdate) {
        super(model_1.QueueDefinitions.STATUS_PAGE_INCIDENT_UPDATE_CREATED);
        this.statusPageIncidentUpdate = statusPageIncidentUpdate;
    }
}
exports.StatusPageIncidentUpdateCreatedMessage = StatusPageIncidentUpdateCreatedMessage;
