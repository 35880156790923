import PageHeader from '../../components/page-header/pageHeader';
import { useAppSelector } from '../../app/store';
import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import NotificationHistoryTable from '../../components/notification-history-table/notificationHistoryTable';
import { Box, Icon, IconButton } from '@chakra-ui/react';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { generatePath, Outlet, useMatch, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import NotificationIntegrationsList from '../../components/notification-integrations-list/notificationIntegrationsList';
import PageControls from '../../components/page-controls/pageControls';
import Routes from '../../utils/routes';
import NotificationPolicyTable from '../../components/notification-policy-table/notificationPolicyTable';
import { useOnCallScheduleMutation, useOnCallScheduleQuery } from '../../query/onCallScheduleQueries';
import OnCallScheduleOverview from '../../components/on-call-schedule-overview/onCallScheduleOverview';
import EditOnCallScheduleForm from '../../components/edit-on-call-schedule-form/editOnCallScheduleForm';
import { BsTrash2Fill } from 'react-icons/bs';
import DeleteConfirmationModal from '../../components/delete-confirmation-modal/deleteConfirmationModal';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';

const OnCallSchedulePage = () => {
  const {
    app: { initialized },
  } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const params = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onCallScheduleId = params.id;
  const overviewActive = Boolean(useMatch(Routes.ON_CALL_SCHEDULE_OVERVIEW));
  const settingsActive = Boolean(useMatch(Routes.ON_CALL_SCHEDULE_SETTINGS));
  const breadcrumbs = [
    { title: 'Settings', route: Routes.SETTINGS },
    { title: 'On-call schedules', route: Routes.ON_CALL_SCHEDULES },
  ];
  const { mutateAsync, isLoading: mutating } = useOnCallScheduleMutation();

  useEffect(() => {
    if (!overviewActive && !settingsActive) {
      navigate(
        generatePath(Routes.ON_CALL_SCHEDULE_OVERVIEW, {
          id: onCallScheduleId,
        })
      );
    }
  }, [overviewActive, settingsActive]);
  const { data: onCallSchedule, isLoading } = useOnCallScheduleQuery(onCallScheduleId);
  const handleDelete = async () => {
    await mutateAsync(
      { onCallScheduleId, action: MutationAction.DELETE },
      {
        onSuccess: () => {
          toast.success('On-call schedule deleted');
          navigate(Routes.ON_CALL_SCHEDULES);
        },
        onError: () => {
          setShowDeleteModal(false);
        },
      }
    );
  };

  return (
    <Box w="100%">
      <PageHeader title={onCallSchedule?.name} breadcrumbs={breadcrumbs} loading={!initialized || isLoading} />

      <PageControls
        tabs={
          <>
            <PageControls.Tab
              isActive={overviewActive}
              onClick={() =>
                navigate(
                  generatePath(Routes.ON_CALL_SCHEDULE_OVERVIEW, {
                    id: onCallScheduleId,
                  })
                )
              }
            >
              Overview
            </PageControls.Tab>
            <PageControls.Tab
              isActive={settingsActive}
              onClick={() =>
                navigate(
                  generatePath(Routes.ON_CALL_SCHEDULE_SETTINGS, {
                    id: onCallScheduleId,
                  })
                )
              }
            >
              Settings
            </PageControls.Tab>
          </>
        }
      >
        <IconButton
          colorScheme="red"
          aria-label="Delete notification policy"
          icon={<Icon as={BsTrash2Fill} boxSize={6} />}
          size="md"
          onClick={() => setShowDeleteModal(true)}
        />
      </PageControls>
      <PageWrapper loading={!initialized || isLoading} px={4}>
        <Outlet context={{ onCallScheduleId }} />
      </PageWrapper>
      <DeleteConfirmationModal
        title="Delete on-call schedule"
        content="Are you sure you want to delete this on-call schedule?"
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
        loading={mutating}
      />
    </Box>
  );
};

export const OnCallScheduleOverviewOutlet = () => {
  const { onCallScheduleId } = useOutletContext<{ onCallScheduleId: string }>();

  return <OnCallScheduleOverview onCallScheduleId={onCallScheduleId} />;
};

export const OnCallScheduleSettingsOutlet = () => {
  const navigate = useNavigate();
  const { onCallScheduleId } = useOutletContext<{ onCallScheduleId: string }>();

  const onUpdated = () =>
    navigate(
      generatePath(Routes.ON_CALL_SCHEDULE_OVERVIEW, {
        id: onCallScheduleId,
      })
    );

  return <EditOnCallScheduleForm onCallScheduleId={onCallScheduleId} onUpdated={onUpdated} />;
};

export default OnCallSchedulePage;
