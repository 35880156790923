import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import React, { useMemo, useState } from 'react';
import { useGetOnCallScheduleCalendarEventsQuery, usePostOnCallScheduleCalendarEventsQuery } from '../../query/onCallScheduleQueries';
import { OnCallScheduleFormModel } from '../../models/onCallScheduleFormModel';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { PartialDeep } from 'type-fest';
import { OnCallScheduleCalendarEventsRequestVO, OnCallScheduleCalendarEventVO } from '@um/uptime-monitoring-shared';
import { format } from 'date-fns';
import { useOrganizationUsersQuery } from '../../query/userQueries';
import { combineUsersName } from '../../utils/combineUsersName';
import CalendarEventColors from '../../utils/calendarEventColors';
import { Box, Flex, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Portal, VStack } from '@chakra-ui/react';
import { EventContentArg } from '@fullcalendar/core';
import { formatInTimeZone } from 'date-fns-tz';

type FormOnCallScheduleCalendarProps = {
  formValues: PartialDeep<OnCallScheduleFormModel>;
};

type OnCallScheduleCalendarProps = {
  onCallScheduleId: string;
};

const FormOnCallScheduleCalendar: React.FC<FormOnCallScheduleCalendarProps> = ({ formValues }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [request, isRequestReady] = useMemo(() => {
    const users = (formValues.users || []).filter((user) => user.userId);
    const request = {
      users,
      startDate: formValues.startDate,
      timezone: formValues.timezone,
      scheduleRotation: formValues.scheduleRotation,
      dailySchedule: formValues.dailySchedule,
      organizationId,
    };
    const isRequestReady = Boolean(
      users.length > 0 &&
        users.some((user) => user.userId) &&
        request.startDate &&
        request.scheduleRotation &&
        (request.dailySchedule || []).length === 7 &&
        organizationId &&
        startAt &&
        endAt
    );
    return [request, isRequestReady];
  }, [formValues, startAt, endAt]);

  const { data } = usePostOnCallScheduleCalendarEventsQuery(startAt, endAt, request as OnCallScheduleCalendarEventsRequestVO, {
    enabled: isRequestReady,
  });
  const hash = data?.hash;
  const eventsData = data?.events || [];

  return <EventCalendar hash={hash} eventsData={eventsData} setStartAt={setStartAt} setEndAt={setEndAt} />;
};

export const OnCallScheduleCalendar: React.FC<OnCallScheduleCalendarProps> = ({ onCallScheduleId }) => {
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const isRequestReady = useMemo(() => Boolean(onCallScheduleId && startAt && endAt), [onCallScheduleId, startAt, endAt]);

  const { data } = useGetOnCallScheduleCalendarEventsQuery(startAt, endAt, onCallScheduleId, {
    enabled: isRequestReady,
  });
  const hash = data?.hash;
  const eventsData = data?.events || [];

  return <EventCalendar hash={hash} eventsData={eventsData} setStartAt={setStartAt} setEndAt={setEndAt} />;
};

type EventCalendarProps = {
  hash?: number;
  eventsData?: OnCallScheduleCalendarEventVO[];
  setStartAt: (date: string) => void;
  setEndAt: (date: string) => void;
};

const EventCalendar: React.FC<EventCalendarProps> = ({ hash, eventsData, setStartAt, setEndAt }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { data } = useOrganizationUsersQuery(organizationId);
  const { result: users } = data || {};

  const scheduleColors = useMemo(() => {
    const scheduleIds = Array.from(new Set((eventsData || []).map((event) => event.scheduleId)));
    const colorsLength = CalendarEventColors.length;

    return scheduleIds.reduce((agg, scheduleId, idx) => {
      return { ...agg, [scheduleId]: CalendarEventColors[idx % colorsLength] };
    }, {});
  }, [hash, eventsData]);
  const events = useMemo(() => {
    return (eventsData || []).map((eventItem) => {
      const user = users.find((userItem) => userItem.id === eventItem.userId);
      const title = combineUsersName(user);
      const color = scheduleColors[eventItem.scheduleId];
      const { startsAt, endsAt } = eventItem;

      return {
        start: eventItem.startsAt,
        end: eventItem.endsAt,
        title,
        display: 'block',
        backgroundColor: color,
        startsAt,
        endsAt,
      };
    });
  }, [hash, eventsData, users, scheduleColors]);

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      firstDay={1}
      events={events}
      timeZone="UTC"
      eventContent={(eventInfo) => <EventContent eventInfo={eventInfo} />}
      datesSet={(val) => {
        setStartAt(format(val.start, 'yyyy-MM-dd'));
        setEndAt(format(val.end, 'yyyy-MM-dd'));
      }}
    />
  );
};

const EventContent: React.FC<{ eventInfo: EventContentArg }> = ({ eventInfo }) => {
  const Row = ({ title, content }) => (
    <VStack alignItems="flex-start" spacing={0} width="100%">
      <Box color="gray.600" fontSize="sm">
        {title}
      </Box>
      <Box>{content}</Box>
    </VStack>
  );

  const { title, extendedProps } = eventInfo.event;

  return (
    <Popover>
      <PopoverTrigger>
        <Box>{eventInfo.event._def.title}</Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent color="black" fontSize="md">
          <PopoverHeader fontSize="lg" fontWeight="bold">
            On-call schedule
          </PopoverHeader>
          <PopoverBody>
            <VStack spacing={1}>
              <Row title="Name" content={title} />
              <Row title="Starts at" content={formatInTimeZone(extendedProps.startsAt, 'UTC', 'yyyy-MM-dd HH:mm')} />
              <Row title="Ends at" content={formatInTimeZone(extendedProps.endsAt, 'UTC', 'yyyy-MM-dd HH:mm')} />
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default FormOnCallScheduleCalendar;
