import { Box, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import ImpersonateUserForm from './impersonateUserForm';
import React from 'react';

const AdminPanel = () => {
  return (
    <Box display="flex" flexDirection="column">
      <ImpersonateUserForm />
    </Box>
  );
};

export const AdminPanelModal = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Administration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display="flex" alignItems="center" gap="4" mb="2">
            <Divider />
            <Text fontWeight="bold" fontSize="lg">
              Impersonation
            </Text>
            <Divider />
          </Box>
          <AdminPanel />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default AdminPanel;
