import DataCard from '../data-card/dataCard';
import AppLink from '../app-link/appLink';
import { generatePath } from 'react-router-dom';
import Routes from '../../utils/routes';
import React from 'react';
import { IntegrationType, NotificationHistoryResponse } from '@um/uptime-monitoring-shared';
import getNotificationHistoryReasonText from '../../utils/getNotificationHistoryReasonText';
import getNotificationHistoryStatusText from '../../utils/getNotificationHistoryStatusText';
import Spinner from '../spinner/spinner';
import { useNotificationHistoryQuery } from '../../query/notificationsQueries';

type NotificationDetailsProps = {
  notification?: NotificationHistoryResponse;
  notificationId?: string;
  onClose?: () => void;
};

const NotificationDetails: React.FC<NotificationDetailsProps> = ({ notification, notificationId, onClose }) => {
  const { isFetched, data: fetchedNotification } = useNotificationHistoryQuery(notificationId, { enabled: Boolean(!notification && notificationId) });
  const finalNotification = notification || fetchedNotification;
  const initialized = finalNotification || isFetched;

  if (!initialized) {
    return <Spinner hideLoading my={4} />;
  }

  const emailNotification = finalNotification.notificationIntegrationType === IntegrationType.EMAIL;
  const smsNotification = finalNotification.notificationIntegrationType === IntegrationType.SMS;

  return (
    <DataCard header={<DataCard.Header onClose={onClose}>Notification details</DataCard.Header>}>
      {finalNotification.monitorGroupId && (
        <DataCard.Row heading="Monitor group">
          <AppLink
            underline
            to={generatePath(Routes.MONITOR_GROUP_OVERVIEW, {
              id: finalNotification.monitorGroupId,
            })}
          >
            {finalNotification.monitorGroupName}
          </AppLink>
        </DataCard.Row>
      )}
      {finalNotification.monitorId && (
        <DataCard.Row heading="Monitor">
          <AppLink
            underline
            to={generatePath(Routes.MONITOR_DETAILS_OVERVIEW, {
              id: finalNotification.monitorId,
            })}
          >
            {finalNotification.monitorName}
          </AppLink>
        </DataCard.Row>
      )}
      {finalNotification.notificationIntegrationId && (
        <DataCard.Row heading="Notification integration">
          <AppLink
            underline
            to={generatePath(Routes.NOTIFICATION_INTEGRATION, {
              id: finalNotification.notificationIntegrationId,
            })}
          >
            {finalNotification.notificationIntegrationName}
          </AppLink>
        </DataCard.Row>
      )}
      <DataCard.Row heading="Notification reason">{getNotificationHistoryReasonText(finalNotification.reason)}</DataCard.Row>
      {emailNotification && <DataCard.Row heading="Recipient">{finalNotification?.metadata?.recipient || '-'}</DataCard.Row>}
      {smsNotification && <DataCard.Row heading="Recipient">{finalNotification?.metadata?.phoneNumber || '-'}</DataCard.Row>}
      {smsNotification && <DataCard.Row heading="Message sent">{finalNotification?.metadata?.message || '-'}</DataCard.Row>}
      <DataCard.Row heading="Status">{getNotificationHistoryStatusText(finalNotification.status)}</DataCard.Row>
    </DataCard>
  );
};

export default NotificationDetails;
