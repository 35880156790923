import { NotificationChannelAssignment, NotificationChannelOptionVO } from '@um/uptime-monitoring-shared';

export const notificationChannelOptionToReference = ({
  notificationPolicyId,
  notificationIntegrationId,
  onCallScheduleId,
}: NotificationChannelOptionVO | NotificationChannelAssignment) => ({
  notificationPolicyId,
  notificationIntegrationId,
  onCallScheduleId,
});
