import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

type Props = BoxProps & {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'blue' | 'red';
};

const ClickableText: React.FC<Props> = ({ children, color = 'blue', size = 'sm', ...rest }) => {
  return (
    <Box display="inline-block" textDecoration="underline" color={`${color}.400`} cursor="pointer" fontSize={size} {...rest}>
      {children}
    </Box>
  );
};

export default ClickableText;
