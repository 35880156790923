"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnqueueNotificationPolicyStatesMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class EnqueueNotificationPolicyStatesMessage extends workerMessage_1.WorkerMessage {
    constructor() {
        super(model_1.QueueDefinitions.ENQUEUE_NOTIFICATION_POLICY_STATES);
    }
}
exports.EnqueueNotificationPolicyStatesMessage = EnqueueNotificationPolicyStatesMessage;
