import Routes from '../../utils/routes';
import React from 'react';
import { IntegrationResponse } from '@um/uptime-monitoring-shared';
import { generatePath, Link } from 'react-router-dom';
import Card from '../card/card';
import { Box } from '@chakra-ui/react';

type Props = { integration: IntegrationResponse };

const NotificationIntegrationListItem: React.FC<Props> = ({ integration, children }) => {
  return (
    <Box as={Link} to={generatePath(Routes.NOTIFICATION_INTEGRATION, { id: integration.id })} color="black" _hover={{ color: 'black' }}>
      <Card height="100%" justifyContent="center">
        {children}
      </Card>
    </Box>
  );
};

export type IntegrationListSegmentProps = {
  integration: IntegrationResponse;
  onToggleIntegration?: (evt, id: string, enabled: boolean) => void;
};

export default NotificationIntegrationListItem;
