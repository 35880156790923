import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAppSelector } from '../../app/store';
import CreateStatusPageForm from '../../components/create-status-page-form/createStatusPageForm';

const CreateStatusPagePage = () => {
  const { initialized } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  return (
    <Box w="100%">
      <PageHeader
        title="Create status page"
        breadcrumbs={[
          {
            title: 'Status pages',
            route: Routes.STATUS_PAGES,
          },
        ]}
      />
      <PageWrapper loading={!initialized} px={4}>
        {initialized && <CreateStatusPageForm onCreated={() => navigate(Routes.STATUS_PAGES)} />}
      </PageWrapper>
    </Box>
  );
};

export default CreateStatusPagePage;
