import { Box, Button, Flex, HStack, PinInput, PinInputField, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { IntegrationResponse } from '@um/uptime-monitoring-shared';
import statusPingClient from '../../app/client/statusPingClient';
import { logError } from '../../utils/logError';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

type Props = {
  integration: IntegrationResponse;
  onVerified?: (integration: IntegrationResponse) => void;
};

const VerifySmsNotificationIntegrationForm: React.FC<Props> = ({ integration, onVerified }) => {
  const [inProgress, setInProgress] = useState(false);
  const [token, setToken] = useState('');

  const verify = async () => {
    try {
      setInProgress(true);

      const response = await statusPingClient.protectedPostVerifyNotificationIntegration(integration.id, token);

      onVerified && onVerified(response);
    } catch (e: AxiosError | any) {
      const message = e?.response?.data?.message || 'Error verifying phone number. Please try again later';
      toast.error(message);
      setToken('');
      setInProgress(false);
      logError(e);
    }
  };
  const disabled = token.length !== 6 || inProgress;

  return (
    <Flex flexDirection="column" gap={4} alignItems="center">
      <Text fontSize="lg" textAlign="center">
        We have sent a verification code to <Text fontWeight="bold">{integration?.smsOptions?.phoneNumber}</Text>. Enter the code below to verify your
        phone number.
      </Text>
      <Box>
        <HStack>
          <PinInput isDisabled={inProgress} value={token} onChange={setToken}>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
      </Box>
      <Button colorScheme="blue" onClick={verify} isDisabled={disabled} isLoading={inProgress}>
        Verify
      </Button>
    </Flex>
  );
};

export default VerifySmsNotificationIntegrationForm;
