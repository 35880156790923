import { MutationOptions, useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { CreateStatusPageVO, OrganizationUserResponse, UserResponse } from '@um/uptime-monitoring-shared';
import { MutationAction } from './mutationAction';
import { UserProfileFormModel } from '../components/user-profile-form/userProfileForm';
import mutationDefaults from './mutationDefaults';
import { QueryResultWithHash, toQueryResultWithHash } from '../models/queryResultWithHash';

export const useCronExpressionQuery = (expression: string, timezone: string, options: UseQueryOptions<QueryResultWithHash<string[]>> = {}) => {
  return useQuery(['cron', expression, timezone], () => toQueryResultWithHash(statusPingClient.protectedGetCron(expression, timezone)), {
    ...options,
    cacheTime: 600000,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    enabled: Boolean(expression) && options.enabled !== false,
    retry: false,
  });
};
