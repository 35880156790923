import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponse, IntegrationResponse, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';

type SliceType = {
  initialized: boolean;
  monitor: ProtectedMonitorDetailsResponse;
  notificationIntegrations: IntegrationResponse[];
  updated: boolean;
  inProgress: boolean;
  error: ErrorResponse;
};

const initialState: SliceType = {
  initialized: false,
  monitor: null,
  notificationIntegrations: [],
  updated: false,
  inProgress: false,
  error: null,
};

export const editMonitor = createSlice({
  name: 'editMonitor',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      return state;
    },
    setMonitor: (state, action: PayloadAction<ProtectedMonitorDetailsResponse>) => {
      state.monitor = action.payload;
      return state;
    },
    setNotificationIntegrations: (state, action: PayloadAction<IntegrationResponse[]>) => {
      state.notificationIntegrations = action.payload;
      return state;
    },
    setUpdated: (state, action: PayloadAction<boolean>) => {
      state.updated = action.payload;
      return state;
    },
    setInProgress: (state, action: PayloadAction<boolean>) => {
      state.inProgress = action.payload;
      return state;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      return state;
    },
    reset: () => {
      return initialState;
    },
  },
});

export default editMonitor.reducer;
