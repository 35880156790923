"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericTaskMessageType = exports.GenericTaskMessage = void 0;
const workerMessage_1 = require("../workerMessage");
const model_1 = require("../../model");
class GenericTaskMessage extends workerMessage_1.WorkerMessage {
    constructor(genericTaskMessageType) {
        super(model_1.QueueDefinitions.GENERIC_TASK_MESSAGE, genericTaskMessageType);
    }
}
exports.GenericTaskMessage = GenericTaskMessage;
var GenericTaskMessageType;
(function (GenericTaskMessageType) {
    GenericTaskMessageType["ADD_USER_TO_MAILING_LIST"] = "ADD_USER_TO_MAILING_LIST";
    GenericTaskMessageType["CLEANUP_OLD_MONITOR_CHECKS"] = "CLEANUP_OLD_MONITOR_CHECKS";
    GenericTaskMessageType["BACKUP_DATABASE"] = "BACKUP_DATABASE";
    GenericTaskMessageType["SEND_PRODUCT_NEWSLETTER_MESSAGE"] = "SEND_PRODUCT_NEWSLETTER_MESSAGE";
    GenericTaskMessageType["EXPIRE_OVERDUE_TRIALS"] = "EXPIRE_OVERDUE_TRIALS";
    GenericTaskMessageType["DELETE_OLD_NOT_ACTIVATED_USERS"] = "DELETE_OLD_NOT_ACTIVATED_USERS";
})(GenericTaskMessageType = exports.GenericTaskMessageType || (exports.GenericTaskMessageType = {}));
