"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDeletedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class UserDeletedMessage extends workerMessage_1.WorkerMessage {
    constructor(user) {
        super(model_1.QueueDefinitions.USER_DELETED);
        this.user = user;
    }
}
exports.UserDeletedMessage = UserDeletedMessage;
