import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Link as ChakraLink,
  Table,
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
} from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { TableColumnHeaderProps } from '@chakra-ui/table';
import Spinner from '../spinner/spinner';
import { Link, LinkProps } from 'react-router-dom';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { DataTableContext, DataTableContextState } from './dataTableContext';
import { SetOptional } from 'type-fest';
import { DataTableRowContext } from './dataTableRowContext';

type CellProps = TableCellProps & {
  tooltip?: string | React.ReactNode;
  noHover?: boolean;
};

type BodyProps = TableBodyProps & {
  showLoading?: boolean;
};

type RowProps = TableRowProps & {
  itemId?: string;
};

const Body: React.FC<BodyProps> = ({ showLoading, children, ...rest }) => (
  <Tbody position="relative" {...rest}>
    <Box position="absolute" width="100%" height="100%" backgroundColor="rgba(74,85,104,0.1)" display={showLoading ? 'block' : 'none'}>
      <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
        <Spinner size="lg" hideLoading />
      </Flex>
    </Box>
    {children}
  </Tbody>
);
const Head: React.FC<TableHeadProps> = ({ children, ...rest }) => <Thead {...rest}>{children}</Thead>;
const Row = Tr;
const HeaderCell: React.FC<TableColumnHeaderProps> = ({ children, ...rest }) => (
  <Th textAlign="center" {...rest}>
    {children}
  </Th>
);

const HeaderRow: React.FC<RowProps> = ({ children, ...rest }) => {
  const { allowSelection, onToggleAllItems, allItemsSelected, selectedItems } = useContext(DataTableContext);
  const handleCheckboxClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleAllItems && onToggleAllItems();
  };
  const { indeterminate, checked } = useMemo(() => {
    return {
      indeterminate: allowSelection && (selectedItems || []).length > 0 && !allItemsSelected,
      checked: allowSelection && allItemsSelected,
    };
  }, [allowSelection, selectedItems, allItemsSelected]);

  return (
    <Tr {...rest}>
      {allowSelection && (
        <HeaderCell cursor="pointer" onClick={handleCheckboxClick}>
          <Checkbox colorScheme="blue" size="lg" isChecked={checked} isIndeterminate={indeterminate} />
        </HeaderCell>
      )}
      {children}
    </Tr>
  );
};

const LinkRow: React.FC<RowProps & SetOptional<LinkProps, 'to'>> = ({ itemId, to, children, onClick, ...rest }) => {
  const { allowSelection, onToggleItem, selectedItems } = useContext(DataTableContext);
  const handleCheckboxClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggleItem && onToggleItem(itemId);
  };
  const checked = useMemo(() => {
    return allowSelection && (selectedItems || []).includes(itemId);
  }, [allowSelection, selectedItems]);
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }
  };
  const [hovering, setHovering] = useBoolean();

  return (
    <DataTableRowContext.Provider value={{ hovering, on: setHovering.on, off: setHovering.off }}>
      <ChakraLink
        as={Link}
        to={to}
        display="table-row"
        cursor="pointer"
        background={hovering ? 'gray.50' : 'inherit'}
        textDecoration="none !important"
        position="relative"
        onClick={handleClick}
        pr={3}
        {...rest}
      >
        {allowSelection && (
          <DataTable.Cell tooltip={null} onClick={handleCheckboxClick}>
            <Checkbox colorScheme="blue" size="lg" isChecked={checked} />
          </DataTable.Cell>
        )}
        {children}
      </ChakraLink>
    </DataTableRowContext.Provider>
  );
};
const Cell: React.FC<CellProps> = ({
  overflow = 'hidden',
  children,
  tooltip = children,
  maxWidth = '160px',
  alignItems = 'center',
  justifyContent = 'center',
  noHover = false,
  ...rest
}) => {
  const { on, off } = useContext(DataTableRowContext);
  const finalTooltip = typeof tooltip !== 'undefined' ? tooltip : children;

  return (
    <Td verticalAlign="middle" onMouseEnter={noHover ? off : on} onMouseLeave={off} {...rest}>
      <Flex alignItems={alignItems} justifyContent={justifyContent} whiteSpace="nowrap">
        <Box flexGrow={1} minWidth="0px" width="100%" textAlign="center" maxWidth={maxWidth}>
          {finalTooltip ? (
            <Tooltip label={<Box>{finalTooltip}</Box>}>
              <Box maxWidth={maxWidth} overflow={overflow} textOverflow="ellipsis">
                {children}
              </Box>
            </Tooltip>
          ) : (
            <Box maxWidth={maxWidth} overflow={overflow} textOverflow="ellipsis">
              {children}
            </Box>
          )}
        </Box>
      </Flex>
    </Td>
  );
};

export const PaginationRow = ({ disabled = false, hasPreviousPage, hasNextPage, loading, onPreviousPageClick, onNextPageClick }) => {
  return (
    <Flex justifyContent={'space-between'} alignItems="center" h={16} width="100%" px={4}>
      <div>
        {hasPreviousPage && (
          <Button
            variant="ghost"
            colorScheme="gray"
            disabled={loading || disabled}
            onClick={onPreviousPageClick}
            leftIcon={<Icon as={BsArrowLeft} boxSize={4} />}
          >
            Previous page
          </Button>
        )}
      </div>
      {loading && <Spinner size="md" hideLoading />}
      <div>
        {hasNextPage && (
          <Button variant="ghost" onClick={onNextPageClick} disabled={loading || disabled} rightIcon={<Icon as={BsArrowRight} boxSize={4} />}>
            Next page
          </Button>
        )}
      </div>
    </Flex>
  );
};

export type DataTableProps = Omit<TableProps, 'children'> &
  DataTableContextState & {
    showLoading?: boolean;
    showPagination?: boolean;
    hasPreviousPage?: boolean;
    hasNextPage?: boolean;
    pageLoading?: boolean;
    paginationDisabled?: boolean;
    onPreviousPageClick?: () => void;
    onNextPageClick?: () => void;
    columnsCount?: number;
    children: () => React.ReactNode;
  };

type DataTableType = React.FC<DataTableProps> & {
  Body: typeof Body;
  Head: typeof Head;
  Row: typeof Row;
  HeaderRow: typeof HeaderRow;
  Cell: typeof Cell;
  HeaderCell: typeof HeaderCell;
  PaginationRow: typeof PaginationRow;
  LinkRow: typeof LinkRow;
};

const DataTable: DataTableType = ({
  showLoading,
  children,
  showPagination,
  hasPreviousPage,
  hasNextPage,
  pageLoading,
  onPreviousPageClick,
  onNextPageClick,
  paginationDisabled,
  allowSelection,
  onToggleItem,
  onToggleAllItems,
  selectedItems,
  allItemsSelected,
  ...rest
}) => {
  return (
    <DataTableContext.Provider value={{ allowSelection, onToggleItem, onToggleAllItems, selectedItems, allItemsSelected }}>
      <Box overflow="auto" background="white" position="relative" borderRadius="md" boxShadow="md">
        <Table background="white" whiteSpace="nowrap" {...rest}>
          {showLoading && (
            <DataTable.Body>
              <DataTable.Row>
                <DataTable.Cell tooltip={null}>
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Spinner />
                  </Flex>
                </DataTable.Cell>
              </DataTable.Row>
            </DataTable.Body>
          )}
          {!showLoading && !!children && children()}
        </Table>
        {!showLoading && showPagination && (hasNextPage || hasPreviousPage) && (
          <Box h={16} position="sticky" left="0">
            <PaginationRow
              loading={pageLoading}
              disabled={paginationDisabled}
              hasPreviousPage={hasPreviousPage}
              hasNextPage={hasNextPage}
              onPreviousPageClick={onPreviousPageClick}
              onNextPageClick={onNextPageClick}
            />
          </Box>
        )}
      </Box>
    </DataTableContext.Provider>
  );
};

DataTable.Body = Body;
DataTable.Head = Head;
DataTable.Row = Row;
DataTable.Cell = Cell;
DataTable.HeaderCell = HeaderCell;
DataTable.PaginationRow = PaginationRow;
DataTable.LinkRow = LinkRow;
DataTable.HeaderRow = HeaderRow;

export default DataTable;
