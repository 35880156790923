import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';

const selectSelf = (state: RootState) => state;
const selectedOrganizationId = createSelector(selectSelf, (state) => state.app.selectedOrganization?.id);
const selectedOrganization = createSelector(selectSelf, (state) => state.app.selectedOrganization);
const organizations = createSelector(selectSelf, (state) => state.app.organizations);
const selectFeatures = createSelector(selectedOrganization, (organization) => organization?.features);
const selectIsAdmin = createSelector(selectSelf, (state) => state.app.user?.admin || state.app.user?.impersonated || false);
const selectIsImpersonated = createSelector(selectSelf, (state) => state.app.user?.impersonated || false);
const selectUser = createSelector(selectSelf, (state) => state.app.user);
const activeSubscription = createSelector(selectSelf, (state) => state.app.activeSubscription);

const selectors = {
  selectIsAdmin,
  selectedOrganizationId,
  selectedOrganization,
  selectIsImpersonated,
  selectUser,
  selectFeatures,
  organizations,
  activeSubscription,
};

export default selectors;
