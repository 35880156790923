import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAppSelector } from '../../app/store';
import CreateStatusPageForm from '../../components/create-status-page-form/createStatusPageForm';
import { useStatusPageQuery } from '../../query/statusPageQueries';
import CreateStatusPageIncidentForm from '../../components/create-status-page-incident-form/createStatusPageIncidentForm';

const CreateStatusPageIncidentPage = () => {
  const { initialized } = useAppSelector((state) => state.app);

  const params = useParams();
  const navigate = useNavigate();

  const { statusPageId } = params;
  const { data: statusPage, isFetched: statusPageFetched } = useStatusPageQuery(statusPageId);

  return (
    <Box w="100%">
      <PageHeader
        title="Create"
        breadcrumbs={[
          { title: 'Status pages', route: Routes.STATUS_PAGES },
          {
            title: statusPage?.name,
            route: generatePath(Routes.STATUS_PAGE, {
              id: statusPageId,
            }),
          },
          {
            title: 'Incidents',
            route: generatePath(Routes.STATUS_PAGE_INCIDENTS, {
              id: statusPageId,
            }),
          },
        ]}
      />
      <PageWrapper loading={!initialized || !statusPageFetched} px={4}>
        {initialized && (
          <CreateStatusPageIncidentForm
            onCreated={() =>
              navigate(
                generatePath(Routes.STATUS_PAGE_INCIDENTS, {
                  id: statusPageId,
                })
              )
            }
            statusPageId={statusPageId}
          />
        )}
      </PageWrapper>
    </Box>
  );
};

export default CreateStatusPageIncidentPage;
