import { useFormContext } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React, { useEffect } from 'react';
import formValidationRules from '../../utils/formValidationRules';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';

const { required } = formValidationRules;

const EmailOptionsFormFields = ({ disabled }) => {
  const { control, unregister } = useFormContext();

  const recipientFieldPath = `emailOptions.recipient`;

  useEffect(() => {
    return () => {
      unregister(recipientFieldPath);
    };
  }, []);

  return (
    <FormFieldWrapper title="Recipient email address" description="We will send a confirmation email to this address">
      <ControlledInput isDisabled={disabled} name={recipientFieldPath} placeholder="Recipient email address" control={control} rules={{ required }} />
    </FormFieldWrapper>
  );
};

export default EmailOptionsFormFields;
