import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import Routes from '../../utils/routes';
import PageHeader from '../../components/page-header/pageHeader';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import CreateNotificationPolicyForm from '../../components/create-notification-policy-form/createNotificationPolicyForm';

const CreateNotificationPolicyPage = () => {
  const navigate = useNavigate();

  const onCreated = () => {
    navigate(Routes.NOTIFICATIONS_POLICIES);
  };

  const breadcrumbs = [
    { title: 'Settings', route: Routes.SETTINGS },
    {
      title: 'Notification policies',
      route: Routes.NOTIFICATIONS_POLICIES,
    },
  ];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader title="Create" breadcrumbs={breadcrumbs} />
      <PageWrapper px={4}>
        <CreateNotificationPolicyForm onCreated={onCreated} />
      </PageWrapper>
    </Flex>
  );
};

export default CreateNotificationPolicyPage;
