import React from 'react';
import * as ReactDOM from 'react-dom/client';
import MyApp from './app';
import './styles/globals.css';
import { bugsnagClient } from './utils/logError';
import ReactGA from 'react-ga';
import { AppConfig } from './utils/config';
import 'focus-visible/dist/focus-visible';

if (AppConfig.GoogleAnalyticsId) {
  ReactGA.initialize(AppConfig.GoogleAnalyticsId);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

if (bugsnagClient) {
  const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <MyApp />
      </ErrorBoundary>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <MyApp />
    </React.StrictMode>
  );
}
