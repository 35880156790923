import { NotificationReason } from '@um/uptime-monitoring-shared';

const getNotificationHistoryReasonText = (reason: NotificationReason) => {
  if (reason === NotificationReason.MONITOR_DOWN) {
    return 'Monitor down';
  } else if (reason === NotificationReason.MONITOR_UP) {
    return 'Monitor healthy';
  } else if (reason === NotificationReason.MONITOR_GROUP_DOWN) {
    return 'Monitor group down';
  } else if (reason === NotificationReason.MONITOR_GROUP_UP) {
    return 'Monitor group healthy';
  } else if (reason === NotificationReason.SSL_CERTIFICATE_EXPIRATION) {
    return 'SSL certificate expiration';
  } else if (reason === NotificationReason.DOMAIN_NAME_EXPIRATION) {
    return 'Domain name expiration';
  }

  return reason;
};

export default getNotificationHistoryReasonText;
