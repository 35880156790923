import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../error-fallback/errorFallback';
import ReactGA from 'react-ga4';
import { AppConfig } from '../../utils/config';
import WithSubnavigation from '../navbar/navbar';
import { Box } from '@chakra-ui/react';
import { DesktopSidebar } from '../sidebar/sidebar';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import Spinner from '../spinner/spinner';
import trackEvent from '../../utils/trackEvent';

interface Props {
  active: boolean;
  hideSidebar?: boolean;
  children: React.ReactNode;
}

const Layout = ({ active = true, hideSidebar = false, children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    if (AppConfig.GoogleAnalyticsId) {
      ReactGA.initialize(AppConfig.GoogleAnalyticsId);
      ReactGA.send('pageview');
    }
  }, []);

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send('pageview');
    }

    trackEvent('Page Viewed', {
      pathname: location.pathname,
    });
  }, [location?.pathname]);

  if (!active) {
    return <>{children}</>;
  }

  return (
    <Box>
      <WithSubnavigation />
      <Box display="flex" justifyContent="center">
        <Box display="flex" justifyContent="center" width="100%" position="relative" maxW="container.xl">
          <DesktopSidebar hidden={hideSidebar} />
          <Box flexGrow={1} minWidth="0px" width="100%" mb={20}>
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <ErrorBoundary onReset={reset} FallbackComponent={ErrorFallback}>
                  <Suspense fallback={<Spinner mt={6} />}>{children}</Suspense>
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
