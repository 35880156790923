import React from 'react';
import { Control, Controller, ControllerProps, ControllerRenderProps } from 'react-hook-form';
import { prepareRules } from '../../utils/formValidationRules';
import Slider from 'rc-slider';
import FormFieldLabel from '../form-field-label/formFieldLabel';
import { Box, Flex, InputGroup, InputProps, InputRightAddon, Tooltip } from '@chakra-ui/react';
import Input from '../input/input';

type Props = {
  label?: string;
  error?: string;
} & ControllerRenderProps<any> &
  Omit<InputProps, 'error' | 'onChange'>;

type ControlledProps = { control: Control<any> } & Omit<Partial<ControllerProps>, 'control'>;

const marks = {
  1000: { label: '1s' },
  10000: { label: '10s' },
  30000: { label: '30s' },
};

const TimeoutSelector: React.FC<Props> = ({ placeholder, className, label, error, children, onChange, value }) => {
  return (
    <div className={className}>
      <FormFieldLabel>{label}</FormFieldLabel>
      <Flex gap={4}>
        <Slider min={100} marks={marks} step={100} included defaultValue={1000} max={30000} value={value} onChange={(value) => onChange(value)} />
        <Box maxW="170px">
          <InputGroup size="md">
            <Input placeholder={placeholder} error={error} onChange={onChange} type="number" value={value} />
            <InputRightAddon>
              <Tooltip label="milliseconds">ms</Tooltip>
            </InputRightAddon>
          </InputGroup>
        </Box>
      </Flex>
      {children}
    </div>
  );
};

TimeoutSelector.displayName = 'TimeoutSelector';

export const ControlledTimeoutSelector = ({ control, name, rules, ...rest }: ControlledProps) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => (
        <TimeoutSelector error={error?.message} onChange={(value) => field.onChange(value)} {...rest} {...field} />
      )}
    />
  );
};

ControlledTimeoutSelector.displayName = 'ControlledTimeoutSelector';

export default TimeoutSelector;
