"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaddleSubscriptionStatus = exports.PaddleSubscriptionEventName = exports.PaddleSubscriptionEventStatus = void 0;
var PaddleSubscriptionEventStatus;
(function (PaddleSubscriptionEventStatus) {
    PaddleSubscriptionEventStatus["CREATED"] = "CREATED";
    PaddleSubscriptionEventStatus["PROCESSED"] = "PROCESSED";
})(PaddleSubscriptionEventStatus = exports.PaddleSubscriptionEventStatus || (exports.PaddleSubscriptionEventStatus = {}));
var PaddleSubscriptionEventName;
(function (PaddleSubscriptionEventName) {
    PaddleSubscriptionEventName["SUBSCRIPTION_CREATED"] = "subscription_created";
    PaddleSubscriptionEventName["SUBSCRIPTION_UPDATED"] = "subscription_updated";
    PaddleSubscriptionEventName["SUBSCRIPTION_CANCELLED"] = "subscription_cancelled";
    PaddleSubscriptionEventName["SUBSCRIPTION_PAYMENT_SUCCEEDED"] = "subscription_payment_succeeded";
    PaddleSubscriptionEventName["SUBSCRIPTION_PAYMENT_FAILED"] = "subscription_payment_failed";
    PaddleSubscriptionEventName["SUBSCRIPTION_PAYMENT_REFUNDED"] = "subscription_payment_refunded";
})(PaddleSubscriptionEventName = exports.PaddleSubscriptionEventName || (exports.PaddleSubscriptionEventName = {}));
var PaddleSubscriptionStatus;
(function (PaddleSubscriptionStatus) {
    PaddleSubscriptionStatus["ACTIVE"] = "active";
    PaddleSubscriptionStatus["TRIALING"] = "trialing";
    PaddleSubscriptionStatus["PAST_DUE"] = "past_due";
    PaddleSubscriptionStatus["PAUSED"] = "paused";
    PaddleSubscriptionStatus["DELETED"] = "deleted";
})(PaddleSubscriptionStatus = exports.PaddleSubscriptionStatus || (exports.PaddleSubscriptionStatus = {}));
