import { Button, ButtonProps } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Plan } from '@um/uptime-monitoring-shared';
import statusPingClient from '../../app/client/statusPingClient';
import { toast } from 'react-hot-toast';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';

type Props = ButtonProps & {
  plan: Plan;
};

const UpgradeNowButton: React.FC<Props> = ({ children, plan, ...rest }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const [inProgress, setInProgress] = useState(false);

  const openCheckout = async (plan: Plan) => {
    try {
      setInProgress(true);

      const checkoutSession = await statusPingClient.protectedGetSubscriptionCheckoutSession(organizationId, plan);

      if (!checkoutSession || !checkoutSession.checkoutSessionUrl) {
        toast.error('Error occurred opening checkout page. Please try again later');
        return;
      }

      window.location.href = checkoutSession.checkoutSessionUrl;
    } finally {
      setInProgress(false);
    }
  };

  return (
    <Button colorScheme="darkGreen" onClick={() => openCheckout(plan)} isLoading={inProgress} isDisabled={inProgress} {...rest}>
      {children ? children : 'Upgrade now'}
    </Button>
  );
};

export default UpgradeNowButton;
