import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import Cookies from 'js-cookie';
import Routes from '../../../utils/routes';
import { RootState } from '../../store';
import { bugsnagClient, logError } from '../../../utils/logError';
import * as amplitude from '@amplitude/analytics-browser';
import { AppConfig } from '../../../utils/config';

export const SelectedOrganizationIdCookie = 'selectedOrganizationId';

function* initialize() {
  try {
    const initializing = yield select((state: RootState) => state.app.initializing);

    if (initializing) {
      return;
    }

    yield put(actions.app.setInitializing(true));

    let initialized = false;

    do {
      try {
        yield put(actions.app.setInitializationError(false));

        yield call(initializeInternal);

        initialized = true;
      } catch (e) {
        yield put(actions.app.setInitializationError(true));
        yield delay(5000);

        console.log(e);
      }
    } while (!initialized);
  } catch (error) {
    logError(error);
  } finally {
    yield put(actions.app.setInitializing(false));
  }
}

function* initializeInternal() {
  yield put(actions.app.setInitialized(false));

  const user = yield call(statusPingClient.protectedGetUser);

  // if (!user) {
  //   if (typeof window !== 'undefined' && !window.location.pathname.startsWith(Routes.LOGIN)) {
  //     window.location.href = Routes.LOGIN;
  //     return;
  //   }
  // }

  // const organizationId = yield call(
  //   // @ts-ignore
  //   Cookies.get,
  //   SelectedOrganizationIdCookie
  // );
  // const organizations: OrganizationResponse[] = yield call(statusPingClient.protectedGetUserOrganizations);
  // const organization = organizations.find((organization) => organization.id === organizationId) || organizations[0];

  if (bugsnagClient) {
    bugsnagClient.addOnError((event) => {
      if (user) {
        event.setUser(user.id, user.email, user.name);
      }
    });
  }

  if (typeof window !== 'undefined') {
    if (window.$crisp && user && !user.impersonated) {
      const name = `${user.firstName || ''} ${user.lastName || ''}`.trim();

      window.$crisp.push(['set', 'user:email', user.email]);
      window.$crisp.push(['set', 'user:nickname', [name]]);
      window.$crisp.push(['set', 'session:data', ['user_id', user.id]]);
    }
  }

  yield put(actions.app.setUser(user));
  yield put(actions.app.setInitialized(true));
}

function* selectOrganization(action) {
  const organizationId = action.payload;
  const organizations = yield select((state: RootState) => state.app.organizations);
  const organization = organizations.find((organization) => organization.id === organizationId);

  yield put(actions.app.setSelectedOrganization(organization));
  yield call(initialize);
}

function* onSelectedOrganization(action) {
  try {
    const organization = action.payload;

    if (organization) {
      yield call(Cookies.set, SelectedOrganizationIdCookie, organization.id);
      const { activeSubscription } = yield call(statusPingClient.protectedGetUserOrganizationSubscriptions, organization.id);

      yield put(actions.app.setActiveSubscription(activeSubscription));
    } else {
      yield put(actions.app.setActiveSubscription(null));
    }

    yield put(actions.app.setSubscriptionInitialized(true));
  } catch (e) {
    logError(e);
  }
}

function onLoggedIn() {
  try {
    window.location.href = Routes.HOME;
  } catch (e) {
    logError(e);
  }
}

function* onInitialized() {
  try {
    const { initialized, user } = yield select((state: RootState) => state.app);

    if (initialized && user) {
      yield put(actions.websocket.initialize());
    }
  } catch (e) {
    logError(e);
  }
}

export default function* appSaga() {
  yield all([
    takeEvery(actions.app.initialize.type, initialize),
    takeEvery(actions.app.loggedIn.type, onLoggedIn),
    takeEvery(actions.app.setInitialized.type, onInitialized),
    takeEvery(actions.app.setUser.type, onInitialized),
    takeEvery(actions.app.setSelectedOrganization.type, onSelectedOrganization),
    takeEvery(actions.app.selectOrganization.type, selectOrganization),
  ]);
}
