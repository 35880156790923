import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

export const websiteTheme = extendTheme({
  sizes: {
    container: {
      xxs: '320px',
      xs: '480px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
  },
  breakpoints: createBreakpoints({
    xs: '480px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  }),
  colors: {
    darkGreen: {
      50: '#9AE6B4',
      100: '#68D391',
      200: '#48BB78',
      300: '#38A169',
      400: '#2F855A',
      500: '#276749',
      600: '#22543D',
      700: '#1C4532',
      800: '#1C4532',
      900: '#1C4532',
    },
    gray: {
      50: '#F7FAFC',
      100: '#F2F6F9',
      125: '#EDF1F6',
      150: '#E7EDF3',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
  },
});
