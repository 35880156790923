import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import ControlledCheckbox from '../checkbox/checkbox';

const UserNotificationSettingsFields = () => {
  return (
    <>
      <Flex flexDirection="column" gap={2}>
        <Flex flexDirection="column">
          <ControlledCheckbox name="subscribedToProductNewsletter">Product newsletter</ControlledCheckbox>
          <Box color="gray.500">Notifications of new Status Ping features and updates</Box>
        </Flex>

        <Flex flexDirection="column">
          <ControlledCheckbox name="subscribedToWeeklyMonitorReport">Weekly monitor report</ControlledCheckbox>
          <Box color="gray.500">Weekly email notification with statistics of your monitors</Box>
        </Flex>
      </Flex>
    </>
  );
};

export default UserNotificationSettingsFields;
