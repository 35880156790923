import { createAction } from '@reduxjs/toolkit';
import { notificationsOverviewTab } from '../reducer/notificationsOverviewTab';

const initialize = createAction('notificationsOverviewTab/initialize');

const notificationsOverviewTabActions = {
  ...notificationsOverviewTab.actions,
  initialize,
};

export default notificationsOverviewTabActions;
