import React, { useRef } from 'react';
import Routes from '../../utils/routes';
import { generatePath } from 'react-router-dom';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import DataTable from '../data-table/dataTable';
import { useOrganizationNotificationPoliciesQuery } from '../../query/notificationPolicyQueries';

const NotificationPolicyTable = ({ organizationId }) => {
  const tableRef = useRef();
  const {
    data: notificationPolicies,
    isLoading,
    isFetching,
    isInitialLoading,
    isRefetching,
    isFetched,
  } = useOrganizationNotificationPoliciesQuery(organizationId);
  const showLoading = (isFetching || isRefetching) && !isFetched;

  const noPolicies = notificationPolicies?.length < 1;

  return (
    <div ref={tableRef}>
      <DataTable showLoading={isInitialLoading} showPagination={false} columnsCount={7} paginationDisabled>
        {() => (
          <>
            {!noPolicies && (
              <>
                <DataTable.Head>
                  <DataTable.HeaderRow>
                    <DataTable.HeaderCell>Name</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Description</DataTable.HeaderCell>
                    <DataTable.HeaderCell />
                  </DataTable.HeaderRow>
                </DataTable.Head>
                <DataTable.Body showLoading={showLoading}>
                  {!isLoading &&
                    notificationPolicies.map((item) => (
                      <DataTable.LinkRow key={item.id} to={generatePath(Routes.NOTIFICATIONS_POLICY_DETAILS, { id: item.id })}>
                        <DataTable.Cell>{item.name}</DataTable.Cell>
                        <DataTable.Cell>{item.description}</DataTable.Cell>
                        <DataTable.Cell tooltip={null} maxWidth="30px" justifyContent="flex-end">
                          <Icon as={BsChevronRight} />
                        </DataTable.Cell>
                      </DataTable.LinkRow>
                    ))}
                </DataTable.Body>
              </>
            )}
            {noPolicies && (
              <DataTable.Body>
                <DataTable.Row>
                  <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                    <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                      <Box>Once there are any notification policies they will be displayed here.</Box>
                    </Flex>
                  </DataTable.Cell>
                </DataTable.Row>
              </DataTable.Body>
            )}
          </>
        )}
      </DataTable>
    </div>
  );
};

export default NotificationPolicyTable;
