import React, { useMemo, useRef } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Box, Button, Flex, Icon, Link as ChakraLink, VStack } from '@chakra-ui/react';
import { useOrganizationStatusPagesQuery } from '../../query/queries';
import Routes from '../../utils/routes';
import { StatusPage } from '@um/uptime-monitoring-shared';
import { BsChevronRight } from 'react-icons/bs';
import StatusPageStatusIcon from '../status-page-status-icon/statusPageStatusIcon';
import { AppConfig } from '../../utils/config';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import AppLink from '../app-link/appLink';
import DataTable from '../data-table/dataTable';

interface Props {
  organizationId?: string;
}

type StatusPageRowProps = {
  statusPage: StatusPage;
};

const StatusPagesTable: React.FC<Props> = ({ organizationId }) => {
  const tableRef = useRef();
  const { isFetched, data: statusPages } = useOrganizationStatusPagesQuery(organizationId);
  const noStatusPages = isFetched && statusPages?.length < 1;

  return (
    <Box ref={tableRef}>
      <DataTable showLoading={!isFetched}>
        {() => (
          <>
            {!noStatusPages && (
              <>
                <DataTable.Head>
                  <DataTable.HeaderRow>
                    <DataTable.HeaderCell>Status</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Name</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Links</DataTable.HeaderCell>
                    <DataTable.HeaderCell />
                  </DataTable.HeaderRow>
                </DataTable.Head>
                <DataTable.Body>
                  {isFetched && statusPages.map((statusPage) => <StatusPageRow key={statusPage.id} statusPage={statusPage} />)}
                </DataTable.Body>
              </>
            )}
            {noStatusPages && (
              <DataTable.Body>
                <DataTable.Row>
                  <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                    <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                      <Box>You don't have any status pages yet.</Box>
                      <Box>
                        <Button as={Link} to={Routes.CREATE_STATUS_PAGE} variant="outline" colorScheme="blue" size="md" textDecoration="underline">
                          Create one now
                        </Button>
                      </Box>
                    </Flex>
                  </DataTable.Cell>
                </DataTable.Row>
              </DataTable.Body>
            )}
          </>
        )}
      </DataTable>
    </Box>
  );
};

const StatusPageRow: React.FC<StatusPageRowProps> = ({ statusPage }) => {
  const href = useMemo(
    () =>
      generatePath(Routes.STATUS_PAGE_SETTINGS, {
        id: statusPage.id,
      }),
    []
  );
  const links = [
    statusPage.subdomainEnabled ? `https://${statusPage.subdomain}.${AppConfig.StatusPagesDomain}` : '',
    statusPage.customDomainEnabled ? `https://${statusPage.customDomain}` : '',
  ].filter((link) => !!link);

  return (
    <DataTable.LinkRow key={statusPage.id} to={href}>
      <DataTable.Cell overflow="visible" tooltip={null}>
        <StatusPageStatusIcon statusPage={statusPage} />
      </DataTable.Cell>
      <DataTable.Cell>{statusPage.name || '-'}</DataTable.Cell>
      <DataTable.Cell>
        <VStack alignItems="flex-start">
          {links.map((link) => (
            <Box key={link} flexGrow={1} minWidth="0px" width="100%" maxWidth="160px" overflow="hidden" textOverflow="ellipsis" textAlign="center">
              <ChakraLink
                href={link}
                target="_blank"
                isExternal
                textDecoration="underline"
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
              >
                {link} <ExternalLinkIcon mx={1} />
              </ChakraLink>
            </Box>
          ))}
        </VStack>
      </DataTable.Cell>
      <DataTable.Cell tooltip={null}>
        <Icon as={BsChevronRight} />
      </DataTable.Cell>
    </DataTable.LinkRow>
  );
};

export default StatusPagesTable;
