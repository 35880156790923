import React from 'react';
import Spinner from '../spinner/spinner';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { BsPauseCircleFill } from 'react-icons/bs';

type Props = {
  color: string;
  text: string;
  paused: boolean;
  loading: boolean;
  pausedTooltip?: string;
};

const StatusIcon: React.FC<Props> = ({ color, text, paused, loading, pausedTooltip = 'Paused' }) => {
  if (loading) {
    return (
      <Tooltip label="Loading">
        <span>
          <Flex alignItems="center" justifyContent="center" w={6} h={6} margin="auto">
            <Spinner size={'xs'} hideLoading />
          </Flex>
        </span>
      </Tooltip>
    );
  }

  if (paused) {
    return (
      <Tooltip label={pausedTooltip}>
        <span>
          <Flex alignItems="center" justifyContent="center" margin="auto">
            <Icon as={BsPauseCircleFill} w={6} h={6} color="gray.400" />
          </Flex>
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={text}>
      <Box display="flex" h={6} w={6} position="relative" margin="auto">
        <Box className="pulse" background={color} h={6} w={6} position="relative" display="inline-flex" borderRadius="full" />
      </Box>
    </Tooltip>
  );
};

export default StatusIcon;
