import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceType = {
  initialized: boolean;
  connecting: boolean;
  websocket: WebSocket;
};

const initialState: SliceType = {
  connecting: false,
  initialized: false,
  websocket: null,
};

export const websocket = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      return state;
    },
    setConnecting: (state, action: PayloadAction<boolean>) => {
      state.connecting = action.payload;
      return state;
    },
    setWebsocket: (state, action: PayloadAction<WebSocket>) => {
      state.websocket = action.payload;
      return state;
    },
  },
});

export default websocket.reducer;
