import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputLeftAddon,
  InputProps as ChakraInputProps,
  InputRightAddon,
  VStack,
} from '@chakra-ui/react';
import Input from '../input/input';
import { FormSelector } from '../form-select/formSelector';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { AsYouType, CountryCode, getCountries, getCountryCallingCode, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { components, createFilter, SingleValueProps } from 'react-select';
import ct from 'countries-and-timezones';
import { prepareRules } from '../../utils/formValidationRules';
import { Control, Controller } from 'react-hook-form';
import FormFieldLabel from '../form-field-label/formFieldLabel';

type CountryOption = {
  label: string;
  selectedLabel: string;
  value: CountryCode;
};

export interface PhoneNumberInputProps extends Omit<ChakraInputProps, 'error' | 'onChange'> {
  label?: string;
  error?: string;
  inputLabel?: React.ReactNode;
  inputLeftAddon?: React.ReactNode;
  inputRightAddon?: React.ReactNode;
  regularLabel?: boolean;
  rules?: any;
  helperText?: string;
  countryCode?: string;
  onChange?: (value: string) => void;
  value?: string;
}

export type ControlledPhoneNumberInputProps = {
  control: Control<any>;
  required?: boolean;
  regularLabel?: boolean;
  inputLabel?: React.ReactNode;
  inputLeftAddon?: React.ReactNode;
  inputRightAddon?: React.ReactNode;
} & PhoneNumberInputProps;

const SingleValue = ({ ...props }: SingleValueProps<any>) => {
  return <components.SingleValue {...props}>{props.data.selectedLabel}</components.SingleValue>;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  countryCode = 'US',
  label,
  inputLeftAddon,
  inputRightAddon,
  error,
  regularLabel,
  helperText,
  children,
  onChange,
  value,
  isDisabled,
  ...rest
}) => {
  const options = useMemo(() => {
    const countries = getCountries();

    return countries
      .map((countryCode) => {
        const code = getCountryCallingCode(countryCode);
        const country = ct.getCountry(countryCode);

        if (!code || !country) {
          return null;
        }

        return { label: `${country.name} (+${code})`, selectedLabel: `${countryCode} (+${code})`, value: countryCode };
      })
      .filter((item) => Boolean(item));
  }, []);
  const [country, setCountry] = useState(() => {
    const phoneNumber = value ? parsePhoneNumber(value) : null;
    const possibleCountries = phoneNumber ? phoneNumber.getPossibleCountries() : [countryCode];
    const finalCountryCode = possibleCountries.length > 0 ? possibleCountries[0] : null;

    return options.find((option) => option.value === finalCountryCode);
  });

  const setPhoneNumber = (value: string) => onChange(value);

  const updateCountry = (newCountry: CountryOption) => {
    const code = country ? getCountryCallingCode(country.value) : '';
    const internationalCode = `+${code}`;

    setCountry(newCountry);
    setPhoneNumber(value.replace(internationalCode, ''));
  };

  const formatToInternational = useCallback((inputPhoneNumber: string) => {
    if (!country) {
      return { internationalNumber: null, internationalCode: null };
    }

    const code = getCountryCallingCode(country.value);
    const internationalCode = `+${code}`;
    const asYouType = new AsYouType({ defaultCountry: country.value, defaultCallingCode: internationalCode });
    asYouType.input(inputPhoneNumber || '');
    const number = asYouType.getNumber();
    const internationalNumber = number ? number.formatInternational() : null;

    return { internationalNumber, internationalCode };
  }, [country]);

  const updatePhoneNumber = (newPhoneNumber: string) => {
    const { internationalNumber } = formatToInternational(newPhoneNumber);

    if (internationalNumber && validatePhoneNumberLength(internationalNumber, country.value) === 'TOO_LONG') {
      return;
    }

    setPhoneNumber(internationalNumber ? newPhoneNumber : '');
  };

  useEffect(() => {
    const { internationalNumber } = formatToInternational(value);

    setPhoneNumber(internationalNumber ? internationalNumber : '');
  }, [country, value]);

  const hasError = !!error;

  return (
    <VStack>
      <FormControl isInvalid={hasError}>
        <InputGroup size="lg">
          <Flex gap={1} w="100%">
            {label && <FormFieldLabel regular={regularLabel}>{label}</FormFieldLabel>}
            <Box>
              <FormSelector
                isSearchable
                options={options}
                value={country}
                filterOption={createFilter({
                  trim: true,
                  ignoreCase: true,
                  ignoreAccents: true,
                  matchFrom: 'any',
                })}
                isDisabled={isDisabled}
                components={{ SingleValue }}
                onChange={updateCountry}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    minHeight: 42,
                    minWidth: 130,
                  }),
                }}
              />
            </Box>
            <Box width="100%">
              {inputLeftAddon && <InputLeftAddon p={0}>{inputLeftAddon}</InputLeftAddon>}
              <Input
                value={value}
                isDisabled={isDisabled}
                onChange={(e) => updatePhoneNumber(e.target.value)}
                isInvalid={hasError}
                placeholder="Phone number"
                {...rest}
              />
              {inputRightAddon && <InputRightAddon p={0}>{inputRightAddon}</InputRightAddon>}
            </Box>
          </Flex>
        </InputGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
      {children}
    </VStack>
  );
};

export const ControlledPhoneNumberInput: React.FC<ControlledPhoneNumberInputProps> = ({ control, name, rules, inputLabel, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return <PhoneNumberInput error={error?.message} inputLabel={inputLabel} {...rest} {...field} />;
      }}
    />
  );
};

ControlledPhoneNumberInput.displayName = 'ControlledPhoneNumberInput';

export default PhoneNumberInput;
