import { PartialDeep } from 'type-fest';
import { OnCallDailyScheduleVO, OnCallScheduleRotation, OnCallScheduleUserVO, Weekday, WeekdayValues } from '@um/uptime-monitoring-shared';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';

export type OnCallScheduleFormModel = {
  id?: string;
  organizationId: string;
  name: string;
  startDate: string;
  timezone: string;
  dailySchedule: OnCallDailyScheduleVO[];
  scheduleRotation: OnCallScheduleRotation;
  users: OnCallScheduleUserVO[];
};

export const emptyOnCallScheduleUser = (order: number) => ({
  id: uuid(),
  userId: null,
  order,
});

export const OnCallScheduleFormModelDefaultValues: PartialDeep<OnCallScheduleFormModel> = {
  name: '',
  startDate: format(new Date(), 'yyyy-MM-dd'),
  timezone: null,
  dailySchedule: WeekdayValues.map((weekday) => ({ weekday, timeOfDayStart: '08:00', timeOfDayEnd: '18:00', enabled: true })),
  scheduleRotation: OnCallScheduleRotation.WEEKLY,
  users: [emptyOnCallScheduleUser(1)],
};
