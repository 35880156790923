import React, { useState } from 'react';
import { MutationAction } from '../../query/mutationAction';
import mutationDefaults from '../../query/mutationDefaults';
import { useNotificationPolicyMutation } from '../../query/notificationPolicyQueries';
import { NotificationPolicyModelVO } from '@um/uptime-monitoring-shared';
import {
  emptyNotificationPolicyStep,
  NotificationPolicyFormModel,
  NotificationPolicyFormModelDefaultValues,
} from '../../models/notificationPolicyFormModel';
import NotificationPolicyForm from '../notification-policy-form/notificationPolicyForm';
import { PartialDeep } from 'type-fest';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import toast from 'react-hot-toast';

export type CreateNotificationPolicyFormProps = {
  onCreated?: (data?: NotificationPolicyModelVO) => any;
  onError?: (err?: any) => any;
  showCards?: boolean;
};

const CreateNotificationPolicyForm: React.FC<CreateNotificationPolicyFormProps> = ({ onCreated, onError, showCards = true }) => {
  const [formErrors, setFormErrors] = useState([]);
  const { data: organizationId } = useSelectedOrganizationIdQuery();

  const { isLoading, mutateAsync } = useNotificationPolicyMutation({
    onSuccess: (data) => {
      toast.success('Notification policy created');

      onCreated && onCreated(data);
    },
    onError: mutationDefaults.onErrorWithFormHandling(setFormErrors, onError),
  });

  const handleSubmit = async (data: NotificationPolicyFormModel) => {
    await mutateAsync({ notificationPolicy: data, action: MutationAction.POST });
  };

  const formValues: PartialDeep<NotificationPolicyFormModel> = {
    ...NotificationPolicyFormModelDefaultValues,
    organizationId,
    steps: [emptyNotificationPolicyStep(1)],
  };

  return (
    <NotificationPolicyForm
      formValues={formValues}
      mainActionText="Create"
      disabled={isLoading}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      showCards={showCards}
    />
  );
};

export const CreateNotificationPolicyFormModal = ({ open, onClose, onCreated }) => {
  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create notification policy</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateNotificationPolicyForm showCards={false} onCreated={onCreated} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateNotificationPolicyForm;
