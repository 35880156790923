import { useFormContext } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import { getFormErrorMessage } from '../../utils/getFormErrorMessage';
import React, { useMemo } from 'react';
import HttpMonitorAssertionOperationDropdown from '../http-monitor-assertion-operation-dropdown/httpMonitorAssertionOperationDropdown';
import { FormSelect } from '../form-controller/formController';
import formValidationRules from '../../utils/formValidationRules';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import WebsiteLink from '../website-link/websiteLink';
import { AppConfig } from '../../utils/config';

type Props = { fieldPrefix: string; disabled: boolean };

const JSONResponseBodyPropertyFormFields: React.FC<Props> = ({ fieldPrefix, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const operationFieldPath = `${fieldPrefix}operation`;
  const propertyFieldPath = `${fieldPrefix}property`;
  const valueFieldPath = `${fieldPrefix}value`;
  const rules = useMemo(
    () => ({
      validate: formValidationRules.conditionalRequired(!disabled),
    }),
    [disabled]
  );
  console.log(propertyFieldPath);
  return (
    <>
      <FormFieldWrapper
        description={
          <span>
            Specify path to JSON property that you want to be compared.{' '}
            <WebsiteLink href={`${AppConfig.DocsBaseUrl}/user-guides/json-property-extraction`} target="_blank">
              Learn more
            </WebsiteLink>
          </span>
        }
      >
        <ControlledInput name={propertyFieldPath} isDisabled={disabled} placeholder="Property path" regularLabel rules={rules} control={control} />
      </FormFieldWrapper>
      <FormFieldWrapper description="Comparison operation">
        <FormSelect name={operationFieldPath} regularLabel rules={rules} control={control}>
          {(field) => (
            <HttpMonitorAssertionOperationDropdown
              disabled={disabled}
              placeholder="Comparison operation"
              error={getFormErrorMessage(errors, operationFieldPath)}
              {...field}
            />
          )}
        </FormSelect>
      </FormFieldWrapper>
      <FormFieldWrapper description="Expected value">
        <ControlledInput isDisabled={disabled} name={valueFieldPath} placeholder="Expected value" control={control} rules={rules} regularLabel />
      </FormFieldWrapper>
    </>
  );
};

export default JSONResponseBodyPropertyFormFields;
