"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnCallScheduleRotationValues = exports.OnCallScheduleRotationNames = exports.OnCallScheduleRotation = void 0;
var OnCallScheduleRotation;
(function (OnCallScheduleRotation) {
    OnCallScheduleRotation["DAILY"] = "DAILY";
    OnCallScheduleRotation["WEEKLY"] = "WEEKLY";
    OnCallScheduleRotation["MONTHLY"] = "MONTHLY";
})(OnCallScheduleRotation = exports.OnCallScheduleRotation || (exports.OnCallScheduleRotation = {}));
exports.OnCallScheduleRotationNames = {
    [OnCallScheduleRotation.DAILY]: 'Daily',
    [OnCallScheduleRotation.WEEKLY]: 'Weekly',
    [OnCallScheduleRotation.MONTHLY]: 'Monthly',
};
exports.OnCallScheduleRotationValues = Object.values(OnCallScheduleRotation);
