import React, { useEffect, useMemo, useState } from 'react';
import { prepareRules } from '../../utils/formValidationRules';
import { Control, Controller } from 'react-hook-form';
import { Props as SelectProps } from 'react-select';
import { useOrganizationMonitorOptionsQuery, useSelectedOrganizationIdQuery } from '../../query/queries';
import AsyncFormSelect from '../form-select/formSelector';

export type ControlledSelectProps = {
  control: Control<any>;
  name: string;
  regularLabel?: boolean;
  rules?: any;
  error?: any;
} & Pick<SelectProps, 'isMulti' | 'isDisabled'>;

export type MonitorSelectProps = {
  error?: any;
} & SelectProps;

const MonitorSelect: React.FC<MonitorSelectProps> = ({ onChange, value, isMulti, error, ...rest }) => {
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isLoading, data: monitors } = useOrganizationMonitorOptionsQuery(organizationId);
  const [monitorOptions, setMonitorOptions] = useState([]);
  const [query, setQuery] = useState('');

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    setQuery(inputValue);
    return inputValue;
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setMonitorOptions(monitors.map((monitor) => ({ value: monitor.id, label: monitor.name })));
  }, [isLoading]);

  const handleChange = (options) => {
    if (Array.isArray(options)) {
      onChange(
        options.map((option) => option.value),
        null
      );
    } else {
      onChange(options, null);
    }
  };

  const selectedOptions = useMemo(() => {
    if (Array.isArray(value)) {
      return monitorOptions.filter((monitor) => value.includes(monitor.value));
    }

    return monitorOptions.find((monitor) => value === monitor.value);
  }, [value, monitorOptions]);

  return (
    <AsyncFormSelect
      isSearchable
      defaultOptions={monitorOptions}
      cacheOptions
      isMulti={isMulti}
      onInputChange={handleInputChange}
      onChange={handleChange}
      value={selectedOptions}
      isLoading={isLoading}
      isDisabled={isLoading}
      error={error?.message}
      {...rest}
    />
  );
};

MonitorSelect.displayName = 'MonitorSelect';

export const ControlledMonitorSelect: React.FC<ControlledSelectProps> = ({ control, name, rules, ...rest }) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return <MonitorSelect error={error?.message} value={field.value} onChange={field.onChange} {...rest} />;
      }}
    />
  );
};

ControlledMonitorSelect.displayName = 'ControlledMonitorSelect';

export default MonitorSelect;
