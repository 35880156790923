"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageDeletedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class StatusPageDeletedMessage extends workerMessage_1.WorkerMessage {
    constructor(statusPage) {
        super(model_1.QueueDefinitions.STATUS_PAGE_DELETED);
        this.statusPage = statusPage;
    }
}
exports.StatusPageDeletedMessage = StatusPageDeletedMessage;
