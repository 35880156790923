"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationChannelOptionTypeValues = exports.NotificationChannelOptionType = void 0;
var NotificationChannelOptionType;
(function (NotificationChannelOptionType) {
    NotificationChannelOptionType["NOTIFICATION_INTEGRATION"] = "NOTIFICATION_INTEGRATION";
    NotificationChannelOptionType["NOTIFICATION_POLICY"] = "NOTIFICATION_POLICY";
    NotificationChannelOptionType["ON_CALL_SCHEDULE"] = "ON_CALL_SCHEDULE";
})(NotificationChannelOptionType = exports.NotificationChannelOptionType || (exports.NotificationChannelOptionType = {}));
exports.NotificationChannelOptionTypeValues = Object.values(NotificationChannelOptionType);
