import { useAppSelector } from '../app/store';
import React from 'react';
import PageWrapper from '../components/page-wrapper/pageWrapper';
import PageHeader from '../components/page-header/pageHeader';
import UpdateOrganizationForm from '../components/organization-form/updateOrganizationForm';
import { Flex } from '@chakra-ui/react';
import { useSelectedOrganizationQuery } from '../query/organizationQueries';
import EmptyPageTopMenu from '../components/empty-page-header/emptyPageHeader';
import Routes from '../utils/routes';

const OrganizationPage = () => {
  const { initialized } = useAppSelector((state) => state.app);
  const { isLoading, data: organization } = useSelectedOrganizationQuery();
  const breadcrumbs = [{ title: 'Settings', route: Routes.SETTINGS }];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized || isLoading} title="Organization settings" breadcrumbs={breadcrumbs} />
      <EmptyPageTopMenu />
      <PageWrapper loading={!initialized || isLoading} p={4}>
        <UpdateOrganizationForm organization={organization} />
      </PageWrapper>
    </Flex>
  );
};

export default OrganizationPage;
