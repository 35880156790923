import { MutationOptions, useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { NotificationPolicyModelVO } from '@um/uptime-monitoring-shared';
import { MutationAction } from './mutationAction';
import mutationDefaults from './mutationDefaults';

export interface NotificationPolicyMutationParameters {
  notificationPolicyId?: string;
  notificationPolicy?: NotificationPolicyModelVO;
  action: MutationAction;
}

export const useOrganizationNotificationPoliciesQuery = (organizationId: string, options = {}) => {
  return useQuery(
    ['notificationPolicy', 'organization', organizationId],
    async () => {
      return await statusPingClient.protectedGetNotificationPolicies(organizationId);
    },
    {
      enabled: Boolean(organizationId),
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useNotificationPolicyQuery = (id: string, options: UseQueryOptions<NotificationPolicyModelVO> = {}) => {
  return useQuery(
    ['notificationPolicy', id],
    async () => {
      return await statusPingClient.protectedGetNotificationPolicy(id);
    },
    {
      enabled: Boolean(id),
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useNotificationPolicyMutation = (options: MutationOptions<any, any, NotificationPolicyMutationParameters> = {}) => {
  const queryClient = useQueryClient();
  return useMutation<any, any, NotificationPolicyMutationParameters>(
    async ({ notificationPolicyId, notificationPolicy, action }: NotificationPolicyMutationParameters) => {
      if (action === MutationAction.PUT) {
        return await statusPingClient.protectedPutNotificationPolicy(notificationPolicyId, notificationPolicy);
      } else if (action === MutationAction.DELETE) {
        return await statusPingClient.protectedDeleteNotificationPolicy(notificationPolicyId);
      } else if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostNotificationPolicy(notificationPolicy);
      }

      return null;
    },
    {
      onError: mutationDefaults.onError,
      ...options,
      onSuccess: (data: NotificationPolicyModelVO, variables: NotificationPolicyMutationParameters, context) => {
        if (data) {
          queryClient.setQueryData(['notificationPolicy', data.id], data);
          queryClient.invalidateQueries(['notificationPolicy', 'organization', data.organizationId]);
        } else if (variables.notificationPolicyId) {
          queryClient.invalidateQueries(['notificationPolicy']);
        }

        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};
