"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifyDomainNameExpirationMonitorIntegrationMessage = exports.NotifySSLCertificateExpirationMonitorIntegrationMessage = exports.NotifyIncidentNotificationIntegrationMessage = exports.NotifyNotificationIntegrationMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
const notifyMonitorIntegrationType_1 = require("../model/notifyMonitorIntegrationType");
class NotifyNotificationIntegrationMessage extends workerMessage_1.WorkerMessage {
    constructor(type, integration) {
        super(model_1.QueueDefinitions.NOTIFY_MONITOR_INTEGRATION);
        this.type = type;
        this.integration = integration;
    }
}
exports.NotifyNotificationIntegrationMessage = NotifyNotificationIntegrationMessage;
class NotifyIncidentNotificationIntegrationMessage extends NotifyNotificationIntegrationMessage {
    constructor(incident, originalIncident, integration) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.INCIDENT, integration);
        this.incident = incident;
        this.originalIncident = originalIncident;
        this.integration = integration;
    }
}
exports.NotifyIncidentNotificationIntegrationMessage = NotifyIncidentNotificationIntegrationMessage;
class NotifySSLCertificateExpirationMonitorIntegrationMessage extends NotifyNotificationIntegrationMessage {
    constructor(monitor, monitorCertificateInformation, integration) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.SSL_CERTIFICATE_EXPIRATION, integration);
        this.monitor = monitor;
        this.monitorCertificateInformation = monitorCertificateInformation;
        this.integration = integration;
    }
}
exports.NotifySSLCertificateExpirationMonitorIntegrationMessage = NotifySSLCertificateExpirationMonitorIntegrationMessage;
class NotifyDomainNameExpirationMonitorIntegrationMessage extends NotifyNotificationIntegrationMessage {
    constructor(monitor, domainWhois, integration) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.DOMAIN_NAME_EXPIRATION, integration);
        this.monitor = monitor;
        this.domainWhois = domainWhois;
        this.integration = integration;
    }
}
exports.NotifyDomainNameExpirationMonitorIntegrationMessage = NotifyDomainNameExpirationMonitorIntegrationMessage;
