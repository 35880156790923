"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendResetPasswordTokenEmailMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class SendResetPasswordTokenEmailMessage extends workerMessage_1.WorkerMessage {
    constructor(resetPasswordToken) {
        super(model_1.QueueDefinitions.SEND_RESET_PASSWORD_TOKEN_EMAIL);
        this.resetPasswordToken = resetPasswordToken;
    }
}
exports.SendResetPasswordTokenEmailMessage = SendResetPasswordTokenEmailMessage;
