"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageIncidentBadgeStyles = void 0;
const statusPageIncident_1 = require("./statusPageIncident");
exports.StatusPageIncidentBadgeStyles = {
    [statusPageIncident_1.StatusPageIncidentStatus.RESOLVED]: {
        colorScheme: 'darkGreen',
        text: 'Resolved',
    },
    [statusPageIncident_1.StatusPageIncidentStatus.DEGRADED]: {
        colorScheme: 'orange',
        text: 'Degraded',
    },
    [statusPageIncident_1.StatusPageIncidentStatus.MONITORING]: {
        colorScheme: 'blue',
        text: 'Monitoring',
    },
    [statusPageIncident_1.StatusPageIncidentStatus.DOWNTIME]: {
        colorScheme: 'red',
        text: 'Downtime',
    },
};
