import { useFormContext } from 'react-hook-form';
import { StatusPageIncidentUpdateFormModel } from '../../models/statusPageIncidentUpdateFormModel';
import React, { useMemo } from 'react';
import { StatusPageSectionEntryVO } from '@um/uptime-monitoring-shared';
import { ControlledFormSelector } from '../form-select/formSelector';

const ControlledStatusPageIncidentResourceStatusSelector = ({ name, statusPageResources, rules = null, disabled = false }) => {
  const { control, watch } = useFormContext<StatusPageIncidentUpdateFormModel>();
  const value = watch(name);

  const options = useMemo(
    () =>
      (statusPageResources || []).map((item: StatusPageSectionEntryVO) => ({
        label: item.name || '-',
        value: item.id,
      })),
    [statusPageResources]
  );
  const selectedOption = useMemo(() => options.find((option) => option.value === value), [options, value]);

  return (
    <ControlledFormSelector
      control={control}
      name={name}
      options={options}
      value={selectedOption}
      transformOption={(option) => option.value}
      isDisabled={disabled}
      rules={rules}
    />
  );
};

export default ControlledStatusPageIncidentResourceStatusSelector;
