import { differenceInHours } from 'date-fns';
import { logError } from './logError';

export enum CertificateCheckStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  VALID = 'VALID',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED',
}

const getCertificateCheckStatus = (certificateExpiryDate: string): CertificateCheckStatus => {
  if (!certificateExpiryDate) {
    return CertificateCheckStatus.NOT_CHECKED;
  }

  try {
    const parsedExpiryDate = new Date(certificateExpiryDate);
    const now = new Date();
    const difference = differenceInHours(parsedExpiryDate, now);

    if (difference > 14 * 24) {
      return CertificateCheckStatus.VALID;
    } else if (difference > 0 && difference <= 14 * 24) {
      return CertificateCheckStatus.EXPIRES_SOON;
    }

    return CertificateCheckStatus.EXPIRED;
  } catch (e) {
    logError(e);
    return CertificateCheckStatus.NOT_CHECKED;
  }
};

export default getCertificateCheckStatus;
