import React, { useCallback, useEffect, useState } from 'react';
import { ErrorType, HttpProtocolOptions, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, ButtonProps, Flex } from '@chakra-ui/react';
import WebsiteUrlControlledInput from '../monitor-form/websiteUrlControlledInput';
import { useQuickMonitorMutation } from '../../query/monitorQueries';
import { useSelectedOrganizationIdQuery } from '../../query/organizationQueries';

type Props = {
  onMonitorCreated?: (monitor: ProtectedMonitorDetailsResponse) => any;
  buttonProps?: ButtonProps;
};

export type QuickMonitorCreateFormModel = {
  url: string;
  protocol: string;
};

const defaultValues: QuickMonitorCreateFormModel = {
  url: '',
  protocol: HttpProtocolOptions.HTTPS,
};

const QuickMonitorCreateForm: React.FC<Props> = ({ onMonitorCreated, buttonProps = {} }) => {
  const formMethods = useForm<QuickMonitorCreateFormModel>({ defaultValues });
  const { handleSubmit, setError, reset } = formMethods;
  const [formErrors, setFormErrors] = useState([]);
  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isLoading: mutationLoading, mutateAsync } = useQuickMonitorMutation();

  useEffect(() => {
    if (formErrors && formErrors.length > 0) {
      for (const formError of formErrors) {
        setError(formError.dataPath as any, {
          type: 'manual',
          message: formError.message,
        });
      }
    }
  }, [formErrors]);

  const onSubmit = useCallback(
    async (data: QuickMonitorCreateFormModel) => {
      await mutateAsync(
        { ...data, organizationId },
        {
          onSuccess: (data: ProtectedMonitorDetailsResponse) => {
            toast.success('Monitor created');
            reset();

            onMonitorCreated && onMonitorCreated(data);
          },
          onError: (error: any) => {
            const errorResponse = error?.response?.data;
            if (errorResponse && errorResponse.errorType !== ErrorType.VALIDATION_FAILED) {
              toast.error('Error occurred. Please try again later');
            } else if (errorResponse && errorResponse.errorType === ErrorType.MONITORS_QUOTA_REACHED) {
              toast.error('You have reached the limit of Monitors you can create');
            } else if (errorResponse && errorResponse.errorType === ErrorType.VALIDATION_FAILED) {
              setFormErrors(errorResponse.errors);
            }
          },
        }
      );
    },
    [organizationId]
  );

  return (
    <FormProvider {...formMethods}>
      <Flex as="form" flexDirection="column" gap={4} w="100%" onSubmit={handleSubmit(onSubmit)}>
        <WebsiteUrlControlledInput name="url" protocolFieldName="protocol" required={true} />
        <Button colorScheme="blue" type="submit" size="lg" {...buttonProps} isDisabled={mutationLoading} isLoading={mutationLoading}>
          Quick create
        </Button>
      </Flex>
    </FormProvider>
  );
};

export default QuickMonitorCreateForm;
