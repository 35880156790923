import React from 'react';
import { HttpMonitorAssertionType } from '@um/uptime-monitoring-shared';
import Select from 'react-select';

const assertionTypeOptions = [
  {
    label: 'Website becomes unreachable',
    value: HttpMonitorAssertionType.UNAVAILABLE,
  },
  {
    label: 'Response HTTP status code is not one of...',
    value: HttpMonitorAssertionType.STATUS_CODE,
  },
  {
    label: 'JSON response does not satisfy the following condition',
    value: HttpMonitorAssertionType.JSON_RESPONSE_BODY_PROPERTY,
  },
];

const HttpMonitorAssertionTypeSelect = ({ value, ...rest }) => {
  return <Select options={assertionTypeOptions} value={assertionTypeOptions.find((option) => option.value === value)} {...rest} />;
};

export default HttpMonitorAssertionTypeSelect;
