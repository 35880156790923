import { Box, Flex } from '@chakra-ui/react';
import PricingFaq from './pricingFaq';
import NeedHelp from '../need-help/needHelp';
import React from 'react';
import PlanSelectionCard from '../plan-selection-card/planSelectionCard';
import PlanDescriptions from '@um/uptime-monitoring-shared/dist/model/planDescriptions';
import { Plan } from '@um/uptime-monitoring-shared';
import UpgradeNowButton from '../upgrade-now-button/upgradeNowButton';
import StartTrialButton from '../start-trial-button/startTrialButton';
import WebsiteLink from '../website-link/websiteLink';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useSelectedOrganizationQuery, useSelectedOrganizationSubscriptionsQuery } from '../../query/organizationQueries';

const AppPricing = () => {
  const { data: organization } = useSelectedOrganizationQuery();
  const { data: subscriptionResponse, isLoading: loadingSubscription } = useSelectedOrganizationSubscriptionsQuery();
  const canUpgrade =
    (!loadingSubscription && !subscriptionResponse?.activeSubscription && organization?.plan === Plan.FREE) || organization?.trialActive;
  const canStartTrial = !organization?.trialActive && !organization?.trialCompleted && organization?.plan === Plan.FREE;
  const plan = organization?.plan;

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      <Flex alignItems="center" justifyContent="center" textAlign="center" w="100%" color="gray.600" fontSize="xl" mb={6}>
        Choose the most suitable plan for your organization
      </Flex>
      <Flex gap={4} flexDirection={{ base: 'column', md: 'row' }}>
        <PricingCard
          price="$9 per month"
          plan={Plan.SOLO}
          canUpgrade={canUpgrade}
          name="Solo plan"
          canStartTrial={canStartTrial}
          currentPlan={plan}
          trialEnabled={false}
        />
        <PricingCard
          price="$29 per month"
          plan={Plan.TEAM}
          canUpgrade={canUpgrade}
          name="Team plan"
          canStartTrial={canStartTrial}
          currentPlan={plan}
          trialEnabled={true}
        />
        <PricingCard
          price="$69 per month"
          plan={Plan.BUSINESS}
          canUpgrade={canUpgrade}
          name="Business plan"
          canStartTrial={canStartTrial}
          currentPlan={plan}
          trialEnabled={false}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="center" width="100%" mt={6}>
        <WebsiteLink href="https://statusping.co/pricing#plan-comparison" target="_blank" wrapperProps={{ fontSize: 'xl' }}>
          Full plan comparison
          <ExternalLinkIcon mx={1} />
        </WebsiteLink>
      </Flex>
      <PricingFaq mt={12} w="100%" />
      <NeedHelp mt={12} />
    </Flex>
  );
};

const PricingCard = ({ name, price, canStartTrial, trialEnabled, plan, currentPlan, canUpgrade }) => {
  return (
    <PlanSelectionCard
      name={
        <Box>
          <Box fontSize="2xl" lineHeight="1" fontWeight="bold">
            {PlanDescriptions[plan].name} plan
          </Box>
        </Box>
      }
      features={PlanDescriptions[plan].features}
      cursor="default"
      footer={
        <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" mt={2} gap={2}>
          <Box fontWeight="bold" fontSize="2xl" py={4}>
            {price}
          </Box>
          {canUpgrade && (
            <UpgradeNowButton plan={plan} colorScheme="darkGreen" size="md" w="100%">
              Upgrade to {name}
            </UpgradeNowButton>
          )}
          {canStartTrial && (
            <StartTrialButton w="100%" plan={plan} visibility={trialEnabled ? 'visible' : 'hidden'}>
              Start 21 day trial
            </StartTrialButton>
          )}
        </Flex>
      }
      boxShadow={currentPlan === plan ? '0 0 0 5px #22543D' : 'outline'}
    />
  );
};

export default AppPricing;
