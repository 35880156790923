import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { RootState } from '../../store';
import { logError } from '../../../utils/logError';
import { getSelectedOrganizationId } from '../../../utils/getSelectedOrganizationId';

export function* initialize(action) {
  try {
    yield put(actions.monitors.setInitialized(false));

    const organizationId = action.payload;
    const [monitors, downtimeStatistics] = yield all([
      call(statusPingClient.protectedGetMonitors, { organizationId }),
      call(statusPingClient.protectedGetStatistics, { organizationId }),
    ]);

    yield put(actions.monitors.setMonitors(monitors));
    yield put(actions.monitors.setDowntimeStatistics(downtimeStatistics));
    yield put(actions.monitors.setInitialized(true));
  } catch (e) {
    logError(e);
  }
}

function* onMonitorUpdatedEvent(action) {
  try {
    const monitor = action.payload;

    yield call(replaceUpdatedMonitor, monitor);
  } catch (e) {
    logError(e);
  }
}

function* onMonitorCreatedEvent(action) {
  try {
    yield call(addCreatedMonitor, action.payload);
  } catch (e) {
    logError(e);
  }
}

function* onMonitorCreated(action) {
  try {
    yield call(addCreatedMonitor, action.payload);
  } catch (e) {
    logError(e);
  }
}

function* addCreatedMonitor(monitor) {
  const { initialized, monitors } = yield select((state: RootState) => state.monitors);
  const organizationId = yield call(getSelectedOrganizationId);

  if (!initialized || monitor.organizationId !== organizationId) {
    return;
  }

  const monitorExists = (monitors || []).some((item) => item.id === monitor.id);

  if (monitorExists) {
    yield call(replaceUpdatedMonitor, monitor);
  } else {
    const updatedMonitors = [monitor, ...(monitors || [])];
    const downtimeStatistics = yield call(statusPingClient.protectedGetStatistics, { organizationId });

    yield put(actions.monitors.setDowntimeStatistics(downtimeStatistics));
    yield put(actions.monitors.setMonitors(updatedMonitors));
  }
}

function* replaceUpdatedMonitor(monitor) {
  const { initialized, monitors } = yield select((state: RootState) => state.monitors);

  if (!initialized) {
    return;
  }

  const updatedMonitors = (monitors || []).map((item) => (item.id === monitor.id && monitor.updatedAt > item.updatedAt ? monitor : item));

  yield put(actions.monitors.setMonitors(updatedMonitors));
}

export default function* monitorsSaga() {
  yield all([
    takeEvery(actions.websocket.onMonitorUpdatedEvent.type, onMonitorUpdatedEvent),
    takeEvery(actions.websocket.onMonitorCreatedEvent.type, onMonitorCreatedEvent),
    takeEvery(actions.monitors.onMonitorCreated.type, onMonitorCreated),
    takeEvery(actions.monitors.initialize.type, initialize),
  ]);
}
