import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import React from 'react';

type Props = Omit<CheckboxProps, 'onChange'> & {
  name: string;
  onChange?: (checked: boolean) => boolean;
};

const ControlledCheckbox: React.FC<Props> = ({ name, onChange, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Checkbox
            size="lg"
            isChecked={field.value || false}
            onChange={(evt) => {
              const result = !onChange || onChange(evt.target.checked);

              if (result) {
                field.onChange(evt.target.checked);
              }
            }}
            {...rest}
          />
        );
      }}
    />
  );
};

export default ControlledCheckbox;
