import Select, { AsyncProps } from 'react-select/async';
import { default as SyncSelect, Props } from 'react-select';
import { FormControl, FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import React from 'react';
import { prepareRules } from '../../utils/formValidationRules';
import { Control, Controller } from 'react-hook-form';

export type AsyncFormSelectProps = AsyncProps<any, any, any> & {
  name?: string;
  regularLabel?: boolean;
  rules?: any;
  error?: any;
  isDisabled?: boolean;
  isMulti?: boolean;
  helperText?: React.ReactNode;
};

export type FormSelectorProps = Props<any, any, any> & {
  name?: string;
  regularLabel?: boolean;
  rules?: any;
  error?: any;
  isDisabled?: boolean;
  isMulti?: boolean;
  helperText?: React.ReactNode;
};

export type ControlledFormSelectorProps = FormSelectorProps & {
  control: Control<any>;
  transformOption?: (value: any) => any;
};

const AsyncFormSelect: React.FC<AsyncFormSelectProps> = (props) => {
  const { error, styles, helperText, ...rest } = props;
  const hasError = Boolean(error);

  return (
    <FormControl isInvalid={hasError}>
      <Select
        isSearchable
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (controlStyles) => ({
            ...controlStyles,
            ...(styles?.control ? styles.control(controlStyles, props as any) : {}),
            borderColor: error ? 'red' : controlStyles.borderColor,
            '&:hover': {
              borderColor: error ? 'red' : controlStyles['&:hover']['borderColor'],
            },
          }),
        }}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export const FormSelector: React.FC<FormSelectorProps> = (props) => {
  const { error, styles, helperText, ...rest } = props;
  const hasError = Boolean(error);

  return (
    <FormControl isInvalid={hasError}>
      <SyncSelect
        isSearchable
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (controlStyles) => ({
            ...controlStyles,
            ...(styles?.control ? styles.control(controlStyles, props as any) : {}),
            borderColor: error ? 'red' : controlStyles.borderColor,
            '&:hover': {
              borderColor: error ? 'red' : controlStyles['&:hover']['borderColor'],
            },
          }),
        }}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export const ControlledFormSelector: React.FC<ControlledFormSelectorProps> = ({
  control,
  name,
  rules,
  transformOption = (value) => value,
  ...rest
}) => {
  const finalRules = prepareRules(rules);

  return (
    <Controller
      control={control}
      name={name}
      rules={finalRules}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormSelector error={error?.message} value={field.value} onChange={(newValue) => field.onChange(transformOption(newValue))} {...rest} />
        );
      }}
    />
  );
};

export default AsyncFormSelect;
