import React, { useMemo, useState } from 'react';
import { MutationAction } from '../../query/mutationAction';
import mutationDefaults from '../../query/mutationDefaults';
import Spinner from '../spinner/spinner';
import { useNotificationPolicyMutation, useNotificationPolicyQuery } from '../../query/notificationPolicyQueries';
import { NotificationPolicyModelVO } from '@um/uptime-monitoring-shared';
import { NotificationPolicyFormModel } from '../../models/notificationPolicyFormModel';
import NotificationPolicyForm from '../notification-policy-form/notificationPolicyForm';

export type EditNotificationPolicyFormProps = {
  notificationPolicyId: string;
  onUpdated?: (data?: NotificationPolicyModelVO) => any;
  onError?: (err?: any) => any;
};

const EditNotificationPolicyForm: React.FC<EditNotificationPolicyFormProps> = ({ notificationPolicyId, onUpdated, onError }) => {
  const [formErrors, setFormErrors] = useState([]);

  const { data: notificationPolicy, isFetched } = useNotificationPolicyQuery(notificationPolicyId);
  const { isLoading, mutateAsync } = useNotificationPolicyMutation({
    onSuccess: (data) => {
      onUpdated && onUpdated(data);
    },
    onError: mutationDefaults.onErrorWithFormHandling(setFormErrors, onError),
  });

  const handleSubmit = async (data: NotificationPolicyFormModel) => {
    await mutateAsync({ notificationPolicyId, notificationPolicy: data, action: MutationAction.PUT });
  };

  const formValues: NotificationPolicyFormModel = useMemo(() => {
    if (!isFetched) {
      return null;
    }

    return {
      id: notificationPolicy.id,
      name: notificationPolicy.name,
      description: notificationPolicy.description,
      firstStepAfter: notificationPolicy.firstStepAfter,
      organizationId: notificationPolicy.organizationId,
      steps: notificationPolicy.steps,
    };
  }, [notificationPolicy, isFetched]);

  if (!isFetched) {
    return <Spinner />;
  }

  return (
    <NotificationPolicyForm formValues={formValues} mainActionText="Save" disabled={isLoading} formErrors={formErrors} onSubmit={handleSubmit} />
  );
};

export default EditNotificationPolicyForm;
