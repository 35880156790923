import { Box, Button, Flex, Grid, Icon, IconButton, StackDivider, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { ControlledTextArea } from '../text-area/textArea';
import { onSubmitWrapper } from '../../utils/onSubmitWrapper';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { ControlledStatusPageIncidentStatusSelector } from '../status-page-incident-status-selector/statusPageIncidentStatusSelector';
import { FormFieldError, StatusPageSectionEntryVO } from '@um/uptime-monitoring-shared';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsTrash2Fill } from 'react-icons/bs';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import Card from '../card/card';
import { StatusPageIncidentFormModel, StatusPageIncidentFormModelDefaultValues } from '../../models/statusPageIncidentFormModel';
import ControlledStatusPageIncidentResourceStatusSelector from '../status-page-incident-resource-status-selector/statusPageIncidentResourceStatusSelector';
import { ControlledTimezoneSelect } from '../timezone-select/timezoneSelect';
import formValidationRules from '../../utils/formValidationRules';
import ControlledStatusPageIncidentStatusPageVisibilitySelector from '../status-page-incident-status-page-visibility-selector/statusPageIncidentStatusPageVisibilitySelector';

type Props = {
  formValues?: StatusPageIncidentFormModel;
  statusPageResources: StatusPageSectionEntryVO[];
  mainActionText: string;
  disabled?: boolean;
  formErrors?: FormFieldError[];
  onSubmit: (data: StatusPageIncidentFormModel) => void;
};
const { required } = formValidationRules;

const StatusPageIncidentForm: React.FC<Props> = ({
  formValues = StatusPageIncidentFormModelDefaultValues,
  mainActionText,
  statusPageResources,
  disabled,
  onSubmit,
  formErrors,
}) => {
  const formMethods = useForm<StatusPageIncidentFormModel>({ defaultValues: formValues });
  const { handleSubmit, control } = formMethods;

  const resourcesField = useFieldArray({
    control: formMethods.control,
    name: 'resources',
  });

  useEffect(() => {
    if (formErrors && formErrors.length > 0) {
      for (const formError of formErrors) {
        formMethods.setError(formError.dataPath as any, {
          type: 'manual',
          message: formError.message,
        });
      }
    }
  }, [formErrors]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmitWrapper(handleSubmit(onSubmit))}>
        <Card>
          <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
            <FormFieldWrapper title="Incident status" description="Status of the incident">
              <ControlledStatusPageIncidentStatusSelector control={control} name="status" isDisabled={disabled} rules={{ required }} createMode />
            </FormFieldWrapper>
            <FormFieldWrapper title="Resource statuses" description="Statuses of individual resources affected by this incident">
              <ResourceStatusesFormFields statusPageResources={statusPageResources} resourcesField={resourcesField} disabled={disabled} />
            </FormFieldWrapper>
            <FormFieldWrapper
              title="Status page visibility"
              description="If Hidden option is selected then this incident will not be displayed in the status page. Resource statuses will still be affected by a hidden incident"
            >
              <ControlledStatusPageIncidentStatusPageVisibilitySelector name="statusPageVisibility" disabled={disabled} rules={{ required }} />
            </FormFieldWrapper>
            <FormFieldWrapper title="Timezone" description="Timezone to be used to display times of changes related to this incident">
              <ControlledTimezoneSelect name="timezone" control={control} placeholder="Timezone" isDisabled={disabled} rules={{ required }} />
            </FormFieldWrapper>
            <FormFieldWrapper title="Message" description="Update message to be displayed to status page visitors">
              <ControlledTextArea w="100%" name="message" control={control} placeholder="Update message" isDisabled={disabled} />
            </FormFieldWrapper>
          </VStack>
          <Flex justifyContent="center" width="100%" mt={8}>
            <Button type="submit" colorScheme="blue" width="min-content" isDisabled={disabled}>
              {mainActionText}
            </Button>
          </Flex>
        </Card>
      </form>
    </FormProvider>
  );
};

const ResourceStatusesFormFields = ({ statusPageResources, resourcesField, disabled = false }) => {
  const { control } = useFormContext<StatusPageIncidentFormModel>();

  return (
    <Flex flexDirection="column" gap={2} maxWidth="500px">
      <Grid gridTemplateColumns="1fr 1fr min-content" gap={2} alignItems="center">
        {resourcesField.fields.map((field, idx) => {
          return (
            <React.Fragment key={`${field.statusPageResourceId}-${idx}`}>
              <ControlledStatusPageIncidentResourceStatusSelector
                statusPageResources={statusPageResources}
                name={`resources[${idx}].statusPageResourceId`}
                disabled={disabled}
                rules={{ required }}
              />
              <ControlledStatusPageIncidentStatusSelector
                control={control}
                name={`resources[${idx}].status`}
                isDisabled={disabled}
                rules={{ required }}
                createMode
              />
              <IconButton
                colorScheme="red"
                aria-label="Delete resource status change"
                icon={<Icon as={BsTrash2Fill} boxSize={6} />}
                size="sm"
                onClick={() => resourcesField.remove(idx)}
                disabled={disabled}
              />
            </React.Fragment>
          );
        })}
      </Grid>
      {resourcesField.fields.length < statusPageResources.length && (
        <Button
          colorScheme="blue"
          variant="outline"
          w="100%"
          onClick={() => resourcesField.append({ statusPageResourceId: null, status: null })}
          isDisabled={disabled}
        >
          <Flex alignItems="center" gap={1}>
            <Icon boxSize={6} as={AiFillPlusCircle} />
            <Box fontSize="xl">Add resource</Box>
          </Flex>
        </Button>
      )}
    </Flex>
  );
};

export default StatusPageIncidentForm;
