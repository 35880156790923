import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { CreateStatusPageVO, StatusPage, StatusPageModelVO, UpdateStatusPageVO } from '@um/uptime-monitoring-shared';
import { MutationAction } from './mutationAction';
import queryDefaults from './queryDefaults';

export interface StatusPageMutationParameters {
  statusPageId?: string;
  updateRequest?: UpdateStatusPageVO;
  createRequest?: CreateStatusPageVO;
  action: MutationAction;
}

export const useStatusPageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<StatusPageModelVO | StatusPage, any, StatusPageMutationParameters>(
    async ({ statusPageId, updateRequest, createRequest, action }: StatusPageMutationParameters) => {
      if (action === MutationAction.PUT) {
        return await statusPingClient.protectedPutStatusPage(statusPageId, updateRequest);
      } else if (action === MutationAction.POST) {
        return await statusPingClient.protectedPostStatusPage(createRequest);
      } else if (action === MutationAction.DELETE) {
        return await statusPingClient.protectedDeleteStatusPage(statusPageId);
      }

      return null;
    },
    {
      onSuccess: (data: StatusPageModelVO, variables) => {
        const id = data?.id;

        if (id && variables.action !== MutationAction.DELETE) {
          queryClient.setQueryData(['status-pages', id], data);
        } else if (id) {
          queryClient.invalidateQueries(['status-pages', id]);
        }

        if (data && data.organizationId) {
          queryClient.invalidateQueries(['status-pages', 'organization', data.organizationId]);
        }
      },
    }
  );
};

export const useOrganizationStatusPagesQuery = (organizationId: string, refetchInterval?: number | false) => {
  return useQuery(['status-pages', 'organization', organizationId], () => statusPingClient.protectedGetStatusPages(organizationId), {
    enabled: !!organizationId,
    refetchInterval,
  });
};

export const useStatusPageQuery = (statusPageId: string, options: UseQueryOptions<StatusPageModelVO> = {}) => {
  return useQuery(['status-pages', statusPageId], () => statusPingClient.protectedGetStatusPage(statusPageId), {
    staleTime: 2000,
    ...queryDefaults,
    ...options,
  });
};

export const useStatusPageOrganizationResourceOptionsQuery = (organizationId: string) => {
  return useQuery(
    ['status-pages', 'organization', organizationId, 'resources', 'options'],
    () => statusPingClient.protectedGetStatusPageResourceOptions(organizationId),
    {
      enabled: !!organizationId,
    }
  );
};

export default { useStatusPageMutation, useOrganizationStatusPagesQuery, useStatusPageQuery, useStatusPageOrganizationResourceOptionsQuery };
