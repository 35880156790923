import { StatusPageStatus } from '@um/uptime-monitoring-shared';

const colors: { [key: string]: string } = {
  [StatusPageStatus.UP]: 'green.500',
  [StatusPageStatus.PARTIALLY_FAILING]: 'orange.500',
  [StatusPageStatus.DOWN]: 'red.500',
};
const statusText: { [key: string]: string } = {
  [StatusPageStatus.UP]: 'Operating',
  [StatusPageStatus.PARTIALLY_FAILING]: 'Partially failing',
  [StatusPageStatus.DOWN]: 'Down',
};

export const statusPageStatusText = (status: StatusPageStatus) => statusText[status] || statusText[StatusPageStatus.UP];
export const statusPageStatusColor = (status: StatusPageStatus) => colors[status] || colors[StatusPageStatus.UP];
