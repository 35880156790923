import { useStatusPageIncidentMutation, useStatusPageIncidentQuery } from '../../query/statusPageIncidentQueries';
import DataCard from '../data-card/dataCard';
import React from 'react';
import StatusPageIncidentStatusBadge from '../status-page-incident-status-badge/statusPageIncidentStatusBadge';
import { Box, Button, Flex } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import { DateFormats } from '../../utils/formatToUtc';
import { StatusPageIncidentStatusPageVisibility, Timezones } from '@um/uptime-monitoring-shared';
import useStatusPageVisibilityText from '../../hooks/useStatusPageVisibilityText';
import { MutationAction } from '../../query/mutationAction';
import { Icon } from '@chakra-ui/icons';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

type Props = {
  statusPageIncidentId: string;
};

const StatusPageIncidentInformation: React.FC<Props> = ({ statusPageIncidentId }) => {
  const { mutateAsync, isLoading: mutating } = useStatusPageIncidentMutation();
  const { data: statusPageIncident, isFetched: statusPageIncidentFetched } = useStatusPageIncidentQuery(statusPageIncidentId);

  const { result } = statusPageIncident || {};

  const changeVisibility = async (visibility: StatusPageIncidentStatusPageVisibility) => {
    await mutateAsync({
      statusPageIncidentId,
      updateRequest: { statusPageVisibility: visibility },
      action: MutationAction.PATCH,
    });
  };

  return (
    <DataCard loading={!statusPageIncidentFetched}>
      {() => {
        const { status, resources, timezone, startedAt, resolvedAt, message, statusPageVisibility } = result;
        const timezoneMetadata = Timezones.find((tz) => tz.value === timezone);
        const statusPageVisibilityText = useStatusPageVisibilityText(statusPageVisibility);
        return (
          <DataCard.Stack>
            <DataCard.Row heading="Current incident status">
              <StatusPageIncidentStatusBadge status={status} />
            </DataCard.Row>
            <DataCard.Row heading="Visibility in status page">
              <Flex alignItems="center" gap={2}>
                <Box>{statusPageVisibilityText}</Box>
                {statusPageVisibility === StatusPageIncidentStatusPageVisibility.VISIBLE && (
                  <Button
                    size="sm"
                    colorScheme="blackAlpha"
                    isDisabled={mutating}
                    isLoading={mutating}
                    leftIcon={<Icon as={BsFillEyeSlashFill} />}
                    onClick={() => changeVisibility(StatusPageIncidentStatusPageVisibility.HIDDEN)}
                  >
                    Make hidden
                  </Button>
                )}
                {statusPageVisibility === StatusPageIncidentStatusPageVisibility.HIDDEN && (
                  <Button
                    size="sm"
                    colorScheme="blackAlpha"
                    isDisabled={mutating}
                    isLoading={mutating}
                    leftIcon={<Icon as={BsFillEyeFill} />}
                    onClick={() => changeVisibility(StatusPageIncidentStatusPageVisibility.VISIBLE)}
                  >
                    Make visible
                  </Button>
                )}
              </Flex>
            </DataCard.Row>
            <DataCard.Row heading="Current resource statuses">
              <Flex flexDirection="column" gap={1}>
                {resources.map((resource) => {
                  return (
                    <Box>
                      {resource.resourceName} <StatusPageIncidentStatusBadge status={resource.status} />
                    </Box>
                  );
                })}
              </Flex>
            </DataCard.Row>

            <DataCard.Row heading="Timezone">{timezoneMetadata?.label || timezone}</DataCard.Row>
            <DataCard.Row heading="Initial message">{message?.text ? message.text : '-'}</DataCard.Row>
            <DataCard.Row heading="Started at">
              {startedAt ? formatInTimeZone(startedAt, timezone, DateFormats.FULL_SECONDS_WITH_TZ) : '-'}
            </DataCard.Row>
            <DataCard.Row heading="Resolved at">
              {resolvedAt ? formatInTimeZone(resolvedAt, timezone, DateFormats.FULL_SECONDS_WITH_TZ) : '-'}
            </DataCard.Row>
          </DataCard.Stack>
        );
      }}
    </DataCard>
  );
};

export default StatusPageIncidentInformation;
