"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toNotifyOnCallScheduleMessage = exports.toNotifyNotificationIntegrationMessage = exports.NotifyDomainNameExpirationNotificationChannelMessage = exports.NotifySSLCertificateExpirationNotificationChannelMessage = exports.NotifyIncidentNotificationChannelMessage = exports.NotifyNotificationChannelMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
const notifyMonitorIntegrationType_1 = require("../model/notifyMonitorIntegrationType");
const notifyNotificationIntegrationMessage_1 = require("./notifyNotificationIntegrationMessage");
const notifyOnCallScheduleMessage_1 = require("./notifyOnCallScheduleMessage");
class NotifyNotificationChannelMessage extends workerMessage_1.WorkerMessage {
    constructor(type, notificationChannel) {
        super(model_1.QueueDefinitions.NOTIFY_NOTIFICATION_CHANNEL);
        this.type = type;
        this.notificationChannel = notificationChannel;
    }
}
exports.NotifyNotificationChannelMessage = NotifyNotificationChannelMessage;
class NotifyIncidentNotificationChannelMessage extends NotifyNotificationChannelMessage {
    constructor(incident, originalIncident, notificationChannel) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.INCIDENT, notificationChannel);
        this.incident = incident;
        this.originalIncident = originalIncident;
        this.notificationChannel = notificationChannel;
    }
}
exports.NotifyIncidentNotificationChannelMessage = NotifyIncidentNotificationChannelMessage;
class NotifySSLCertificateExpirationNotificationChannelMessage extends NotifyNotificationChannelMessage {
    constructor(monitor, monitorCertificateInformation, notificationChannel) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.SSL_CERTIFICATE_EXPIRATION, notificationChannel);
        this.monitor = monitor;
        this.monitorCertificateInformation = monitorCertificateInformation;
        this.notificationChannel = notificationChannel;
    }
}
exports.NotifySSLCertificateExpirationNotificationChannelMessage = NotifySSLCertificateExpirationNotificationChannelMessage;
class NotifyDomainNameExpirationNotificationChannelMessage extends NotifyNotificationChannelMessage {
    constructor(monitor, domainWhois, notificationChannel) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.DOMAIN_NAME_EXPIRATION, notificationChannel);
        this.monitor = monitor;
        this.domainWhois = domainWhois;
        this.notificationChannel = notificationChannel;
    }
}
exports.NotifyDomainNameExpirationNotificationChannelMessage = NotifyDomainNameExpirationNotificationChannelMessage;
const toNotifyNotificationIntegrationMessage = (message, integration) => {
    if (message.type === notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.INCIDENT) {
        const { incident, originalIncident } = message;
        return new notifyNotificationIntegrationMessage_1.NotifyIncidentNotificationIntegrationMessage(incident, originalIncident, integration);
    }
    else if (message.type === notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.DOMAIN_NAME_EXPIRATION) {
        const { monitor, domainWhois } = message;
        return new notifyNotificationIntegrationMessage_1.NotifyDomainNameExpirationMonitorIntegrationMessage(monitor, domainWhois, integration);
    }
    else if (message.type === notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.SSL_CERTIFICATE_EXPIRATION) {
        const { monitor, monitorCertificateInformation } = message;
        return new notifyNotificationIntegrationMessage_1.NotifySSLCertificateExpirationMonitorIntegrationMessage(monitor, monitorCertificateInformation, integration);
    }
    throw new Error(`Unable to convert to notification integration message ${JSON.stringify(message)}`);
};
exports.toNotifyNotificationIntegrationMessage = toNotifyNotificationIntegrationMessage;
const toNotifyOnCallScheduleMessage = (message) => {
    const { notificationChannel: { onCallScheduleId }, } = message;
    const notifySms = true;
    const notifyEmail = true;
    // const { notifySms = false, notifyEmail = false } = onCallScheduleConfiguration || {};
    if (message.type === notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.INCIDENT) {
        const { incident, originalIncident } = message;
        return new notifyOnCallScheduleMessage_1.NotifyOnCallScheduleIncidentMessage(incident, originalIncident, onCallScheduleId, notifySms, notifyEmail);
    }
    else if (message.type === notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.DOMAIN_NAME_EXPIRATION) {
        const { monitor, domainWhois } = message;
        return new notifyOnCallScheduleMessage_1.NotifyOnCallScheduleDomainNameExpirationMonitorMessage(monitor, domainWhois, onCallScheduleId, notifySms, notifyEmail);
    }
    else if (message.type === notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.SSL_CERTIFICATE_EXPIRATION) {
        const { monitor, monitorCertificateInformation } = message;
        return new notifyOnCallScheduleMessage_1.NotifyOnCallScheduleDomainNameExpirationMonitorMessage(monitor, monitorCertificateInformation, onCallScheduleId, notifySms, notifyEmail);
    }
    throw new Error(`Unable to convert to notification integration message ${JSON.stringify(message)}`);
};
exports.toNotifyOnCallScheduleMessage = toNotifyOnCallScheduleMessage;
