"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpMonitorAssertionOperationTypeValues = exports.HttpMonitorAssertionTypeValues = exports.HttpMonitorAssertionOperationType = exports.HttpMonitorAssertionType = void 0;
var HttpMonitorAssertionType;
(function (HttpMonitorAssertionType) {
    HttpMonitorAssertionType["UNAVAILABLE"] = "UNAVAILABLE";
    HttpMonitorAssertionType["STATUS_CODE"] = "STATUS_CODE";
    HttpMonitorAssertionType["RESPONSE_BODY"] = "RESPONSE_BODY";
    HttpMonitorAssertionType["HEADER"] = "HEADER";
    HttpMonitorAssertionType["JSON_RESPONSE_BODY_PROPERTY"] = "JSON_RESPONSE_BODY_PROPERTY";
})(HttpMonitorAssertionType = exports.HttpMonitorAssertionType || (exports.HttpMonitorAssertionType = {}));
var HttpMonitorAssertionOperationType;
(function (HttpMonitorAssertionOperationType) {
    HttpMonitorAssertionOperationType["EQUALS"] = "EQUALS";
    HttpMonitorAssertionOperationType["NOT_EQUALS"] = "NOT_EQUALS";
    HttpMonitorAssertionOperationType["GREATER_THAN"] = "GREATER_THAN";
    HttpMonitorAssertionOperationType["GREATER_THAN_OR_EQUAL"] = "GREATER_THAN_OR_EQUAL";
    HttpMonitorAssertionOperationType["LESS_THAN"] = "LESS_THAN";
    HttpMonitorAssertionOperationType["LESS_THAN_OR_EQUAL"] = "LESS_THAN_OR_EQUAL";
    HttpMonitorAssertionOperationType["IS_ONE_OF"] = "IS_ONE_OF";
})(HttpMonitorAssertionOperationType = exports.HttpMonitorAssertionOperationType || (exports.HttpMonitorAssertionOperationType = {}));
exports.HttpMonitorAssertionTypeValues = Object.values(HttpMonitorAssertionType);
exports.HttpMonitorAssertionOperationTypeValues = Object.values(HttpMonitorAssertionOperationType);
