const required = { value: true, message: 'This field is mandatory' };
const passwordMin6Characters = {
  value: 6,
  message: 'Password must consist of at least 6 characters',
};

const conditionalRequired = (condition: boolean) => {
  return (value) => {
    if (!condition) {
      return true;
    }

    return value !== null && value !== undefined ? null : 'This field is mandatory';
  };
};

const formValidationRules = {
  required,
  conditionalRequired,
  passwordMin6Characters,
};

export const prepareRules = (rules) => {
  if (!rules) {
    return rules;
  }

  const keys = Object.keys(rules);

  return keys.reduce((agg, key) => {
    if (typeof rules[key] === 'boolean' && rules[key] && formValidationRules[key]) {
      return { ...agg, [key]: formValidationRules[key] };
    } else if (rules[key]) {
      return { ...agg, [key]: rules[key] };
    }

    return agg;
  }, {});
};

export default formValidationRules;
