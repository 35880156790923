import React, { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import Spinner from '../spinner/spinner';
import { OnCallScheduleFormModel } from '../../models/onCallScheduleFormModel';
import { useOnCallScheduleMutation, useOnCallScheduleQuery } from '../../query/onCallScheduleQueries';
import { MutationAction } from '../../query/mutationAction';
import toast from 'react-hot-toast';
import { dismissibleToast } from '../../utils/dismissibleToast';
import { OnCallScheduleModelVO } from '@um/uptime-monitoring-shared';
import { format } from 'date-fns';
import onCallScheduleForm from '../on-call-schedule-form/onCallScheduleForm';
import { formatInTimeZone } from 'date-fns-tz';

type Props = {
  onCallScheduleId: string;
  onUpdated?: (data: OnCallScheduleModelVO) => void;
};

const OnCallScheduleForm = lazy(() => import('../on-call-schedule-form/onCallScheduleForm'));

const EditOnCallScheduleForm: React.FC<Props> = ({ onCallScheduleId, onUpdated }) => {
  const [formErrors, setFormErrors] = useState([]);
  const { mutateAsync, isLoading } = useOnCallScheduleMutation();
  const { isLoading: onCallScheduleLoading, data: onCallSchedule } = useOnCallScheduleQuery(onCallScheduleId);

  const handleSubmit = useCallback(async (data: OnCallScheduleFormModel) => {
    await mutateAsync(
      { onCallScheduleId, updateRequest: data, action: MutationAction.PUT },
      {
        onSuccess: (data: OnCallScheduleModelVO) => {
          toast.success('On-call schedule updated');

          onUpdated && onUpdated(data);
        },
        onError: (error: any) => {
          const errorResponse = error?.response?.data;
          if (errorResponse && errorResponse.errorType === 'VALIDATION_FAILED') {
            toast.error(dismissibleToast(errorResponse.message || 'Please fix errors in the form'), {
              duration: 10000,
            });

            setFormErrors(errorResponse.errors);
          } else {
            toast.error('Error occurred. Please try again later');
          }
        },
      }
    );
  }, []);
  const formValues: OnCallScheduleFormModel = useMemo(() => {
    if (!onCallSchedule || onCallScheduleLoading) {
      return null;
    }

    return {
      ...onCallSchedule,
      startDate: formatInTimeZone(onCallSchedule.startDate, onCallSchedule.timezone, 'yyyy-MM-dd'),
    };
  }, [onCallSchedule, onCallScheduleLoading]);

  if (onCallScheduleLoading) {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <OnCallScheduleForm mainActionText="Save" formValues={formValues} onSubmit={handleSubmit} disabled={isLoading} formErrors={formErrors} />
    </Suspense>
  );
};

export default EditOnCallScheduleForm;
