import { useFormContext } from 'react-hook-form';
import React, { useCallback } from 'react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import ControlledCheckbox from '../checkbox/checkbox';
import { VStack } from '@chakra-ui/react';
import { useAppSelector } from '../../app/store';
import selectors from '../../app/selectors';
import { PlanFeature } from '@um/uptime-monitoring-shared';
import actions from '../../app/actions';
import { UpgradeModalReason } from '../../app/model/upgradeModalReason';
import { useDispatch } from 'react-redux';
import { MonitorFormModel } from '../../models/monitorFormModel';
import useHasFeature from '../../hooks/useHasFeature';

const DomainNameMonitorOptionsFormFields: React.FC = () => {
  const { watch, setValue } = useFormContext<MonitorFormModel>();
  const dispatch = useDispatch();
  const canMonitorDomainName = useHasFeature(PlanFeature.DOMAIN_NAME_MONITORING);

  const notify1DayBeforeExpirationField = 'httpMonitorOptions.domainNameMonitorOptions.notify1DayBeforeExpiration';
  const notify7DaysBeforeExpirationField = 'httpMonitorOptions.domainNameMonitorOptions.notify7DaysBeforeExpiration';
  const notify14DaysBeforeExpirationField = 'httpMonitorOptions.domainNameMonitorOptions.notify14DaysBeforeExpiration';
  const enabledFieldName = 'httpMonitorOptions.monitorDomainName';
  const enabled = watch(enabledFieldName);

  const handleEnabledChange = useCallback(
    (checked: boolean) => {
      if (checked && !canMonitorDomainName) {
        dispatch(actions.upgrade.showUpgradeModal(UpgradeModalReason.DOMAIN_NAME_CHECK_NOT_AVAILABLE));
        return false;
      }

      setValue(notify1DayBeforeExpirationField, checked);
      setValue(notify7DaysBeforeExpirationField, checked);
      setValue(notify14DaysBeforeExpirationField, checked);

      return true;
    },
    [canMonitorDomainName]
  );

  return (
    <FormFieldWrapper title="Domain name monitoring" description="Get notified when your website's domain name is about to expire">
      <ControlledCheckbox name={enabledFieldName} fontWeight="bold" onChange={handleEnabledChange}>
        Monitor domain name expiration
      </ControlledCheckbox>
      <VStack pl={8} mt={4} alignItems="flex-start">
        <ControlledCheckbox isDisabled={!enabled} name={notify1DayBeforeExpirationField}>
          Notify 1 day before expiration
        </ControlledCheckbox>
        <ControlledCheckbox isDisabled={!enabled} name={notify7DaysBeforeExpirationField}>
          Notify 7 days before expiration
        </ControlledCheckbox>
        <ControlledCheckbox isDisabled={!enabled} name={notify14DaysBeforeExpirationField}>
          Notify 14 days before expiration
        </ControlledCheckbox>
      </VStack>
    </FormFieldWrapper>
  );
};

export default DomainNameMonitorOptionsFormFields;
