import React, { useMemo, useRef } from 'react';
import { generatePath, Link, LinkProps } from 'react-router-dom';
import { Box, Button, Flex, Grid, GridProps, Icon } from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import { useOrganizationMonitorGroupsQuery } from '../../query/queries';
import MonitorGroupStatusIcon from '../monitor-group-status-icon/monitorGroupStatusIcon';
import Routes from '../../utils/routes';
import AppLink from '../app-link/appLink';
import DataTable from '../data-table/dataTable';

interface Props {
  organizationId?: string;
}

const MonitorGroupsTable: React.FC<Props> = ({ organizationId }) => {
  const tableRef = useRef();
  const queryData = useOrganizationMonitorGroupsQuery(organizationId);
  const { isFetched, data: monitorGroups, isLoading } = queryData;
  const noMonitorGroups = isFetched && monitorGroups?.length < 1;

  return (
    <div ref={tableRef}>
      <DataTable showLoading={!isFetched || isLoading}>
        {() => (
          <>
            {!noMonitorGroups && (
              <>
                <DataTable.Head>
                  <DataTable.HeaderRow>
                    <DataTable.HeaderCell>Status</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Name</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Monitors</DataTable.HeaderCell>
                    <DataTable.HeaderCell />
                  </DataTable.HeaderRow>
                </DataTable.Head>
                <DataTable.Body>{isFetched && monitorGroups.map((item) => <MonitorGroupRow key={item.id} monitorGroup={item} />)}</DataTable.Body>
              </>
            )}
            {noMonitorGroups && (
              <DataTable.Body>
                <DataTable.Row>
                  <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                    <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                      <Box>You don't have any monitor groups yet.</Box>
                      <Box>
                        <Button as={Link} to={Routes.CREATE_MONITOR_GROUP} variant="outline" colorScheme="blue" size="md" textDecoration="underline">
                          Create one now
                        </Button>
                      </Box>
                    </Flex>
                  </DataTable.Cell>
                </DataTable.Row>
              </DataTable.Body>
            )}
          </>
        )}
      </DataTable>
    </div>
  );
};

const MonitorGroupRow = ({ monitorGroup }) => {
  const href = useMemo(
    () =>
      generatePath(Routes.MONITOR_GROUP_OVERVIEW, {
        id: monitorGroup.id,
      }),
    []
  );

  return (
    <DataTable.LinkRow to={href}>
      <DataTable.Cell overflow="visible" tooltip={null}>
        <MonitorGroupStatusIcon monitorGroup={monitorGroup} />
      </DataTable.Cell>
      <DataTable.Cell>
        <Box>{monitorGroup.name || '-'}</Box>
      </DataTable.Cell>
      <DataTable.Cell>{monitorGroup.monitorIds.length}</DataTable.Cell>
      <DataTable.Cell tooltip={null}>
        <Icon as={BsChevronRight} />
      </DataTable.Cell>
    </DataTable.LinkRow>
  );
};

type StyledGridProps = GridProps &
  Partial<LinkProps> & {
    displayChevron?: boolean;
  };

const StyledGrid: React.FC<StyledGridProps> = ({ displayChevron, ...rest }) => {
  const templateColumns = displayChevron ? '120px minmax(50px, auto) 120px 80px' : '120px minmax(50px, auto) 120px';

  return <Grid templateColumns={{ base: 'minmax(0, 100%)', md: templateColumns }} width="100%" gap={4} px={{ base: 4, md: 0 }} py={2} {...rest} />;
};

export default MonitorGroupsTable;
