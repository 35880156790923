import React, { useMemo, useRef } from 'react';
import Routes from '../../utils/routes';
import { generatePath } from 'react-router-dom';
import { Avatar, AvatarGroup, Box, Flex, Icon } from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import DataTable from '../data-table/dataTable';
import { useOrganizationOnCallSchedulesQuery } from '../../query/onCallScheduleQueries';
import { useOrganizationUsersQuery } from '../../query/userQueries';
import { combineUsersName } from '../../utils/combineUsersName';
import { useOrganizationOnCallDutiesQuery } from '../../query/onCallDutyQueries';
import UserLabel from '../user-label/userLabel';

const OnCallSchedulesTable = ({ organizationId }) => {
  const tableRef = useRef();
  const {
    data: onCallSchedulesData,
    isLoading,
    isFetching,
    isInitialLoading,
    isRefetching,
    isFetched,
  } = useOrganizationOnCallSchedulesQuery(organizationId);
  const { data: usersData, isFetching: isFetchingUsers } = useOrganizationUsersQuery(organizationId);
  const { data: onCallDutiesData, isFetching: isFetchingOnCallDuties } = useOrganizationOnCallDutiesQuery(organizationId);

  const { result: users, hash: usersHash } = usersData || {};
  const { result: onCallSchedules, hash: onCallSchedulesHash } = onCallSchedulesData || {};
  const { result: onCallDuties, hash: onCallDutiesHash } = onCallDutiesData || {};

  const showLoading = (isFetching || isRefetching || isFetchingUsers || isFetchingOnCallDuties) && !isFetched;

  const noOnCallSchedules = onCallSchedules?.length < 1;

  const scheduleData = useMemo(() => {
    return (onCallSchedules || []).map((onCallSchedule) => ({
      ...onCallSchedule,
      onCall: (onCallDuties || []).find((onCallDuty) => onCallDuty.onCallScheduleId === onCallSchedule.id),
      users: (onCallSchedule.users || []).map((scheduleUser) => {
        const user = (users || []).find((item) => item.id === scheduleUser.userId);

        return { ...scheduleUser, name: combineUsersName(user) };
      }),
    }));
  }, [onCallSchedulesHash, usersHash, onCallSchedules, users, onCallDuties, onCallDutiesHash]);

  return (
    <div ref={tableRef}>
      <DataTable showLoading={isInitialLoading} showPagination={false} columnsCount={7} paginationDisabled>
        {() => (
          <>
            {!noOnCallSchedules && (
              <>
                <DataTable.Head>
                  <DataTable.HeaderRow>
                    <DataTable.HeaderCell>Name</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Currently on-call</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Users</DataTable.HeaderCell>
                    <DataTable.HeaderCell />
                  </DataTable.HeaderRow>
                </DataTable.Head>
                <DataTable.Body showLoading={showLoading}>
                  {!isLoading &&
                    scheduleData.map((item) => (
                      <DataTable.LinkRow key={item.id} to={generatePath(Routes.ON_CALL_SCHEDULE_OVERVIEW, { id: item.id })}>
                        <DataTable.Cell>{item.name}</DataTable.Cell>
                        <DataTable.Cell tooltip={null}>{item.onCall ? <UserLabel user={item.onCall} /> : '-'}</DataTable.Cell>
                        <DataTable.Cell tooltip={null}>
                          <UsersCell users={item.users} />
                        </DataTable.Cell>
                        <DataTable.Cell tooltip={null} maxWidth="30px" justifyContent="flex-end">
                          <Icon as={BsChevronRight} />
                        </DataTable.Cell>
                      </DataTable.LinkRow>
                    ))}
                </DataTable.Body>
              </>
            )}
            {noOnCallSchedules && (
              <DataTable.Body>
                <DataTable.Row>
                  <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                    <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                      <Box>Once there are any on call schedules they will be displayed here.</Box>
                    </Flex>
                  </DataTable.Cell>
                </DataTable.Row>
              </DataTable.Body>
            )}
          </>
        )}
      </DataTable>
    </div>
  );
};

const UsersCell = ({ users }) => {
  return (
    <AvatarGroup size="sm" max={3}>
      {users.map((user) => (
        <Avatar key={user.userId} name={user.name} />
      ))}
    </AvatarGroup>
  );
};

export default OnCallSchedulesTable;
