import React, { useState } from 'react';
import NotificationIntegrationListItem, { IntegrationListSegmentProps } from './notificationIntegrationListItem';
import { Box, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import Toggle from '../toggle/toggle';
import icons from '../../icons';

const SlackIntegrationListSegment: React.FC<IntegrationListSegmentProps> = ({ integration, onToggleIntegration }) => {
  const [enabled, setEnabled] = useState(integration.enabled);

  const handleToggleIntegration = (evt) => {
    const updated = !enabled;
    setEnabled(updated);

    onToggleIntegration(evt, integration.id, updated);
  };

  return (
    <NotificationIntegrationListItem integration={integration}>
      <HStack gap={4}>
        <Box>
          <Icon as={icons.SlackIcon} boxSize={12} />
        </Box>
        <Flex flexDirection="column" whiteSpace="normal" gap={2}>
          <Text as="div" fontSize="xl" fontWeight="bold">
            {integration.name}
          </Text>
          <Text as="div" color="gray.600" whiteSpace="normal">
            Notifications will be sent to Slack channel
          </Text>
        </Flex>
        <Box flexGrow={1}>
          <Toggle value={enabled} onChange={handleToggleIntegration} />
        </Box>
      </HStack>
    </NotificationIntegrationListItem>
  );
};

export default SlackIntegrationListSegment;
