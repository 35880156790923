import { all, call, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import { RootState } from '../../store';
import { AppConfig } from '../../../utils/config';
import { eventChannel } from 'redux-saga';
import { ApplicationEventType, WebsocketApplicationEventMessage } from '@um/uptime-monitoring-shared';
import EventEmitter from 'events';
import { logError } from '../../../utils/logError';

export const applicationEventEmitter = new EventEmitter();

applicationEventEmitter.setMaxListeners(30);

function wsChannel(websocket) {
  return eventChannel((emitter) => {
    websocket.onmessage = (e) => {
      try {
        const message = JSON.parse(e.data) as WebsocketApplicationEventMessage;

        applicationEventEmitter.emit('message', message);

        if (message.messageType === ApplicationEventType.MONITOR_CHECK_UPDATED) {
          return emitter(actions.websocket.onMonitorCheckUpdatedEvent(message.payload));
        } else if (message.messageType === ApplicationEventType.MONITOR_CHECK_CREATED) {
          return emitter(actions.websocket.onMonitorCheckCreatedEvent(message.payload));
        }
      } catch (e: any) {
        console.log('Unable to parse event', e.data);
      }
    };

    websocket.onclose = function (e) {
      return emitter(actions.websocket.websocketClosed());
    };

    websocket.onerror = (err) => {
      console.error('Socket encountered error: ', err, 'Closing socket');
      websocket.close();
    };

    return () => {
      // do whatever to interrupt the socket communication here
    };
  });
}

function* initialize() {
  try {
    const initialized = yield select((state: RootState) => state.websocket.initialized);

    if (initialized) {
      return;
    }
    yield put(actions.websocket.setInitialized(true));

    yield fork(connect);
  } catch (e) {
    logError(e);
  }
}

function* connect() {
  while (true) {
    try {
      const connecting = yield select((state: RootState) => state.websocket.connecting);

      if (connecting) {
        return;
      }

      yield put(actions.websocket.setConnecting(true));
      yield put(actions.websocket.setInitialized(true));

      const ws = new WebSocket(`${AppConfig.WebsocketHost}/ws`);
      yield call(watchRequests, ws);
    } catch (e) {
      console.log('Websocket not connected', e);
    } finally {
      yield delay(5000);
      yield put(actions.websocket.setConnecting(false));
    }
  }
}

function* watchRequests(websocket) {
  try {
    const requestChan = yield call(wsChannel, websocket);

    while (true) {
      const action = yield take(requestChan);

      if (action.type === actions.websocket.websocketClosed.type) {
        console.log('websocket closed');
        return;
      }

      if (action) {
        yield put(action);
      }
    }
  } catch (e) {
    console.log('watchRequests errored', e);
  }
}

export default function* websocketSaga() {
  yield all([takeEvery(actions.websocket.initialize.type, initialize)]);
}
