import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { NotificationsHistoryResponse } from '@um/uptime-monitoring-shared';
import { logError } from '../../../utils/logError';
import { getSelectedOrganizationId } from '../../../utils/getSelectedOrganizationId';

function* initialize() {
  try {
    const organizationId = yield call(getSelectedOrganizationId);
    const notificationsHistoryResponse: NotificationsHistoryResponse = yield call(statusPingClient.protectedGetNotificationsHistory, organizationId);

    yield put(actions.notificationsOverviewTab.setNotificationsHistory(notificationsHistoryResponse.notificationsHistory));

    yield put(actions.notificationsOverviewTab.setInitialized(true));
  } catch (e) {
    logError(e);
  }
}

export default function* notificationsOverviewTabSaga() {
  yield all([takeEvery(actions.notificationsOverviewTab.initialize.type, initialize)]);
}
