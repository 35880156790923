import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { dismissibleToast } from '../utils/dismissibleToast';
import Spinner from '../components/spinner/spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from '../app/actions';
import { Box, Flex } from '@chakra-ui/react';
import { useVerifyEmailAddressMutation } from '../query/queries';
import { MutationAction } from '../query/mutationAction';
import Routes from '../utils/routes';

const VerifyEmailAddressPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { mutate } = useVerifyEmailAddressMutation({
    onSuccess: () => {
      toast.success('Email address has been verified! You will now be redirected to the application', {
        duration: 5000,
      });

      setTimeout(() => {
        dispatch(actions.app.loggedIn());
      }, 4000);
    },
    onError: () => {
      toast.error(dismissibleToast('Unable to verify email address. Please try again later'), { duration: 99999999 });

      navigate(Routes.LOGIN);
    },
  });

  useEffect(() => {
    const token = searchParams.get('token');

    if (!token) {
      return;
    }

    mutate({ token, action: MutationAction.POST });
  }, []);

  return (
    <Flex minH="100%" alignItems="center" justifyContent="center" py={12} px={{ base: 4, sm: 6, lg: 8 }}>
      <Flex maxW="container.md" w="100%" flexDirection="column" gap={8}>
        <div>
          <Box as="img" mx="auto" maxHeight="64px" w="auto" src="/logo.png" alt="Status Ping logo" />
          <Box as="h2" mt={6} textAlign="center" fontSize="3xl">
            Verify email address
          </Box>
        </div>
        <Spinner />
      </Flex>
    </Flex>
  );
};

export default VerifyEmailAddressPage;
