"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class OrganizationUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(updatedOrganization, originalOrganization) {
        super(model_1.QueueDefinitions.ORGANIZATION_UPDATED);
        this.updatedOrganization = updatedOrganization;
        this.originalOrganization = originalOrganization;
    }
    getRoutingKey() {
        var _a, _b;
        return ((_a = this.updatedOrganization) === null || _a === void 0 ? void 0 : _a.id) || ((_b = this.originalOrganization) === null || _b === void 0 ? void 0 : _b.id) || super.getRoutingKey();
    }
}
exports.OrganizationUpdatedMessage = OrganizationUpdatedMessage;
