import { call, put, takeEvery } from 'redux-saga/effects';
import actions from '../../actions';
import statusPingClient from '../../client/statusPingClient';
import { logError } from '../../../utils/logError';

export function* initialize(action) {
  try {
    const monitorExecutionId = action.payload;

    const monitorExecution = yield call(statusPingClient.protectedGetMonitorCheck, monitorExecutionId);
    const monitor = yield call(statusPingClient.protectedGetMonitor, monitorExecution.monitorId);

    yield put(actions.monitorCheckDetails.setMonitor(monitor));
    yield put(actions.monitorCheckDetails.setMonitorCheck(monitorExecution));

    yield put(actions.monitorCheckDetails.setInitialized(true));
  } catch (e) {
    logError(e);
  }
}

export default function* monitorsSaga() {
  yield takeEvery(actions.monitorCheckDetails.initialize.type, initialize);
}
