import { Flex, Icon, IconProps, Tooltip } from '@chakra-ui/react';
import { IntegrationType } from '@um/uptime-monitoring-shared';
import React from 'react';
import { IntegrationTypeOptions } from '../../models/integrationTypeOptions';

interface Props extends IconProps {
  type: IntegrationType;
}

const options = IntegrationTypeOptions.reduce((agg, item) => {
  return { ...agg, [item.type]: item };
}, {});

const NotificationIntegrationIcon: React.FC<Props> = ({ type, ...rest }) => {
  const option = options[type];

  if (!option) {
    return null;
  }

  return (
    <Tooltip label={option.name}>
      <span>
        <Flex alignItems="center" justifyContent={{ base: 'flex-start', md: 'center' }}>
          <Icon as={option.icon} boxSize={6} fill="red" color="red" {...rest} />
        </Flex>
      </span>
    </Tooltip>
  );
};

export default NotificationIntegrationIcon;
