import { useAppSelector } from '../../app/store';
import Routes from '../../utils/routes';
import { Flex } from '@chakra-ui/react';
import PageHeader from '../../components/page-header/pageHeader';
import EmptyPageTopMenu from '../../components/empty-page-header/emptyPageHeader';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import React from 'react';
import CreateOnCallScheduleForm from '../../components/create-on-call-schedule-form/createOnCallScheduleForm';
import { useNavigate } from 'react-router-dom';

const CreateOnCallSchedulePage = () => {
  const navigate = useNavigate();
  const { initialized } = useAppSelector((state) => state.app);
  const breadcrumbs = [
    { title: 'Settings', route: Routes.SETTINGS },
    { title: 'On-call schedules', route: Routes.ON_CALL_SCHEDULES },
  ];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={!initialized} title="Create" breadcrumbs={breadcrumbs} />
      <EmptyPageTopMenu />
      <PageWrapper loading={!initialized} p={4}>
        <CreateOnCallScheduleForm onCreated={() => navigate(Routes.ON_CALL_SCHEDULES)} />
      </PageWrapper>
    </Flex>
  );
};

export default CreateOnCallSchedulePage;
