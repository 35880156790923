"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnCallScheduleUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class OnCallScheduleUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(onCallScheduleId, onCallSchedule, originalOnCallSchedule) {
        super(model_1.QueueDefinitions.ON_CALL_SCHEDULE_UPDATED);
        this.onCallScheduleId = onCallScheduleId;
        this.onCallSchedule = onCallSchedule;
        this.originalOnCallSchedule = originalOnCallSchedule;
    }
}
exports.OnCallScheduleUpdatedMessage = OnCallScheduleUpdatedMessage;
