import PageWrapper from '../page-wrapper/pageWrapper';
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import CompleteOrganizationSetupForm from './completeOrganizationSetupForm';

type Props = {
  onSetupCompleted?: () => void;
};

export const CompleteOrganizationSetupPage: React.FC<Props> = ({ onSetupCompleted }) => {
  return (
    <PageWrapper>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" gap={4} py={8} px={4}>
        <Box fontSize="3xl" fontWeight="bold">
          Complete organization setup
        </Box>
        <Box maxW="container.sm" width="100%">
          <CompleteOrganizationSetupForm onSetupCompleted={onSetupCompleted} />
        </Box>
      </Flex>
    </PageWrapper>
  );
};
