import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { MonitorGroupResponse } from '@um/uptime-monitoring-shared';
import { MutationAction } from './mutationAction';
import { MonitorGroupFormModel } from '../components/monitor-group-form/monitorGroupFormModel';
import queryKeys from './queryKeys';

export * from './statusPageQueries';
export * from './organizationQueries';

export interface MonitorGroupMutationParameters {
  monitorGroupId: string;
  monitorGroup?: MonitorGroupFormModel;
  action: MutationAction;
}

export const useMonitorGroupQuery = (monitorGroupId: string, options: UseQueryOptions<MonitorGroupResponse> = {}) => {
  return useQuery(['monitor-group', monitorGroupId], () => statusPingClient.protectedGetMonitorGroup(monitorGroupId), options);
};

export const useMonitorGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ monitorGroupId, monitorGroup, action }: MonitorGroupMutationParameters) => {
      if (action === MutationAction.PATCH) {
        return await statusPingClient.protectedPatchMonitorGroup(monitorGroupId, monitorGroup);
      } else if (action === MutationAction.DELETE) {
        return await statusPingClient.protectedDeleteMonitorGroup(monitorGroupId);
      }

      return null;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['monitor-group']),
    }
  );
};

export const useVerifyEmailAddressMutation = (options = {}) => {
  return useMutation<any, any, any>(
    async ({ token, action }) => {
      if (action === MutationAction.POST) {
        return await statusPingClient.publicPostVerifyEmailAddress(token);
      }

      return null;
    },
    { ...options, retry: false }
  );
};

export const useOrganizationMonitorOptionsQuery = (organizationId: string) => {
  return useQuery(['monitors', 'organization', organizationId, 'options'], () => statusPingClient.protectedGetMonitorOptions(organizationId), {
    enabled: !!organizationId,
  });
};

export const useOrganizationMonitorsQuery = (organizationId: string, refetchInterval?: number | false) => {
  return useQuery(['monitors', 'organization', organizationId], () => statusPingClient.protectedGetMonitors({ organizationId }), {
    enabled: !!organizationId,
    refetchInterval,
  });
};

export const useOrganizationDowntimeStatisticsQuery = (organizationId: string, refetchInterval?: number | false) => {
  return useQuery(queryKeys.organizationMonitorStatistics(organizationId), () => statusPingClient.protectedGetStatistics({ organizationId }), {
    enabled: !!organizationId,
    refetchInterval,
  });
};

export const useMonitorGroupDowntimeStatisticsQuery = (monitorGroupId: string, refetchInterval?: number | false) => {
  return useQuery(['monitor-statistics', 'monitor-group-id', monitorGroupId], () => statusPingClient.protectedGetStatistics({ monitorGroupId }), {
    enabled: !!monitorGroupId,
    refetchInterval,
  });
};

export const useOrganizationMonitorGroupsQuery = (organizationId: string, refetchInterval?: number | false) => {
  return useQuery(['monitor-group', 'organization', organizationId], () => statusPingClient.protectedGetMonitorGroups(organizationId), {
    enabled: !!organizationId,
    refetchInterval,
  });
};

export const useMonitorGroupNotificationIntegrationsQuery = (monitorGroupId: string, refetchInterval?: number | false) => {
  return useQuery(
    ['monitor-group', monitorGroupId, 'integrations'],
    () => statusPingClient.protectedGetNotificationIntegrations({ monitorGroupId }),
    {
      enabled: Boolean(monitorGroupId),
      refetchInterval,
      cacheTime: 0,
      staleTime: 0,
    }
  );
};

export const useMonitorQuery = (monitorId: string) => {
  return useQuery(['monitors', monitorId], () => statusPingClient.protectedGetMonitor(monitorId), {
    enabled: !!monitorId,
  });
};

export const useMonitorDowntimeStatisticsQuery = (monitorId: string) => {
  return useQuery(
    ['monitor-statistics', monitorId],
    async () => {
      const downtimeStatistics = await statusPingClient.protectedGetStatistics({ monitorId });

      return (downtimeStatistics || []).length > 0 ? downtimeStatistics[0] : null;
    },
    {
      enabled: !!monitorId,
    }
  );
};

export const usePeriodicMonitorResponseTimeStatisticsQuery = (monitorId: string) => {
  return useQuery(
    ['monitor-statistics', monitorId, 'response-time-periodic'],
    () => statusPingClient.protectedGetPeriodicMonitorResponseTimeStatistics({ monitorId }),
    {
      enabled: !!monitorId,
      keepPreviousData: true,
    }
  );
};

export const usePeriodicMonitorDowntimeStatisticsQuery = (monitorId: string) => {
  return useQuery(
    ['monitor-statistics', monitorId, 'downtime-periodic'],
    () => statusPingClient.protectedGetPeriodicMonitorDowntimeStatistics({ monitorId }),
    {
      enabled: !!monitorId,
      keepPreviousData: true,
    }
  );
};

export const useMonitorGroupMonitorsQuery = (monitorGroupId: string) => {
  return useQuery(['monitors', 'monitor-group-id', monitorGroupId], () => statusPingClient.protectedGetMonitors({ monitorGroupId }), {
    enabled: !!monitorGroupId,
  });
};

export const useMonitorLatestChecks = (monitorId: string) => {
  return useQuery(
    ['monitor-checks', 'monitor', monitorId],
    async () => {
      const response = await statusPingClient.protectedGetMonitorChecks(monitorId);

      return response.monitorChecks;
    },
    {
      enabled: Boolean(monitorId),
    }
  );
};
