import axios, { AxiosInstance } from 'axios';
import { AppConfig } from '../../utils/config';
import { Organization } from '../model/organization';
import { IntegrationFormModel } from '../../components/integration-form/integrationForm';

import {
  CompleteUserProfileVO,
  CreateMonitorGroupVO,
  CreateMonitorVO,
  CreateNotificationPolicyVO,
  CreateOnCallScheduleVO,
  CreateOrganizationTrialVO,
  CreateStatusPageIncidentUpdateVO,
  CreateStatusPageIncidentVO,
  CreateStatusPageVO,
  CustomerPortalSessionVO,
  DowntimeStatisticsVO,
  GetDowntimeStatisticsVO,
  GetMonitorPeriodDowntimeStatisticsVO,
  IncidentResponse,
  IncidentWithMetadata,
  IntegrationResponse,
  MonitorCheckResponse,
  MonitorChecksResponse,
  MonitorGroupResponse,
  MonitorGroupsResponse,
  MonitorOptionVO,
  MonitorStatisticsTimeframe,
  MonitorStatisticsVO,
  MonitorStatisticsWindow,
  NotificationChannelAssignment,
  NotificationChannelAssignmentEntity,
  NotificationChannelOptionVO,
  NotificationHistoryResponse,
  NotificationPolicy,
  NotificationPolicyModelVO,
  NotificationsHistoryResponse,
  OnCallDuty,
  OnCallScheduleCalendarEventsRequestVO,
  OnCallScheduleCalendarEventVO,
  OnCallScheduleModelVO,
  OrganizationResponse,
  OrganizationSubscriptionsResponse,
  OrganizationUserResponse,
  PeriodicMonitorResponseTimeStatisticsResponse,
  Plan,
  ProtectedFindMonitorsVO,
  ProtectedGetIncidentsRequest,
  ProtectedMonitorDetailsResponse,
  ProtectedPatchStatusPageIncidentRequest,
  ProtectedPeriodicMonitorDowntimeStatisticsResponse,
  PublicPostLoginRequest,
  SetupOrganizationVO,
  SignUpRequest,
  StatusPage,
  StatusPageIncidentModelVO,
  StatusPageIncidentUpdate,
  StatusPageModelVO,
  StatusPageResourceOptionVO,
  StripeCheckoutSessionVO,
  UpdateMonitorGroupVO,
  UpdateNotificationIntegrationVO,
  UpdateNotificationPolicyVO,
  UpdateOnCallScheduleVO,
  UpdateOrganizationVO,
  UpdateStatusPageVO,
  UpdateUserVO,
  UserResponse,
  UserSettings,
  UserSettingsResponse,
} from '@um/uptime-monitoring-shared';
import { prepareRequestParams } from '../../utils/prepareRequestParams';
import { logError } from '../../utils/logError';
import { GetIncidentsRequest } from '../model/getIncidentsRequest';
import { MonitorFormModel } from '../../models/monitorFormModel';

class StatusPingClient {
  public client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: AppConfig.ApiBaseUrl,
      withCredentials: true,
    });
  }

  protectedGetMonitors = async (request: ProtectedFindMonitorsVO): Promise<ProtectedMonitorDetailsResponse[]> => {
    const response = await this.client.get(`/v1/protected/monitor`, {
      params: request,
    });

    return response.data.monitors;
  };

  protectedGetUserOrganizations = async (): Promise<OrganizationResponse[]> => {
    try {
      const response = await this.client.get('/v1/protected/organization');

      return response.data.organizations;
    } catch (e: any) {
      if (e.response?.status === 403) {
        return [];
      }

      throw e;
    }
  };

  protectedGetUserOrganizationSubscriptions = async (id: string): Promise<OrganizationSubscriptionsResponse> => {
    const response = await this.client.get(`/v1/protected/organization/${id}/subscription`);

    return response.data;
  };

  protectedGetUser = async (): Promise<UserResponse> => {
    try {
      const response = await this.client.get('/v1/protected/user');

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 403) {
        return null;
      }

      throw e;
    }
  };

  protectedGetUserSettings = async (token?: string): Promise<UserSettingsResponse> => {
    const response = await this.client.get('/v1/protected/user/settings', {
      params: { token },
    });

    return response.data;
  };

  protectedPatchUser = async (request: UpdateUserVO): Promise<UserResponse> => {
    const response = await this.client.patch('/v1/protected/user', request);

    return response.data;
  };

  protectedPatchUserSettings = async (request: Partial<UserSettings>, token?: string): Promise<UserResponse> => {
    const response = await this.client.patch(
      '/v1/protected/user/settings',
      {
        subscribedToWeeklyMonitorReport: request.subscribedToWeeklyMonitorReport,
        subscribedToProductNewsletter: request.subscribedToProductNewsletter,
      },
      { params: { token } }
    );

    return response.data;
  };

  protectedPatchOrganization = async (id: string, request: UpdateOrganizationVO): Promise<OrganizationResponse> => {
    const response = await this.client.patch(`/v1/protected/organization/${id}`, request);

    return response.data;
  };

  protectedPostOrganization = async (organization: Organization): Promise<Organization> => {
    const response = await this.client.post('/v1/protected/organization', {
      name: organization.name,
      description: organization.description,
    });

    return response.data;
  };

  protectedPostOrganizationUser = async (organizationId: string, email: string): Promise<void> => {
    await this.client.post(`/v1/protected/organization/${organizationId}/user`, {
      email,
    });
  };

  protectedDeleteOrganizationUser = async (organizationId: string, userId: string): Promise<void> => {
    await this.client.delete(`/v1/protected/organization/${organizationId}/user/${userId}`);
  };

  protectedPostMonitor = async (monitor: MonitorFormModel): Promise<ProtectedMonitorDetailsResponse> => {
    const request: CreateMonitorVO = {
      name: monitor.name,
      description: monitor.description,
      organizationId: monitor.organizationId,
      transport: monitor.transport,
      schedule: monitor.schedule,
      httpMonitorOptions: monitor.httpMonitorOptions,
      icmpPingMonitorOptions: monitor.icmpPingMonitorOptions,
      notificationChannels: monitor.notificationChannels,
      checkInMonitorOptions: monitor.checkInMonitorOptions,
      enabled: monitor.enabled,
    };
    const response = await this.client.post('/v1/protected/monitor', request);

    return response.data;
  };

  protectedPostResetMonitor = async (id: string): Promise<ProtectedMonitorDetailsResponse> => {
    const response = await this.client.post(`/v1/protected/monitor/${id}/reset`);

    return response.data;
  };

  protectedPostIncidentAcknowledge = async (incidentId: string): Promise<IncidentWithMetadata> => {
    const response = await this.client.post(`/v1/protected/incident/${incidentId}/acknowledge`);

    return response.data;
  };

  protectedPostMonitorGroup = async (monitorGroup: CreateMonitorGroupVO): Promise<MonitorGroupResponse> => {
    const response = await this.client.post('/v1/protected/monitor-groups', monitorGroup);

    return response.data;
  };

  protectedPostMonitorQuick = async (url: string, protocol: string, organizationId: string): Promise<ProtectedMonitorDetailsResponse> => {
    const response = await this.client.post('/v1/protected/monitor/quick', {
      url,
      protocol,
      organizationId,
    });

    return response.data;
  };

  protectedPatchMonitors = async (organizationId: string, monitorIds: string[], enabled: boolean): Promise<ProtectedMonitorDetailsResponse[]> => {
    const response = await this.client.patch('/v1/protected/monitor', {
      organizationId,
      monitorIds,
      enabled,
    });

    return response.data;
  };

  protectedDeleteMonitors = async (organizationId: string, monitorIds: string[]): Promise<void> => {
    await this.client.delete('/v1/protected/monitor', {
      data: {
        organizationId,
        monitorIds,
      },
    });
  };

  protectedGetMonitor = async (id: string): Promise<ProtectedMonitorDetailsResponse> => {
    const response = await this.client.get(`/v1/protected/monitor/${id}`);

    return response.data;
  };

  protectedPatchMonitor = async (id: string, request: MonitorFormModel): Promise<ProtectedMonitorDetailsResponse> => {
    const response = await this.client.patch(`/v1/protected/monitor/${id}`, request);

    return response.data;
  };

  protectedGetMonitorChecks = async (id: string): Promise<MonitorChecksResponse> => {
    const response = await this.client.get(`/v1/protected/monitor/${id}/monitor-checks`);

    return response.data;
  };

  protectedGetMonitorCheck = async (id: string): Promise<MonitorCheckResponse> => {
    const response = await this.client.get(`/v1/protected/monitor-checks/${id}`);

    return response.data;
  };

  protectedPostIntegration = async (integration: IntegrationFormModel): Promise<IntegrationResponse> => {
    const response = await this.client.post('/v1/protected/notification-integrations', {
      name: integration.name,
      type: integration.type,
      emailOptions: integration.emailOptions,
      slackOptions: integration.slackOptions,
      smsOptions: integration.smsOptions,
      microsoftTeamsOptions: integration.microsoftTeamsOptions,
      pushoverOptions: integration.pushoverOptions,
      organizationId: integration.organizationId,
    });

    return response.data;
  };

  protectedPostUserProfile = async (request: CompleteUserProfileVO): Promise<void> => {
    await this.client.post('/v1/protected/user/profile', request);
  };

  protectedGetNotificationIntegrations = async ({
    organizationId,
    monitorId,
    monitorGroupId,
  }: {
    organizationId?: string;
    monitorId?: string;
    monitorGroupId?: string;
  }): Promise<IntegrationResponse[]> => {
    const response = await this.client.get('/v1/protected/notification-integrations', {
      params: {
        organizationId,
        monitorId,
        monitorGroupId,
      },
    });

    return response.data.integrations;
  };

  protectedGetNotificationIntegration = async (id: string): Promise<IntegrationResponse> => {
    const response = await this.client.get(`/v1/protected/notification-integrations/${id}`);

    return response.data;
  };

  protectedGetMonitorStatistics = async (
    monitorId: string,
    timeframe: MonitorStatisticsTimeframe,
    window: MonitorStatisticsWindow
  ): Promise<MonitorStatisticsVO[]> => {
    const response = await this.client.get(
      `/v1/protected/monitor/${monitorId}/statistics?type=AVG_RESPONSE_TIME&type=MIN_RESPONSE_TIME&type=MAX_RESPONSE_TIME&window=${window}&timeframe=${timeframe}&order=asc`
    );

    return response.data.statistics;
  };

  protectedGetStatistics = async (request: GetDowntimeStatisticsVO): Promise<DowntimeStatisticsVO[]> => {
    const response = await this.client.get(`/v1/protected/monitor-statistics`, { params: request });

    return response.data.downtimeStatistics;
  };

  protectedGetPeriodicMonitorDowntimeStatistics = async (
    request: GetMonitorPeriodDowntimeStatisticsVO
  ): Promise<ProtectedPeriodicMonitorDowntimeStatisticsResponse> => {
    const response = await this.client.get(`/v1/protected/monitor-statistics/downtime-periodic`, { params: request });

    return response.data;
  };

  protectedGetPeriodicMonitorResponseTimeStatistics = async (
    request: GetMonitorPeriodDowntimeStatisticsVO
  ): Promise<PeriodicMonitorResponseTimeStatisticsResponse> => {
    const response = await this.client.get(`/v1/protected/monitor-statistics/response-time-periodic`, { params: request });

    return response.data;
  };

  protectedGetNotificationsHistory = async (request: ProtectedGetIncidentsRequest): Promise<NotificationsHistoryResponse> => {
    const response = await this.client.get(`/v1/protected/notification-history`, {
      params: prepareRequestParams(request),
    });

    return response.data;
  };

  protectedGetNotificationChannelOptions = async (organizationId: string, types: string[] = []): Promise<NotificationChannelOptionVO[]> => {
    const response = await this.client.get(`/v1/protected/notification-channel/options`, {
      params: { organizationId, ...(types.length > 0 ? { types: types.join(',') } : {}) },
    });

    return response.data.notificationChannels;
  };

  protectedGetNotificationPolicies = async (organizationId: string): Promise<NotificationPolicy[]> => {
    const response = await this.client.get(`/v1/protected/notification-policy`, {
      params: { organizationId },
    });

    return response.data;
  };

  protectedGetNotificationPolicy = async (id: string): Promise<NotificationPolicyModelVO> => {
    const response = await this.client.get(`/v1/protected/notification-policy/${id}`);

    return response.data;
  };

  protectedGetNotificationChannelAssignments = async (entity: NotificationChannelAssignmentEntity): Promise<NotificationChannelAssignment[]> => {
    const response = await this.client.get(`/v1/protected/notification-channel/assignments`, {
      params: entity,
    });

    return response.data;
  };

  protectedGetNotificationHistory = async (notificationId: string): Promise<NotificationHistoryResponse> => {
    const response = await this.client.get(`/v1/protected/notification-history/${notificationId}`);

    return response.data;
  };

  protectedGetIncidents = async (request: GetIncidentsRequest): Promise<{ hasNextPage: boolean; incidents: IncidentWithMetadata[] }> => {
    const response = await this.client.get(`/v1/protected/incidents`, {
      params: prepareRequestParams(request),
    });

    return response.data;
  };

  protectedGetMonitorGroups = async (organizationId: string): Promise<MonitorGroupResponse[]> => {
    const response = await this.client.get<MonitorGroupsResponse>(`/v1/protected/monitor-groups`, {
      params: { organizationId },
    });

    return response.data.monitorGroups;
  };

  protectedGetStatusPages = async (organizationId: string): Promise<StatusPage[]> => {
    const response = await this.client.get<StatusPage[]>(`/v1/protected/status-pages`, {
      params: { organizationId },
    });

    return response.data;
  };

  protectedGetMonitorGroup = async (id: string): Promise<MonitorGroupResponse> => {
    const response = await this.client.get(`/v1/protected/monitor-groups/${id}`);

    return response.data;
  };

  protectedGetStatusPage = async (id: string): Promise<StatusPageModelVO> => {
    const response = await this.client.get(`/v1/protected/status-pages/${id}`);

    return response.data;
  };

  protectedPatchMonitorGroup = async (id: string, request: UpdateMonitorGroupVO): Promise<MonitorGroupResponse> => {
    const response = await this.client.patch(`/v1/protected/monitor-groups/${id}`, request);

    return response.data;
  };

  protectedGetIncident = async (id: string): Promise<IncidentResponse> => {
    try {
      const response = await this.client.get(`/v1/protected/incidents/${id}`);

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }

      throw e;
    }
  };

  protectedPostVerifyNotificationIntegration = async (id: string, verificationToken: string): Promise<IntegrationResponse> => {
    try {
      const response = await this.client.post(`/v1/protected/notification-integration/${id}/verify`, {
        verificationToken,
      });

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }

      throw e;
    }
  };

  protectedPatchNotificationIntegration = async (id: string, request: UpdateNotificationIntegrationVO): Promise<IntegrationResponse> => {
    const response = await this.client.patch(`/v1/protected/notification-integrations/${id}`, request);

    return response.data;
  };

  protectedDeleteMonitor = async (monitorId: string): Promise<void> => {
    await this.client.delete(`/v1/protected/monitor/${monitorId}`);
  };

  protectedDeleteMonitorGroup = async (monitorGroupId: string): Promise<void> => {
    await this.client.delete(`/v1/protected/monitor-groups/${monitorGroupId}`);
  };

  protectedDeleteNotificationIntegration = async (id: string): Promise<void> => {
    await this.client.delete(`/v1/protected/notification-integrations/${id}`);
  };

  protectedGetLogout = async (): Promise<void> => {
    await this.client.get(`/v1/protected/logout`);
  };

  publicPostSignUp = async (request: SignUpRequest): Promise<void> => {
    await this.client.post(`/v1/public/sign-up`, request);
  };

  publicPostRequestResetPassword = async (email: string): Promise<void> => {
    await this.client.post(`/v1/public/reset-password`, { email });
  };

  publicPostResetPassword = async (token: string, password: string): Promise<void> => {
    await this.client.post(`/v1/public/reset-password/${token}`, { password });
  };

  publicPostVerifyEmailAddress = async (token: string): Promise<void> => {
    await this.client.post(`/v1/public/verify-email-address`, { token });
  };

  publicPostLogin = async (request: PublicPostLoginRequest): Promise<void> => {
    await this.client.post(`/v1/public/login`, request);
  };

  privatePostRunMonitorCheck = async (monitorId: string, times: string, ignoreInProgress: boolean): Promise<void> => {
    await this.client.post(`/v1/private/monitor/${monitorId}/run-monitor-check?times=${times}&ignoreInProgress=${ignoreInProgress}`);
  };

  privateGetUsers = async (query: string): Promise<UserResponse[]> => {
    const response = await this.client.get(`/v1/private/user?query=${query}`);

    return response.data;
  };

  protectedGetOrganizationUsers = async (organizationId: string): Promise<OrganizationUserResponse[]> => {
    const response = await this.client.get(`/v1/protected/organization/${organizationId}/users`);

    return response.data.users;
  };

  protectedGetCron = async (expression: string, timezone: string): Promise<string[]> => {
    const response = await this.client.get(`/v1/protected/cron`, {
      params: { expression, timezone },
    });

    return response.data;
  };

  protectedPostOnCallScheduleCalendarEvents = async (
    startAt: string,
    endAt: string,
    request: OnCallScheduleCalendarEventsRequestVO
  ): Promise<OnCallScheduleCalendarEventVO[]> => {
    const response = await this.client.post(`/v1/protected/on-call-schedule/events`, request, {
      params: { startAt, endAt },
    });

    return response.data;
  };

  protectedGetOnCallScheduleCalendarEvents = async (
    startAt: string,
    endAt: string,
    onCallScheduleId: string
  ): Promise<OnCallScheduleCalendarEventVO[]> => {
    const response = await this.client.get(`/v1/protected/on-call-schedule/${onCallScheduleId}/events`, {
      params: { startAt, endAt },
    });

    return response.data;
  };

  protectedGetMonitorOptions = async (organizationId: string): Promise<MonitorOptionVO[]> => {
    const response = await this.client.get(`/v1/protected/monitor/options`, {
      params: { organizationId },
    });

    return response.data.monitors;
  };

  protectedGetOrganization = async (organizationId: string): Promise<OrganizationResponse> => {
    const response = await this.client.get(`/v1/protected/organization/${organizationId}`);

    return response.data;
  };

  protectedGetStatusPageResourceOptions = async (organizationId: string): Promise<StatusPageResourceOptionVO[]> => {
    const response = await this.client.get(`/v1/protected/status-pages/resources/options`, {
      params: { organizationId },
    });

    return response.data;
  };

  protectedPutStatusPage = async (statusPageId: string, updateRequest: UpdateStatusPageVO): Promise<StatusPageModelVO> => {
    const response = await this.client.put(`/v1/protected/status-pages/${statusPageId}`, updateRequest);

    return response.data;
  };

  protectedPutNotificationPolicy = async (
    notificationPolicyId: string,
    updateRequest: UpdateNotificationPolicyVO
  ): Promise<NotificationPolicyModelVO> => {
    const response = await this.client.put(`/v1/protected/notification-policy/${notificationPolicyId}`, updateRequest);

    return response.data;
  };

  protectedPostNotificationPolicy = async (createRequest: CreateNotificationPolicyVO): Promise<NotificationPolicyModelVO> => {
    const response = await this.client.post(`/v1/protected/notification-policy`, createRequest);

    return response.data;
  };

  protectedPostOrganizationSetup = async (organizationId: string, request: SetupOrganizationVO): Promise<OrganizationResponse> => {
    const response = await this.client.post(`/v1/protected/organization/${organizationId}/setup`, request);

    return response.data;
  };

  protectedPostOrganizationTrial = async (organizationId: string, request: CreateOrganizationTrialVO): Promise<OrganizationResponse> => {
    const response = await this.client.post(`/v1/protected/organization/${organizationId}/trial`, request);

    return response.data;
  };

  protectedPostStatusPage = async (createRequest: CreateStatusPageVO): Promise<StatusPageModelVO> => {
    const response = await this.client.post(`/v1/protected/status-pages`, createRequest);

    return response.data;
  };

  protectedDeleteStatusPage = async (statusPageId: string): Promise<StatusPage> => {
    try {
      const response = await this.client.delete(`/v1/protected/status-pages/${statusPageId}`);

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }

      logError(e);
      throw e;
    }
  };

  privatePostUserImpersonate = async (userId: string): Promise<UserResponse> => {
    const response = await this.client.post(`/v1/private/user/${userId}/impersonate`);

    return response.data;
  };

  privatePostCancelImpersonation = async (): Promise<void> => {
    await this.client.post(`/v1/private/user/cancel-impersonation`);
  };

  protectedGetSubscriptionCustomerPortalSession = async (organizationId: string): Promise<CustomerPortalSessionVO> => {
    try {
      const response = await this.client.get(`/v1/protected/organization/${organizationId}/subscription/customer-portal-session`);

      return response.data;
    } catch (e) {
      logError(e);

      return null;
    }
  };

  protectedGetSubscriptionCheckoutSession = async (organizationId: string, plan: Plan): Promise<StripeCheckoutSessionVO> => {
    try {
      const response = await this.client.get(`/v1/protected/organization/${organizationId}/subscription/checkout-session`, {
        params: {
          plan,
        },
      });

      return response.data;
    } catch (e) {
      logError(e);

      return null;
    }
  };

  protectedDeleteNotificationPolicy = async (notificationPolicyId: string): Promise<void> => {
    await this.client.delete(`/v1/protected/notification-policy/${notificationPolicyId}`);
  };

  protectedPostOnCallSchedule = async (createRequest: CreateOnCallScheduleVO): Promise<OnCallScheduleModelVO> => {
    const response = await this.client.post(`/v1/protected/on-call-schedule`, createRequest);

    return response.data;
  };

  protectedPutOnCallSchedule = async (onCallScheduleId: string, updateRequest: UpdateOnCallScheduleVO): Promise<OnCallScheduleModelVO> => {
    const response = await this.client.put(`/v1/protected/on-call-schedule/${onCallScheduleId}`, updateRequest);

    return response.data;
  };

  protectedDeleteOnCallSchedule = async (onCallScheduleId: string): Promise<void> => {
    await this.client.delete(`/v1/protected/on-call-schedule/${onCallScheduleId}`);
  };

  protectedGetOnCallSchedules = async (organizationId: string): Promise<OnCallScheduleModelVO[]> => {
    const response = await this.client.get(`/v1/protected/on-call-schedule`, {
      params: { organizationId },
    });

    return response.data;
  };

  protectedGetOnCallSchedule = async (id: string): Promise<OnCallScheduleModelVO> => {
    try {
      const response = await this.client.get(`/v1/protected/on-call-schedule/${id}`);

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }

      throw e;
    }
  };

  protectedGetOnCallScheduleActiveOnCallDuty = async (onCallScheduleId: string): Promise<OnCallDuty> => {
    try {
      const response = await this.client.get(`/v1/protected/on-call-schedule/${onCallScheduleId}/active-on-call-duty`);

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }

      throw e;
    }
  };

  protectedGetOnCallDuties = async (organizationId: string): Promise<OnCallDuty[]> => {
    const response = await this.client.get(`/v1/protected/on-call-duty`, {
      params: { organizationId },
    });

    return response.data;
  };

  protectedGetStatusPageIncidents = async (statusPageId: string): Promise<StatusPageIncidentModelVO[]> => {
    const response = await this.client.get(`/v1/protected/status-pages/${statusPageId}/incidents`);

    return response.data;
  };

  protectedGetStatusPageIncident = async (statusPageIncidentId: string): Promise<StatusPageIncidentModelVO> => {
    const response = await this.client.get(`/v1/protected/status-page-incident/${statusPageIncidentId}`);

    return response.data;
  };

  protectedPostStatusPageIncidentUpdate = async (
    statusPageIncidentId: string,
    createRequest: CreateStatusPageIncidentUpdateVO
  ): Promise<StatusPageIncidentUpdate> => {
    try {
      const response = await this.client.post(`/v1/protected/status-page-incident/${statusPageIncidentId}/update`, createRequest);

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }

      throw e;
    }
  };

  protectedPostStatusPageIncident = async (createRequest: CreateStatusPageIncidentVO): Promise<StatusPageIncidentModelVO> => {
    const response = await this.client.post(`/v1/protected/status-page-incident`, createRequest);

    return response.data;
  };

  protectedPatchStatusPageIncident = async (
    statusPageIncidentId: string,
    updateRequest: ProtectedPatchStatusPageIncidentRequest
  ): Promise<StatusPageIncidentModelVO> => {
    try {
      const response = await this.client.patch(`/v1/protected/status-page-incident/${statusPageIncidentId}`, updateRequest);

      return response.data;
    } catch (e: any) {
      if (e.response?.status === 404) {
        return null;
      }

      throw e;
    }
  };

  protectedDeleteStatusPageIncident = async (statusPageIncidentId: string): Promise<void> => {
    await this.client.delete(`/v1/protected/status-page-incident/${statusPageIncidentId}`);
  };
}

const statusPingClient = new StatusPingClient();

export default statusPingClient;
