import React, { useCallback, useEffect, useMemo, useState } from 'react';
import actions from '../app/actions';
import { useAppDispatch, useAppSelector } from '../app/store';
import PageHeader from '../components/page-header/pageHeader';
import Routes from '../utils/routes';
import PageWrapper from '../components/page-wrapper/pageWrapper';
import QuickMonitorCreateForm from '../components/quick-monitor-create-form/quickMonitorCreateForm';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import AppLink from '../components/app-link/appLink';
import { PlanFeature } from '@um/uptime-monitoring-shared';
import { UpgradeModalReason } from '../app/model/upgradeModalReason';
import { useOrganizationDowntimeStatisticsQuery, useOrganizationMonitorsQuery, useSelectedOrganizationIdQuery } from '../query/queries';
import useFeatureQuotaReached from '../hooks/useFeatureQuotaReached';
import MonitorOverviewTable from '../components/monitor-overview-table/monitorOverviewTable';
import MonitorPageHeader from '../components/monitors-page-header/monitorPageHeader';
import FeatureQuotaExceededWarning from '../components/feature-quota-exceeded-warning/featureQuotaExceededWarning';

const MonitorsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    app: { initialized: appInitialized },
  } = useAppSelector((state) => state);

  useEffect(() => {
    return () => {
      dispatch(actions.monitors.setInitialized(false));
    };
  }, []);

  const { data: organizationId } = useSelectedOrganizationIdQuery();
  const { isFetched: monitorsFetched, data: monitors, isLoading: monitorsLoading } = useOrganizationMonitorsQuery(organizationId, 10000);
  const {
    isFetched: statisticsFetched,
    data: downtimeStatistics,
    isLoading: statisticsLoading,
  } = useOrganizationDowntimeStatisticsQuery(organizationId, 10000);
  const initialized = useMemo(() => monitorsFetched && statisticsFetched && appInitialized, [monitorsFetched, statisticsFetched, appInitialized]);
  const monitorsCount = monitors?.length || 0;
  const [quotaReached] = useFeatureQuotaReached(PlanFeature.MONITORS_LIMIT, monitorsCount);
  const [selectedMonitors, setSelectedMonitors] = useState([]);

  const hasMonitors = monitorsCount > 0;
  const showGetStarted = initialized && !hasMonitors;
  const monitorIds = useMemo(() => {
    return (monitors || []).map((monitor) => monitor.id);
  }, [monitors]);

  const onClickCreateMonitor = useCallback(() => {
    if (quotaReached) {
      dispatch(actions.upgrade.showUpgradeModal(UpgradeModalReason.MONITOR_QUOTA));
    } else {
      navigate(Routes.CREATE_MONITOR);
    }
  }, [quotaReached]);

  useEffect(() => {
    const newSelectedMonitors = selectedMonitors.filter((id) => monitorIds.includes(id));

    if (
      selectedMonitors.length === newSelectedMonitors.length &&
      selectedMonitors.every((id) => newSelectedMonitors.includes(id)) &&
      newSelectedMonitors.every((id) => selectedMonitors.includes(id))
    ) {
      return;
    }

    setSelectedMonitors(newSelectedMonitors);
  }, [monitorIds, selectedMonitors, setSelectedMonitors]);

  const onToggleMonitorSelection = useCallback(
    (monitorId: string) => {
      if (selectedMonitors.includes(monitorId)) {
        setSelectedMonitors(selectedMonitors.filter((id) => id !== monitorId));
      } else {
        setSelectedMonitors(selectedMonitors.concat([monitorId]));
      }
    },
    [selectedMonitors]
  );
  const onToggleAllMonitorsSelection = useCallback(() => {
    if (selectedMonitors.length > 0) {
      setSelectedMonitors([]);
    } else {
      setSelectedMonitors((monitors || []).map((monitor) => monitor.id));
    }
  }, [selectedMonitors, monitors]);
  const unselectAll = useCallback(() => setSelectedMonitors([]), [setSelectedMonitors]);

  const allMonitorsSelected = useMemo(() => {
    return selectedMonitors.length === monitorIds.length;
  }, [selectedMonitors, monitorIds]);

  return (
    <Box w="100%">
      <PageHeader title="Monitors">
        {initialized && !showGetStarted && (
          <div>
            <Button colorScheme="blue" onClick={onClickCreateMonitor}>
              Create monitor
            </Button>
          </div>
        )}
      </PageHeader>
      <MonitorPageHeader selectedMonitorIds={selectedMonitors} onActionPerformed={unselectAll} />
      <PageWrapper px={4}>
        <FeatureQuotaExceededWarning used={monitorsCount} feature={PlanFeature.MONITORS_LIMIT} mb={4}>
          You have exceeded your current plan's monitor quota therefore some of your monitors have been paused.
        </FeatureQuotaExceededWarning>
        {initialized && showGetStarted && (
          <Flex flexDirection="column" alignItems="center" position="relative" maxW="container.xs" margin="auto" gap={4}>
            <Text as="h1" fontSize="4xl" align="center" fontWeight="extrabold">
              Create your first monitor
            </Text>
            <Box fontSize="xl" textAlign="justify">
              {
                "Create a monitor which will periodically check your website's status. Get notified when your website goes down and take action before your visitors notice"
              }
            </Box>
            <QuickMonitorCreateForm onMonitorCreated={(monitor) => dispatch(actions.monitors.onMonitorCreated(monitor))} />
            <Divider borderColor="gray.200" />
            <Button as={Link} to={Routes.CREATE_MONITOR} variant="outline" colorScheme="blue" size="md" textDecoration="underline">
              Full monitor form
            </Button>
          </Flex>
        )}
        {(!initialized || hasMonitors) && (
          <MonitorOverviewTable
            allowSelection
            showLoading={!monitorsFetched || !statisticsFetched || monitorsLoading || statisticsLoading}
            onToggleItem={onToggleMonitorSelection}
            onToggleAllItems={onToggleAllMonitorsSelection}
            allItemsSelected={allMonitorsSelected}
            selectedItems={selectedMonitors}
            monitors={monitors}
            downtimeStatistics={downtimeStatistics}
          />
        )}
      </PageWrapper>
    </Box>
  );
};

export default MonitorsPage;
