"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageIncidentStatusPageVisibilityValues = exports.StatusPageIncidentStatusValues = exports.StatusPageIncidentStatusPageVisibility = exports.StatusPageIncidentStatus = void 0;
var StatusPageIncidentStatus;
(function (StatusPageIncidentStatus) {
    StatusPageIncidentStatus["RESOLVED"] = "RESOLVED";
    StatusPageIncidentStatus["DEGRADED"] = "DEGRADED";
    StatusPageIncidentStatus["MONITORING"] = "MONITORING";
    StatusPageIncidentStatus["DOWNTIME"] = "DOWNTIME";
})(StatusPageIncidentStatus = exports.StatusPageIncidentStatus || (exports.StatusPageIncidentStatus = {}));
var StatusPageIncidentStatusPageVisibility;
(function (StatusPageIncidentStatusPageVisibility) {
    StatusPageIncidentStatusPageVisibility["VISIBLE"] = "VISIBLE";
    StatusPageIncidentStatusPageVisibility["HIDDEN"] = "HIDDEN";
})(StatusPageIncidentStatusPageVisibility = exports.StatusPageIncidentStatusPageVisibility || (exports.StatusPageIncidentStatusPageVisibility = {}));
exports.StatusPageIncidentStatusValues = Object.values(StatusPageIncidentStatus);
exports.StatusPageIncidentStatusPageVisibilityValues = Object.values(StatusPageIncidentStatusPageVisibility);
