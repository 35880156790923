"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorGroupStatusValues = exports.MonitorGroupStatus = void 0;
var MonitorGroupStatus;
(function (MonitorGroupStatus) {
    MonitorGroupStatus["NONE"] = "NONE";
    MonitorGroupStatus["UP"] = "UP";
    MonitorGroupStatus["PARTIALLY_FAILING"] = "PARTIALLY_FAILING";
    MonitorGroupStatus["DOWN"] = "DOWN";
    MonitorGroupStatus["ERROR"] = "ERROR";
    MonitorGroupStatus["IN_PROGRESS"] = "IN_PROGRESS";
})(MonitorGroupStatus = exports.MonitorGroupStatus || (exports.MonitorGroupStatus = {}));
exports.MonitorGroupStatusValues = Object.values(MonitorGroupStatus);
