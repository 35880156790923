import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MonitorCheckResponse, ProtectedMonitorDetailsResponse } from '@um/uptime-monitoring-shared';

type SliceType = {
  initialized: boolean;
  monitor: ProtectedMonitorDetailsResponse;
  monitorCheck: MonitorCheckResponse;
};

const initialState: SliceType = {
  initialized: false,
  monitor: null,
  monitorCheck: null,
};

export const monitorCheckDetails = createSlice({
  name: 'monitorCheckDetails',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      return state;
    },
    setMonitor: (state, action: PayloadAction<ProtectedMonitorDetailsResponse>) => {
      state.monitor = action.payload;
      return state;
    },
    setMonitorCheck: (state, action: PayloadAction<MonitorCheckResponse>) => {
      state.monitorCheck = action.payload;
      return state;
    },
    reset: () => {
      return initialState;
    },
  },
});

export default monitorCheckDetails.reducer;
