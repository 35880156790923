import { Card as ChakraCard, CardProps } from '@chakra-ui/react';
import React from 'react';

const Card: React.FC<CardProps> = ({ children, ...rest }) => {
  return (
    <ChakraCard p={4} bg={'white'} boxShadow={'md'} borderRadius={'md'} width={'100%'} {...rest}>
      {children}
    </ChakraCard>
  );
};

export default Card;
