import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useCronExpressionQuery } from '../../query/cronQueries';
import { formatInTimeZone } from 'date-fns-tz';
import { DateFormats } from '../../utils/formatToUtc';

const CronExpressionSchedules = ({ expression, timezone }) => {
  const { isError, data: schedules } = useCronExpressionQuery(expression, timezone);

  return (
    <Flex flexDirection="column" gap={1} color="gray.500">
      {!isError && <Box>Expected check-in times</Box>}
      {!isError ? (
        schedules?.result?.map((schedule) => <Box key={schedule}>{formatInTimeZone(schedule, timezone, DateFormats.FULL_SECONDS_WITH_TZ)}</Box>)
      ) : (
        <Box>Invalid CRON expression</Box>
      )}
    </Flex>
  );
};

export default CronExpressionSchedules;
