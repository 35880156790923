import React, { useState } from 'react';
import IntegrationForm, { IntegrationFormModel } from '../integration-form/integrationForm';
import { IntegrationResponse, IntegrationType } from '@um/uptime-monitoring-shared';
import { operationWrapper } from '../../utils/operationWrapper';
import statusPingClient from '../../app/client/statusPingClient';
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Routes from '../../utils/routes';
import toast from 'react-hot-toast';
import VerifySmsNotificationIntegrationForm from '../verify-sms-notification-integration/verifySmsNotificationIntegrationForm';
import Card from '../card/card';
import { FormMode } from '../../utils/formMode';

export type Props = {
  notificationIntegration: IntegrationResponse;
  onUpdated?: () => any;
};

const EditNotificationIntegrationForm: React.FC<Props> = ({ notificationIntegration, onUpdated }) => {
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (data: IntegrationFormModel) => {
    const updateNotificationIntegration = operationWrapper(
      async () => {
        setInProgress(true);

        await statusPingClient.protectedPatchNotificationIntegration(notificationIntegration.id, {
          name: data.name,
          enabled: data.enabled,
          slackOptions: data.slackOptions,
          pushoverOptions: data.pushoverOptions,
          microsoftTeamsOptions: data.microsoftTeamsOptions,
        });
      },
      {
        successCallback: onUpdated,
        errorCallback: (error) => {
          setError(error);
          setInProgress(false);
        },
      }
    );

    updateNotificationIntegration();
  };

  return (
    <>
      {notificationIntegration.type === IntegrationType.SMS && !notificationIntegration.verified && (
        <>
          <Box p={4} mb={4}>
            <Text fontWeight="bold" fontSize="3xl" mb={4}>
              Verify phone number
            </Text>
            <Card>
              <VerifySmsNotificationIntegrationForm
                integration={notificationIntegration}
                onVerified={() => navigate(Routes.NOTIFICATION_INTEGRATIONS)}
              />
            </Card>
          </Box>
        </>
      )}
      <IntegrationForm formValues={notificationIntegration} disabled={inProgress} onSubmit={handleSubmit} error={error} mode={FormMode.EDIT}>
        {({ onSubmit }) => (
          <Flex justifyContent="center">
            <Button type="submit" isLoading={inProgress} colorScheme="blue" width="min-content" size="lg" onClick={onSubmit}>
              Update integration
            </Button>
          </Flex>
        )}
      </IntegrationForm>
    </>
  );
};

export const DeleteNotificationIntegrationModal = ({ id, open, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDelete = operationWrapper(
    async () => {
      setLoading(true);

      await statusPingClient.protectedDeleteNotificationIntegration(id);
    },
    {
      successCallback: () => {
        toast.success('Notification integration deleted');
        navigate(Routes.NOTIFICATION_INTEGRATIONS);
      },
      errorCallback: () => {
        toast.error('Error occurred. Please try again later');
        onClose();
        setLoading(false);
      },
    }
  );

  return (
    <Modal isOpen={open} onClose={onClose} closeOnOverlayClick={!loading} closeOnEsc={!loading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete notification integration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete this notification integration? This action is not reversible</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleDelete} isLoading={loading} disabled={loading}>
            Delete
          </Button>
          <Button variant="outline" disabled={loading} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditNotificationIntegrationForm;
