import toast from 'react-hot-toast';
import { Icon } from '@chakra-ui/react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import React from 'react';

const informationToast = (message: string) =>
  toast(message, {
    icon: <Icon as={BsExclamationCircleFill} fill="blue.200" />,
  });

export default informationToast;
