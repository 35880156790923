"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifyOnCallScheduleDomainNameExpirationMonitorMessage = exports.NotifyOnCallScheduleSSLCertificateExpirationMonitorMessage = exports.NotifyOnCallScheduleIncidentMessage = exports.NotifyOnCallScheduleMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
const notifyMonitorIntegrationType_1 = require("../model/notifyMonitorIntegrationType");
class NotifyOnCallScheduleMessage extends workerMessage_1.WorkerMessage {
    constructor(type, onCallScheduleId, notifySms, notifyEmail) {
        super(model_1.QueueDefinitions.NOTIFY_ON_CALL_SCHEDULE);
        this.type = type;
        this.onCallScheduleId = onCallScheduleId;
        this.notifySms = notifySms;
        this.notifyEmail = notifyEmail;
    }
}
exports.NotifyOnCallScheduleMessage = NotifyOnCallScheduleMessage;
class NotifyOnCallScheduleIncidentMessage extends NotifyOnCallScheduleMessage {
    constructor(incident, originalIncident, onCallScheduleId, notifySms, notifyEmail) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.INCIDENT, onCallScheduleId, notifySms, notifyEmail);
        this.incident = incident;
        this.originalIncident = originalIncident;
        this.onCallScheduleId = onCallScheduleId;
        this.notifySms = notifySms;
        this.notifyEmail = notifyEmail;
    }
}
exports.NotifyOnCallScheduleIncidentMessage = NotifyOnCallScheduleIncidentMessage;
class NotifyOnCallScheduleSSLCertificateExpirationMonitorMessage extends NotifyOnCallScheduleMessage {
    constructor(monitor, monitorCertificateInformation, onCallScheduleId, notifySms, notifyEmail) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.SSL_CERTIFICATE_EXPIRATION, onCallScheduleId, notifySms, notifyEmail);
        this.monitor = monitor;
        this.monitorCertificateInformation = monitorCertificateInformation;
        this.onCallScheduleId = onCallScheduleId;
        this.notifySms = notifySms;
        this.notifyEmail = notifyEmail;
    }
}
exports.NotifyOnCallScheduleSSLCertificateExpirationMonitorMessage = NotifyOnCallScheduleSSLCertificateExpirationMonitorMessage;
class NotifyOnCallScheduleDomainNameExpirationMonitorMessage extends NotifyOnCallScheduleMessage {
    constructor(monitor, domainWhois, onCallScheduleId, notifySms, notifyEmail) {
        super(notifyMonitorIntegrationType_1.NotifyMonitorIntegrationType.DOMAIN_NAME_EXPIRATION, onCallScheduleId, notifySms, notifyEmail);
        this.monitor = monitor;
        this.domainWhois = domainWhois;
        this.onCallScheduleId = onCallScheduleId;
        this.notifySms = notifySms;
        this.notifyEmail = notifyEmail;
    }
}
exports.NotifyOnCallScheduleDomainNameExpirationMonitorMessage = NotifyOnCallScheduleDomainNameExpirationMonitorMessage;
