import { FormProvider, useForm } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React from 'react';
import { PartialDeep } from 'type-fest';
import formValidationRules from '../../utils/formValidationRules';
import Routes from '../../utils/routes';
import AppLink from '../app-link/appLink';
import { Alert, AlertIcon, Box, Button, Flex, FlexProps } from '@chakra-ui/react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { Icon } from '@chakra-ui/icons';
import Card from '../card/card';

export type SignUpFormModel = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

const defaultValues: PartialDeep<SignUpFormModel> = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
};

type Props = {
  disabled?: boolean;
  loading?: boolean;
  error?: string;
  onSignUpUsingGoogle: () => void;
  onSubmit: (data: SignUpFormModel) => void;
} & Omit<FlexProps, 'onSubmit'>;

const { required, passwordMin6Characters } = formValidationRules;

const SignUpForm = ({ disabled, loading, onSubmit, error, onSignUpUsingGoogle, ...rest }: Props) => {
  const formMethods = useForm<SignUpFormModel>({ defaultValues });
  const { handleSubmit, control } = formMethods;

  return (
    <Flex alignItems="center" minH="100%" justifyContent="center">
      <Card>
        <Flex flexDirection="column" maxW="container.md" w="100%" gap={2}>
          <div>
            <Box as="h2" fontSize="3xl" textAlign="center" fontWeight="bold">
              Sign up
            </Box>
          </div>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <Button w="100%" colorScheme={'red'} size="lg" onClick={onSignUpUsingGoogle} isDisabled={disabled} isLoading={loading}>
            <Icon as={AiOutlineGoogle} boxSize={6} mr={2} />
            Sign up using Google
          </Button>
          <Box textAlign="center" fontSize="lg">
            or using email and password
          </Box>
          <FormProvider {...formMethods}>
            <Flex as="form" flexDirection="column" gap={6} {...rest} onSubmit={handleSubmit(onSubmit)}>
              <Flex flexDirection="column" gap={2}>
                <ControlledInput name="email" control={control} placeholder="Email" type="email" rules={{ required }} />
                <ControlledInput
                  name="password"
                  control={control}
                  placeholder="Password"
                  type="password"
                  rules={{ required, minLength: passwordMin6Characters }}
                />
                <ControlledInput name="firstName" control={control} placeholder="First name" />
                <ControlledInput name="lastName" control={control} placeholder="Last name" />
              </Flex>
              <Button type="submit" size="lg" colorScheme="blue" w="100%" isDisabled={disabled} isLoading={loading}>
                Submit
              </Button>
            </Flex>
          </FormProvider>
          <Flex alignItems="center" justifyContent="center" fontSize="lg">
            <AppLink to={Routes.LOGIN}>Back to login</AppLink>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default SignUpForm;
