import { useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import formValidationRules from '../../utils/formValidationRules';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import { Box } from '@chakra-ui/react';
import { ControlledPhoneNumberInput } from '../phone-number-input/phoneNumberInput';
import useTimezoneCountry from '../../hooks/useTimezoneCountry';

const { required } = formValidationRules;

const SmsOptionsFormFields = ({ disabled }) => {
  const { control, unregister } = useFormContext();

  const phoneNumberFieldPath = `smsOptions.phoneNumber`;
  const defaultCountry = useTimezoneCountry();

  useEffect(() => {
    return () => {
      unregister(phoneNumberFieldPath);
    };
  }, []);

  return (
    <FormFieldWrapper title="Phone number" description={<Box>We'll send a message to verify your phone number</Box>}>
      <ControlledPhoneNumberInput
        name={phoneNumberFieldPath}
        control={control}
        placeholder="Phone number"
        rules={{ required }}
        isDisabled={disabled}
        countryCode={defaultCountry}
      />
    </FormFieldWrapper>
  );
};

export default SmsOptionsFormFields;
