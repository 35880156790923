"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageUpdatedMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class StatusPageUpdatedMessage extends workerMessage_1.WorkerMessage {
    constructor(originalStatusPage, updatedStatusPage) {
        super(model_1.QueueDefinitions.STATUS_PAGE_UPDATED);
        this.originalStatusPage = originalStatusPage;
        this.updatedStatusPage = updatedStatusPage;
    }
}
exports.StatusPageUpdatedMessage = StatusPageUpdatedMessage;
