import React, { useMemo, useRef } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Badge, Box, Button, Flex, Icon, Link as ChakraLink, VStack } from '@chakra-ui/react';
import { useOrganizationStatusPagesQuery } from '../../query/queries';
import Routes from '../../utils/routes';
import { StatusPage, StatusPageIncidentBadgeStyles, StatusPageIncidentModelVO } from '@um/uptime-monitoring-shared';
import { BsChevronRight } from 'react-icons/bs';
import StatusPageStatusIcon from '../status-page-status-icon/statusPageStatusIcon';
import { AppConfig } from '../../utils/config';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import AppLink from '../app-link/appLink';
import DataTable from '../data-table/dataTable';
import { useStatusPageIncidentsQuery } from '../../query/statusPageIncidentQueries';
import { format, formatInTimeZone } from 'date-fns-tz';
import { DateFormats } from '../../utils/formatToUtc';
import StatusPageIncidentStatusBadge from '../status-page-incident-status-badge/statusPageIncidentStatusBadge';
import useStatusPageVisibilityText from '../../hooks/useStatusPageVisibilityText';

interface Props {
  statusPageId?: string;
}

type StatusPageIncidentRowProps = {
  statusPageIncident: StatusPageIncidentModelVO;
};

const StatusPageIncidentsTable: React.FC<Props> = ({ statusPageId }) => {
  const tableRef = useRef();
  const { isFetched, data } = useStatusPageIncidentsQuery(statusPageId);
  const statusPageIncidents = data?.result;
  const noStatusPageIncidents = isFetched && statusPageIncidents?.length < 1;

  return (
    <Box ref={tableRef}>
      <DataTable showLoading={!isFetched}>
        {() => (
          <>
            {!noStatusPageIncidents && (
              <>
                <DataTable.Head>
                  <DataTable.HeaderRow>
                    <DataTable.HeaderCell>Status</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Resources</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Visibility</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Started at</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Resolved at</DataTable.HeaderCell>
                  </DataTable.HeaderRow>
                </DataTable.Head>
                <DataTable.Body>
                  {isFetched &&
                    statusPageIncidents.map((statusPage) => <StatusPageIncidentRow key={statusPage.id} statusPageIncident={statusPage} />)}
                </DataTable.Body>
              </>
            )}
            {noStatusPageIncidents && (
              <DataTable.Body>
                <DataTable.Row>
                  <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                    <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                      <Box>There are no status page incidents yet.</Box>
                      <Box>
                        <Button
                          as={Link}
                          to={generatePath(Routes.CREATE_STATUS_PAGE_INCIDENT, { statusPageId })}
                          variant="outline"
                          colorScheme="blue"
                          size="md"
                          textDecoration="underline"
                        >
                          Create one now
                        </Button>
                      </Box>
                    </Flex>
                  </DataTable.Cell>
                </DataTable.Row>
              </DataTable.Body>
            )}
          </>
        )}
      </DataTable>
    </Box>
  );
};

const StatusPageIncidentRow: React.FC<StatusPageIncidentRowProps> = ({ statusPageIncident }) => {
  const href = useMemo(
    () =>
      generatePath(Routes.STATUS_PAGE_INCIDENT, {
        statusPageId: statusPageIncident.statusPageId,
        statusPageIncidentId: statusPageIncident.id,
      }),
    []
  );
  const { status, resources, startedAt, resolvedAt, timezone, statusPageVisibility } = statusPageIncident;
  const statusPageVisibilityText = useStatusPageVisibilityText(statusPageVisibility);

  return (
    <DataTable.LinkRow key={statusPageIncident.id} to={href}>
      <DataTable.Cell overflow="visible" tooltip={null}>
        <StatusPageIncidentStatusBadge status={status} />
      </DataTable.Cell>
      <DataTable.Cell>{resources?.length || 0}</DataTable.Cell>
      <DataTable.Cell>{statusPageVisibilityText}</DataTable.Cell>
      <DataTable.Cell overflow="visible">{formatInTimeZone(startedAt, timezone, DateFormats.FULL_SECONDS_WITH_TZ)}</DataTable.Cell>
      <DataTable.Cell overflow="visible">
        {resolvedAt ? formatInTimeZone(resolvedAt, timezone, DateFormats.FULL_SECONDS_WITH_TZ) : '-'}
      </DataTable.Cell>
    </DataTable.LinkRow>
  );
};

export default StatusPageIncidentsTable;
