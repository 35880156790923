import { createContext } from 'react';

export type DataTableContextState = {
  allowSelection?: boolean;
  onToggleItem?: (itemId: string) => void;
  onToggleAllItems?: () => void;
  selectedItems?: string[];
  allItemsSelected?: boolean;
};

const initialState: DataTableContextState = {
  allowSelection: false,
};

export const DataTableContext = createContext<DataTableContextState>(initialState);
