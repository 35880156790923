import React, { useState } from 'react';
import { operationWrapper } from '../utils/operationWrapper';
import SignUpForm, { SignUpFormModel } from '../components/sign-up-form/signUpForm';
import Routes from '../utils/routes';
import statusPingClient from '../app/client/statusPingClient';
import toast from 'react-hot-toast';
import { dismissibleToast } from '../utils/dismissibleToast';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const onSignup = async (data: SignUpFormModel) => {
    const login = operationWrapper(
      async () => {
        setLoading(true);
        setDisabled(true);

        await statusPingClient.publicPostSignUp(data);
      },
      {
        successCallback: () => {
          setLoading(false);

          toast.success(
            dismissibleToast(`You've signed up successfully! We have sent instructions on how to activate your account to your email address`),
            { duration: 9999999 }
          );

          navigate(Routes.LOGIN);
        },
        errorCallback: (e: any) => {
          setLoading(false);
          setDisabled(false);

          const errorMessage = e?.response?.data?.message;
          setError(errorMessage || 'Error occurred please try again later');
        },
      }
    );

    await login();
  };

  const onSignUpUsingGoogle = async () => {
    try {
      setLoading(true);
      setDisabled(true);

      window.location.href = Routes.GOOGLE_AUTH;
    } catch (e: any) {
      setLoading(false);
      setDisabled(false);

      const errorMessage = e?.response?.data?.message;
      setError(errorMessage || 'Error occurred please try again later');
    }
  };
  return (
    <Flex minH="100vh" alignItems="center" justifyContent="center" py={12} px={4} backgroundColor="gray.200">
      <Flex maxW="container.xs" width="100%" flexDirection="column" gap={6}>
        <Box as="img" mx={'auto'} w={'auto'} maxHeight="64px" src="/logo.png" alt="Status Ping" />
        <SignUpForm onSubmit={onSignup} onSignUpUsingGoogle={onSignUpUsingGoogle} loading={loading} disabled={disabled} error={error} />
      </Flex>
    </Flex>
  );
};

export default SignUpPage;
