import { FormProvider, useForm } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React, { FormHTMLAttributes, useEffect, useLayoutEffect, useState } from 'react';
import { FormSelect } from '../form-controller/formController';
import { PartialDeep } from 'type-fest';
import { FormFieldError } from '@um/uptime-monitoring-shared';
import { Box, Button, StackDivider, Text, VStack } from '@chakra-ui/react';
import Card from '../card/card';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import formValidationRules from '../../utils/formValidationRules';
import { ControlledMonitorSelect } from '../monitor-select/monitorSelect';
import NotificationChannelsFormFields from '../notification-channels-form-fields/notificationChannelsFormFields';
import { ControlledToggle } from '../toggle/toggle';
import { MonitorGroupFormModel, MonitorGroupFormModelDefaultValues } from './monitorGroupFormModel';

type Props = {
  formValues?: PartialDeep<MonitorGroupFormModel>;
  mainActionText: string;
  disabled: boolean;
  onSubmit: (data: MonitorGroupFormModel) => void;
  formErrors?: FormFieldError[];
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>;

const { required } = formValidationRules;

const MonitorGroupForm = ({
  formValues = MonitorGroupFormModelDefaultValues,
  mainActionText,
  className,
  disabled,
  onSubmit,
  formErrors,
  ...rest
}: Props) => {
  const formMethods = useForm<MonitorGroupFormModel>({ defaultValues: formValues });
  const [formErrorsProcessed, setFormErrorsProcessed] = useState(0);
  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = formMethods;
  const { monitorIds, notifyOfStatusChange } = watch();

  useEffect(() => {
    if (formErrors && formErrors.length > 0) {
      for (const formError of formErrors) {
        setError(formError.dataPath as any, {
          type: 'manual',
          message: formError.message,
        });
      }

      setFormErrorsProcessed(formErrorsProcessed + 1);
    }
  }, [formErrors]);

  useLayoutEffect(() => {
    const erroneousElement = document.querySelector('.ui.error');

    if (erroneousElement) {
      erroneousElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formErrorsProcessed]);

  const submit = (data: MonitorGroupFormModel) => {
    onSubmit(data);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={className} {...rest} onSubmit={handleSubmit(submit)}>
        <VStack spacing={4}>
          <Card>
            <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
              <FormFieldWrapper title="Name" description="User friendly name that will help you easily identify this group">
                <ControlledInput name="name" control={control} placeholder="Name" rules={{ required }} isDisabled={disabled} />
              </FormFieldWrapper>
              <FormFieldWrapper title="Description">
                <ControlledInput name="description" control={control} placeholder="Description" isDisabled={disabled} />
              </FormFieldWrapper>
              <FormFieldWrapper title="Monitors" description="Select monitors that should be in this group">
                <FormSelect name="monitorIds" rules={{ required }} control={control}>
                  {(field) => (
                    <ControlledMonitorSelect
                      isMulti
                      placeholder="Monitors"
                      value={monitorIds}
                      error={errors.monitorIds}
                      isDisabled={disabled}
                      {...field}
                    />
                  )}
                </FormSelect>
              </FormFieldWrapper>
            </VStack>
          </Card>

          <Card>
            <Text fontWeight="bold" fontSize="3xl" mb={4}>
              Notification Settings
            </Text>
            <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
              <FormFieldWrapper title="Notify of status changes" description="If enabled you will be notified when monitor group status changes">
                <ControlledToggle w="100%" name="notifyOfStatusChange" control={control} />
              </FormFieldWrapper>
              {notifyOfStatusChange && <NotificationChannelsFormFields />}
              {notifyOfStatusChange && (
                <FormFieldWrapper
                  title="Suppress individual monitor notifications"
                  leftColumnFlexSize="4"
                  description={
                    <VStack spacing={2}>
                      <Box>
                        If enabled then the monitors in this group will not trigger notifications. Instead only monitor group notifications will be
                        sent.
                      </Box>
                      <Box>Enable this if you are more interested in status of all monitors in the group rather than specific monitors.</Box>
                    </VStack>
                  }
                >
                  <ControlledToggle w="100%" name="suppressIndividualMonitorNotifications" control={control} />
                </FormFieldWrapper>
              )}
            </VStack>
          </Card>
          <Button type="submit" disabled={disabled} colorScheme="blue" width="min-content" size="lg">
            {mainActionText}
          </Button>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default MonitorGroupForm;
