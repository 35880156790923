import { FormProvider, useForm, useWatch } from 'react-hook-form';
import React, { FormHTMLAttributes, useEffect } from 'react';
import { FormSelect } from '../form-controller/formController';
import { PartialDeep } from 'type-fest';
import IntegrationTypeSelect from '../integration-type-select/integrationTypeSelect';
import EmailOptionsFormFields from './emailOptionsFormFields';
import { ControlledInput } from '../input/input';
import formValidationRules from '../../utils/formValidationRules';
import SlackOptionsFormFields from './slackOptionsFormFields';
import {
  ErrorResponse,
  IntegrationType,
  MicrosoftTeamsIntegrationOptions,
  PushoverIntegrationOptions,
  SMSIntegrationOptions,
} from '@um/uptime-monitoring-shared';
import { Box, StackDivider, Text, VStack } from '@chakra-ui/react';
import { ControlledToggle } from '../toggle/toggle';
import { fillFormErrors } from '../../hooks/fillFormErrors';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import Card from '../card/card';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import SmsOptionsFormFields from './smsOptionsFormFields';
import { FormMode } from '../../utils/formMode';
import MicrosoftTeamsOptionsFormFields from './microsoftTeamsOptionsFormFields';
import { onSubmitWrapper } from '../../utils/onSubmitWrapper';
import PushoverOptionsFormFields from './pushoverOptionsFormFields';

export type IntegrationFormModel = {
  name: string;
  type: IntegrationType;
  emailOptions?: {
    recipient: string;
  };
  slackOptions?: {
    webhookUrl: string;
  };
  microsoftTeamsOptions?: MicrosoftTeamsIntegrationOptions;
  smsOptions?: SMSIntegrationOptions;
  pushoverOptions?: PushoverIntegrationOptions;
  enabled: boolean;
  organizationId?: string;
};

const defaultValues: PartialDeep<IntegrationFormModel> = {
  type: null,
  enabled: true,
};

type ChildrenProps = {
  onSubmit: () => void;
};

type Props = Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
  formValues?: PartialDeep<IntegrationFormModel>;
  disabled: boolean;
  mode?: FormMode;
  children?: React.FC<ChildrenProps>;
  error?: AxiosError<ErrorResponse>;
  onSubmit: (data: IntegrationFormModel) => void;
  showCard?: boolean;
};

const { required } = formValidationRules;

const IntegrationForm: React.FC<Props> = ({
  formValues = defaultValues,
  disabled,
  mode,
  onSubmit,
  error,
  children,
  showCard = true,
  ...rest
}: Props) => {
  const formMethods = useForm<IntegrationFormModel>({
    defaultValues: formValues,
  });
  const {
    handleSubmit,
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    const errorResponse = error?.response?.data;

    if (errorResponse && errorResponse.errorType !== 'VALIDATION_FAILED') {
      toast.error('Error occurred. Please try again later');
    } else if (errorResponse && errorResponse.errorType === 'VALIDATION_FAILED') {
      if (errorResponse.message) {
        toast.error(errorResponse.message);
      }

      fillFormErrors(error?.response?.data, { setError, clearErrors });
    }
  }, [error]);

  const { type } = getValues();
  const { type: watchedType } = useWatch({ control });
  const submitHandler = handleSubmit(onSubmit);
  const disableModify = mode === FormMode.EDIT;
  const WrapperComponent = showCard ? Card : Box;

  return (
    <Box px={4}>
      <FormProvider {...formMethods}>
        <VStack spacing={4} align="stretch">
          <WrapperComponent width="100%">
            <form {...rest} onSubmit={onSubmitWrapper(submitHandler)}>
              <VStack spacing={4} divider={<StackDivider borderColor="gray.200" />} align="stretch">
                <FormFieldWrapper title="Name">
                  <ControlledInput name="name" type="text" control={control} placeholder="Name" rules={{ required }} />
                </FormFieldWrapper>
                <FormFieldWrapper title="Integration type" description="Type represents the notification channel where notifications will be sent">
                  <FormSelect name="type" rules={{ required }} control={control}>
                    {(field) => (
                      <IntegrationTypeSelect
                        isDisabled={disableModify}
                        label="Integration type"
                        value={type}
                        error={errors.type?.message}
                        {...field}
                      />
                    )}
                  </FormSelect>
                </FormFieldWrapper>
                <FormFieldWrapper title="Enabled" description="While notification integration is disabled we will not send any notifications to it">
                  <ControlledToggle containerProps={{ width: '100%' }} name="enabled" control={control} />
                </FormFieldWrapper>
              </VStack>
            </form>
          </WrapperComponent>
          {watchedType && (
            <>
              <Text fontWeight="bold" fontSize="3xl" mb={4}>
                Integration configuration
              </Text>
              <WrapperComponent width="100%">
                <Box>
                  <div>
                    {watchedType === IntegrationType.EMAIL && <EmailOptionsFormFields disabled={disableModify} />}
                    {watchedType === IntegrationType.SLACK && <SlackOptionsFormFields />}
                    {watchedType === IntegrationType.MICROSOFT_TEAMS && <MicrosoftTeamsOptionsFormFields />}
                    {watchedType === IntegrationType.SMS && <SmsOptionsFormFields disabled={disableModify} />}
                    {watchedType === IntegrationType.PUSHOVER && <PushoverOptionsFormFields />}
                  </div>
                </Box>
              </WrapperComponent>
            </>
          )}
          <Box>{children && children({ onSubmit: submitHandler })}</Box>
        </VStack>
      </FormProvider>
    </Box>
  );
};

export default IntegrationForm;
