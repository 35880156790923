import { Organization } from '../../app/model/organization';
import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import mutationDefaults from '../../query/mutationDefaults';
import { useStatusPageIncidentMutation } from '../../query/statusPageIncidentQueries';
import { MutationAction } from '../../query/mutationAction';
import StatusPageIncidentForm from '../status-page-incident-form/statusPageIncidentForm';
import { CreateStatusPageIncidentVO } from '@um/uptime-monitoring-shared';
import { StatusPageIncidentFormModel, StatusPageIncidentFormModelDefaultValues } from '../../models/statusPageIncidentFormModel';
import { useStatusPageQuery } from '../../query/statusPageQueries';
import useDefaultUserTimezone from '../../hooks/useDefaultUserTimezone';

export type CreateStatusPageIncidentUpdateFormProps = {
  statusPageId: string;
  onCreated?: (project?: Organization) => any;
  onError?: (err?: any) => any;
};

const CreateStatusPageIncidentForm: React.FC<CreateStatusPageIncidentUpdateFormProps> = ({ statusPageId, onCreated, onError }) => {
  const [formErrors, setFormErrors] = useState([]);
  const { isLoading, mutateAsync } = useStatusPageIncidentMutation({
    onSuccess: (data) => {
      toast.success('Status page incident created');

      onCreated && onCreated(data);
    },
    onError: mutationDefaults.onErrorWithFormHandling(setFormErrors, onError),
  });
  const defaultTimezone = useDefaultUserTimezone();
  const handleSubmit = async (data: StatusPageIncidentFormModel) => {
    const createRequest: CreateStatusPageIncidentVO = {
      ...data,
      statusPageId,
      startedAt: new Date().toISOString(),
    };
    await mutateAsync({ createRequest, action: MutationAction.POST });
  };
  const { data: statusPage, isFetched: statusPageFetched } = useStatusPageQuery(statusPageId);
  const statusPageResources = useMemo(() => {
    if (!statusPageFetched) {
      return null;
    }

    return (statusPage?.sections || []).reduce(
      (sectionsAgg, section) => sectionsAgg.concat((section.entries || []).reduce((agg, entry) => agg.concat(entry), [])),
      []
    );
  }, [statusPageFetched, statusPage]);

  const formValues = useMemo(() => {
    if (!statusPageFetched) {
      return null;
    }

    return {
      ...StatusPageIncidentFormModelDefaultValues,
      timezone: defaultTimezone?.name,
      resources: statusPageResources.map((item) => ({ statusPageResourceId: item.id, status: null })),
    };
  }, [statusPageFetched]);

  return (
    <StatusPageIncidentForm
      mainActionText="Create incident"
      formValues={formValues}
      statusPageResources={statusPageResources}
      disabled={isLoading}
      formErrors={formErrors}
      onSubmit={handleSubmit}
    />
  );
};

export default CreateStatusPageIncidentForm;
