import { MonitorTransport } from '@um/uptime-monitoring-shared';
import { Tag } from '@chakra-ui/react';

type Props = {
  transport: MonitorTransport;
};

const tagText = {
  [MonitorTransport.HTTP]: 'HTTP',
  [MonitorTransport.ICMP_PING]: 'Ping',
  [MonitorTransport.CHECK_IN]: 'Check-in',
};

const MonitorTransportLabel: React.FC<Props> = ({ transport }) => {
  if (!transport) {
    return null;
  }

  return <Tag fontWeight="bold">{tagText[transport]}</Tag>;
};

export default MonitorTransportLabel;
