"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExchangeBindings = exports.ExchangeBinding = void 0;
const queueDefinitions_1 = require("./queueDefinitions");
class ExchangeBinding {
    constructor(sourceExchangeName, destinationExchangeName) {
        this.sourceExchangeName = sourceExchangeName;
        this.destinationExchangeName = destinationExchangeName;
    }
    static fromQueueToQueue(source, destination) {
        return new ExchangeBinding(source.exchangeName, destination.exchangeName);
    }
}
exports.ExchangeBinding = ExchangeBinding;
exports.ExchangeBindings = [
    ExchangeBinding.fromQueueToQueue(queueDefinitions_1.QueueDefinitions.INCIDENT_CREATED, queueDefinitions_1.QueueDefinitions.INCIDENT_CREATED_STATUS_PAGE_INCIDENT),
    ExchangeBinding.fromQueueToQueue(queueDefinitions_1.QueueDefinitions.INCIDENT_UPDATED, queueDefinitions_1.QueueDefinitions.INCIDENT_UPDATED_STATUS_PAGE_INCIDENT),
];
