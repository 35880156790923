import { applicationEventEmitter } from '../app/saga/websocket/websocket';
import { ApplicationEventType, MonitorCheckResponse, WebsocketApplicationEventMessage } from '@um/uptime-monitoring-shared';
import { logError } from '../utils/logError';
import queryKeys from './queryKeys';
import { QueryInvalidator } from './queryInvalidatorContext';

const handleQueryClientWebsocketEvents = (queryInvalidator: QueryInvalidator) => {
  applicationEventEmitter.addListener('message', (message: WebsocketApplicationEventMessage) => {
    try {
      if (ApplicationEventType.MONITOR_CREATED === message.messageType) {
        const { organizationId } = message.payload;

        queryInvalidator.invalidateQueries(queryKeys.organizationMonitors(organizationId));
        queryInvalidator.invalidateQueries(queryKeys.organizationMonitorStatistics(organizationId));
      } else if (ApplicationEventType.MONITOR_UPDATED === message.messageType) {
        const { monitorId, organizationId } = message.payload;

        queryInvalidator.invalidateQueries(queryKeys.monitor(monitorId));
        queryInvalidator.invalidateQueries(queryKeys.organizationMonitors(organizationId));
      } else if ([ApplicationEventType.MONITOR_CHECK_CREATED, ApplicationEventType.MONITOR_CHECK_UPDATED].includes(message.messageType)) {
        const monitorCheck = message.payload as MonitorCheckResponse;

        queryInvalidator.invalidateQueries(['monitor-checks', 'monitor', monitorCheck.monitorId]);
      } else if (ApplicationEventType.STATUS_PAGE_INCIDENT_UPDATED === message.messageType) {
        const { statusPageId, statusPageIncidentId } = message.payload;

        queryInvalidator.invalidateQueries(queryKeys.statusPageIncidents(statusPageId));
        queryInvalidator.invalidateQueries(queryKeys.statusPageIncident(statusPageIncidentId));
      } else if (ApplicationEventType.STATUS_PAGE_INCIDENT_CREATED === message.messageType) {
        const { statusPageId, statusPageIncidentId } = message.payload;

        queryInvalidator.invalidateQueries(queryKeys.statusPageIncidents(statusPageId));
        queryInvalidator.invalidateQueries(queryKeys.statusPageIncident(statusPageIncidentId));
      } else if (ApplicationEventType.INCIDENT_CREATED === message.messageType) {
        const { incidentId } = message.payload;

        queryInvalidator.invalidateQueries(queryKeys.incident(incidentId));
        queryInvalidator.invalidateQueries(queryKeys.incidents());
      } else if (ApplicationEventType.INCIDENT_UPDATED === message.messageType) {
        const { incidentId } = message.payload;

        queryInvalidator.invalidateQueries(queryKeys.incident(incidentId));
        queryInvalidator.invalidateQueries(queryKeys.incidents());
      }
    } catch (e) {
      logError(e);
    }
  });
};

export default handleQueryClientWebsocketEvents;
