import React, { useCallback, useEffect, useState } from 'react';
import actions from '../../app/actions';
import PageWrapper from '../../components/page-wrapper/pageWrapper';
import PageHeader from '../../components/page-header/pageHeader';
import Routes from '../../utils/routes';
import { generatePath, Outlet, useMatch, useNavigate, useOutlet, useOutletContext, useParams } from 'react-router-dom';
import { FallbackWrapper } from '../../components/fallback/fallback';
import AppLink from '../../components/app-link/appLink';
import { Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useMonitorGroupMutation, useMonitorGroupQuery } from '../../query/queries';
import MonitorGroupOverviewTab from '../../components/monitor-group-overview-tab/monitorGroupOverviewTab';
import EditMonitorGroupTab from '../../components/edit-monitor-group-tab/editMonitorGroupTab';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { BsGear } from 'react-icons/bs';
import DeleteConfirmationModal from '../../components/delete-confirmation-modal/deleteConfirmationModal';
import toast from 'react-hot-toast';
import { MutationAction } from '../../query/mutationAction';

export const OVERVIEW_SLUG = 'overview';
export const SETTINGS_SLUG = 'settings';

const MonitorGroupPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const monitorGroupId = params.id ? params.id : null;
  const [paneIndex, setPaneIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const { data: monitorGroup, isLoading, isError } = useMonitorGroupQuery(monitorGroupId, { enabled: !deleteInProgress });
  const { mutateAsync } = useMonitorGroupMutation();
  const overviewPaneActive = useMatch(Routes.MONITOR_DETAILS_OVERVIEW);
  const settingsPaneActive = useMatch(Routes.MONITOR_SETTINGS);

  const handleDelete = useCallback(async () => {
    setDeleteInProgress(true);

    await mutateAsync(
      { monitorGroupId, action: MutationAction.DELETE },
      {
        onSuccess: () => {
          toast.success('Monitor group deleted');
          navigate(Routes.MONITOR_GROUPS);
        },
        onError: () => {
          toast.error('Error occurred. Please try again later');
          setDeleteInProgress(false);
        },
      }
    );
  }, [monitorGroupId]);

  const panes = [
    {
      menuItem: 'Overview',
      slug: OVERVIEW_SLUG,
    },
    {
      menuItem: 'Settings',
      slug: SETTINGS_SLUG,
    },
    {
      noContent: true,
      slug: 'actions',
    },
  ];

  useEffect(() => {
    if (!outlet) {
      navigate(
        generatePath(Routes.MONITOR_GROUP_OVERVIEW, {
          id: monitorGroupId,
        }),
        { replace: true }
      );
    }
  }, [outlet]);

  useEffect(() => {
    if (overviewPaneActive) {
      setPaneIndex(panes.findIndex((pane) => pane.slug === OVERVIEW_SLUG));
    } else if (settingsPaneActive) {
      setPaneIndex(panes.findIndex((pane) => pane.slug === SETTINGS_SLUG));
    }
  }, [overviewPaneActive, settingsPaneActive]);

  const handleTabChange = (index: number) => {
    const pane = panes[index];

    if (pane.noContent) {
      return;
    }

    setPaneIndex(index as number);

    const slug = pane?.slug || OVERVIEW_SLUG;

    if (slug === OVERVIEW_SLUG) {
      navigate(
        generatePath(Routes.MONITOR_GROUP_OVERVIEW, {
          id: monitorGroupId,
        }),
        { replace: true }
      );
    } else if (slug === SETTINGS_SLUG) {
      navigate(
        generatePath(Routes.MONITOR_GROUP_SETTINGS, {
          id: monitorGroupId,
        }),
        { replace: true }
      );
    }
  };

  const breadcrumbs = [{ title: 'Monitor groups', route: Routes.MONITOR_GROUPS }];

  return (
    <Flex flexDirection="column" w="100%">
      <PageHeader loading={isLoading} title={monitorGroup?.name} breadcrumbs={monitorGroup ? breadcrumbs : []} />
      <PageWrapper loading={isLoading}>
        <FallbackWrapper
          showFallback={isError}
          title="Monitor group not found"
          subtitle={
            <>
              Go back to <AppLink to={Routes.MONITOR_GROUPS}>Monitor groups</AppLink>
            </>
          }
        >
          <DeleteConfirmationModal
            title="Delete monitor group"
            content="Are you sure you want to delete this monitor group? This action is not reversible"
            onConfirm={handleDelete}
            onClose={() => setShowDeleteModal(false)}
            loading={deleteInProgress}
            open={showDeleteModal}
          />
          <Tabs isLazy index={paneIndex} onChange={handleTabChange}>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Settings</Tab>
              <Flex justifyContent="flex-end" flexGrow={1}>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    <Icon as={BsGear} boxSize={6} fontWeight="bold" />
                  </MenuButton>
                  <MenuList>
                    <MenuItem color="red" fontWeight="bold" onClick={() => setShowDeleteModal(true)}>
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Outlet context={{ monitorGroupId }} />
              </TabPanel>
              <TabPanel>
                <Outlet context={{ monitorGroupId }} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </FallbackWrapper>
      </PageWrapper>
    </Flex>
  );
};

export const MonitorGroupDetailsOverviewOutlet = () => {
  const { monitorGroupId } = useOutletContext<{ monitorGroupId: string }>();

  return <MonitorGroupOverviewTab monitorGroupId={monitorGroupId} />;
};

export const MonitorGroupDetailsSettingsOutlet = () => {
  const { monitorGroupId } = useOutletContext<{ monitorGroupId: string }>();

  return <EditMonitorGroupTab monitorGroupId={monitorGroupId} />;
};

export default MonitorGroupPage;
