"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusPageUpdatedApplicationEvent = void 0;
const applicationEvent_1 = require("./applicationEvent");
class StatusPageUpdatedApplicationEvent extends applicationEvent_1.ApplicationEvent {
    constructor(statusPageId) {
        super();
        this.statusPageId = statusPageId;
    }
    getMessageType() {
        return applicationEvent_1.ApplicationEventType.STATUS_PAGE_UPDATED;
    }
    getExchangeName() {
        return applicationEvent_1.ApplicationEventExchangeName.STATUS_PAGE_UPDATED;
    }
}
exports.StatusPageUpdatedApplicationEvent = StatusPageUpdatedApplicationEvent;
