import React, { useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Portal,
} from '@chakra-ui/react';

type FullModalProps<T> = {
  open: boolean;
  context: T;
  onClose: () => void;
};

type UseDynamicModalArguments<T> = {
  fullModal?: (props: FullModalProps<T>) => React.ReactNode;
  modalContent?: (context: T) => React.ReactNode;
  render?: (context: T) => React.ReactNode;
  title?: string;
  size?: string;
} & Partial<ModalProps>;

const useDynamicModal = <T,>({ render, modalContent, title, fullModal, size = 'xl', ...rest }: UseDynamicModalArguments<T>) => {
  const [open, setOpen] = useState(false);
  const [context, setContext] = useState<T>(null);
  const onClose = () => setOpen(false);

  const modal = (
    <Portal>
      {fullModal ? (
        fullModal({ onClose, context, open })
      ) : (
        <Modal isOpen={open} onClose={onClose} size={size} {...rest}>
          <ModalOverlay />
          <ModalContent>
            {open && modalContent && modalContent(context)}
            {open && render && (
              <>
                {title && <ModalHeader>{title}</ModalHeader>}
                <ModalCloseButton />
                <ModalBody>{render(context)}</ModalBody>

                <ModalFooter>
                  <Flex alignItems="center" justifyContent="center" width="100%">
                    <Button colorScheme="gray" onClick={onClose}>
                      Close
                    </Button>
                  </Flex>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </Portal>
  );

  return { modal, setOpen, setContext };
};

export default useDynamicModal;
