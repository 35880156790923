import { Box, Flex, FlexProps, Icon, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';

type Props = FlexProps & {
  title?: React.ReactNode;
  description?: React.ReactNode;
  leftColumnFlexSize?: string;
  columnLayout?: boolean;
  infoTooltip?: React.ReactNode;
};

const FormFieldWrapper: React.FC<Props> = ({
  title,
  description,
  children,
  leftColumnFlexSize = '2',
  columnLayout = false,
  infoTooltip,
  maxWidth = '100%',
  ...rest
}) => {
  return (
    <Flex width="100%" gap={2} flexDirection={{ base: 'column', md: columnLayout ? 'column' : 'row' }} {...rest}>
      <Flex flexDirection="column" flex={leftColumnFlexSize} gap={2}>
        {title && (
          <Flex alignItems="center" gap={2} fontSize="lg" color="black" fontWeight="bold">
            {title}
            {infoTooltip && (
              <Tooltip label={infoTooltip}>
                <Flex alignItems="center" justifyContent="center">
                  <Icon as={BsQuestionCircleFill} boxSize={4} fill="gray.400" fontWeight="bold" />
                </Flex>
              </Tooltip>
            )}
          </Flex>
        )}
        {description && <Text color="gray.500">{description}</Text>}
      </Flex>
      <Box flex={{ base: 2, md: 3 }}>
        <Box maxWidth={maxWidth}>{children}</Box>
      </Box>
    </Flex>
  );
};

export default FormFieldWrapper;
