"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PushoverPriorityValues = exports.PushoverNumericPriority = exports.PushoverPriority = void 0;
var PushoverPriority;
(function (PushoverPriority) {
    PushoverPriority["DISABLED"] = "DISABLED";
    PushoverPriority["LOWEST"] = "LOWEST";
    PushoverPriority["LOW"] = "LOW";
    PushoverPriority["NORMAL"] = "NORMAL";
    PushoverPriority["HIGH"] = "HIGH";
    PushoverPriority["EMERGENCY"] = "EMERGENCY";
})(PushoverPriority = exports.PushoverPriority || (exports.PushoverPriority = {}));
exports.PushoverNumericPriority = {
    [PushoverPriority.LOWEST]: -2,
    [PushoverPriority.LOW]: -1,
    [PushoverPriority.NORMAL]: 0,
    [PushoverPriority.HIGH]: 1,
    [PushoverPriority.EMERGENCY]: 2,
};
exports.PushoverPriorityValues = Object.values(PushoverPriority);
