import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MonitorStatisticsTimeframe, MonitorStatisticsVO, MonitorStatisticsWindow } from '@um/uptime-monitoring-shared';

type SliceType = {
  initialized: boolean;
  loading: boolean;
  monitorId: string;
  statistics: MonitorStatisticsVO[];
  dygraphStatistics: any[][];
  timeframe: MonitorStatisticsTimeframe;
  window: MonitorStatisticsWindow;
};

const initialState: SliceType = {
  initialized: false,
  loading: false,
  monitorId: null,
  statistics: [],
  dygraphStatistics: [],
  timeframe: MonitorStatisticsTimeframe.WEEK,
  window: MonitorStatisticsWindow.FIFTEEN_MINUTES,
};

export const monitorResponseStatistics = createSlice({
  name: 'monitorResponseStatistics',
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
      return state;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      return state;
    },
    setMonitorId: (state, action: PayloadAction<string>) => {
      state.monitorId = action.payload;
      return state;
    },
    setTimeframe: (state, action: PayloadAction<MonitorStatisticsTimeframe>) => {
      state.timeframe = action.payload;
      return state;
    },
    setWindow: (state, action: PayloadAction<MonitorStatisticsWindow>) => {
      state.window = action.payload;
      return state;
    },
    setStatistics: (state, action: PayloadAction<MonitorStatisticsVO[]>) => {
      state.statistics = action.payload;
      return state;
    },
    setDygraphStatistics: (state, action: PayloadAction<any[][]>) => {
      state.dygraphStatistics = action.payload;
      return state;
    },
  },
});

export default monitorResponseStatistics.reducer;
