import { FormProvider, useForm } from 'react-hook-form';
import { ControlledInput } from '../input/input';
import React, { FormHTMLAttributes, useEffect } from 'react';
import { PartialDeep } from 'type-fest';
import { Button, Flex } from '@chakra-ui/react';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import Card from '../card/card';

export type OrganizationFormModel = {
  name: string;
};

const defaultValues: PartialDeep<OrganizationFormModel> = {
  name: '',
};

type Props = {
  formValues?: PartialDeep<OrganizationFormModel>;
  mainActionText: string;
  disabled?: boolean;
  loading?: boolean;
  onSubmit: (data: OrganizationFormModel) => void;
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>;

const required = { value: true, message: 'This field is required' };

const OrganizationForm = ({ formValues = defaultValues, mainActionText, disabled, loading, onSubmit }: Props) => {
  const formMethods = useForm<OrganizationFormModel>({
    defaultValues: formValues,
  });
  const { handleSubmit, control } = formMethods;

  useEffect(() => {
    formMethods.reset(formValues);
  }, [formValues]);

  return (
    <FormProvider {...formMethods}>
      <Card>
        <Flex as="form" gap={4} flexDirection="column" onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDirection="column" gap={4}>
            <FormFieldWrapper title="Organization name">
              <ControlledInput name="name" control={control} placeholder="Organization name" rules={{ required }} />
            </FormFieldWrapper>
          </Flex>
          <Button type="submit" colorScheme="blue" alignSelf="flex-end" w={32} disabled={disabled} isLoading={loading}>
            {mainActionText}
          </Button>
        </Flex>
      </Card>
    </FormProvider>
  );
};

export default OrganizationForm;
