import { MutationOptions, useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import statusPingClient from '../app/client/statusPingClient';
import { CreateStatusPageVO, OrganizationUserResponse, UserResponse } from '@um/uptime-monitoring-shared';
import { MutationAction } from './mutationAction';
import { UserProfileFormModel } from '../components/user-profile-form/userProfileForm';
import mutationDefaults from './mutationDefaults';
import { QueryResultWithHash, toQueryResultWithHash } from '../models/queryResultWithHash';

export interface UserProfileMutationParameters {
  updateRequest?: UserProfileFormModel;
  createRequest?: CreateStatusPageVO;
  action: MutationAction;
}

export const useUserProfileMutation = (options: MutationOptions<any, any, any> = {}) => {
  const queryClient = useQueryClient();
  return useMutation<UserResponse, any, UserProfileMutationParameters>(
    async ({ updateRequest, action }: UserProfileMutationParameters) => {
      if (action === MutationAction.PATCH) {
        const [user] = await Promise.all([
          statusPingClient.protectedPatchUser({
            firstName: updateRequest.firstName,
            lastName: updateRequest.lastName,
            phoneNumber: updateRequest.phoneNumber,
            timezone: updateRequest.timezone,
          }),
          statusPingClient.protectedPatchUserSettings({
            subscribedToWeeklyMonitorReport: updateRequest.subscribedToWeeklyMonitorReport,
            subscribedToProductNewsletter: updateRequest.subscribedToProductNewsletter,
          }),
        ]);

        return user;
      }

      return null;
    },
    {
      onError: mutationDefaults.onError,
      ...options,
      onSuccess: (data: UserResponse, variables, context) => {
        const id = data?.id;

        if (id && variables.action !== MutationAction.DELETE) {
          queryClient.setQueryData(['status-pages', id], data);
        } else if (id) {
          queryClient.invalidateQueries(['status-pages', id]);
        }

        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
};

export const useOrganizationUsersQuery = (organizationId: string, options: UseQueryOptions<QueryResultWithHash<OrganizationUserResponse[]>> = {}) => {
  return useQuery(
    ['users', 'organization', organizationId],
    () => toQueryResultWithHash(statusPingClient.protectedGetOrganizationUsers(organizationId)),
    {
      ...options,
      cacheTime: 60000,
      refetchOnMount: true,
      keepPreviousData: true,
      enabled: Boolean(organizationId) && options.enabled !== false,
    }
  );
};
