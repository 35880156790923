"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendWeeklyMonitorReportMessage = void 0;
const workerMessage_1 = require("./workerMessage");
const model_1 = require("../model");
class SendWeeklyMonitorReportMessage extends workerMessage_1.WorkerMessage {
    constructor(userId, organizationId) {
        super(model_1.QueueDefinitions.SEND_WEEKLY_MONITOR_REPORT);
        this.userId = userId;
        this.organizationId = organizationId;
    }
}
exports.SendWeeklyMonitorReportMessage = SendWeeklyMonitorReportMessage;
