import { Box, BoxProps, Button, ButtonProps, Flex } from '@chakra-ui/react';
import React from 'react';

type PageControlsProps = BoxProps & {
  tabs?: React.ReactNode;
  leftChildren?: React.ReactNode;
};

const Tab: React.FC<ButtonProps> = ({ ...rest }) => {
  return <Button variant="outline" colorScheme="gray" borderBottom="none" borderBottomRadius={0} bor {...rest} />;
};

type PageControlsType = React.FC<PageControlsProps> & {
  Tab: typeof Tab;
};

const PageControls: PageControlsType = ({ tabs, children, leftChildren = null, ...rest }) => {
  return (
    <Box mb={4} pr={4} borderBottom="2px solid" borderColor="inherit" {...rest}>
      <Flex justifyContent="space-between" pl={4}>
        <Flex gap={4}>
          {tabs && (
            <Flex gap={2} flexGrow={1} alignItems="flex-end">
              {tabs}
            </Flex>
          )}
          {leftChildren && (
            <Flex pb={2} gap={2}>
              {leftChildren}
            </Flex>
          )}
        </Flex>
        <Flex pb={2} pl={4} gap={2}>
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};

PageControls.Tab = Tab;

export default PageControls;
