import { useFormContext } from 'react-hook-form';
import React, { useMemo } from 'react';
import { StatusPageIncidentStatusPageVisibility } from '@um/uptime-monitoring-shared';
import { ControlledFormSelector } from '../form-select/formSelector';

const options = [
  { label: 'Visible', value: StatusPageIncidentStatusPageVisibility.VISIBLE },
  { label: 'Hidden', value: StatusPageIncidentStatusPageVisibility.HIDDEN },
];

const ControlledStatusPageIncidentStatusPageVisibilitySelector = ({ name, rules = null, disabled = false }) => {
  const { control, watch } = useFormContext();
  const value = watch(name);
  const selectedOption = useMemo(() => options.find((option) => option.value === value), [options, value]);

  return (
    <ControlledFormSelector
      control={control}
      name={name}
      options={options}
      value={selectedOption}
      transformOption={(option) => option.value}
      isDisabled={disabled}
      rules={rules}
    />
  );
};

export default ControlledStatusPageIncidentStatusPageVisibilitySelector;
