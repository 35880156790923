import { differenceInHours } from 'date-fns';
import { logError } from './logError';

export enum DomainNameCheckStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  VALID = 'VALID',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED',
}

const getDomainNameCheckStatus = (domainNameExpiryDate: string): DomainNameCheckStatus => {
  if (!domainNameExpiryDate) {
    return DomainNameCheckStatus.NOT_CHECKED;
  }

  try {
    const parsedExpiryDate = new Date(domainNameExpiryDate);
    const now = new Date();
    const difference = differenceInHours(parsedExpiryDate, now);

    if (difference > 14 * 24) {
      return DomainNameCheckStatus.VALID;
    } else if (difference > 0 && difference <= 14 * 24) {
      return DomainNameCheckStatus.EXPIRES_SOON;
    }

    return DomainNameCheckStatus.EXPIRED;
  } catch (e) {
    logError(e);
    return DomainNameCheckStatus.NOT_CHECKED;
  }
};

export default getDomainNameCheckStatus;
