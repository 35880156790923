import React, { useMemo, useRef, useState } from 'react';
import NotificationIntegrationIcon from '../notifications-overview-tab/notificationIntegrationIcon';
import Routes from '../../utils/routes';
import { DateFormats, formatToUtc } from '../../utils/formatToUtc';
import { generatePath } from 'react-router-dom';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { BsChevronRight } from 'react-icons/bs';
import getNotificationHistoryReasonText from '../../utils/getNotificationHistoryReasonText';
import getNotificationHistoryStatusText from '../../utils/getNotificationHistoryStatusText';
import { useSelectedOrganizationNotificationHistoryQuery } from '../../query/notificationsQueries';
import DataTable from '../data-table/dataTable';
import useDynamicModal from '../../hooks/useDynamicModal';
import NotificationDetails from '../notification-details/notificationDetails';

const NotificationHistoryTable = ({ organizationId }) => {
  const tableRef = useRef();
  const [previousPages, setPreviousPages] = useState([]);
  const [pageStartDate, setPageStartDate] = useState(null);
  const { data, isLoading, isFetching, isInitialLoading, isRefetching, isFetched } = useSelectedOrganizationNotificationHistoryQuery({
    organizationId,
    start: pageStartDate,
  });
  const hasNextPage = useMemo(() => {
    return data?.hasNextPage;
  }, [data]);
  const showLoading = (isFetching || isRefetching) && !isFetched;

  const notifications = data?.notificationsHistory;

  const handleNextPageClick = () => {
    const lastItem = notifications[notifications.length - 1];

    setPreviousPages([...previousPages, { pageStartDate }]);
    setPageStartDate(lastItem.createdAt);
  };

  const handlePreviousPageClick = () => {
    const lastPreviousPage = previousPages[previousPages.length - 1];

    setPreviousPages(previousPages.slice(0, previousPages.length - 1));
    setPageStartDate(lastPreviousPage.pageStartDate);
  };

  const hasPreviousPages = previousPages.length > 0;
  const noNotifications = notifications?.length < 1;
  const {
    modal,
    setOpen: setModalOpen,
    setContext: setModalContext,
  } = useDynamicModal<{ notificationId: string }>({
    modalContent: ({ notificationId }) => <NotificationDetails notificationId={notificationId} onClose={() => setModalOpen(false)} />,
  });

  return (
    <div ref={tableRef}>
      {modal}
      <DataTable
        showLoading={isInitialLoading}
        showPagination={!noNotifications}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPages}
        onNextPageClick={handleNextPageClick}
        onPreviousPageClick={handlePreviousPageClick}
        columnsCount={7}
        paginationDisabled={showLoading}
      >
        {() => (
          <>
            {!noNotifications && (
              <>
                <DataTable.Head>
                  <DataTable.HeaderRow>
                    <DataTable.HeaderCell>Type</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Monitor/Group</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Integration</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Date</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Reason</DataTable.HeaderCell>
                    <DataTable.HeaderCell>Status</DataTable.HeaderCell>
                    <DataTable.HeaderCell />
                  </DataTable.HeaderRow>
                </DataTable.Head>
                <DataTable.Body showLoading={showLoading}>
                  {!isLoading &&
                    notifications.map((item) => (
                      <DataTable.LinkRow
                        key={item.id}
                        onClick={() => {
                          setModalContext({ notificationId: item.id });
                          setModalOpen(true);
                        }}
                        to={generatePath(Routes.NOTIFICATION_DETAILS, { id: item.id })}
                      >
                        <DataTable.Cell tooltip={null}>
                          <NotificationIntegrationIcon type={item.notificationIntegrationType} />
                        </DataTable.Cell>
                        <DataTable.Cell>{item.monitorName || item.monitorGroupName || '-'}</DataTable.Cell>
                        <DataTable.Cell>{item.notificationIntegrationName}</DataTable.Cell>
                        <DataTable.Cell>{formatToUtc(new Date(item.createdAt), DateFormats.FULL_MINUTES)}</DataTable.Cell>
                        <DataTable.Cell>{getNotificationHistoryReasonText(item.reason)}</DataTable.Cell>
                        <DataTable.Cell>{getNotificationHistoryStatusText(item.status)}</DataTable.Cell>
                        <DataTable.Cell tooltip={null}>
                          <Icon as={BsChevronRight} />
                        </DataTable.Cell>
                      </DataTable.LinkRow>
                    ))}
                </DataTable.Body>
              </>
            )}
            {noNotifications && (
              <DataTable.Body>
                <DataTable.Row>
                  <DataTable.Cell overflow="visible" maxWidth="100%" tooltip={null}>
                    <Flex w="100%" alignItems="center" justifyContent="center" py={4} fontSize="xl" flexDirection="column" gap={4}>
                      <Box>Once there are any notifications they will be displayed here.</Box>
                    </Flex>
                  </DataTable.Cell>
                </DataTable.Row>
              </DataTable.Body>
            )}
          </>
        )}
      </DataTable>
    </div>
  );
};

export default NotificationHistoryTable;
