"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationEntityValues = exports.VerificationTokenTypeValues = exports.VerificationEntity = exports.VerificationTokenType = void 0;
var VerificationTokenType;
(function (VerificationTokenType) {
    VerificationTokenType["RESET_PASSWORD_TOKEN"] = "RESET_PASSWORD_TOKEN";
    VerificationTokenType["VERIFY_EMAIL_ADDRESS"] = "VERIFY_EMAIL_ADDRESS";
    VerificationTokenType["VERIFY_PHONE_NUMBER"] = "VERIFY_PHONE_NUMBER";
    VerificationTokenType["LOGIN_LINK"] = "LOGIN_LINK";
})(VerificationTokenType = exports.VerificationTokenType || (exports.VerificationTokenType = {}));
var VerificationEntity;
(function (VerificationEntity) {
    VerificationEntity["NOTIFICATION_INTEGRATION"] = "NOTIFICATION_INTEGRATION";
})(VerificationEntity = exports.VerificationEntity || (exports.VerificationEntity = {}));
exports.VerificationTokenTypeValues = Object.values(VerificationTokenType);
exports.VerificationEntityValues = Object.values(VerificationEntity);
