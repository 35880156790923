import DataCard from '../data-card/dataCard';
import { Box, Button, Flex, Grid, Icon, IconButton, StackDivider, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { ControlledTextArea } from '../text-area/textArea';
import { onSubmitWrapper } from '../../utils/onSubmitWrapper';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useStatusPageQuery } from '../../query/statusPageQueries';
import { useStatusPageIncidentQuery } from '../../query/statusPageIncidentQueries';
import { StatusPageIncidentUpdateFormModel } from '../../models/statusPageIncidentUpdateFormModel';
import Spinner from '../spinner/spinner';
import { ControlledStatusPageIncidentStatusSelector } from '../status-page-incident-status-selector/statusPageIncidentStatusSelector';
import {
  FormFieldError,
  StatusPageIncidentStatus,
  StatusPageIncidentUpdateResourceStatus,
  StatusPageSectionEntryVO,
} from '@um/uptime-monitoring-shared';
import orderBy from 'lodash.orderby';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BsTrash2Fill } from 'react-icons/bs';
import { ControlledFormSelector } from '../form-select/formSelector';
import FormFieldWrapper from '../form-field-wrapper/formFieldWrapper';
import Card from '../card/card';
import StatusPageIncidentStatusBadge from '../status-page-incident-status-badge/statusPageIncidentStatusBadge';
import ControlledStatusPageIncidentResourceStatusSelector from '../status-page-incident-resource-status-selector/statusPageIncidentResourceStatusSelector';

type Props = {
  mainActionText: string;
  statusPageId: string;
  statusPageIncidentId: string;
  disabled?: boolean;
  formErrors?: FormFieldError[];
  onSubmit: (data: StatusPageIncidentUpdateFormModel) => void;
};

const StatusPageIncidentUpdateForm: React.FC<Props> = ({ mainActionText, statusPageId, statusPageIncidentId, disabled, onSubmit, formErrors }) => {
  const { data: statusPage, isFetched: statusPageFetched } = useStatusPageQuery(statusPageId);
  const { data: statusPageIncident, isFetched: statusPageIncidentFetched } = useStatusPageIncidentQuery(statusPageIncidentId);
  const formMethods = useForm<StatusPageIncidentUpdateFormModel>();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = formMethods;
  const fetched = statusPageFetched && statusPageIncidentFetched;

  const submit = (data: StatusPageIncidentUpdateFormModel) => {
    onSubmit(data);

    reset(data, { keepDirty: false });
  };

  const statusPageResources = useMemo(() => {
    if (!fetched) {
      return null;
    }

    return (statusPage?.sections || []).reduce(
      (sectionsAgg, section) => sectionsAgg.concat((section.entries || []).reduce((agg, entry) => agg.concat(entry), [])),
      []
    );
  }, [fetched, statusPage]);
  const statusPageIncidentResources = useMemo(() => {
    if (!fetched) {
      return null;
    }

    return orderBy(statusPageIncident?.result?.resources || [], ['statusPageResourceId']);
  }, [fetched, statusPageIncident?.hash]);
  const resourceStatusesField = useFieldArray({
    control: formMethods.control,
    name: 'resourceStatuses',
  });

  useEffect(() => {
    if (formErrors && formErrors.length > 0) {
      for (const formError of formErrors) {
        formMethods.setError(formError.dataPath as any, {
          type: 'manual',
          message: formError.message,
        });
      }
    }
  }, [formErrors]);

  useEffect(() => {
    if (isDirty || !fetched || !statusPageResources || !statusPageIncidentResources) {
      return;
    }

    const resourceStatuses: StatusPageIncidentUpdateResourceStatus[] = statusPageIncidentResources
      .filter((resource) => statusPageResources.find((item) => item.id === resource.statusPageResourceId))
      .map((resource) => ({
        statusPageResourceId: resource.statusPageResourceId,
        status: null,
      }));

    reset({
      incidentStatus: null,
      message: '',
      statusPageIncidentId,
      resourceStatuses,
    });
  }, [fetched, statusPageResources, statusPageIncidentResources]);

  const incidentStatus = watch('incidentStatus');

  if (!fetched) {
    return <Spinner />;
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmitWrapper(handleSubmit(submit))}>
        <Card>
          <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
            <FormFieldWrapper title="Incident status" description="Status of the incident">
              <ControlledStatusPageIncidentStatusSelector control={control} name="incidentStatus" isDisabled={disabled} />
              {incidentStatus === StatusPageIncidentStatus.RESOLVED && (
                <Box mt={2} fontSize="md" color="gray.500">
                  All resource statuses will be resolved as well
                </Box>
              )}
            </FormFieldWrapper>
            <FormFieldWrapper title="Resource statuses" description="Statuses of individual resources affected by this incident">
              {incidentStatus === StatusPageIncidentStatus.RESOLVED ? (
                <Box mt={2} fontSize="md" color="gray.500">
                  All resource statuses will be set to <StatusPageIncidentStatusBadge status={StatusPageIncidentStatus.RESOLVED} />
                </Box>
              ) : (
                <ResourceStatusesFormFields
                  statusPageResources={statusPageResources}
                  resourceStatusesField={resourceStatusesField}
                  disabled={disabled}
                />
              )}
            </FormFieldWrapper>
            <FormFieldWrapper title="Message" description="Update message to be displayed to status page visitors">
              <ControlledTextArea w="100%" name="message" control={control} placeholder="Update message" isDisabled={disabled} />
            </FormFieldWrapper>
          </VStack>
          <Flex justifyContent="center" width="100%" mt={8}>
            <Button type="submit" colorScheme="blue" width="min-content" isDisabled={disabled}>
              {mainActionText}
            </Button>
          </Flex>
        </Card>
      </form>
    </FormProvider>
  );
};

const ResourceStatusesFormFields = ({ statusPageResources, resourceStatusesField, disabled = false }) => {
  const { control } = useFormContext<StatusPageIncidentUpdateFormModel>();

  return (
    <Flex flexDirection="column" gap={2} maxWidth="500px">
      <Grid gridTemplateColumns="1fr 1fr min-content" gap={2} alignItems="center">
        {resourceStatusesField.fields.map((field, idx) => {
          return (
            <React.Fragment key={`${field.statusPageResourceId}-${idx}`}>
              <ControlledStatusPageIncidentResourceStatusSelector
                statusPageResources={statusPageResources}
                name={`resourceStatuses[${idx}].statusPageResourceId`}
                disabled={disabled}
              />
              <Box>
                <ControlledStatusPageIncidentStatusSelector control={control} name={`resourceStatuses[${idx}].status`} isDisabled={disabled} />
              </Box>
              <IconButton
                colorScheme="red"
                aria-label="Delete resource status change"
                icon={<Icon as={BsTrash2Fill} boxSize={6} />}
                size="sm"
                onClick={() => resourceStatusesField.remove(idx)}
                disabled={disabled}
              />
            </React.Fragment>
          );
        })}
      </Grid>
      {resourceStatusesField.fields.length < statusPageResources.length && (
        <Button
          colorScheme="blue"
          variant="outline"
          w="100%"
          onClick={() => resourceStatusesField.append({ statusPageResourceId: null, status: null })}
          isDisabled={disabled}
        >
          <Flex alignItems="center" gap={1}>
            <Icon boxSize={6} as={AiFillPlusCircle} />
            <Box fontSize="xl">Add resource</Box>
          </Flex>
        </Button>
      )}
    </Flex>
  );
};

export default StatusPageIncidentUpdateForm;
